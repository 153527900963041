import { BranchFilter } from 'src/common/components/BranchFilter/BranchFilter';
import { CustomDatePicker } from 'src/common/components/CustomDatePicker/CustomDatePicker';
import { Layout, Pagination, Row, Typography } from 'antd';
import { NotificationComponent } from './NotifivationComponent/NotificationComponent';
import { useGetNotificationsQuery } from 'src/app/services/api';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';
import { minutesTimezoneOffset } from '../../common/utils/utils';

const disabledDate = (current: Dayjs) => {
  return current && current.startOf('d') > dayjs().startOf('d');
};

export const NotificationPage = () => {
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [selectedBranchId, setSelectedBranchId] = useState<string>('');
  const [currentDate, setCurrentDate] = useState(dayjs());

  const { data: notifications, refetch } = useGetNotificationsQuery({
    branchId: selectedBranchId,
    offset: pageOffset,
    count: pageCount,
    asOfDate: currentDate.startOf('day').toISOString(),
    sortType: '',
  });

  const handleBranchSelect = (value: string) => {
    setSelectedBranchId(value);
  };

  const handleDateChange = (selectedDate: Dayjs | null) => {
    if (selectedDate) {
      setCurrentDate(selectedDate);
    }
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };

  const handleNotificationViewed = () => {
    // refetch();
  };

  return (
    <Layout className={styles.NotificationWrapper}>
      <Typography className={styles.Title}>Уведомления</Typography>
      <Row className={styles.FilterRow}>
        <BranchFilter value={selectedBranchId} onChange={handleBranchSelect} />
        <CustomDatePicker value={currentDate} onChange={handleDateChange} disabledDate={disabledDate} />
      </Row>
      <Layout className={styles.NotificationScroll}>
        {!notifications?.data.length ? (
          <Typography className={styles.EmptyNotificationsText}>Отсутствуют уведомления на выбранную дату</Typography>
        ) : (
          notifications?.data.map((notification) => (
            <NotificationComponent
              key={notification.idNotification}
              notification={notification}
              onViewed={handleNotificationViewed}
            />
          ))
        )}
      </Layout>
      <Pagination
        current={pageNumber}
        total={notifications?.total}
        hideOnSinglePage={true}
        pageSize={pageCount}
        showSizeChanger={true}
        onChange={handlePageChange}
      />
    </Layout>
  );
};
