import { useEffect } from "react";
import styles from './styles.module.scss';

const renderMicroFrontend = (name: string, history: any) => {
    // @ts-ignore
    window[`render${name}`](`${name}-container`, history);
};

export const getUrl = (val: any) => {
    let url = val;
    try {
        url = (new URL(val))?.href;
    } catch {
        try {
            url = (new URL(`${window.location.origin}${val}`))?.href;
        } catch (e) {
            console.error(e);            
        };
    };

    return url;
};

export function MicroFrontend({ name, host, history }: any) {
    useEffect(() => {
        const scriptId = `micro-frontend-script-${name}`;
        const configScriptId = `micro-frontend-config-script-${name}`;
        const styleId = `micro-frontend-style-${name}`;

        if (document.getElementById(scriptId) && document.getElementById(configScriptId)) {
            renderMicroFrontend(name, history);
            return;
        }

        const url = getUrl(host);

        

        fetch(`${url}/asset-manifest.json`)
            .then((res) => {
                const scriptConfig = document.createElement("script");
                scriptConfig.src = `${url}/config.js`;
                scriptConfig.id = configScriptId;
                scriptConfig.crossOrigin = "";
                document.head.appendChild(scriptConfig);

                return res.json();
            })
            .then((manifest) => {
                const script = document.createElement("script");
                script.id = scriptId;
                script.crossOrigin = "";
                const hostElements = url.split('/');
                const srcElements: string[] = [];
                manifest.files["main.js"]?.split('/')?.forEach((f: string) => {
                    if (!hostElements.includes(f)) {
                        srcElements.push(f);
                    }
                });
                script.src = `${url}/${srcElements.join('/')}?v=${new Date().getTime()}`;
                script.onload = () => {
                    renderMicroFrontend(name, history);
                };
                script.id = scriptId;
                document.head.appendChild(script);

                if (manifest.files["main.css"]) {
                    const link = document.createElement("link");
                    link.rel = "stylesheet";
                    const hrefElements: string[] = [];
                    manifest.files["main.css"]?.split('/')?.forEach((f: string) => {
                        if (!hostElements.includes(f)) {
                            hrefElements.push(f);
                        }
                    });
                    link.href = `${url}/${hrefElements.join('/')}?v=${new Date().getTime()}`;
                    link.id = styleId;
                    document.head.appendChild(link);
                }
            }).catch((error) => console.warn(error));

        return () => {
            // @ts-ignore
            window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
            document.getElementById(styleId)?.remove();
            document.getElementById(scriptId)?.remove();
            document.getElementById(configScriptId)?.remove();
        };
    }, []);

    return <main className={styles.TMScheduleContainer} id={`${name}-container`} style={{width: '100%', height: '100%'}}/>;
}