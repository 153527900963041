import { Button } from 'antd';
import { Props } from './CustomButton.types';
import React from 'react';
import styles from './styles.module.scss';

export const CustomButton = (props: Props) => (
  <Button className={styles.container} onClick={props.buttonOnClick}>
    {props.buttonText}
  </Button>
);
