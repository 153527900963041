import { CustomButton, Header, SupportBlock } from '../../Components';
import { Props } from './DoctorIsNotInDBBlock.types';
import React from 'react';
import styles from './styles.module.scss';

export const DoctorIsNotInDBBlock = (props: Props) => {
  const { addDoctorToClinicButtonOnClick } = props;

  return (
    <div className={styles.container}>
      <Header text="Специалист не найден в нашей базе" />

      <div className={styles.description}>
        Вы можете перейти к заполнению профиля врача и добавлению его в свою клинику.
      </div>

      <div className={styles.addDoctorToClinic}>
        <CustomButton buttonText="Добавить врача в свою клинику" buttonOnClick={addDoctorToClinicButtonOnClick} />
      </div>

      <div className={styles.supportBlock}>
        <SupportBlock />
      </div>
    </div>
  );
};
