import {
  Branch,
  DictionaryItem,
  DictionarySubwayItem,
  DictionarySubwayLineItem,
  DictionarySubwayStationItem,
  PermissionEntity,
  SelectOption,
} from '../types';
import dayjs, { Dayjs } from 'dayjs';
import { PaymentStatusType } from '../types/enums';
import SubwayLineDot from 'src/features/CompanyPage/BranchesPage/SubwayLineDot/SubwayLineDot';
import React from 'react';

export const getDateAppointmentLabel = (dataString: string) => dayjs(dataString).format('HH:mm DD.MM.YYYY');
export const getDateUpdatePromotiontLabel = (dataString: string) => dayjs(dataString).format('DD.MM.YYYY HH:mm');
export const getDateLabel = (dataString: string) => dayjs(dataString).format('DD.MM.YYYY');
export const getAppointmentTime = (dataStart: Dayjs, dataEnd: Dayjs) =>
  `${dataStart.format('HH:mm')} - ${dataEnd.format('HH:mm')}`;
export const getTimeLabel = (dataString: string) => dayjs(dataString).format('HH:mm');

export const getFsmsName = (id: string, list?: DictionaryItem[]) => {
  return list?.filter((specialty) => specialty.code === id)[0]?.name;
};

export const getMilaId = (id: string) => `...${id.slice(-4)}`;

export const getNavUserName = (name: string) => (name.length > 20 ? `${name.slice(0, 17)}...` : name);

export const getPageVisibility = (permissions: PermissionEntity[], pageName: string) =>
  !!permissions?.find((permission: PermissionEntity) => permission.name === pageName);

export const getPreparedBranches = (branchesList: Branch[], availableFiltersBranches: string[]) =>
  branchesList
    .filter((branch: Branch) => availableFiltersBranches.includes(branch.branchId))
    .map((branch: Branch) => ({ label: branch.shortName, value: branch.branchId }));

export const getPreparedSortedBranches = (branchesList: Branch[], availableBranches: string[]) =>
  [...branchesList]
    .filter((branch: Branch) => availableBranches.includes(branch.branchId))
    .sort((a: Branch, b: Branch) => a.shortName.localeCompare(b.shortName));

type GetBranchesClaimsType = {
  name: string;
  value: string;
}[];

export const getBranchesClaims = (data: GetBranchesClaimsType) => {
  return data
    .map((claim: any) => {
      if (claim.name === 'has-access-to-branch') {
        return claim.value;
      } else return null;
    })
    .filter((entity: string) => entity !== null);
};

export const prepareBranchesByPermissions = (branchesList: Branch[], availableBranches: string[]) =>
  branchesList ? getPreparedBranches(branchesList, availableBranches) : [];

export const transformDictonaryToOptions = (items: DictionaryItem[]): SelectOption[] =>
  items.map((item) => ({ label: item.name, value: item.code }));

export const transformSubwayEntityToOptions = (
  item: DictionarySubwayItem | DictionarySubwayLineItem | DictionarySubwayStationItem,
  color?: string,
  parentId?: number,
) => {
  const icon: React.JSX.Element | undefined = color ? <SubwayLineDot color={color} /> : undefined;

  return { label: item.name, value: `${item.name}-${parentId}`, key: `${item.name}-${parentId}`, icon: icon };
};

export const getPaymentStatus = (status: PaymentStatusType): string => {
  switch (status) {
    case PaymentStatusType.CreatedAndNotPaid:
      return 'Создан, но не оплачен';
    case PaymentStatusType.WithdrawBySystem:
      return 'Деньги списаны системой Mila';
    case PaymentStatusType.WithdrawByClinic:
      return 'Деньги списаны клиникой';
    case PaymentStatusType.WithdrawByClinicWithoutReport:
      return 'Деньги списаны клиникой. Без заключения';
    case PaymentStatusType.WithdrawByClinicAndReportIsReady:
      return 'Деньги списаны клиникой. Заключение готово';
    case PaymentStatusType.WithdrawByPatientInOrderOfCancellingAnAppointment:
      return 'Деньги списаны за отмену(согл условиям)';
    case PaymentStatusType.Canceled:
      return 'Платеж отменен';
    case PaymentStatusType.RefundedBySystem:
      return 'Деньги возвращены системой Mila';
    case PaymentStatusType.RefundedByPatient:
      return 'Деньги возвращены по инициативе пользователя';
    case PaymentStatusType.RefundedByClinic:
      return 'Деньги возвращены по инициативе клиники';
    case PaymentStatusType.MoneyReserved:
      return 'Деньги зарезервированы';
    case PaymentStatusType.PaymentSystemError:
      return 'Ошибка платежной системы';
  }

  return '';
};

export const minutesTimezoneOffset = dayjs().utcOffset();

/**
 * Генерация пароля
 * @param lengthPassword длина пароля
 * @returns
 */
export const generatePassword = (lengthPassword: number) => {
  const length = lengthPassword;
  const charset = "abcdefghijklmnopqrstuvwxyz";
  const symbols = "!$^&*-=+_?";
  const numbers = "0123456789";
  const upperLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let retVal = "";

  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  let excludeValues: any[] = [];
  let index = random(0, lengthPassword - 1, excludeValues);
  excludeValues.push(index);
  retVal = retVal.substring(0, index) + symbols[random(0, symbols.length - 1, [])] + retVal.substring(index + 1);

  index = random(0, lengthPassword - 1, excludeValues);
  excludeValues.push(index);
  retVal = retVal.substring(0, index) + numbers[random(0, numbers.length - 1, [])] + retVal.substring(index + 1);

  index = random(0, lengthPassword - 1, excludeValues);
  excludeValues.push(index);
  retVal = retVal.substring(0, index) + upperLetters[random(0, upperLetters.length - 1, [])] + retVal.substring(index + 1);

  return retVal;
};

export const random: any = (min: any, max: any, exclude: any[]) => {
  let randomVal = null;
  do {
    randomVal = Math.floor(Math.random() * max) + min;
  } while (exclude.includes(randomVal));
  return randomVal;
};
