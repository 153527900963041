import { AgreementType } from '../../../../common/types/enums';
import {Button, Col, Form, Popconfirm, Row, Select, Tooltip, Typography, Upload, notification, Input} from 'antd';
import { CancelIcon } from '../../../../common/Icons/icons';
import { CustomInput } from 'src/common/components/CustomInput/CustomInput';
import { ReactComponent as DownCaretIcon } from '../../../../common/Icons/DownCaretIcon.svg';
import { ReactComponent as EditIcon } from '../../../../common/Icons/EditIcon.svg';
import { MilaService } from '../../../../common/types';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/QuestionMarkIcon.svg';
import { SketchPicker } from 'react-color';
import { useForm } from 'antd/lib/form/Form';
import {
  useGetWhiteLabelDataQuery,
  useGetWhiteLabelFileInfoQuery,
  useUpdateWhiteLabelDataMutation,
  useUploadWhiteLabelAgreementMutation,
  useUploadWhiteLabelFavIconMutation,
  useUploadWhiteLabelPwaIconMutation,
  useUploadWhiteLabelIconMutation,
  useUploadWhiteLabelLogoMutation,
} from '../../../../app/services/api';
import Checkbox from 'antd/es/checkbox/Checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import FloatLabelInput from "../../../../common/FloatLabelInput/FloatLabelInput";
import { ReactComponent as PlusIcon } from '../../../../assets/PlusIcon.svg';
import { ReactComponent as CrossSignIcon } from '../../../../assets/CrossSignIcon.svg';

function WhiteLabelSettingsPage() {
  const { data: whiteLabelData, refetch: refetchWhiteLabelData } = useGetWhiteLabelDataQuery('');

  const [uploadLogo] = useUploadWhiteLabelLogoMutation();
  const [uploadAgreement] = useUploadWhiteLabelAgreementMutation();
  const [uploadIcon] = useUploadWhiteLabelIconMutation();
  const [uploadFavicon] = useUploadWhiteLabelFavIconMutation();
  const [uploadPwaicon] = useUploadWhiteLabelPwaIconMutation();
  const [updateWhiteLabelData, updateRequest] = useUpdateWhiteLabelDataMutation();

  const [form] = useForm<any>();

  const [logoPath, setLogoPath] = useState<string>('');
  const [medCardAgreementPath, setMedCardAgreementPath] = useState<string>('');
  const [notificationAgreementPath, setNotificationAgreementPath] = useState<string>('');
  const [personalDataAgreementPath, setPersonalDataAgreementPath] = useState<string>('');
  const [offerAgreementPath, setOfferAgreementPath] = useState<string>('');
  const [iconPath, setIconPath] = useState<string>('');
  const [favIconPath, setFavIconPath] = useState<string>('');
  const [pwaIconPath, setPwaIconPath] = useState<string>('');
  const [isSubmitDisabled, setSubmitBtnDisabled] = useState<boolean>(true);

  const [headersColor, setHeadersColor] = useState<string>('');
  const [linksColor, setLinksColor] = useState<string>('');
  const [buttonsBgColor, setButtonsBgColor] = useState<string>('');
  const [buttonsTextColor, setButtonsTextColor] = useState<string>('');
  const [appointmentBgColor, setAppointmentBgColor] = useState<string>('');
  const [appointmentTextColor, setAppointmentTextColor] = useState<string>('');
  const [updateBgColor, setUpdateBgColor] = useState<string>('');
  const [updateTextColor, setUpdateTextColor] = useState<string>('');
  const [image, setImage] = useState<File | undefined>();
  const [selectedServices, setSelectedServices] = useState<MilaService[]>([]);
  const [imageFav, setImageFav] = useState<File | null>(null);
  const [favImgUrl, setFavImgUrl] = useState<any>(null);
  const [imagePwa, setImagePwa] = useState<File | null>(null);
  const [pwaImgUrl, setPwaImgUrl] = useState<any>(null);
  const [imageIcon, setImageIcon] = useState<File | null>(null);
  const [iconImgUrl, setIconImgUrl] = useState<any>(null);
  const [imageLogo, setImageLogo] = useState<File | null>(null);
  const [logoImgUrl, setLogoImgUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: medCardAgreement } = useGetWhiteLabelFileInfoQuery(medCardAgreementPath, {
    skip: !medCardAgreementPath,
    refetchOnMountOrArgChange: true,
  });
  const { data: notificationAgreement } = useGetWhiteLabelFileInfoQuery(notificationAgreementPath, {
    skip: !notificationAgreementPath,
    refetchOnMountOrArgChange: true,
  });
  const { data: personalDataAgreement } = useGetWhiteLabelFileInfoQuery(personalDataAgreementPath, {
    skip: !personalDataAgreementPath,
    refetchOnMountOrArgChange: true,
  });
  const { data: offerAgreement } = useGetWhiteLabelFileInfoQuery(offerAgreementPath, {
    skip: !offerAgreementPath,
    refetchOnMountOrArgChange: true,
  });

  const setDefaultValues = useCallback(() => {
    form.setFieldsValue({
      orgName: whiteLabelData?.displayName,
      copyrightTitle: whiteLabelData?.copyrightTitle,
      copyrightDescription: whiteLabelData?.copyrightDescription,
      emails: whiteLabelData?.supportEmails ?? ['']
    });
    setLogoPath(whiteLabelData?.idLogoFile ?? '');
    setMedCardAgreementPath(whiteLabelData?.idMedCardAgreementFile ?? '');
    setNotificationAgreementPath(whiteLabelData?.idNotificationAgreementFile ?? '');
    setPersonalDataAgreementPath(whiteLabelData?.idPersonalDataAgreementFile ?? '');
    setOfferAgreementPath(whiteLabelData?.idOfferAgreementFile ?? '');
    setIconPath(whiteLabelData?.idIconFile ?? '');
    setFavIconPath(whiteLabelData?.idFaviconFile ?? '');
    setPwaIconPath(whiteLabelData?.idPwaIconFile ?? '');
    setHeadersColor(whiteLabelData?.headersColor ?? '');
    setLinksColor(whiteLabelData?.linksColor ?? '');
    setButtonsBgColor(whiteLabelData?.mainButtonColor.backgroundColor ?? '');
    setButtonsTextColor(whiteLabelData?.mainButtonColor.textColor ?? '');
    setAppointmentBgColor(whiteLabelData?.nearestAppointmentColor.backgroundColor ?? '');
    setAppointmentTextColor(whiteLabelData?.nearestAppointmentColor.textColor ?? '');
    setUpdateBgColor(whiteLabelData?.medCardUpdatesColor.backgroundColor ?? '');
    setUpdateTextColor(whiteLabelData?.medCardUpdatesColor.textColor ?? '');
    setSelectedServices(whiteLabelData?.services ?? []);
  }, [form, whiteLabelData]);

  useEffect(() => {
    setDefaultValues();
    if (whiteLabelData) {
      setLogoPath(whiteLabelData?.idLogoFile);
      setIconPath(whiteLabelData?.idIconFile);
      setFavIconPath(whiteLabelData?.idFaviconFile);
      setPwaIconPath(whiteLabelData?.idPwaIconFile);
      setHeadersColor(whiteLabelData?.headersColor);
      setLinksColor(whiteLabelData?.linksColor);
      setButtonsBgColor(whiteLabelData?.mainButtonColor.backgroundColor);
      setButtonsTextColor(whiteLabelData?.mainButtonColor.textColor);
      setAppointmentBgColor(whiteLabelData?.nearestAppointmentColor.backgroundColor);
      setAppointmentTextColor(whiteLabelData?.nearestAppointmentColor.textColor);
      setUpdateBgColor(whiteLabelData?.medCardUpdatesColor.backgroundColor);
      setUpdateTextColor(whiteLabelData?.medCardUpdatesColor.textColor);
      setMedCardAgreementPath(whiteLabelData?.idMedCardAgreementFile);
      setNotificationAgreementPath(whiteLabelData?.idNotificationAgreementFile);
      setPersonalDataAgreementPath(whiteLabelData?.idPersonalDataAgreementFile);
      setOfferAgreementPath(whiteLabelData?.idOfferAgreementFile);
      setSelectedServices(whiteLabelData?.services);
    }
  }, [whiteLabelData]);

  const onFormValuesChange = () => {
    setSubmitBtnDisabled(false);
  };

  const handleSubmitForm = async (values: any) => {
    setIsLoading(true);
    if (imageLogo) {
      await uploadLogo(imageLogo)
          .unwrap()
          .then((id: string) => {
            setLogoPath(id);
          });
    }
    if (imagePwa) {
      uploadPwaicon(imagePwa)
        .unwrap()
        .then((id: string) => {
          setPwaIconPath(id);
        });
    }
    if (imageIcon) {
      uploadIcon(imageIcon)
        .unwrap()
        .then((id: string) => {
          setIconPath(id);
        });
    }
    if (imageFav) {
      uploadFavicon(imageFav)
        .unwrap()
        .then((id: string) => {
          setFavIconPath(id);
        });
    }
    updateWhiteLabelData({
      headersColor,
      linksColor,
      displayName: values.orgName,
      copyrightTitle: values.copyrightTitle,
      copyrightDescription: values.copyrightDescription,
      mainButtonColor: {
        textColor: buttonsTextColor,
        backgroundColor: buttonsBgColor,
      },
      nearestAppointmentColor: {
        textColor: appointmentTextColor,
        backgroundColor: appointmentBgColor,
      },
      medCardUpdateColor: {
        textColor: updateTextColor,
        backgroundColor: updateBgColor,
      },
      services: selectedServices,
      supportEmails: values.emails
    })
      .unwrap()
      .then(() => {
        setSubmitBtnDisabled(true);
        refetchWhiteLabelData();
        notification.success({ message: 'Настройки сохранены' });
      })
      .catch((error) => notification.error({ message: 'Ошибка сохранения настроек' }))
        .finally(() => setIsLoading(false));
  };

  const handleChangeLogo = () => {
    document.getElementById('logoUpload')?.click();
  };

  const handleChangeIcon = () => {
    document.getElementById('iconUpload')?.click();
  };

  const handleChangeFavIcon = () => {
    document.getElementById('favIconUpload')?.click();
  };

  const handleChangePwaIcon = () => {
    document.getElementById('pwaIconUpload')?.click();
  };

  const handleLogoRemove = () => {
    setLogoPath('');
    setLogoImgUrl('');
    setImageLogo(null);
  };

  const handleIconRemove = () => {
    setIconPath('');
    setIconImgUrl('');
    setImageIcon(null);
  };

  const handleFavIconRemove = () => {
    setFavIconPath('');
    setFavImgUrl('');
    setImageFav(null);
  };

  const handlePwaIconRemove = () => {
    setPwaIconPath('');
    setPwaImgUrl('');
    setImagePwa(null);
  };

  const handleNotificationAgreementRemove = () => {
    setNotificationAgreementPath('');
  };

  const handleOfferAgreementRemove = () => {
    setOfferAgreementPath('');
  };

  const handleMedCardAgreementRemove = () => {
    setMedCardAgreementPath('');
  };

  const handlePersonalDataAgreementRemove = () => {
    setPersonalDataAgreementPath('');
  };

  const handleLogoChange = (image: File | undefined) => {
    if (image) {
      setImageLogo(image);
      setLogoImgUrl(URL.createObjectURL(image));
      setLogoPath('');
      // uploadLogo(image)
      //   .unwrap()
      //   .then((id: string) => {
      //     setLogoPath(id);
      //   });
      setImage(undefined);
      setSubmitBtnDisabled(false);
    }
  };

  const handleAgreementChange: any = (file: any, agreementType: AgreementType) => {
    if (file?.file) {
      uploadAgreement({ file: file.file, agreementType })
        .unwrap()
        .then((id: string) => {
          switch (agreementType) {
            case AgreementType.PersonalData:
              setPersonalDataAgreementPath(id);
              break;

            case AgreementType.MedCard:
              setMedCardAgreementPath(id);
              break;

            case AgreementType.Notification:
              setNotificationAgreementPath(id);
              break;

            case AgreementType.Offer:
              setOfferAgreementPath(id);
              break;
          }
        });
      setSubmitBtnDisabled(false);
    }
  };

  const handleIconChange = (image: File | undefined) => {
    if (image) {
      setImageIcon(image);
      setIconPath('');
      setIconImgUrl(URL.createObjectURL(image));
      // uploadIcon(image)
      //   .unwrap()
      //   .then((id: string) => {
      //     setIconPath(id);
      //   });
      setImage(undefined);
      setSubmitBtnDisabled(false);
    }
  };

  const handleFavIconChange = (image: File | undefined) => {
    if (image) {
      setImageFav(image);
      setFavIconPath('');
      setFavImgUrl(URL.createObjectURL(image));
      // uploadFavicon(image)
      //   .unwrap()
      //   .then((id: string) => {
      //     setFavIconPath(id);
      //   });
      setImage(undefined);
      setSubmitBtnDisabled(false);
    }
  };

  const handlePwaIconChange = (image: File | undefined) => {
    if (image) {
      setPwaIconPath('');
      setImagePwa(image);
      setPwaImgUrl(URL.createObjectURL(image));
      // uploadPwaicon(image)
      //   .unwrap()
      //   .then((id: string) => {
      //     setPwaIconPath(id);
      //   });
      setImage(undefined);
      setSubmitBtnDisabled(false);
    }
  };

  const renderColorOption = (colorHex: string) => {
    return (
      <div className={styles.colorSelectorWrapper}>
        <div className={styles.coloredSquare} style={{ background: colorHex }}></div>
        <span>{colorHex}</span>
      </div>
    );
  };

  const checkImageType = (file: any, allowJpg = true, allowPng = true, allowsSvg = true) => {
    const isProperFormat =
        allowsSvg && file.type === 'image/svg+xml' || allowJpg && file.type === 'image/jpg' || allowJpg && file.type === 'image/jpeg' || allowPng && file.type === 'image/png';
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
      setImage(undefined);
      return false;
    } else {
      setImage(file);
      return true;
    }
  };

  const handleShowTestByLink = () => {
    window.open('', '_blank', 'noopener,noreferrer');
  };

  const services = [
    {
      text: 'Записаться на прием в клинику',
      key: MilaService.OnSite,
    },
    {
      text: 'Записаться на онлайн-консультацию',
      key: MilaService.Online,
    },
    {
      text: 'Вызвать врача на дом',
      key: MilaService.AtHome,
    },
    // {
    //   text: 'Написать клинике в чат',
    //   key: MilaService.ChatWithClinic,
    // },
    {
      text: 'Найти скидки и акции в клиниках',
      key: MilaService.Discounts,
    },
    {
      text: 'Загрузить документы в медкарту',
      key: MilaService.UploadDocuments,
    },
    {
      text: 'Поделиться медкартой',
      key: MilaService.SharedMedcard,
    },
    {
      text: 'Добавить свою клинику в Mila',
      key: MilaService.AddClinic,
    },
    {
      text: 'Добавить профили родных в Mila',
      key: MilaService.AddProfile,
    },
  ];

  const changeServices = (service: any) => {
    setSubmitBtnDisabled(false);
    if (selectedServices.find((s) => s === service) !== undefined) {
      setSelectedServices([...selectedServices.filter((s) => s !== service)]);
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const humanFileSize = (size: any) => {
    if (!size) {
      return 0;
    }
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${+(size / Math.pow(1024, i)).toFixed(0)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
  };

  const handleAgreementDownload = (fileId?: string) => {
    if (fileId) {
      window.open(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${fileId}?inline=false`,
        '_blank',
        'noopener,noreferrer',
      );
    }
  };

  const handleEmailsChange = (value: string, fieldKey: number) => {
    const updatedEmails = [...form.getFieldValue('emails')];

    updatedEmails.splice(fieldKey, 1, value);
    form.setFieldValue('emails', updatedEmails);
  };

  const handleEmailRemove = (fieldKey: number) => () => {
    const updatedEmails = [...form.getFieldValue('emails')];

    updatedEmails.splice(fieldKey, 1);
    form.setFieldValue('emails', updatedEmails);
    onFormValuesChange();
  };

  return (
    <div className={styles.pageContainer}>
      <Form form={form} layout="vertical" onFinish={handleSubmitForm} onValuesChange={onFormValuesChange}>
        <div className={styles.generalDataContainer}>
          <Col className={styles.generalDataFormTitle}>
            <Typography.Text>Общие данные для настройки Личного кабинета пациента</Typography.Text>
          </Col>
          <Row gutter={24} className={styles.inputsRowWrapper}>
            <Row className={styles.singleFormRow}>
              <Col>
                <Form.Item name="orgName">
                  <CustomInput
                    floatLabel="Название организации"
                    maxLength={500}
                    className={styles.Input}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="copyrightTitle">
                  <CustomInput
                      floatLabel="Текст для копирайта"
                      maxLength={500}
                      className={styles.Input}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.singleFormRow}>
              <Col>
                <Form.Item name="copyrightDescription">
                  <CustomInput
                      floatLabel="Подпись под копирайтом"
                      maxLength={500}
                      isTextArea={true}
                      className={styles.Input}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </div>

        <Col className={styles.blockContainer}>
          <Col className={styles.blockTitle}>
            <Typography.Title level={3}>Контакты службы техподдержки</Typography.Title>
          </Col>
          <Col className={styles.mailsListSubTitle}>
            <Typography.Text>
              Укажите email адреса службы техподдержки для получения обращений пациентов из раздела «Помощь» в ЛК пациента
            </Typography.Text>
          </Col>
          <Col className={styles.inputsMailWrapper}>
            <Form.List name="emails">
              {(fields, { add }) => (
                  <>
                    <Col>
                      {fields.map((field, index) => (
                          <Col key={field.key}>
                            <FloatLabelInput label="Email" value={form.getFieldValue('emails')[index]}>
                              <Form.Item
                                  {...field}
                                  name={field.name}
                                  validateTrigger={['onChange', 'onBlur']}
                                  noStyle
                                  rules={[
                                    {
                                      message: 'Пожалуйста введите корректную электронную почту',
                                      pattern:
                                          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                                    },
                                  ]}
                              >
                                <Input onChange={(e) => handleEmailsChange(e.target.value, field.key)} />
                              </Form.Item>
                            </FloatLabelInput>
                            {fields.length > 1 && index !== 0 ? (
                                <CrossSignIcon
                                    className={`dynamic-delete-button ${styles.deleteButton}`}
                                    onClick={handleEmailRemove(index)}
                                />
                            ) : null}
                          </Col>
                      ))}
                    </Col>
                    <Col className={styles.newEmailButtonWrapper}>
                      <Form.Item className={styles.addNewBtn}>
                        <div onClick={() => add()}>
                          <PlusIcon />
                          <Typography.Text>Добавить Email</Typography.Text>
                        </div>
                      </Form.Item>
                    </Col>
                  </>
              )}
            </Form.List>
          </Col>
        </Col>

        <div className={styles.colorForm}>
          <Col className={styles.generalDataFormTitle}>
            <Typography.Text>Цветовая схема и графические элементы</Typography.Text>
          </Col>

          <Row className={styles.logoPhotoRow}>
            <Col className={`${styles.textFormRow} ${styles.logoPhotoCol}`}>
              <Typography.Text>Загрузите логотип компании в формате SVG</Typography.Text>
            </Col>
            <Col className={styles.logoPhotoCol}>
              <Form.Item>
                <Upload.Dragger
                  className={styles.fileUploader}
                  accept={'.svg'}
                  maxCount={1}
                  customRequest={() => handleLogoChange(image)}
                  beforeUpload={(image) => checkImageType(image, false, false)}
                  showUploadList={{ showRemoveIcon: false }}
                  openFileDialogOnClick={false}
                  id="logoUpload"
                >
                  {logoPath ? (
                    <img
                      alt=""
                      src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${logoPath}?inline=true`}
                      height={136}
                      width={145}
                    />
                  ) : logoImgUrl ? (
                      <img
                          alt=""
                          src={logoImgUrl}
                          height={136}
                          width={145}
                      />
                      ) : (
                    <p>Загрузите логотип</p>
                  )}
                </Upload.Dragger>
              </Form.Item>
              <div className={styles.imgPhotoIcons}>
                <Tooltip title={'Рекомендуемый формат - SVG'} placement="right">
                  <QuestionMarkIcon />
                </Tooltip>

                <EditIcon
                  onClick={() => {
                    handleChangeLogo();
                  }}
                />
              </div>
              <Popconfirm
                title={`Вы уверены, что хотите удалить логотип?`}
                onConfirm={handleLogoRemove}
                okText="Да"
                cancelText="Нет"
              >
                {(logoPath || logoImgUrl) && (
                  <div className={styles.removePhotoLabel}>
                    <Typography.Text>Удалить логотип</Typography.Text>
                  </div>
                )}
              </Popconfirm>
            </Col>

            <Col className={`${styles.textFormRow} ${styles.logoPhotoCol}`}>
              <Typography.Text>Загрузите иконку для использования в текстовых подсказках в формате SVG</Typography.Text>
            </Col>

            <Col className={styles.logoPhotoCol}>
              <Form.Item>
                <Upload.Dragger
                  className={styles.fileUploader}
                  accept={'.svg'}
                  maxCount={1}
                  customRequest={() => handleIconChange(image)}
                  beforeUpload={(image) => checkImageType(image, false, false)}
                  showUploadList={{ showRemoveIcon: false }}
                  openFileDialogOnClick={false}
                  id="iconUpload"
                >
                  {iconPath ? (
                    <img
                      alt=""
                      src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${iconPath}?inline=true`}
                      height={136}
                      width={145}
                    />
                  ) : iconImgUrl ? (
                      <img
                          alt=""
                          src={iconImgUrl}
                          height={136}
                          width={145}
                      />
                      ) : (
                    <p>Загрузите иконку</p>
                  )}
                </Upload.Dragger>
              </Form.Item>
              <div className={styles.imgPhotoIcons}>
                <Tooltip title={'Рекомендуемый формат - SVG'} placement="right">
                  <QuestionMarkIcon />
                </Tooltip>

                <EditIcon
                  onClick={() => {
                    handleChangeIcon();
                  }}
                />
              </div>
              <Popconfirm
                title={`Вы уверены, что хотите удалить иконку?`}
                onConfirm={handleIconRemove}
                okText="Да"
                cancelText="Нет"
              >
                {(iconPath || iconImgUrl) && (
                  <div className={styles.removePhotoLabel}>
                    <Typography.Text>Удалить иконку</Typography.Text>
                  </div>
                )}
              </Popconfirm>
            </Col>
          </Row>

          <Row className={styles.logoPhotoRow}>
            <Col className={`${styles.textFormRow} ${styles.logoPhotoCol}`}>
              <Typography.Text>
                Загрузите иконку для использования в PWA приложении в формате PNG без прозрачного фона
              </Typography.Text>
            </Col>
            <Col className={styles.logoPhotoCol}>
              <Form.Item>
                <Upload.Dragger
                    className={styles.fileUploader}
                    accept={'.png'}
                    maxCount={1}
                    customRequest={() => handlePwaIconChange(image)}
                    beforeUpload={(image) => checkImageType(image, false, true, false)}
                    showUploadList={{ showRemoveIcon: false }}
                    openFileDialogOnClick={false}
                    id="pwaIconUpload"
                >
                  {pwaIconPath ? (
                      <img
                          alt=""
                          src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${pwaIconPath}?inline=true`}
                          height={136}
                          width={145}
                      />
                  ) : pwaImgUrl ? (
                      <img
                          alt=""
                          src={pwaImgUrl}
                          height={136}
                          width={145}
                      />
                      ) : (
                      <p>Загрузите иконку</p>
                  )}
                </Upload.Dragger>
              </Form.Item>
              <div className={styles.imgPhotoIcons}>
                <Tooltip title={'Рекомендуемый формат - PNG'} placement="right">
                  <QuestionMarkIcon />
                </Tooltip>

                <EditIcon
                    onClick={() => {
                      handleChangePwaIcon();
                    }}
                />
              </div>
              <Popconfirm
                  title={`Вы уверены, что хотите удалить иконку?`}
                  onConfirm={handlePwaIconRemove}
                  okText="Да"
                  cancelText="Нет"
              >
                {(pwaIconPath || pwaImgUrl) && (
                    <div className={styles.removePhotoLabel}>
                      <Typography.Text>Удалить иконку</Typography.Text>
                    </div>
                )}
              </Popconfirm>
            </Col>

            <Col className={`${styles.textFormRow} ${styles.logoPhotoCol}`}>
              <Typography.Text>
                Загрузите иконку для использования в качестве favicon для сайта в формате SVG
              </Typography.Text>
            </Col>
            <Col className={styles.logoPhotoCol}>
              <Form.Item>
                <Upload.Dragger
                  className={styles.fileUploader}
                  accept={'.svg'}
                  maxCount={1}
                  customRequest={() => handleFavIconChange(image)}
                  beforeUpload={(image) => checkImageType(image, false, false, true)}
                  showUploadList={{ showRemoveIcon: false }}
                  openFileDialogOnClick={false}
                  id="favIconUpload"
                >
                  {favIconPath ? (
                    <img
                      alt=""
                      src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${favIconPath}?inline=true`}
                      height={136}
                      width={145}
                    />
                  ) : favImgUrl ? (
                      <img
                          alt=""
                          src={favImgUrl}
                          height={136}
                          width={145}
                      />
                      ) : (
                    <p>Загрузите иконку</p>
                  )}
                </Upload.Dragger>
              </Form.Item>
              <div className={styles.imgPhotoIcons}>
                <Tooltip title={'Рекомендуемый формат - SVG'} placement="right">
                  <QuestionMarkIcon />
                </Tooltip>

                <EditIcon
                  onClick={() => {
                    handleChangeFavIcon();
                  }}
                />
              </div>
              <Popconfirm
                title={`Вы уверены, что хотите удалить иконку?`}
                onConfirm={handleFavIconRemove}
                okText="Да"
                cancelText="Нет"
              >
                {(favIconPath || favImgUrl) && (
                  <div className={styles.removePhotoLabel}>
                    <Typography.Text>Удалить иконку</Typography.Text>
                  </div>
                )}
              </Popconfirm>
            </Col>
          </Row>

          <Col className={styles.generalDataFormSubTitle}>
            <Typography.Text>Настройка основных элементов интерфейса</Typography.Text>
          </Col>

          <Row className={styles.inputsRowWrapper}>
            <Row gutter={[25, 25]} className={styles.colorsFormRow}>
              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет заголовков основных разделов</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(headersColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={headersColor ?? ''}
                      onChangeComplete={(color) => {
                        setHeadersColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>

              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет ссылок и активных элементов интерфейса</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(linksColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={linksColor ?? ''}
                      onChangeComplete={(color) => {
                        setLinksColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>
            </Row>

            <Row gutter={[25, 25]} className={styles.colorsFormRow}>
              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет фона для основных кнопок</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(buttonsBgColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={buttonsBgColor ?? ''}
                      onChangeComplete={(color) => {
                        setButtonsBgColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>

              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет текста внутри основных кнопок</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(buttonsTextColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={buttonsTextColor ?? ''}
                      onChangeComplete={(color) => {
                        setButtonsTextColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>
            </Row>
          </Row>

          <Col className={styles.generalDataFormSubTitle}>
            <Typography.Text>Настройка блоков в разделе “Важное” на главной странице</Typography.Text>
          </Col>

          <Row className={styles.inputsRowWrapper}>
            <Row gutter={[25, 25]} className={styles.colorsFormRow}>
              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет фона для блока о ближайшей записи</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(appointmentBgColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={appointmentBgColor ?? ''}
                      onChangeComplete={(color) => {
                        setAppointmentBgColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>

              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет текста внутри блока о ближайшей записи</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(appointmentTextColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={appointmentTextColor ?? ''}
                      onChangeComplete={(color) => {
                        setAppointmentTextColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>
            </Row>

            <Row gutter={[25, 25]} className={styles.colorsFormRow}>
              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет фона для блока обновления в медкарте</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(updateBgColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={updateBgColor ?? ''}
                      onChangeComplete={(color) => {
                        setUpdateBgColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>

              <Col className={styles.textFormRow}>
                <Typography.Text>Цвет текста внутри блока обновления в медкарте</Typography.Text>
              </Col>
              <Col>
                <Select
                  style={{ height: '56px', width: '187px' }}
                  className={styles.selectorColorWrapper}
                  suffixIcon={<DownCaretIcon />}
                  value={{ label: renderColorOption(updateTextColor), value: '' }}
                  labelInValue={true}
                  dropdownAlign={{ offset: [-20, -20] }}
                  dropdownRender={() => (
                    <SketchPicker
                      color={updateTextColor ?? ''}
                      onChangeComplete={(color) => {
                        setUpdateTextColor(color.hex);
                        setSubmitBtnDisabled(false);
                      }}
                    />
                  )}
                  popupClassName={styles.colorPallete}
                  popupMatchSelectWidth={225}
                />
              </Col>
            </Row>
          </Row>

          <Col className={styles.generalDataFormSubTitle}>
            <Typography.Text>Настройка отображения блоков в разделе “Сервисы” на главной странице</Typography.Text>
          </Col>

          <Row gutter={24} className={styles.inputsRowWrapper}>
            <Row className={styles.singleFormRow}>
              {services?.map((service: any) => (
                  <Col span={12} key={service.key}>
                    <Checkbox
                        checked={selectedServices.find((s) => s === service.key) !== undefined}
                        onChange={() => {
                          changeServices(service.key);
                        }}
                    >
                      {service.text}
                    </Checkbox>
                  </Col>
              ))}
            </Row>
          </Row>
        </div>

        <div className={styles.agreementsForm}>
          <Col className={styles.generalDataFormTitle}>
            <Typography.Text>Тексты пользовательских согласий</Typography.Text>
          </Col>

          {/* Согласия на моковых данных */}

          <Row gutter={24} className={styles.agreementsRowWrapper}>
            <Row className={styles.singleColorFormRow}>
              <Row className={styles.agreementsFormSubTitle}>
                <Typography.Text>Согласие пользователя на обработку персональных данных</Typography.Text>
              </Row>
            </Row>

            <Row className={styles.agreementUploadButtonContainer}>
              <Col>
                <Row>
                  <Form.Item name="personalAgreement">
                    {!personalDataAgreementPath ? (
                      <Upload
                        listType="text"
                        fileList={[]}
                        showUploadList={{ showRemoveIcon: false }}
                        customRequest={(file) => handleAgreementChange(file, AgreementType.PersonalData)}
                        className={styles.uploadDocumentForm}
                      >
                        <Button className={styles.buttonUploadAgreement}>Загрузить документ</Button>
                      </Upload>
                    ) : (
                      <Row>
                        <Col>
                          <Popconfirm
                            title={`Вы уверены, что хотите удалить документ?`}
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={handlePersonalDataAgreementRemove}
                          >
                            <Button icon={<CancelIcon />} />
                          </Popconfirm>
                        </Col>
                        <Col className={styles.docInfoWrapper}>
                          <div className={styles.docNameWrapper}>
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={() => handleAgreementDownload(personalDataAgreementPath)}
                            >
                              {personalDataAgreement?.name}
                            </span>{' '}
                            <span>({humanFileSize(personalDataAgreement?.size)})</span>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Row>

          <Row gutter={24} className={styles.agreementsRowWrapper}>
            <Row className={styles.singleColorFormRow}>
              <Row className={styles.agreementsFormSubTitle}>
                <Typography.Text>Согласие пользователя на просмотр медицинских сведений</Typography.Text>
              </Row>
            </Row>

            <Row className={styles.agreementUploadButtonContainer}>
              <Col>
                <Row>
                  <Form.Item name="medicalAgreement">
                    {!medCardAgreementPath ? (
                      <Upload
                        listType="text"
                        fileList={[]}
                        customRequest={(file) => handleAgreementChange(file, AgreementType.MedCard)}
                        showUploadList={{ showRemoveIcon: false }}
                        className={styles.uploadDocumentForm}
                      >
                        <Button className={styles.buttonUploadAgreement}>Загрузить документ</Button>
                      </Upload>
                    ) : (
                      <Row>
                        <Col>
                          <Popconfirm
                            title={`Вы уверены, что хотите удалить документ?`}
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={handleMedCardAgreementRemove}
                          >
                            <Button icon={<CancelIcon />} />
                          </Popconfirm>
                        </Col>
                        <Col className={styles.docInfoWrapper}>
                          <div className={styles.docNameWrapper}>
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={() => handleAgreementDownload(medCardAgreementPath)}
                            >
                              {medCardAgreement?.name}
                            </span>{' '}
                            <span>({humanFileSize(medCardAgreement?.size)})</span>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Row>

          <Row gutter={24} className={styles.agreementsRowWrapper}>
            <Row className={styles.singleColorFormRow}>
              <Row className={styles.agreementsFormSubTitle}>
                <Typography.Text>Согласие пользователя на получение уведомлений</Typography.Text>
              </Row>
            </Row>

            <Row className={styles.agreementUploadButtonContainer}>
              <Col>
                <Row>
                  <Form.Item name="notificationAgreement">
                    {!notificationAgreementPath ? (
                      <Upload
                        listType="text"
                        fileList={[]}
                        showUploadList={{ showRemoveIcon: false }}
                        customRequest={(file) => handleAgreementChange(file, AgreementType.Notification)}
                        className={styles.uploadDocumentForm}
                      >
                        <Button className={styles.buttonUploadAgreement}>Загрузить документ</Button>
                      </Upload>
                    ) : (
                      <Row>
                        <Col>
                          <Popconfirm
                            title={`Вы уверены, что хотите удалить документ?`}
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={handleNotificationAgreementRemove}
                          >
                            <Button icon={<CancelIcon />} />
                          </Popconfirm>
                        </Col>
                        <Col className={styles.docInfoWrapper}>
                          <div className={styles.docNameWrapper}>
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={() => handleAgreementDownload(notificationAgreementPath)}
                            >
                              {notificationAgreement?.name}
                            </span>{' '}
                            <span>({humanFileSize(notificationAgreement?.size)})</span>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Row>

          <Row gutter={24} className={styles.agreementsRowWrapper}>
            <Row className={styles.singleColorFormRow}>
              <Row className={styles.agreementsFormSubTitle}>
                <Typography.Text>Оферта на оказание платных медицинских услуг
                  с использованием телемедицинских технологий</Typography.Text>
              </Row>
            </Row>

            <Row className={styles.agreementUploadButtonContainer}>
              <Col>
                <Row>
                  <Form.Item name="notificationAgreement">
                    {!offerAgreementPath ? (
                      <Upload
                        listType="text"
                        fileList={[]}
                        showUploadList={{ showRemoveIcon: false }}
                        customRequest={(file) => handleAgreementChange(file, AgreementType.Offer)}
                        className={styles.uploadDocumentForm}
                      >
                        <Button className={styles.buttonUploadAgreement}>Загрузить документ</Button>
                      </Upload>
                    ) : (
                      <Row>
                        <Col>
                          <Popconfirm
                            title={`Вы уверены, что хотите удалить документ?`}
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={handleOfferAgreementRemove}
                          >
                            <Button icon={<CancelIcon />} />
                          </Popconfirm>
                        </Col>
                        <Col className={styles.docInfoWrapper}>
                          <div className={styles.docNameWrapper}>
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={() => handleAgreementDownload(offerAgreementPath)}
                            >
                              {offerAgreement?.name}
                            </span>{' '}
                            <span>({humanFileSize(offerAgreement?.size)})</span>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Row>
        </div>

        <Row className={styles.formButtonsContainer} style={{'justifyContent': 'center'}} gutter={[10, 10]}>
          {/*<Col>*/}
          {/*  <Button disabled className={styles.buttonDemo} onClick={handleShowTestByLink}>*/}
          {/*    Посмотреть кабинет пациента по тестовой ссылке*/}
          {/*  </Button>*/}
          {/*</Col>*/}

          <Col className={styles.saveButtons}>
            <Col>
              <Button className={styles.buttonCancel} onClick={() => setDefaultValues()}>
                Отмена
              </Button>
            </Col>

            <Col>
              <Button
                className={isSubmitDisabled ? styles.buttonSubmitDisabled : styles.buttonSubmit}
                htmlType="submit"
                disabled={isSubmitDisabled}
                loading={isLoading}
              >
                Сохранить настройки
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default WhiteLabelSettingsPage;
