import { Image, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

interface ICompanyCardProps {
  title: string;
  description: string;
  imagePath?: string;
  linkPath: string;
}

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions*/

function CompanyCard(props: ICompanyCardProps) {
  const { title, description, imagePath, linkPath } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.companyCard} onClick={() => navigate(linkPath)}>
        <div className={styles.cardTitle}>
          <Typography.Text>{title}</Typography.Text>
        </div>
        <div className={styles.cardDescription}>
          <Typography.Text>{description}</Typography.Text>
        </div>
        <Image preview={false} src={imagePath} alt={`logo-${title}`} />
      </div>
    </>
  );
}

export default CompanyCard;
