import {
  OidcClientSettings,
  OidcMetadata,
  SigninRequest,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts';
import { notification } from 'antd';

const IDENTITY_CONFIG: OidcClientSettings = {
  authority: `${window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL}/realms/Mila/`,
  client_id: window.__RUNTIME_CONFIG__.REACT_APP_IDENTITY_CLIENT_ID as string,
  redirect_uri: window.__RUNTIME_CONFIG__.REACT_APP_REDIRECT_URL as string,
  loadUserInfo: true,
  post_logout_redirect_uri: window.__RUNTIME_CONFIG__.REACT_APP_REDIRECT_URL,
  scope: 'openid',
  client_secret: window.__RUNTIME_CONFIG__.REACT_APP_IDENTITY_CLIENT_SECRET as string,
};

const METADATA_OIDC: Partial<OidcMetadata> = {
  authorization_endpoint: `${window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL}/realms/Mila/protocol/openid-connect/auth`,
  token_endpoint: `${window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL}/realms/Mila/protocol/openid-connect/token`,
  userinfo_endpoint: `${window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL}/realms/Mila/protocol/openid-connect/userinfo`,
  end_session_endpoint: `${window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL}/realms/Mila/protocol/openid-connect/logout`,
};

class CustomUserManager extends UserManager {
  public constructor(settings: UserManagerSettings) {
    super(settings);
  }

  public createLoginRequest = async (): Promise<SigninRequest> => {
    return await this._client.createSigninRequest({});
  };
}

class AuthService {
  public UserManager: CustomUserManager;

  public constructor() {
    this.UserManager = new CustomUserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    this.UserManager.events.addUserLoaded(() => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        // do nothing
      }
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.logout();
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      this.revokeTokens();
    });
  }

  public signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then((user) => {
      console.log(user);
    });
  };

  public signInWithAmo = async () => {
    await this.UserManager.signinRedirect({
      prompt: 'login',
      scope: 'openid email',
      extraQueryParams: { kc_idp_hint: 'amo-crm' },
    });
  };

  public getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  public getToken = async () => {
    return (await this.UserManager.getUser())?.id_token;
  };

  public parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  public isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL}:${window.__RUNTIME_CONFIG__.REACT_APP_IDENTITY_CLIENT_ID}`,
      )!,
    );

    return !!oidcStorage && !!oidcStorage.id_token;
  };

  public signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  public signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  public signInWithLoginAndPassword = async (login: string, password: string): Promise<void> => {
    await this.UserManager.removeUser();
    await this.UserManager.signinResourceOwnerCredentials({
      username: login,
      password: password,
      skipUserInfo: false,
    })
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
        notification.error({ message: 'Неверная электронная почта или пароль' });
      });
  };

  public resetCredentialsUrl = async (): Promise<string> =>
    `${IDENTITY_CONFIG.authority}login-actions/reset-credentials?client_id=${IDENTITY_CONFIG.client_id}`;

  public logout = async (): Promise<void> => {
    await this.UserManager.signoutSilent();
    await this.UserManager.clearStaleState();
  };

  public changeUserPasswordUrl = async (): Promise<string> => {
    const signInRequest = await this.UserManager.createLoginRequest();
    return `${signInRequest.url}&kc_action=UPDATE_PASSWORD`;
  };

  public signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(`${process.env.PUBLIC_URL}/`);
    });
    this.UserManager.clearStaleState();
  };

  public revokeTokens() {
    this.UserManager.revokeTokens();
  }
}

export const oidcAuthService = new AuthService();
