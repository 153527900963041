import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';

const useSelectorSortFilter = () => {
  const filterOptions: FilterFunc<DefaultOptionType> = (inputValue, option) => {
    if (inputValue.length < 3) return true;

    const a = `${option!.label}`.toLowerCase();
    const b = inputValue.toLowerCase();

    return a.includes(b);
  };

  const sortOptions = (optionA: DefaultOptionType, optionB: DefaultOptionType) => {
    return `${optionA.label}`.toLowerCase().localeCompare(`${optionB.label}`.toLowerCase());
  };

  return { filterOptions, sortOptions };
};

export default useSelectorSortFilter;
