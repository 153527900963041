import { BranchFilter } from 'src/common/components/BranchFilter/BranchFilter';
import { CheckButton, EditIcon } from 'src/common/Icons/icons';
import { ColumnsType } from 'antd/es/table';
import { ConfirmEditModal } from 'src/common/components/ConfirmEditModal/ConfirmEditModal';
import { CustomTable } from 'src/common/components/CustomTable/CustomTable';
import { Form, Layout } from 'antd';
import { RecordsStub } from 'src/common/components/RecordsStub/RecordsStub';
import { StatusText } from 'src/common/components/StatusText/StatusText';
import { WaitingListRequest } from 'src/common/types';
import { getPageVisibility } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import { useForm } from 'antd/es/form/Form';
import { useGetWaitingListRequestsQuery, useUpdateWaitingListRequestMutation } from 'src/app/services/api';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';

export const WaitingListRequests = () => {
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [selectedBranchId, setSelectedBranchId] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const isRecordsVisible =
    getPageVisibility(permissions, 'can-access-page-records-waiting-list-clinic') ||
    getPageVisibility(permissions, 'can-access-page-appointments-waiting-list');

  const [form] = useForm();
  const [editingKey, setEditingKey] = useState('');

  const { data: recordsList, refetch } = useGetWaitingListRequestsQuery({
    sortType: 'created desc',
    offset: pageOffset,
    count: pageCount,
    branchId: selectedBranchId,
  });

  const [updateWaitingListRequest] = useUpdateWaitingListRequestMutation();

  const formattedRecords = useMemo(
    () =>
      recordsList?.data
        ? recordsList.data.map((record) => ({
            ...record,
            created: dayjs(record.created),
            birthDay: dayjs(record.birthDay),
          }))
        : [],
    [recordsList],
  );

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };

  const isEditing = useCallback(
    (record: WaitingListRequest) => record.idMilaUserPatientPutMeOnTheWaitingListRequest === editingKey,
    [editingKey],
  );

  const edit = useCallback(
    (record: WaitingListRequest, e: Event) => {
      e.stopPropagation();
      form.setFieldsValue({ comment: record.comment, status: record.status });
      if (record.idMilaUserPatientPutMeOnTheWaitingListRequest) {
        setEditingKey(record.idMilaUserPatientPutMeOnTheWaitingListRequest);
      }
    },
    [form],
  );

  const save = useCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    await updateWaitingListRequest({ ...values, id: editingKey });
    setEditingKey('');
    refetch();
  }, [editingKey, form, refetch, updateWaitingListRequest]);

  const columns: ColumnsType<WaitingListRequest> = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'edit',
        width: 70,
        render: (_: any, record: WaitingListRequest) => {
          const editable = isEditing(record);
          return editable ? (
            <CheckButton className={styles.EditButton} onClick={() => save()} />
          ) : (
            <EditIcon className={styles.EditButton} onClick={(e) => edit(record, e as unknown as Event)} />
          );
        },
      },

      {
        title: 'ID записи',
        dataIndex: 'milaId',
        key: 'milaId',
      },
      {
        title: 'Статус заявки',
        dataIndex: 'status',
        key: 'status',
        editable: true,
        width: 190,
        render: (_: any, record: WaitingListRequest) => {
          return <StatusText record={record} />;
        },
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) => item1.status - item2.status,
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        editable: true,
        width: 230,
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) => item1.comment.localeCompare(item2.comment),
      },
      {
        title: 'Создана',
        dataIndex: 'created',
        key: 'created',
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) => item1.created.diff(item2.created),
        render: (val: Dayjs) => val.format('HH:mm DD.MM.YYYY'),
      },
      {
        title: 'Пациент',
        dataIndex: 'fio',
        key: 'fio',
        className: styles.ColoredText,
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) => item1.fio.localeCompare(item2.fio),
      },
      {
        title: 'Дата рожд.',
        dataIndex: 'birthDay',
        key: 'birthDay',
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) => item1.birthDay.diff(item2.birthDay),
        render: (val: Dayjs) => val.isValid() ? val.format('DD.MM.YYYY') : '-',
      },
      {
        title: 'Телефон',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) =>
          item1.phoneNumber.localeCompare(item2.phoneNumber),
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        className: styles.ColoredText,
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) => item1.email.localeCompare(item2.email),
      },
      {
        title: 'Специалист',
        dataIndex: 'doctorFio',
        key: 'doctorFio',
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) =>
          item1.doctorFio.localeCompare(item2.doctorFio),
      },
      {
        title: 'Специализация',
        dataIndex: 'doctorSpeciality',
        key: 'doctorSpeciality',
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) =>
          item1.doctorSpeciality.localeCompare(item2.doctorSpeciality),
      },
      {
        title: 'Филиал',
        dataIndex: 'branchName',
        key: 'branchName',
        width: 230,
        sorter: (item1: WaitingListRequest, item2: WaitingListRequest) =>
          item1.branchName.localeCompare(item2.branchName),
      },
    ],
    [edit, isEditing, save],
  );

  const mergedColumns = columns.map((col) => {
    // @ts-ignore
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: WaitingListRequest) => ({
        record,
        // @ts-ignore
        inputType: col.dataIndex === 'status' ? 'select' : 'text',
        // @ts-ignore
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleRowClick = (record: WaitingListRequest) => {
    if (editingKey && record.idMilaUserPatientPutMeOnTheWaitingListRequest !== editingKey) {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    save();
  };

  const handleCancel = () => {
    setEditingKey('');
    setIsModalOpen(false);
  };

  const handleBranchSelect = (value: string) => {
    setSelectedBranchId(value);
  };

  return (
    <Layout className={styles.TableContainer}>
      {isRecordsVisible && <BranchFilter value={selectedBranchId} onChange={handleBranchSelect} />}
      <Form form={form}>
        {isRecordsVisible ? (
          <CustomTable
            dataSource={formattedRecords}
            // @ts-ignore
            columns={mergedColumns}
            total={recordsList?.total}
            onPageChange={handlePageChange}
            pageNumber={pageNumber}
            onRowClick={handleRowClick}
            className={styles.RecordsTable}
          />
        ) : (
          <RecordsStub
            title="Здесь будут видны все заявки на прием к специалистам, у которых нет открытого расписания."
            text="Если вы считаете, что данные тут не отображены, пожалуйста, обратитесь в службу технической поддержки."
          />
        )}
      </Form>
      <ConfirmEditModal isOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </Layout>
  );
};
