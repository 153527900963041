import React, {useEffect, useState} from 'react';
import {useGetPracticeBranchesQuery} from '../../app/services/api';
import {useAppSelector} from '../../app/hooks';
import {Branch} from '../../common/types';
import {getPreparedBranches} from '../../common/utils/utils';
import {Button, Col, Layout, notification, Row, Select, Typography} from 'antd';
import {DownCaretIcon} from '../../common/Icons/icons';
import { ReactComponent as Warn } from '../../assets/Warn.svg';
import { ReactComponent as OkIcon } from '../../assets/OkIcon.svg';
import { useRunSzpvSyncForMedOrganizationMutation } from 'src/app/services/apiSync';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

export const ScheduleSyncPage = () => {
    const [branch, setBranch] = useState<string>("");
    const [branchesListToSelector, setBranchesListToSelector] = useState<{
        label: string;
        value: string;
    }[]>([]);
    const [branchesHasTmData, setBranchesHasTmData] = useState<boolean>(false);
    const [syncDate, setSyncDate] = useState<Date | null>(null);

    const { data: branchesList } = useGetPracticeBranchesQuery('');
    const availableBranches = useAppSelector((state) => state.app.availableBranches);
    const [runSzpvSyncForMedOrganization, syncResult] = useRunSzpvSyncForMedOrganizationMutation();

    const prepareBranches = (branchesData: Branch[] | undefined) =>
        branchesData ? getPreparedBranches(branchesList || [], availableBranches ?? []) : [];

    useEffect(() => {
        const branchesWithTmData = branchesList?.filter((item) => item.useTmData);
        const preparedBranches = prepareBranches(branchesWithTmData);

        if (branchesWithTmData) {
            const brachesToSelector = preparedBranches.filter((item) => {
                const itemIndex = branchesWithTmData.findIndex((branch) => branch.branchId === item.value);
                return itemIndex !== - 1;
            });

            brachesToSelector && setBranchesListToSelector(brachesToSelector);
        }
    }, [branchesList]);

    useEffect(() => {
        branchesList?.forEach((branch) => {
            if (branch.useTmData &&
                branchesListToSelector.findIndex((item) => item.value === branch.branchId) !== -1) {
                setBranchesHasTmData(true);
            }
        });
    }, [branchesListToSelector, branchesList]);

    const syncRequest = () => {
        runSzpvSyncForMedOrganization({ moId: branch })
            .unwrap()
            .then(() => {
                setSyncDate(new Date());
            })
            .catch((error) => notification.error({ message: `Ошибка синхронизации: ${error.data.detail}` }));
    };

    return (
        <Layout className={`${styles.ScheduleWrapper} ${!branchesHasTmData ? styles.NoScheduleWrapper : ""}`}>
            {
                branchesHasTmData &&
                <>
                    <Typography className={styles.Title}>Обмен данными с МИС</Typography>
                    <div className={styles.generalDataContainer}>
                        <Col className={styles.generalDataFormTitle}>
                            <Typography.Text>Синхронизация данных</Typography.Text>
                        </Col>
                        <Col className={styles.infoBlock}>
                            <div>
                                <Typography className={styles.infoNote}>
                                    <Warn/>
                                    Как происходит синхронизация с МИС
                                </Typography>
                                <div>
                                    Данные расписания обновляются автоматически (для них не нужно запускать
                                    синхронизацию)!
                                    При синхронизации обновляются данные о врачах и услугах (включая стоимость)
                                    в выбранном филиале (только те, для которых МИС передала статус active=true).
                                    Если из МИС получен новый врач - по умолчанию его профиль будет выключен
                                    для отображения пациентам в Mila - в разделе «Врачи и медперсонал» необходимо
                                    будет перепроверить профиль такого врача, дополнить данными и включить отображение
                                    для пациентов. Новые услуги будут включены сразу.
                                </div>
                                <br />
                                <div>
                                    Если синхронизация прошла с ошибкой - никакие обновления не будут загружены,
                                    в ЛК отображаются данные, загруженные при последней успешной синхронизации.
                                    Пожалуйста, при возникновении ошибок синхронизации, обратитесь в службу
                                    поддержки N3.Health или вашей МИС.
                                </div>
                            </div>
                        </Col>
                        <Select
                            className={styles.BranchesSelector}
                            options={branchesListToSelector}
                            placeholder={'Выберите филиал'}
                            onChange={(value) => setBranch(value)}
                            suffixIcon={<DownCaretIcon/>}
                        />
                        <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
                            <Col>
                                <Button disabled={!branch} loading={syncResult.isLoading} className={styles.buttonSubmit} onClick={syncRequest}>
                                    Запустить синхронизацию
                                </Button>
                            </Col>
                        </Row>
                        <div>
                            Синхронизация может занять некоторое время. Пожалуйста, не закрывайте страницу.
                        </div>
                        {syncDate && <div style={{marginTop: 22}}><OkIcon style={{marginRight: 10}}/>
                            Данные успешно синхронизированы: {dayjs(syncDate).format('DD MMMM YYYY г. в HH:mm ')}</div>}
                    </div>
                </>
            }
        </Layout>
    );
};