import { BranchFilter } from 'src/common/components/BranchFilter/BranchFilter';
import { Layout, Row } from 'antd';
import { NavTabs } from 'src/common/components/NavTabs/NavTabs';
import { Outlet, useLocation } from 'react-router';
import { getPreparedBranches } from '../../../common/utils/utils';
import { setServiceBranch } from 'src/app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useEffect, useMemo } from 'react';
import { useGetPracticeBranchesQuery } from '../../../app/services/api';
import styles from './styles.module.scss';
import { ServiceTabKey } from '../../../common/types/enums';

const tabsItems = [
  {
    label: 'Виджет онлайн-записи',
    key: 'widget',
  },
  {
    label: 'Профиль клиники в Mila',
    key: 'patient',
  },
  {
    label: 'White Label',
    key: 'whiteLabel',
  },
  {
    label: 'Телемедицина в Mila',
    key: 'tmk',
  },
  {
    label: 'Расписание в Mila',
    key: 'schedule',
  },
];

export const ServicePage = () => {
  const { pathname } = useLocation();
  const activeTabKey = useMemo(() => pathname.split('/').reverse()[0], [pathname]);
  const dispatch = useAppDispatch();
  const serviceBranch = useAppSelector((state) => state.app.serviceBranchId);
  const availableFiltersBranches = useAppSelector((state) => state.app.availableBranches) || [];

  const isWhiteLabelTabActive = activeTabKey === ServiceTabKey.WhiteLabel;

  const { data: branchesList } = useGetPracticeBranchesQuery('');

  const handleBranchSelect = (value: string) => {
    dispatch(setServiceBranch(value));
  };

  useEffect(() => {
    if (!serviceBranch && branchesList && branchesList?.length > 0) {
      dispatch(setServiceBranch(getPreparedBranches(branchesList, availableFiltersBranches)?.[0]?.value));
    }
  }, [serviceBranch, branchesList]);

  return (
    <Layout className={styles.ServicePageWrapper}>
      <NavTabs activeTabKey={activeTabKey} items={tabsItems} basicPath="services" />
      {!isWhiteLabelTabActive && (
        <Row className={styles.BranchSelectorRow}>
          <BranchFilter onChange={handleBranchSelect} value={serviceBranch ?? ''} addAll={false} />
        </Row>
      )}
      <Outlet />
    </Layout>
  );
};
