import { AppointmentType } from 'src/common/types';
import { Pagination, Spin, Table } from 'antd';
import { useAppSelector } from '../../../../app/hooks';
import { useGetDoctorGeneralInfoQuery, useGetDoctorProfileRecordsQuery } from '../../../../app/services/api';
import { useGetFerSpecialitiesQuery } from '../../../../app/services/apiDictionary';
import { useLocation, useNavigate } from 'react-router-dom';
import DoctorTabs from './../DoctorTabs';
import DoctorTitle from '../DoctorTitle';
import NavSubMenu from '../../NavSubMenu';
import React, { useMemo, useState } from 'react';
import RecordsStubPage from './RecordsStubPage';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

function DoctorRecordsPage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const practiceDoctorId = useMemo(() => pathname.split('/').reverse()[1], [pathname]);
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);

  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);

  const {
    data: doctorGeneralInfo,
    error: errorDoctorGeneralInfo,
    isLoading,
  } = useGetDoctorGeneralInfoQuery(
    { practiceId: practiceId!, practiceDoctorId },
    {
      skip: !practiceId,
    },
  );

  const { data: recordsList, isLoading: isDoctorRecordsLoading } = useGetDoctorProfileRecordsQuery(
    {
      practiceId: practiceId!,
      practiceDoctorId: practiceDoctorId,
      sortType: 'created desc',
      offset: pageOffset,
      count: pageCount,
    },
    { skip: !practiceId },
  );

  const { data: specialitiesDictionaryList } = useGetFerSpecialitiesQuery('');

  const showTotal = (total: number) => {
    return <span className={styles.totalPaginationResults}>Всего записей: {total}</span>;
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };

  const columns = [
    {
      title: 'ID записи',
      dataIndex: 'milaId',
      key: 'milaId',
      width: 107,
      // ellipsis: true,
    },
    {
      title: 'Создана',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 147,
      ellipsis: true,
    },
    {
      title: 'Синхр. с МИС.',
      dataIndex: 'misSync',
      key: 'misSync',
      width: 135,
    },
    {
      title: 'Тип',
      dataIndex: 'appType',
      key: 'appType',
      width: 120,
    },
    {
      title: 'Дата приёма',
      dataIndex: 'receptionDate',
      key: 'receptionDate',
      width: 132,
    },
    {
      title: 'Время',
      dataIndex: 'time',
      key: 'time',
      width: 116,
    },
    {
      title: 'Пациент',
      dataIndex: 'patiient',
      key: 'patiient',
      width: 210,
      className: styles.cellLinkText,
      ellipsis: true,
    },
    {
      title: 'Дата рожд.',
      dataIndex: 'birthdate',
      key: 'birthdate',
      width: 129,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      width: 155,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      width: 156,
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'speciality',
      key: 'speciality',
      width: 198,
    },
    {
      title: 'Услуги',
      dataIndex: 'amenities',
      key: 'amenities',
      width: 166,
    },
    {
      title: 'Цена, Р.',
      dataIndex: 'price',
      key: 'price',
      width: 98,
    },
    {
      title: 'Филиал',
      dataIndex: 'branch',
      key: 'branch',
      width: 175,
      ellipsis: true,
    },
  ];

  const getAppointmentTypeText = (recordData: AppointmentType) => {
    switch (+recordData) {
      case AppointmentType.OnSite:
        return 'В клинике';
      case AppointmentType.AtHome:
        return 'На дому';
      case AppointmentType.Tmk:
        return 'Онлайн';
      default:
        return '';
    }
  };

  const getMisSyncText = (recordData: boolean) => {
    return recordData ? (
      <div className={styles.statusTextWrapper}>
        <div className={styles.greenCircle}></div>
        <span>Да</span>
      </div>
    ) : (
      <div className={styles.statusTextWrapper}>
        <div className={styles.greenCircle} style={{ background: '#FA6060' }}></div>
        <span>Нет</span>
      </div>
    );
  };

  const getDateAppointmentLabel = (dataString: string) => dayjs(dataString).format('HH:mm DD.MM.YYYY');
  const getDateLabel = (dataString: string) => dayjs(dataString).format('DD.MM.YYYY');
  const getAppointmentTime = (dataStartString: string, dataEndString: string) =>
    `${dayjs(dataStartString).format('HH:mm')} - ${dayjs(dataEndString).format('HH:mm')}`;

  const getFsmsName = (id: string) =>
    specialitiesDictionaryList?.filter((speciality: { code: string; name: string }) => speciality.code === id)[0]?.name;

  const getMilaId = (id: string) => `...${id.slice(-4)}`;

  const prepareRecordsTable = (data: any) => {
    return data
      ? data?.map((record: any) => {
          return {
            key: record.milaId,
            milaId: getMilaId(record.milaId),
            createdAt: getDateAppointmentLabel(record.created),
            misSync: getMisSyncText(record.isMisConfirmed),
            appType: getAppointmentTypeText(record.appointmentType),
            receptionDate: getDateLabel(record.visitStartDate),
            time: getAppointmentTime(record.visitStartDate, record.visitEndDate),
            patiient: record.patientFullName,
            birthdate: getDateLabel(record.patientBirthDay),
            phone: record.patientPhone,
            email: record.patientEmail,
            speciality: getFsmsName(record.specialization),
            amenities: record.specializationServiceMisName,
            price: record.cost,
            branch: record.medOrganizationShortName,
          };
        })
      : [];
  };

  const isRecordsVisible = recordsList?.data.length !== 0 && !isDoctorRecordsLoading;

  if (errorDoctorGeneralInfo) {
    navigate(`/company/doctors/${practiceId ?? ''}`);
  }

  return (
    <>
      <NavSubMenu />
      {isLoading ? (
        <Spin size="large" className={styles.pageLoader} />
      ) : (
        <div className={styles.pageContainer}>
          <DoctorTitle doctorGeneralInfo={doctorGeneralInfo} practiceId={practiceId} />

          <DoctorTabs doctorId={practiceDoctorId} />
          {isRecordsVisible ? (
            <>
              <div className={styles.pageContentContainer}>
                <Table
                  columns={columns}
                  dataSource={prepareRecordsTable(recordsList?.appointmentsBatch)}
                  pagination={false}
                  className={styles.recordsTable}
                  scroll={{ x: 2000 }}
                />
              </div>
              <Pagination
                total={recordsList?.total}
                className={styles.paginationWrapper}
                showSizeChanger
                showTotal={showTotal}
                onChange={handlePageChange}
                current={pageNumber}
              />
            </>
          ) : (
            <RecordsStubPage />
          )}
        </div>
      )}
    </>
  );
}

export default DoctorRecordsPage;
