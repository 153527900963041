import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PermissionEntity } from 'src/common/types';

export interface AppState {
  selectedBranchId?: string;
  serviceBranchId?: string;
  selectedBranchName?: string;
  networkId?: string;
  medOrganizationId?: string;
  permissions?: PermissionEntity[];
  availableBranches?: string[];
}

const initialState: AppState = {
  selectedBranchId: '',
  serviceBranchId: '',
  selectedBranchName: '',
  networkId: '',
  medOrganizationId: '',
  permissions: [],
  availableBranches: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setBranch: (state, action: PayloadAction<string>) => {
      state.selectedBranchId = action.payload;
    },

    setServiceBranch: (state, action: PayloadAction<string>) => {
      state.serviceBranchId = action.payload;
    },

    setBranchName: (state, action: PayloadAction<string>) => {
      state.selectedBranchName = action.payload;
    },

    setNetwork: (state, action: PayloadAction<string>) => {
      state.networkId = action.payload;
    },

    setMedOrganizationId: (state, action: PayloadAction<string>) => {
      state.medOrganizationId = action.payload;
    },

    setPermissionsList: (state, action: PayloadAction<PermissionEntity[]>) => {
      state.permissions = action.payload;
    },

    setAvailableBranches: (state, action: PayloadAction<string[]>) => {
      state.availableBranches = action.payload;
    },

    resetApp: () => initialState,
  },
});

export const {
  setBranch,
  setServiceBranch,
  setBranchName,
  setNetwork,
  setMedOrganizationId,
  setPermissionsList,
  setAvailableBranches,
  resetApp,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
