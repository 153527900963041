import { useMemo } from 'react';
import { Branch } from '../../common/types';

export const useGetUseTmData = (branchesList: Branch[] | undefined, practiceId: string | undefined) => {
  const useTmData = useMemo((): boolean => {
    if (!branchesList) {
      return true;
    }

    const currentBranch = branchesList.find((branch) => branch.branchId === practiceId);
    if (currentBranch) {
      return !!currentBranch.useTmData;
    } else {
      return true;
    }
  }, [branchesList, practiceId]);
  return useTmData;
};
