import { DownCaretIcon } from 'src/common/Icons/icons';
import { Select, SelectProps } from 'antd';
import React, { useMemo } from 'react';
import styles from './WidthFixedSelect.module.scss';

type IProps = SelectProps;

export const WidthFixedSelect: React.FC<IProps> = ({ ...props }) => {
  const className = useMemo(
    () => (props.className ? `${styles.root} ${props.className}` : styles.root),
    [props.className],
  );

  const options = useMemo(
    () =>
      props.options?.map((option) => {
        return {
          ...option,
          className: option.className ? `${styles.optionWrap} ${option.className}` : styles.optionWrap,
        };
      }) ?? [],
    [props.options],
  );

  return <Select suffixIcon={<DownCaretIcon />} {...props} className={className} options={options}></Select>;
};
