import { AmenitySpecialityContext } from '../rows/RowsList';
import { Button, Checkbox, Input, Modal, Popconfirm, notification } from 'antd';
import { CompositeProfilesSelect } from '../selects/CompositeProfilesSelect';
import {
  DeleteMedOrganizationSpecialtyServiceArgs,
  NewPracticeService,
  PracticeService,
  UpdateMedOrganizationSpecialtyServiceArgs,
} from 'src/common/types';
import { ServicesSelect } from '../selects/ServicesSelect';
import { SpecialitiesSelect } from '../selects/SpecialitiesSelect';
import { ameniniesActions } from 'src/app/services/redux/AmenitiesSlice';
import { useAmenityValidation } from '../hooks/useAmenityValidation';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useContext, useState } from 'react';
import {
  useDeleteServicesSpecialityDataMutation,
  useGetPracticeServicesQuery,
  useUpdateServicesSpecialityDataMutation,
} from 'src/app/services/api';
import FloatLabel from 'src/common/components/FloatLabel';
import styles from './AmenityForm.module.scss';

interface IProps {
  service: PracticeService;
}

export function EditForm({ service }: IProps) {
  const dispatch = useAppDispatch();

  const branchId = useAppSelector((state) => state.app.selectedBranchId!);
  const branchName = useAppSelector((state) => state.app.selectedBranchName);

  const { refetch: refetchServices } = useGetPracticeServicesQuery(branchId);
  const [updateMutation, updateRequest] = useUpdateServicesSpecialityDataMutation();
  const [deleteMutation, deleteRequest] = useDeleteServicesSpecialityDataMutation();

  const { speciality } = useContext(AmenitySpecialityContext);

  const [specialityCode, setSpecialityCode] = useState<string>(speciality.specialityFerId);
  const [serviceName, setServiceName] = useState<string>(service.serviceNameForPatients);
  const [cost, setCost] = useState<string>(`${service.cost}`);
  const [selectedMode, setSelectedMode] = useState<'isTmk' | 'isOnSite' | 'isAtHome'>(
    (service.isTmk && 'isTmk') || (service.isOnSite && 'isOnSite') || (service.isAtHome && 'isAtHome') || 'isOnSite',
  );
  const [profileCode, setProfileCode] = useState<string>(service.compositeProfileId);
  const [serviceCode, setServiceCode] = useState<string>(service.serviceKindId);
  const [showMode, setShowMode] = useState<boolean>(service.showInWidget);

  const isPending = updateRequest.status === 'pending' || deleteRequest.status === 'pending';

  const { validateFields } = useAmenityValidation();

  const isTmk = selectedMode === 'isTmk';
  const isOnSite = selectedMode === 'isOnSite';
  const isAtHome = selectedMode === 'isAtHome';

  const closeEditForm = () => {
    dispatch(ameniniesActions.setEditServiceId(null));
  };

  const cancelHandle = () => {
    if (isPending) return;

    closeEditForm();
  };

  const submitHandle = () => {
    if (isPending) return;

    const data: NewPracticeService = {
      specialityFerId: specialityCode!,
      nameForPatient: serviceName,
      cost: cost as unknown as number,
      isOnSite: isOnSite,
      isTmk: isTmk,
      isAtHome: isAtHome,
      showInWidget: showMode,
      compositeProfileId: profileCode!,
      serviceKindId: serviceCode!,
    };

    if (!validateFields(data)) return;

    const putData: UpdateMedOrganizationSpecialtyServiceArgs = {
      ...data,
      medOrganizationId: branchId,
      serviceId: service.serviceId,
      specialityId: service.specialityId,
      specialityName: speciality.specialityName,
    };

    updateMutation(putData)
      .unwrap()
      .then(() => {
        refetchServices();
        notification.success({ message: 'Услуга успешно обновлена' });
        closeEditForm();
      })
      .catch((error) => {
        notification.error({
          message: `При выполнении запроса возникла ошибка: ${error.status} ${error.data.title ?? ''}`,
        });
      });
  };

  const deleteHandle = () => {
    if (isPending) return;

    const data: DeleteMedOrganizationSpecialtyServiceArgs = {
      medOrganizationId: speciality.medOrganizationId,
      specialityId: service.specialityId,
      serviceId: service.serviceId,
    };

    deleteMutation(data)
      .unwrap()
      .then(() => {
        refetchServices();
        closeEditForm();
        notification.success({ message: 'Услуга удалена' });
      })
      .catch((error) => {
        notification.error({
          message: `При выполнении запроса возникла ошибка: ${error.status} ${error.data.title ?? ''}`,
        });
      });
  };

  return (
    <Modal
      className={styles.modal}
      open={true}
      onCancel={cancelHandle}
      centered
      footer={null}
      width={763}
      maskClosable={false}
    >
      <div className={`${styles.container} ${styles.editForm}`}>
        <h1 className={styles.title}>{`Редактировать услугу в ${branchName}`}</h1>

        <div className={styles.block}>
          <SpecialitiesSelect currentValue={specialityCode} onSelect={setSpecialityCode} />

          <FloatLabel label="Наименование услуги для пациентов *" fixSmall={!!serviceName}>
            <Input
              className={styles.input}
              value={serviceName}
              onChange={(event) => setServiceName(event.target.value)}
            />
          </FloatLabel>

          <div className={styles.row}>
            <div className={styles.cost}>
              <FloatLabel label="Цена услуги, Р *" fixSmall={!!cost}>
                <Input className={styles.input} value={cost} onChange={(event) => setCost(event.target.value)} />
              </FloatLabel>
            </div>

            <label className={styles.radio}>
              <Input type="radio" checked={isOnSite} onClick={() => setSelectedMode('isOnSite')} onChange={() => {}} />
              <span>В клинике</span>
            </label>

            <label className={styles.radio}>
              <Input type="radio" checked={isTmk} onClick={() => setSelectedMode('isTmk')} onChange={() => {}} />
              <span>Онлайн</span>
            </label>

            <label className={styles.radio}>
              <Input type="radio" checked={isAtHome} onClick={() => setSelectedMode('isAtHome')} onChange={() => {}} />
              <span>На дому</span>
            </label>
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.attributes}>Атрибуты услуги</div>
          <CompositeProfilesSelect currentValue={profileCode} onSelect={setProfileCode} />
          <ServicesSelect currentValue={serviceCode} onSelect={setServiceCode} />
          <Checkbox rootClassName={styles.showmode} checked={showMode} onChange={(e) => setShowMode(e.target.checked)}>
            Отображать услугу для пациентов в сервисах Mila
          </Checkbox>
        </div>

        <div className={styles.buttons}>
          <Popconfirm
            title={`Удалить услугу "${service.serviceNameForPatients}"?`}
            okText="Удалить"
            cancelText="Отмена"
            onConfirm={deleteHandle}
          >
            <Button className={styles.delete}>Удалить услугу из филиала</Button>
          </Popconfirm>
          <Button className={styles.cancel} onClick={cancelHandle}>
            Отмена
          </Button>

          <Button className={styles.submit} loading={updateRequest.isLoading} onClick={submitHandle}>
            Сохранить изменения
          </Button>
        </div>
      </div>
    </Modal>
  );
}
