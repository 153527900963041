import { Button } from 'antd';
import { CreateForm } from './forms/CreateForm';
import { IconPlus } from 'src/common/Icons/icons';
import React, { useState } from 'react';
import styles from './TableHeader.module.scss';
import { useAppSelector } from '../../../../app/hooks';
import { useGetPracticeBranchesQuery } from '../../../../app/services/api';
import { useGetUseTmData } from '../../helpers';

export const TableHeader: React.FC = () => {
  const [formVisible, setFormVisible] = useState<boolean>(false);

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);

  const useTmData = useGetUseTmData(branchesList, practiceId);

  return (
    <>
      <div className={styles.container}>
        <Button className={`${styles.create} ${useTmData && styles.hidden}`} onClick={() => setFormVisible(true)}>
          <IconPlus />
        </Button>

        <div>Наименование услуги</div>

        <div>Цена, ₽</div>

        <div className={styles.textCenter}>В клинике</div>

        <div className={styles.textCenter}>Онлайн</div>

        <div className={styles.textCenter}>На дому</div>

        <div className={styles.textCenter}>Отображение</div>

        <div></div>
      </div>
      <CreateForm open={formVisible} onClose={() => setFormVisible(false)} />
    </>
  );
};
