import AboutServiceProfileBanner from 'src/assets/AboutServiceProfileBanner.svg';
import AboutServiceScheduleBanner from 'src/assets/AboutServiceScheduleBanner.svg';
import AboutServiceTmkBanner from 'src/assets/AboutServiceTmkBanner.svg';
import AboutServiceWhiteLabelBanner from 'src/assets/AboutServiceWhiteLabelBanner.svg';
import AboutServiceWidgetBanner from 'src/assets/AboutServiceWidgetBanner.svg';

export const serviceData = {
  widget: {
    slideshowImageStaticFiles: [AboutServiceWidgetBanner],
    aboutServiceDeveloper: 'N3.Health',
    aboutServicePrice: 'вкл. в тариф Mila',
    aboutServiceDescription: `Сервис входит во все тарифы Mila и не требует отдельной оплаты. Виджет записи для сайта клиники позволяет вывести в готовом интерфейсе расписание на разные виды услуг — например, очный прием в клинике, онлайн-консультации в Mila, вызов на дом, а также запись в лист ожидания и запрос обратного звонка. Пациентам доступен подбор и поиск по специализациям, врачам, филиалам, на карте, а также оплата онлайн-консультации прямо в виджете. Виджет адаптирован для экранов компьютеров и мобильных устройств.`,
    collapseTitle: `Подробнее о возможностях сервиса`,
    collapseContent: ` 
    <h3>Возможности виджета</h3>
    <ul>
      <li>Поддерживаемые маршруты: запись в клинику, запись на онлайн-консультацию, вызов на дом, запись в лист ожидания, запрос обратного звонка. Запись в слот или заявка на администратора клиники</li>
      <li>Оплата онлайн-консультаций прямо внутри виджета</li>
      <li>Поиск по специальности, врачу, филиалу, услуге</li>
      <li>Оформление публичных профилей компании и врачей </li>
      <li>Мобильная версия</li>
      <li>Виджет встраивается на сайт простым добавлением готового кода (по аналогии с кодом счетчика системы статистики) и отображается для пациента во всплывающем окне.</li>
      <li>Клиника может предварительно загрузить логотип и адаптировать внешний вид виджета под свой фирменный стиль. Также клиника может размещать промо (баннер) на стартовом экране виджета. </li>
      <li>В виджете подключена авторизация пациентов через сервисы Госуслуги и Сбер ID.</li>
      </ul>`,
    limitationsDescription:
      'Для отображения в виджете онлайн-расписания из МИС требуется подключение к сервису N3.Health СЗПВ (Сервис записи на прием к врачу). Без интеграции с сервисом виджет записи будет работать в режиме витрины врачей и услуг и принимать заявки на запись через администратора клиники. Прием платежей в виджете возможен за услуги телемедицинских консультаций при условии подключения по сервису «Телемедицина в Mila» (с таких платежей взимается комиссия).',
    setupDescription: `Сервис входит во все тарифы Mila. Подключение проводится клиникой самостоятельно. <br/>
    <br/>
    <ol>
    <li>Для установки виджета перейдите во вкладку <a href='/mila_practice_account/services/widget/settings'>«Настройки»</a> сервиса и выбирайте нужные вам опции и варианты. Завершив выбор настроек виджета, сохраните их по клику на «Сохранить изменения». По кнопке «Посмотреть виджет по тестовой ссылке» вам доступен предпросмотр виджета с учетом сохраненных настроек и данных о филиалах и услугах клиники. Проверьте корректность отображения данных в виджете. При необходимости вернитесь к заполнению данных или настройке виджета. </li>
    <li>Скопируйте код виджета в разделе «Код для вставки виджета на сайт». Это можно сделать с помощью кнопки «Скопировать код» или другим удобным для вас способом. </li>
    <li>Войдите в административную панель управления вашего сайта и вставьте скопированный HTML-код перед закрывающем тегом <body> на те страницы вашего сайта, куда вы хотите подключить систему. Чаще всего (но не всегда) код нужно вставлять в «шаблон» сайта. Чтобы определить нужный вид и текст для кнопки создайте css правило под селектор MilaButton. </li>
    <li>Если всё сделано правильно, на всех выбранных в предыдущем пункте страницах вашего сайта появится кнопка записи, по клику на которую во всплывающем окне будет отображаться виджет. </li>
    </ol>`,
    supportDescription: 'Техподдержка осуществляется СТП N3.Health',
  },
  patient: {
    slideshowImageStaticFiles: [AboutServiceProfileBanner],
    aboutServiceDeveloper: 'N3.Health',
    aboutServicePrice: 'вкл. в тариф Mila',
    aboutServiceDescription: `Сервис входит во все тарифы Mila и не требует отдельной оплаты. My.mila.online - веб-приложение для пациентов от N3.Health - цифровой ассистент для контроля здоровья, умеющий объединять данные пользователей из разных источников, формировать интегрированную медицинскую карту, выявлять рекомендации врачей и предоставлять пациентам выбор сервисов для поддержки здоровья от клиник-партнеров. Веб-приложение обеспечивает замкнутый цикл взаимодействия между пациентами и клиниками. Пациент может в несколько кликов записаться в клиники, подключенные к платформе N3.Health, поделиться с врачом данными из своей медкарты, после приема получить медицинские документы и рекомендации врача прямо в веб-приложение, а далее при необходимости записаться на повторный прием или онлайн-консультацию.
    <br/>
    <br/>

    <ul>
      <li>Клиника может создать свой профиль в веб-приложении для пациентов Mila и подключить для всех пользователей функцию записи на прием: в клинике, онлайн или на дому. В случае интеграции по сервису N3.Health СЗПВ (Сервис записи на прием к врачу) Mila передаст все записи напрямую в МИС клиники. Также доступна функция ведения расписания клиники внутри Mila. Клиника может публиковать акции и общаться со своими пациентами в чате. </li>
      <li>Услуги клиники включаются в каталог MILA бесплатно и без оплаты за лиды. Исключение: при использовании сервиса «Телемедицина в Mila» взимается комиссия от платежа пользователя.</li>
      <li>Если клиника ранее передавала медицинские данные по пациентам в ЕГИСЗ, Mila распознает таких пациентов среди пользователей веб-приложения и добавит им данную клинику в избранные. Такая клиника будет отображаться прямо на главной странице сервиса, а также «в топе» каталога у пациента. Это помогает медорганизациям возвращать своих «потерянных» пациентов. </li>
      </ul>
      `,
    collapseTitle: `Подробнее о возможностях сервиса`,
    collapseContent: `
    <h3>Сервисы Mila</h3>
    <div class='row'>
      <div class='col'>
        <h4>Аккаунт и профили</h4>
        <ul>
          <li>Веб-версия для работы с компьютера</li>
          <li>Установка PWA на мобильные телефоны</li>
          <li>Авторизация через Госуслуги и Сбер ID</li>
          <li>Свободная регистрация по e-mail</li>
          <li>Онбординг и подписание согласий</li>
          <li>Добавление связанных профилей для детей и родственников</li>
          <li>Управление доступом к данным</li>
          <li>Создание профиля в Mila при записи через виджет</li>
        </ul>
        <h4>Запись</h4>
        <ul>
          <li>Запись на прием в клинику</li>
          <li>Запись на онлайн-консультации, оплата</li>
          <li>Заявки на вызов на дом</li>
          <li>Запись в лист ожидания</li>
          <li>Отмена, перенос записи. Отправка заявки на запись к врачу, когда онлайн-расписание скрыто</li>
          <li>Получение заключения в Mila по итогу консультации</li>
          <li>Витрина специализаций и цен, текстовый поиск, поиск по карте, выбор региона</li>
          <li>Профиль клиники, профили врачей. Управление каталогом услуг и ценами, в том числе персональные правила отображения услуги у врача</li>
          <li>Рейтинг врача, рейтинг клиники на основе оценок приемов</li>
          <li>Блоки «Мои клиники» и «Мои врачи»</li>
          <li>Просмотр детальной информация о записи, добавление в календарь, построение маршрута до клиники</li>
        </ul>
        <h4>Маркетинг и коммуникации</h4>
        <ul>
          <li>Публикация акций и специальных предложений</li>
          <li>Чат с клиникой</li>
          <li>Оценка и отзыв пациента после консультации</li>
          <li>Уведомления об обновлениях внутри сервиса</li>
          <li>Автоматические email-оповещения по событиям</li>
          <li>Оформление публичных профилей компании и врачей</li>
          <li>Контакты клиники в профиле компании</li>
          <li>Обращение в техподдержку</li>
        </ul>
      </div>
      <div class='col'>
        <h4>Интегрированная медкарта</h4>
        <ul>
          <li>Автоматический поиск и загрузка медицинских документов из N3.Health по каждому профилю</li>
          <li>Построение интегрированной медкарты на данных из разных медицинских организаций и лабораторий (заключения, анализы, исследования, рецепты, вакцинация, назначения, рекомендации, другое) </li>
          <li>Отображение случаев медобслуживания на календаре обращений</li>
          <li>PHR - добавление мед. документов пользователем, возможность тегирования по справочникам </li>
          <li>Расшаривание медкарты для врача и свободное расшаривание с огранич. сроком действия ссылки</li>
        </ul>
        <h4>Телемедицина (сервис «Телемедицина в Mila»)</h4>
        <ul>
          <li>Оплата онлайн-консультаций внутри сервиса и виджета</li>
          <li>Видеосвязь, чат</li>
          <li>Статусы оплаты, история платежей и возвратов</li>
          <li>Личный кабинет для врача для онлайн-консультаций</li>
          <li>Не требуются: договор с банком, подключение собственного эквайринга и онлайн-кассы</li>
          <li>Нет абонентской платы за рабочие места врачей, взимается комиссия с платежа пользователя</li>
          <li>«Заморозка» средств на счете пациента и бесплатные возвраты при отмене в течение 6 дней</li>
          <li>Списание оплаты с пациента при отмене записи менее, чем за 2 часа</li>
          <li>Перевод средств на счет клинике в течение 24 часов после списания с пациента</li>
          <li>Передача ссылки на просмотр медкарты в ЛК врача</li>
          <li>Отправка заключения в РЭМД и пользователю Mila</li>        
        </ul>
        <h4>Обмен данными и безопасность</h4>
        <ul>
          <li>Интеграция с МИС через стандартные сервисы платформы N3.Health: ИЭМК, СЗПВ, ОДЛИ, ОДИИ</li>      
          <li>Возможность работы без онлайн-расписания из МИС</li>      
          <li>Официальный «оператор иной информационной системы», уполномоченный Минздравом России для размещения сведений об оказанной медицинской помощи в ЕГИСЗ МЗ РФ</li>      
          <li>Аттестованный оператор персональных данных, регистрационный номер в Реестре операторов Роскомнадзора 78-21-008437, класс защищенности К2</li>        
        </ul>
      </div>
    </div>  
      `,
    limitationsDescription:
      'Для отображения данных от клиники в медкарте пациента необходимо подключение к одному из сервисов N3.Health: ИЭМК,  ОДЛИ или ОДИИ. Для отображения онлайн-расписания (слотов) из МИС требуется подключение к сервису N3.Health СЗПВ (Сервис записи на прием к врачу). Без интеграции с СЗПВ веб-приложение будет работать в режиме витрины врачей и услуг и принимать заявки на запись через администратора клиники. Прием платежей в веб-приложени возможен за услуги телемедицинских консультаций при условии подключения по сервису «Телемедицина в Mila».',
    setupDescription: `Сервис входит во все тарифы Mila. Для подключения обратитесь к вашему менеджеру. `,
    supportDescription: 'Техподдержка осуществляется СТП N3.Health',
  },
  tmk: {
    slideshowImageStaticFiles: [AboutServiceTmkBanner],
    aboutServiceDeveloper: 'N3.Health',
    aboutServicePrice: 'тариф + комиссия',
    aboutServiceDescription: `Сервис входит во все тарифы Mila и не требует отдельной оплаты за подключение и рабочее место врача. Mila позволяет оперативно запустить сервис телемедицинских консультаций «врач-пациент» с онлайн-оплатой в формате видеосвязи или чата. Запись и оплата могут осуществляться пациентом как в веб-приложении Mila, так и через виджет записи на сайте клиники. Пациент подключается к онлайн-консультации из веб-приложения Mila, а для врача открывается личный кабинет, доступный из любого браузера. Врач может оформлять медицинское заключение онлайн в своем личном кабинете, оттуда оно будет передано пациенту, а также выгружено в РЭМД. Для онлайн-консультаций обязательно требуется расписание принимающих врачей. Клиника может вести его в МИС и обмениваться данными с Mila через сервис N3.Health СЗПВ (Сервис записи на прием к врачу), либо подключить сервис <a href='/mila_practice_account/services/schedule'>"Расписание в Mila"</a>. Платеж пользователя за вычетом комиссии переводится в медорганизацию в течение 24 часов после списания с пациента.`,
    collapseTitle: `Подробнее о возможностях сервиса`,
    collapseContent: ` 
      <h3>Возможности сервиса</h3>
      <ul>
      <li>Запись и оплата онлайн-консультаций внутри веб-приложения Mila и виджета на сайте клиники. Возможность отмены и переноса записи пациентом и врачом</li>
      <li>Для врача открывается доступ в личный кабинет для проведения онлайн-консультации</li>
      <li>Формат консультаций: видеосвязь, аудиосвязь, чат</li>
      <li>Статусы оплаты отображаются в ЛК клиники. Для пациентов доступна история платежей и возвратов в веб-приложения Mila</li>
      <li>Нет абонентской платы за рабочие места врачей</li>
      <li>Клинике не потребуются: договор с банком, подключение собственного эквайринга и онлайн-кассы. Перевод средств возможен на счет клиники в любом банке. Потребуется заключение Дополнительного соглашения с ООО «ЭлНетМед» и публикация оферты для пользователей внутри веб-приложения Mila. Эквайринг осуществляет АО «Альфа-Банк»</li>
      <li>«Заморозка» средств на счете пациента и бесплатные возвраты при отмене в течение 6 дней</li>
      <li>Списание оплаты с пациента при отмене записи менее, чем за 2 часа до онлайн-консультации</li>
      <li>Перевод средств на счет клинике в течение 24 часов после списания с пациента</li>
      <li>Передача ссылки на просмотр медкарты пациента в ЛК врача (если пациент поделился ссылкой) </li>
      <li>Отправка заключения в РЭМД и пользователю Mila</li>
      </ul>`,
    limitationsDescription: `<span>Для отображения онлайн-расписания из МИС требуется подключение к сервису N3.Health СЗПВ (Сервис записи на прием к врачу). Либо вы можете вести расписание вручную - требуется подключение сервиса <a href='/mila_practice_account/services/schedule'>"Расписание в Mila"</a>. Без расписания принимающих врачей работа сервиса «Телемедицина в Mila» невозможна.</span>`,
    setupDescription: `Сервис входит во все тарифы Mila. Для подключения обратитесь к вашему менеджеру. Потребуется заключение Дополнительного соглашения с ООО «ЭлНетМед», предоставление заверенной генеральным директором МО копии лицензии в АО «Альфа-Банк» и публикация оферты для пользователей внутри веб-приложения Mila. В разделе «Услуги» ЛК клиники необходимо будет создать услуги с типом «онлайн» для соответствующих врачебных специализаций. `,
    supportDescription: 'Техподдержка осуществляется СТП N3.Health',
  },
  schedule: {
    slideshowImageStaticFiles: [AboutServiceScheduleBanner],
    aboutServiceDeveloper: 'N3.Health',
    aboutServicePrice: 'от 1500 рублей в месяц',
    aboutServiceDescription: `В Mila есть два способа обеспечить пациентам доступ к расписанию принимающих врачей с возможностью записи в слот. 
      Клиника может вести расписание в своей МИС и автоматизированно обмениваться данными с Mila, для этого МИС должна быть интегрирована с
      сервисом «N3.Health СЗПВ» (Сервис записи на прием к врачу). Второй способ -   создавать слоты и вести расписание вручную прямо внутри 
      Mila в сервисе «Расписание в Mila». С помощью сервиса можно создавать слоты как на все, так и только на определенные услуги и на 
      отдельных врачей, оставив для остальных возможность записи через администратора (по кнопке «Отправить заявку» в профиле врача в веб-приложении 
      для пациентов Mila). Например, таким образом можно создать слоты для консультаций онлайн, необходимые для запуска сервиса «Телемедицина в Mila».`,
    collapseTitle: `Подробнее о возможностях сервиса`,
    collapseContent: ` 
      <h3>Возможности сервиса</h3>
      <ul>
      <li>Возможность создавать расписание по шаблону на определенный период времени</li>
      <li>Определение времени слота в зависимости от услуги</li>
      <li>Созданные слоты отображаются в профилях врачей в 
        <a href='https://clinic.mila.online/services/patient'>веб-приложении для пациентов Mila</a>, 
        <a href='https://clinic.mila.online/services/widget'>в Виджете онлайн-записи</a> на сайте клиники и для администраторов в Личном кабинете клиники в Mila
      </li>
      <li>Возможность бронирования и предоплаты слота пациентом</li>
      <li>Возможность бронирования слота администратором</li>
      <li>Отмена бронирования</li>
      </ul>
      `,
    setupDescription: `Сервис предоставляется платно. Для подключения обратитесь к вашему менеджеру. `,
    supportDescription: 'Техподдержка осуществляется СТП N3.Health',
  },
  whiteLabel: {
    slideshowImageStaticFiles: [AboutServiceWhiteLabelBanner],
    aboutServiceDeveloper: 'N3.Health',
    aboutServicePrice: 'от 30 000 рублей в месяц',
    aboutServiceDescription: `Вы можете использовать сервисы N3.Health для пациентов одновременно двумя способами: зарегистрировать организацию в My.mila.online (веб-приложении для пациентов от N3.Health c интегрированной медицинской картой и данными из всех возможных источников) и запустить брендированную версию Личного кабинета и виджета записи для своих пациентов на своем собственном сайте. В этом случае данные в сервисе будут ограничены данными только вашей организации (сети), вы сможете преднастроить интерфейс в соответствии с вашим фирменным стилем, а для записи у пациентов будут доступны только ваши врачи и услуги. Версию WhiteLabel предпочтительно использовать при условии интеграции вашей МИС для автоматического обмена данными с сервисом.`,
    collapseTitle: `Подробнее о возможностях сервиса`,
    collapseContent: ` 
    <h3>Возможности сервиса</h3>
    <ul>
      <li>Автоматическая регистрация пациентов в Личном кабинете посредством учетной записи Госуслуг</li>
      <li>Возможность свободной регистрации пациентов по e-mail с последующим подтверждением через Госуслуги</li>
      <li>Электронная медкарта для пациентов - с документами, переданными вашей организацией через сервис ИЭМК</li>
      <li>Запись на прием - очный, онлайн, на дому, в слот или через администратора</li>
      <li>Телемедицинские консультации (видеосвязь, аудиосвязь, чат) с получением предоплаты от пациентов</li>
      <li>Возможность для пациента дополнять медкарту данными и делиться ей с врачом при записи</li>
      <li>Подробная информация о вашей организации (сети) и врачах для пациентов, удобный поиск специалиста и подбор времени приема </li>
      <li>Возможность размещать информацию о скидках и акциях</li>
      <li>Чат с администратором клиники для пациента</li>
      </ul>`,
    limitationsDescription:
      'Участие в онлайн-консультации смогут принять только пациенты, авторизованные через Госуслуги (требование Приказа Минздрава РФ от 30.11.2017 № 965н).',
    setupDescription: `Сервис предоставляется платно. Для подключения обратитесь к вашему менеджеру. `,
    supportDescription: 'Техподдержка осуществляется СТП N3.Health',
  },
};
