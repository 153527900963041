import { CancelIcon, IconPlus } from 'src/common/Icons/icons';
import { Popconfirm, notification } from 'antd';
import { PositionSelect } from './PositionSelect';
import {
  PracticePositionDeleteArgs,
  PracticePositionPostArgs,
  PracticePositionPutArgs,
  PracticeSpecialityPosition,
} from 'src/common/types';
import { SpecialityPostionsContext } from './ContentList';
import { useAppSelector } from 'src/app/hooks';
import {
  useDeleteSpecialityPositionMutation,
  useGetSpecialitiesPositionsQuery,
  usePostSpecialityPositionMutation,
  usePutSpecialityPositionMutation,
} from 'src/app/services/api';
import React, { useContext, useState } from 'react';
import styles from './ExpandedContent.module.scss';

type PropsType = {
  speciality: PracticeSpecialityPosition;
};

export const ExpandedContent: React.FC<PropsType> = ({ speciality }) => {
  const branchId = useAppSelector((state) => state.app.selectedBranchId!);

  const [createMode, setCreateMode] = useState<boolean>(false);
  const [positionCode, setPositionCode] = useState<string | null>(null);

  const { refetch } = useGetSpecialitiesPositionsQuery(branchId);
  const [createMutation] = usePostSpecialityPositionMutation();
  const [updateMutation] = usePutSpecialityPositionMutation();
  const [deleteMutation] = useDeleteSpecialityPositionMutation();

  const { checkExistingPosition } = useContext(SpecialityPostionsContext);

  const positionAddSubmit = (code: string, name: string) => {
    const data: PracticePositionPostArgs = {
      branchId: branchId,
      specialityFerId: speciality.specialityFerId,
      positionCode: code,
      positionName: name,
    };

    createMutation(data)
      .unwrap()
      .then(() => {
        notification.success({
          message: `Наименование врача добавлено в ${speciality.specialityName}, код ${speciality.specialityFerId}`,
        });
        refetch().finally(() => {
          setCreateMode(false);
          setPositionCode(null);
        });
      })
      .catch((error) => {
        notification.error({
          message: `При выполнении запроса возникла ошибка: ${error.status} ${error.data.title ?? ''}`,
        });
        setPositionCode(null);
      });
  };

  const newPositionSelectHandle = (value: string, option: any) => {
    if (!value) return;

    const checkResult = checkExistingPosition(value);

    if (checkResult.exists) {
      notification.error({
        message: `Данное наименование уже задано в специализации ${checkResult.specialityName}, код ${checkResult.specialityCode}`,
      });

      setPositionCode(null);

      return;
    }

    setPositionCode(value);
    positionAddSubmit(value, option.label);
  };

  const positionChangeHandle = (specialityId: string, positionId: string, code: string, name: string) => {
    const checkResult = checkExistingPosition(code);

    if (checkResult.exists) {
      notification.error({
        message: `Данное наименование уже задано в специализации ${checkResult.specialityName}, код ${checkResult.specialityCode}`,
      });

      setPositionCode(null);

      return;
    }

    const data: PracticePositionPutArgs = {
      branchId: branchId,
      specialityId: specialityId,
      specialityPositionId: positionId,
      positionCode: code,
      positionName: name,
    };

    updateMutation(data)
      .then(() => {
        notification.success({
          message: `Наименование врача изменено в ${speciality.specialityName}, код ${speciality.specialityFerId}`,
        });
        refetch();
      })
      .catch((error) => {
        notification.error({
          message: `При выполнении запроса возникла ошибка: ${error.status} ${error.data.title ?? ''}`,
        });
      });
  };

  const positionDeleteHandle = (specialityId: string, id: string) => {
    const data: PracticePositionDeleteArgs = {
      branchId: branchId,
      specialityId: specialityId,
      specialityPositionId: id,
    };

    deleteMutation(data)
      .then(() => {
        notification.success({ message: `Наименование врача удалено из ${speciality.specialityName}` });
        refetch();
      })
      .catch((error) => {
        notification.error({
          message: `При выполнении запроса возникла ошибка: ${error.status} ${error.data.title ?? ''}`,
        });
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {speciality.positions.map((position, index) => (
          <div key={position.specialityPositionId} className={styles.position}>
            <div className={styles.select}>
              <PositionSelect
                value={position.positionFerId}
                onChange={(value, option) =>
                  positionChangeHandle(position.specialityId, position.specialityPositionId, value, option.label)
                }
              />
            </div>
            {index > 0 && (
              <Popconfirm
                title={`Удалить этот вариант?`}
                onConfirm={() => positionDeleteHandle(position.specialityId, position.specialityPositionId)}
                okText="Удалить"
                cancelText="Отмена"
              >
                <div className={styles.remove}>
                  <CancelIcon />
                </div>
              </Popconfirm>
            )}
          </div>
        ))}
        {createMode && (
          <div className={styles.position}>
            <div className={styles.select}>
              <PositionSelect
                value={positionCode}
                onChange={newPositionSelectHandle}
                placeholder="Выберите незанятый вариант"
              />
            </div>
            <div
              className={styles.remove}
              onClick={() => {
                setCreateMode(false);
                setPositionCode(null);
              }}
            >
              <CancelIcon />
            </div>
          </div>
        )}
      </div>

      {createMode ? (
        <div className={`${styles.add} ${styles.cursordefult}`}>Выберите новое наименование врача</div>
      ) : (
        <div className={styles.add} onClick={() => setCreateMode(true)}>
          <IconPlus /> Добавить еще одно наименование врача для пациентов
        </div>
      )}
    </div>
  );
};
