import React from 'react';
import SpecializationsPng from 'src/common/images/Specializations.png';
import styles from './NoItems.module.scss';

export const NoItems: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={SpecializationsPng} alt="" />

      <h1 className={styles.title}>Настройте отображение специализаций врачей</h1>

      <p className={styles.text}>
        Чтобы перейти к настройке необходимо сначала добавить специализации в разделе “Услуги”. В этом разделе они
        появятся автоматически и вы сможете указать наименования врачей для пациентов.
      </p>

      <p className={styles.text}>
        Для формирования единой структуры каталога врачей и специалистов в Миле мы используем Федеральный справочник
        специальностей и свой справочник синонимов к ним. Но вы можете заменить предложенный нами вариант наименования
        для пациентов: например, вместо “Хирург” указать “Абдоминальный хирург” и “Лазерный хирург”. Одному специалисту
        в рамках специальности по Федеральному справочнику может быть добавлено несколько синонимов.
      </p>

      <p className={styles.text}>
        Сводный перечень специализаций принимающих врачей отображается пациентам при записи на прием в вашу клинику.
        Также специализации отображаются в профилях врачей. Если отображение специализаций врачей не настроено,
        пациентам будет показано значение из справочника Милы. Перечень наименований специализаций устанавливается
        отдельно для каждого филиала.
      </p>
    </div>
  );
};
