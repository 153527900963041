import { CallMeBackRequest } from 'src/common/types';
import { CheckButton, EditIcon } from 'src/common/Icons/icons';
import { ColumnsType } from 'antd/es/table';
import { ConfirmEditModal } from 'src/common/components/ConfirmEditModal/ConfirmEditModal';
import { CustomTable } from 'src/common/components/CustomTable/CustomTable';
import { Form, Layout } from 'antd';
import { RecordsStub } from 'src/common/components/RecordsStub/RecordsStub';
import { StatusText } from 'src/common/components/StatusText/StatusText';
import { getPageVisibility } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import { useForm } from 'antd/es/form/Form';
import { useGetCallbackRequestsQuery, useUpdateCallMeBackRequestMutation } from 'src/app/services/api';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';

export const CallBackRequests = () => {
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const isRecordsVisible =
    getPageVisibility(permissions, 'can-access-page-records-waiting-list-call-me-back') ||
    getPageVisibility(permissions, 'can-access-page-appointments-call-me-back');

  const [form] = useForm();
  const [editingKey, setEditingKey] = useState('');

  const { data: recordsList, refetch } = useGetCallbackRequestsQuery({
    sortType: 'created desc',
    offset: pageOffset,
    count: pageCount,
  });
  const [updateCallMeBackRequest] = useUpdateCallMeBackRequestMutation();

  const formattedRecords = useMemo(
    () =>
      recordsList?.data
        ? recordsList.data.map((record) => ({
            ...record,
            created: dayjs(record.created),
            comment: record.comment ?? '',
          }))
        : [],
    [recordsList],
  );

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };

  const isEditing = useCallback(
    (record: CallMeBackRequest) => record.idMilaWidgetCallMeBackRequest === editingKey,
    [editingKey],
  );

  const edit = useCallback(
    (record: CallMeBackRequest, e: Event) => {
      e.stopPropagation();
      form.setFieldsValue({ comment: record.comment, status: record.status });
      if (record.idMilaWidgetCallMeBackRequest) {
        setEditingKey(record.idMilaWidgetCallMeBackRequest);
      }
    },
    [form],
  );

  const save = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      await updateCallMeBackRequest({ ...values, id: editingKey });
      setEditingKey('');
      refetch();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }, [editingKey, form, refetch, updateCallMeBackRequest]);

  const columns: ColumnsType<CallMeBackRequest> = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'edit',
        width: 70,
        render: (_: any, record: CallMeBackRequest) => {
          const editable = isEditing(record);
          return editable ? (
            <CheckButton className={styles.EditButton} onClick={() => save()} />
          ) : (
            <EditIcon className={styles.EditButton} onClick={(e) => edit(record, e as unknown as Event)} />
          );
        },
      },
      {
        title: 'ID записи',
        dataIndex: 'milaId',
        key: 'milaId',
        width: 110,
      },

      {
        title: 'Статус заявки',
        dataIndex: 'status',
        key: 'status',
        editable: true,
        width: 190,
        render: (_: any, record: CallMeBackRequest) => {
          return <StatusText record={record} />;
        },
        sorter: (item1: CallMeBackRequest, item2: CallMeBackRequest) => item1.status - item2.status,
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        editable: true,
        width: 230,
        sorter: (a: CallMeBackRequest, b: CallMeBackRequest) => a.comment.localeCompare(b.comment),
      },
      {
        title: 'Создана',
        dataIndex: 'created',
        key: 'created',
        sorter: (item1: CallMeBackRequest, item2: CallMeBackRequest) => item1.created.diff(item2.created),
        render: (val: Dayjs) => val.format('HH:mm DD.MM.YYYY'),
      },
      {
        title: 'Заявитель',
        dataIndex: 'name',
        key: 'name',
        sorter: (item1: CallMeBackRequest, item2: CallMeBackRequest) => item1.name.localeCompare(item2.name),
      },
      {
        title: 'Телефон',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        sorter: (item1: CallMeBackRequest, item2: CallMeBackRequest) =>
          item1.phoneNumber.localeCompare(item2.phoneNumber),
      },
      {
        title: 'Предпочтительное время звонка',
        dataIndex: 'whenCallback',
        key: 'whenCallback',
        width: '130px',
        sorter: (item1: CallMeBackRequest, item2: CallMeBackRequest) =>
          item1.whenCallback.localeCompare(item2.whenCallback),
      },
    ],
    [edit, isEditing, save],
  );

  const mergedColumns = columns.map((col) => {
    // @ts-ignore
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: CallMeBackRequest) => ({
        record,
        // @ts-ignore
        inputType: col.dataIndex === 'status' ? 'select' : 'text',
        // @ts-ignore
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleRowClick = (record: CallMeBackRequest) => {
    if (editingKey && record.idMilaWidgetCallMeBackRequest !== editingKey) {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    save();
  };

  const handleCancel = () => {
    setEditingKey('');
    setIsModalOpen(false);
  };

  return (
    <Layout className={styles.TableContainer}>
      <Form form={form}>
        {isRecordsVisible ? (
          <CustomTable
            dataSource={formattedRecords}
            // @ts-ignore
            columns={mergedColumns}
            total={recordsList?.total}
            onPageChange={handlePageChange}
            pageNumber={pageNumber}
            onRowClick={handleRowClick}
          />
        ) : (
          <RecordsStub
            title="Здесь будут видны все запросы на обратный звонок от пациентов."
            text="Если вы считаете, что данные тут не отображены, пожалуйста, обратитесь в службу технической поддержки."
          />
        )}
      </Form>
      <ConfirmEditModal isOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </Layout>
  );
};
