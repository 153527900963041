import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AmenitiesState {
  editServiceId: string | null;
}

const initialState: AmenitiesState = {
  editServiceId: null,
};

export const amenitiesSlice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {
    setEditServiceId: (state, action: PayloadAction<string | null>) => {
      state.editServiceId = action.payload;
    },
  },
});

export const amenitiesReducer = amenitiesSlice.reducer;
export const ameniniesActions = amenitiesSlice.actions;
