import { BranchFilter } from 'src/common/components/BranchFilter/BranchFilter';
import { Button, Col, Layout, Row, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CustomTable } from 'src/common/components/CustomTable/CustomTable';
import { ExclamationMarkIcon } from '../../common/Icons/icons';
import { Link } from 'react-router-dom';
import { Patient } from 'src/common/types';
import { getDateLabel, getPageVisibility } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import { useGetPatientsQuery } from 'src/app/services/api';
import PatientsIcon from '../../common/Icons/PatientsIcon.svg';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';

const columns: ColumnsType<Patient> = [
  {
    title: 'ID',
    dataIndex: 'milaId',
    key: 'milaId',
    width: 92,
  },
  {
    title: 'ФИО пациента',
    dataIndex: 'fio',
    key: 'fio',
    className: styles.ColoredText,
  },
  {
    title: 'Дата рожд.',
    dataIndex: 'birthDay',
    key: 'birthDay',
  },
  {
    title: 'Пол',
    dataIndex: 'gender',
    key: 'gender',
    render: (val) => (val ? 'Мужской' : 'Женский'),
  },
  {
    title: 'Телефон',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Записи',
    dataIndex: 'appointmentsCount',
    key: 'appointmentsCount',
  },
  {
    title: 'Последняя запись',
    dataIndex: 'lastAppointmentDate',
    key: 'lastAppointmentDate',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    className: styles.ColoredText,
  },
  // {
  //   title: 'Место последней записи',
  //   dataIndex: 'lastAppointmentPlace',
  //   key: 'lastAppointmentPlace',
  //   width: 230,
  // },
  // {
  //   title: 'Последний специалист',
  //   dataIndex: 'lastAppointmentDoctor',
  //   key: 'lastAppointmentDoctor',
  // },
  // {
  //   title: 'Специализация',
  //   dataIndex: 'lastAppointmentSpeciality',
  //   key: 'lastAppointmentSpeciality',
  // },
];

const tooltipText = (
  <Typography.Text className={styles.TipText}>
    В данном разделе отображаются данные пациентов, записавшихся в клинику через сервисы Mila (медассистент для
    пациентов и виджет записи для сайта клиники), либо оставивших заявку в лист ожидания. Данные пользователей,
    оставивших заявки на обратный звонок, не попадают в раздел "Пациенты".{' '}
  </Typography.Text>
);

function PatientsPage() {
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [selectedBranchId, setSelectedBranchId] = useState<string>('');

  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const isRecordsVisible = getPageVisibility(permissions, 'can-access-page-patients-profile');

  const { data: recordsList } = useGetPatientsQuery({
    sortType: 'created desc',
    offset: pageOffset,
    count: pageCount,
    branchId: selectedBranchId,
  });

  const formattedRecords = useMemo(
    () =>
      recordsList?.data
        ? recordsList.data.map((record) => ({
            ...record,
            birthDay: getDateLabel(record.birthDay),
            lastAppointmentDate: getDateLabel(record.lastAppointmentDate),
          }))
        : [],
    [recordsList],
  );

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };

  const handleBranchSelect = (value: string) => {
    setSelectedBranchId(value);
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <Col className={styles.pageTitleWrapper}>
          <Typography.Text>Пациенты</Typography.Text>
        </Col>
        <Row className={styles.ToolBarWrapper}>
          <Tooltip title={tooltipText} placement="right" overlayClassName={styles.TooltipOverlay}>
            <Row className={styles.TooltipButton}>
              <ExclamationMarkIcon />
            </Row>
          </Tooltip>
          <BranchFilter onChange={handleBranchSelect} value={selectedBranchId} />
        </Row>
        {isRecordsVisible ? (
          <Layout className={styles.TableContainer}>
            <CustomTable
              dataSource={formattedRecords}
              columns={columns}
              total={recordsList?.total}
              onPageChange={handlePageChange}
              pageNumber={pageNumber}
              totalString={'Всего пациентов'}
            />
          </Layout>
        ) : (
          <div className={styles.pageContent}>
            <Col className={styles.iconContainer}>
              <img src={PatientsIcon} alt="patients-page-icon" />
            </Col>
            <Col className={styles.pageSubTitleWrapper}>
              <Typography.Text>Здесь вы увидите список пациентов</Typography.Text>
            </Col>
            <Col className={styles.pageDescription}>
              <Typography.Text>
                Данные появятся, когда будут подключены сервисы{' '}
                <Typography.Link className={styles.linkText}>Виджет записи</Typography.Link> или
                <Typography.Link className={styles.linkText}> Кабинет пациента.</Typography.Link> <br /> Если Вы
                считаете, что данные пациентов не отображаются по ошибке,
                <br /> пожалуйста, обратитесь в службу поддержки.
              </Typography.Text>
            </Col>
            <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
              <Col>
                <Link to="https://support.n3health.ru/" target="__blank">
                  <Button className={styles.buttonCancel} htmlType="reset">
                    Написать в поддержку
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button className={styles.buttonSubmit} htmlType="submit">
                  Вернуться на главную
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

export default PatientsPage;
