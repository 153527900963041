import { Col } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

type BurgerMenuProps = {
  open?: boolean;
  onClick?: () => void;
};

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ open = false, onClick }) => {
  return (
    <Col onClick={onClick} className={`${styles.burger} ${open ? styles.open : ''} `}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </Col>
  );
};
