import { useForm } from 'antd/lib/form/Form';
import { Props } from './SnilsCheckBlock.types';
import { ChangeEvent } from 'react';

export const useSnilsCheckBlock = (props: Props) => {
  const { setDoctorSnils } = props;
  const [form] = useForm<any>();

  const handleSnilsOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoctorSnils(e.target.value);
  };

  return { handleSnilsOnChange, form };
};
