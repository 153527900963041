import { Button, Col, Form, Row, Typography, notification } from 'antd';
import { CustomSelector } from '../../../common/components/CustomSelector/CustomSelector';
import { getPreparedBranches } from 'src/common/utils/utils';
import { oidcAuthService } from '../../../app/services/auth/oidcAuthService';
import { useAppSelector } from '../../../app/hooks';
import { useForm } from 'antd/lib/form/Form';
import { useGetPracticeBranchesQuery } from '../../../app/services/api';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';

const monthOptions = [
  { value: 1, label: 'Январь' },
  { value: 2, label: 'Февраль' },
  { value: 3, label: 'Март' },
  { value: 4, label: 'Апрель' },
  { value: 5, label: 'Май' },
  { value: 6, label: 'Июнь' },
  { value: 7, label: 'Июль' },
  { value: 8, label: 'Август' },
  { value: 9, label: 'Сентябрь' },
  { value: 10, label: 'Октябрь' },
  { value: 11, label: 'Ноябрь' },
  { value: 12, label: 'Декабрь' },
];

function TmkSettingsPage() {
  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const availableFiltersBranches = useAppSelector((state) => state.app.availableBranches) || [];
  const branchOptions = useMemo(() => {
    return branchesList ? getPreparedBranches(branchesList, availableFiltersBranches) : [];
  }, [availableFiltersBranches, branchesList]);
  const [form] = useForm<any>();
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';
  const [loader, setLoader] = useState(false);

  const handleSubmitForm = async (v: any) => {
    setLoader(true);
    try {
      const bearer = await oidcAuthService.getToken();
      const body = {
        year: v.year,
        month: v.month,
        medOrganizationId: v.medOrganizationId,
      };
      const response = await fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/practice/voximplant/report`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${bearer}`,
          'x-mila-network-id': networkId,
          'x-mila-med-organization-id': medOrganizationId,
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.status < 200 || response.status > 300) {
        notification.error({ message: 'При скачивании отчета произошла ошибка' });
        return;
      }
      const blob = await response.blob();
      const filename = response.headers.get('Content-Disposition')?.split("filename*=UTF-8''")[1];
      const link = document.createElement('a');
      link.download = (filename && decodeURIComponent(filename)) || 'Отчет.xlsx';
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    } finally {
      setLoader(false);
    }
  };

  const getYearOptions = () => {
    const options: any[] = [];
    const curYear = new Date().getFullYear();
    for (let i = 2022; i <= curYear; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  return (
    <div className={styles.pageContainer}>
      <Form form={form} layout="vertical" onFinish={handleSubmitForm}>
        <div className={styles.generalDataContainer}>
          <Col className={styles.generalDataFormTitle}>
            <Typography.Text>Отчет о затраченном времени ВКС</Typography.Text>
          </Col>
          <Row gutter={24}>
            <Row className={styles.singleFormRow}>
              <Col className={styles.BranchSelector}>
                <Form.Item name="medOrganizationId" rules={[{ required: true, message: 'Пожалуйста укажите филиал' }]}>
                  <CustomSelector floatLabel="Выберите филиал" options={branchOptions} />
                </Form.Item>
              </Col>
              <Col className={styles.FilterSelector}>
                <Form.Item name="year" rules={[{ required: true, message: 'Пожалуйста укажите год' }]}>
                  <CustomSelector floatLabel="Укажите год" options={getYearOptions()} />
                </Form.Item>
              </Col>
              <Col className={styles.FilterSelector}>
                <Form.Item name="month" rules={[{ required: true, message: 'Пожалуйста укажите месяц' }]}>
                  <CustomSelector floatLabel="Укажите месяц" options={monthOptions} />
                </Form.Item>
              </Col>
            </Row>
          </Row>
          <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
            <Col>
              <Button className={styles.buttonSubmit} htmlType="submit" loading={loader}>
                Скачать отчёт в XLSX
              </Button>
            </Col>
          </Row>
          <Row>
            <Typography.Text>
              Генерация отчета может занять некоторое время. Пожалуйста, не закрывайте страницу.
            </Typography.Text>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default TmkSettingsPage;
