import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import { ReactComponent as CrossSignIcon } from '../../../../assets/CrossSignIcon.svg';
import { ReactComponent as DownCaretIcon } from '../../../../common/Icons/DownCaretIcon.svg';
import { ReactComponent as EditIcon } from '../../../../common/Icons/EditIcon.svg';
import { PageMode, Props } from './DoctorsInfoPage.types';
import { PhoneInput } from '../../../../common/components/PhoneInput/PhoneInput';
import { ReactComponent as PlusIcon } from '../../../../assets/PlusIcon.svg';
import { PositionsSelect } from '../../AmenitiesPage/components/selects/PositionsSelect';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/QuestionMarkIcon.svg';
import { Specialities } from './components';
import { useDoctorsInfoPage } from './DoctorsInfoPage.hooks';
import DoctorTabs from '../DoctorTabs';
import DoctorTitle from '../DoctorTitle';
import FallbackUserPhoto from '../../../../common/Icons/FallbackUserPhoto.svg';
import FloatLabelInput from '../../../../common/FloatLabelInput/FloatLabelInput';
import InputMask from 'react-input-mask';
import NavSubMenu from '../../NavSubMenu';
import React from 'react';
import styles from '../styles.module.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions*/

export const DoctorsInfoPage = (props: Props) => {
  const { pageMode } = props;
  const {
    isLoading,
    saveLoading,
    doctorGeneralInfo,
    practiceDoctorId,
    form,
    handleSubmitForm,
    setFieldValue,
    fieldValues,
    practiceId,
    genderLabel,
    gender,
    category,
    setGenderValue,
    setCategoryValue,
    degree,
    handleDoctorPhotoChange,
    checkPhotoType,
    setDegreeValue,
    prepareDegrees,
    handleDoctorPhotoRemove,
    handleChangePhoto,
    accessToDoctorPortalResult,
    hasAccessToDoctorPortal,
    resendVerificationEmailResult,
    resendVerificationEmail,
    handleEducationValuesChange,
    doctorContactInfo,
    prepareEducationTypes,
    handleDeleteEducationInfo,
    fileList,
    checkUploadDocType,
    handleDocumentSelection,
    handleCancelButtonOnClick,
    branchesList,
    handleDeleteDoctorFromBranch,
    doctorPhotoSrc,
    setDoctorPhotoFile,
    handleAccessToDoctorPortalClick,
    optionsSpecialities,
    selectedSpecialities,
    handleAddSpecialityOnClick,
    handleDeleteSpecialityOnClick,
    handleSetCanServeAdults,
    handleSetCanServeChildren,
    handleSpecialityOnChange,
    isSzpvSync,
    position,
    setPosition,
    positionsList,
    handlePositionChange,
  } = useDoctorsInfoPage(props);

  return (
    <>
      <NavSubMenu />
      {isLoading ? (
        <Spin size="large" className={styles.pageLoader} />
      ) : (
        <div className={styles.pageContainer}>
          {pageMode === PageMode.Editing ? (
            <DoctorTitle doctorGeneralInfo={doctorGeneralInfo} practiceId={practiceId} />
          ) : (
            <div className={styles.titleDoctorsName}>
              <Typography.Text>
                Добавить врача или специалиста в {branchesList?.find((f) => f.branchId === practiceId)?.shortName}
              </Typography.Text>
            </div>
          )}

          <DoctorTabs doctorId={practiceDoctorId} />
          <Form form={form} layout="vertical" className={styles.branchesInfoForm} onFinish={handleSubmitForm}>
            <div className={styles.generalDataContainer}>
              <Col className={styles.generalDataFormTitle}>
                <Typography.Text>Общие данные</Typography.Text>
              </Col>
              <Row gutter={24} className={styles.inputsRowWrapper}>
                <Col className={styles.inputsWrapper}>
                  <FloatLabelInput label="Фамилия *" value={fieldValues.surname}>
                    <Form.Item
                      name="surname"
                      className={styles.inputSurname}
                      rules={[
                        {
                          required: true,
                          message: 'Укажите фамилию',
                        },
                      ]}
                    >
                      <Input
                        style={{ height: '56px', minWidth: '418px', resize: 'none' }}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                        value={fieldValues.surname}
                      />
                    </Form.Item>
                  </FloatLabelInput>

                  <FloatLabelInput label="Имя *" value={fieldValues.firstname}>
                    <Form.Item
                      name="firstname"
                      className={styles.inputName}
                      rules={[
                        {
                          required: true,
                          message: 'Укажите имя',
                        },
                      ]}
                    >
                      <Input
                        style={{ height: '56px' }}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                        value={fieldValues.firstname}
                      />
                    </Form.Item>
                  </FloatLabelInput>

                  <FloatLabelInput label="Отчество" value={fieldValues.middlename}>
                    <Form.Item name="middlename" className={styles.inputName}>
                      <Input
                        style={{ height: '56px' }}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                        value={fieldValues.middlename}
                      />
                    </Form.Item>
                  </FloatLabelInput>

                  <Row className={styles.inputsHalfRow}>
                    <Col>
                      <FloatLabelInput label="Дата рождения *" value={fieldValues.birthdate}>
                        <Form.Item
                          name="birthdate"
                          rules={[
                            {
                              required: true,
                              message: 'Укажите дату рождения',
                            },
                          ]}
                        >
                          <DatePicker
                            format={'DD.MM.YYYY'}
                            style={{ height: '56px', width: '292px' }}
                            onChange={(value) => setFieldValue({ ...fieldValues, birthdate: value })}
                            value={fieldValues.birthdate}
                            suffixIcon={null}
                            clearIcon={null}
                            placeholder=""
                          />
                        </Form.Item>
                      </FloatLabelInput>
                    </Col>
                    <Col>
                      <FloatLabelInput label="Пол *" value={genderLabel}>
                        <Form.Item
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: 'Укажите пол',
                            },
                          ]}
                        >
                          <Select
                            style={{ height: '56px', width: '99px' }}
                            className={styles.selectorWrapper}
                            onChange={(value: number) => setGenderValue(value)}
                            value={gender}
                            suffixIcon={<DownCaretIcon />}
                            options={[
                              {
                                label: 'Муж',
                                value: 1,
                              },
                              { label: 'Жен', value: 0 },
                            ]}
                          />
                        </Form.Item>
                      </FloatLabelInput>
                    </Col>
                  </Row>
                </Col>

                <Col className={styles.inputsWrapper}>
                  <FloatLabelInput label="Категория *" value={category?.toString()}>
                    <Form.Item
                      name="category"
                      className={styles.inputCategory}
                      rules={[
                        {
                          required: true,
                          message: 'Укажите категорию',
                        },
                      ]}
                    >
                      <Select
                        style={{ height: '56px', width: '417px' }}
                        className={styles.selectorWrapper}
                        onChange={(value) => setCategoryValue(value)}
                        value={category}
                        suffixIcon={<DownCaretIcon />}
                        options={[
                          {
                            label: 'Высшая',
                            value: 0,
                          },
                          { label: 'Первая', value: 1 },
                          { label: 'Вторая', value: 2 },
                          { label: 'Без категории', value: -99 },
                        ]}
                      />
                    </Form.Item>
                  </FloatLabelInput>

                  <Form.Item
                    name="position"
                    rules={[
                      {
                        required: true,
                        message: 'Укажите должность',
                      },
                    ]}
                    className={styles.inputName}
                    style={{ width: 'inherit' }}
                  >
                    <PositionsSelect
                      currentValue={position}
                      onSelect={setPosition}
                      data={positionsList}
                      handlePositionChange={handlePositionChange}
                    />
                  </Form.Item>

                  <FloatLabelInput label="СНИЛС" value={fieldValues.snils} isDisabled>
                    <Form.Item name="snils" className={styles.inputName}>
                      <InputMask
                        mask="999-999-999-99"
                        value={fieldValues.snils}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                        name="snils"
                        style={{ height: '56px' }}
                        disabled
                      >
                        <Input name="snils" />
                      </InputMask>
                    </Form.Item>
                  </FloatLabelInput>

                  <Row className={styles.inputsHalfRow}>
                    <Col>
                      <FloatLabelInput label="Ученая степень" value={degree}>
                        <Form.Item name="degree">
                          <Select
                            style={{ height: '56px', width: '241px' }}
                            className={styles.selectorWrapper}
                            onChange={(value) => setDegreeValue(value)}
                            value={degree}
                            suffixIcon={<DownCaretIcon />}
                            options={prepareDegrees()}
                          />
                        </Form.Item>
                      </FloatLabelInput>
                    </Col>
                    <Col>
                      <FloatLabelInput label="Стаж с" value={fieldValues.experience}>
                        <Form.Item
                          name="experience"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <DatePicker
                            format={'MM.YYYY'}
                            style={{ height: '56px', width: '152px' }}
                            onChange={(value) => setFieldValue({ ...fieldValues, experience: value })}
                            value={fieldValues.experience}
                            suffixIcon={null}
                            clearIcon={null}
                            picker="month"
                            placeholder={''}
                          />
                        </Form.Item>
                      </FloatLabelInput>
                    </Col>
                  </Row>
                </Col>
                <Row className={styles.doctorPhotoRow}>
                  <Col>
                    <Form.Item>
                      <Upload.Dragger
                        className={styles.fileUploader}
                        accept={'.jpg, .jpeg, .svg, .png, .bmp, .tiff, .avif, .webp'}
                        maxCount={1}
                        customRequest={(options) => handleDoctorPhotoChange(options)}
                        beforeUpload={(file) => {
                          const check = checkPhotoType(file);
                          if (check) {
                            setDoctorPhotoFile(file);
                          }
                          return check;
                        }}
                        showUploadList={{ showRemoveIcon: false }}
                        openFileDialogOnClick={false}
                        id="doctorPhotoUpload"
                      >
                        {doctorPhotoSrc ? (
                          <img src={doctorPhotoSrc} alt="" height={153} width={153} />
                        ) : (
                          <img alt="" src={FallbackUserPhoto} height={153} width={153} />
                        )}
                      </Upload.Dragger>
                    </Form.Item>
                    <div className={styles.imgPhotoIcons}>
                      <Tooltip title={'Размер загружаемого файла не более 1 Мб'} placement="right">
                        <QuestionMarkIcon />
                      </Tooltip>

                      <EditIcon
                        onClick={() => {
                          handleChangePhoto();
                        }}
                      />
                    </div>
                    <Popconfirm
                      title={`Вы уверены, что хотите удалить фото доктора?`}
                      onConfirm={handleDoctorPhotoRemove}
                      okText="Да"
                      cancelText="Нет"
                    >
                      {doctorPhotoSrc && (
                        <div className={styles.removePhotoLabel}>
                          <Typography.Text>Удалить фото</Typography.Text>
                        </div>
                      )}
                    </Popconfirm>
                  </Col>
                </Row>
              </Row>

              <Col className={styles.generalDataSpecTitle}>
                <Typography.Text>Специализации</Typography.Text>
              </Col>
              <Specialities
                pageMode={pageMode}
                options={optionsSpecialities}
                selectedSpecialities={selectedSpecialities}
                handleAddSpecialityOnClick={handleAddSpecialityOnClick}
                handleDeleteSpecialityOnClick={handleDeleteSpecialityOnClick}
                setCanServeAdults={handleSetCanServeAdults}
                setCanServeChildren={handleSetCanServeChildren}
                handleOnChange={handleSpecialityOnChange}
                isDisplayingAddSpecialityButton={!isSzpvSync}
                areSelectsDisabled={false}
                isDisplayingDeleteSpecialityIcon={!isSzpvSync ? selectedSpecialities.length > 1 : false}
                isDisplaySameCodeSpecialities={isSzpvSync}
              />

              <Row gutter={24} className={styles.inputsRowWrapper}>
                <Col className={styles.inputsWrapper}>
                  <FloatLabelInput label="О враче" value={fieldValues.aboutDoctor}>
                    <Form.Item name="aboutDoctor" className={styles.inputAboutDoctor}>
                      <Input.TextArea
                        style={{ height: '116px', minWidth: '858px', resize: 'none' }}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                        value={fieldValues.aboutDoctor}
                        showCount
                        maxLength={700}
                      />
                    </Form.Item>
                  </FloatLabelInput>
                </Col>
              </Row>
            </div>

            <Col className={styles.educationFormContainer}>
              <Col className={styles.generalDataFormTitle}>
                <Typography.Text>Образование</Typography.Text>
              </Col>
              <Row className={styles.singleFormRow}></Row>
              <Row className={styles.singleFormRow}>
                <Form.List name="education">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <div key={field.key}>
                            <Row>
                              <Col>
                                <FloatLabelInput label="Вид обучения *" value={'educationType'}>
                                  <Form.Item name={[field.name, 'educationType']} rules={[
                                    {
                                      required: true,
                                      message: 'Укажите вид обучения',
                                    },
                                  ]} className={styles.inputEducationType}>
                                    <Select
                                      style={{ width: '417px', height: '56px' }}
                                      suffixIcon={<DownCaretIcon />}
                                      options={prepareEducationTypes()}
                                      value={
                                        fieldValues?.education[field.key]?.educationType
                                          ? fieldValues.education[field.key]?.educationType
                                          : ''
                                      }
                                    />
                                  </Form.Item>
                                </FloatLabelInput>
                              </Col>
                              <Col className={styles.secondElementForm}>
                                <FloatLabelInput
                                  label="Учебное заведение *"
                                  value={
                                    fieldValues.education[field.key]?.institution
                                      ? fieldValues.education[field.key]?.institution
                                      : ''
                                  }
                                >
                                  <Form.Item name={[field.name, 'institution']} rules={[
                                    {
                                      required: true,
                                      message: 'Укажите учебное заведение',
                                    },
                                  ]}>
                                    <Input
                                      style={{ height: '56px', width: '417px' }}
                                      onChange={(e) =>
                                        handleEducationValuesChange(e.target.value, field.key, 'institution')
                                      }
                                    />
                                  </Form.Item>
                                </FloatLabelInput>
                              </Col>
                            </Row>
                            <Row key={field.key} className={styles.newInstitutionForm}>
                              <Col>
                                <FloatLabelInput
                                  label="Специализация *"
                                  value={
                                    fieldValues.education[field.key]?.speciality
                                      ? fieldValues.education[field.key]?.speciality
                                      : ''
                                  }
                                >
                                  <Form.Item name={[field.name, 'speciality']} rules={[
                                    {
                                      required: true,
                                      message: 'Укажите специализацию',
                                    },
                                  ]}>
                                    <Input
                                      style={{ height: '56px', width: '417px' }}
                                      onChange={(e) =>
                                        handleEducationValuesChange(e.target.value, field.key, 'speciality')
                                      }
                                    />
                                  </Form.Item>
                                </FloatLabelInput>
                              </Col>

                              <Col className={styles.secondElementForm}>
                                <FloatLabelInput label="Год выпуска *" value={'yearGraduate'}>
                                  <Form.Item
                                    name={[field.name, 'educationEndDate']}
                                    className={styles.inputEducationType}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Укажите год выпуска',
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      format={'YYYY'}
                                      style={{ height: '56px', width: '417px' }}
                                      // onChange={(e) => handleEducationValuesChange(e, field.key, 'educationEndDate')}
                                      suffixIcon={null}
                                      clearIcon={null}
                                      picker="year"
                                    />
                                  </Form.Item>
                                </FloatLabelInput>
                              </Col>

                              {fields.length > 1 && index !== 0 ? (
                                <Popconfirm
                                  title={`Вы уверены, что хотите удалить поле?`}
                                  onConfirm={() => {
                                    remove(field.name);
                                    handleDeleteEducationInfo(field.key);
                                  }}
                                  okText="Да"
                                  cancelText="Нет"
                                >
                                  <CrossSignIcon className="dynamic-delete-button" />
                                </Popconfirm>
                              ) : null}
                            </Row>
                          </div>
                        );
                      })}

                      <Row className={styles.addInstitutionTextWrapper}>
                        <Form.Item>
                          <div onClick={() => add()} className={styles.addInstitution}>
                            <PlusIcon />
                            <Typography.Text>Добавить учебное заведение</Typography.Text>
                          </div>
                        </Form.Item>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Row>
            </Col>

            <Col className={styles.educationFormContainer}>
              <Col className={styles.generalDataFormTitle}>
                <Typography.Text>Дипломы и сертификаты</Typography.Text>
              </Col>
              <Row>
                <Form.Item name="uploadDoc">
                  <Upload
                    listType="text"
                    customRequest={(options) => handleDocumentSelection(options)}
                    fileList={fileList as unknown as UploadFile[]}
                    showUploadList={{ showRemoveIcon: false }}
                    className={styles.uploadDocumentForm}
                    beforeUpload={(file) => checkUploadDocType(file)}
                  >
                    <Button className={styles.downloadBtnWrapper}>Загрузить документ</Button>
                  </Upload>
                </Form.Item>
              </Row>
            </Col>

            <Col className={styles.educationFormContainer}>
              <Col className={styles.generalDataFormTitle}>
                <Typography.Text>Контактные данные</Typography.Text>{' '}
                <Tooltip
                  title={
                    'Контактные данные врача не показываются пациентам, а могут быть использованы для уведомлений от системы'
                  }
                  placement="right"
                  className={styles.tooltipTitle}
                >
                  <QuestionMarkIcon />
                </Tooltip>
              </Col>
              <Row className={styles.singleFormRow}>
                <Col>
                  <FloatLabelInput label="Телефон" value={fieldValues.phone}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          message: 'Введите корректный номер телефона',
                          pattern: /(\d|\+\d) \(\d{3}\) \d{3}-\d{4}/,
                        },
                      ]}
                    >
                      <PhoneInput
                        value={doctorContactInfo?.phone && fieldValues.phone}
                        name="phone"
                        style={{ height: '56px', width: '417px' }}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                      />
                    </Form.Item>
                  </FloatLabelInput>
                </Col>
                <Col className={styles.secondElementForm}>
                  <FloatLabelInput label={hasAccessToDoctorPortal ? 'Email *' : 'Email'} value={fieldValues.email}>
                    <Form.Item
                      name="email"
                      className={styles.inputEducationType}
                      rules={[
                        {
                          required: hasAccessToDoctorPortal,
                          message: 'Введите корректную электронную почту',
                          pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                        },
                      ]}
                    >
                      <Input
                        style={{ height: '56px', width: '417px' }}
                        onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id]: e.target.value })}
                        value={fieldValues.email}
                      />
                    </Form.Item>
                  </FloatLabelInput>
                </Col>
              </Row>
            </Col>

            <Col className={styles.servicesFormContainer}>
              <Col className={styles.generalDataFormTitle}>
                <Typography.Text>Отображение в сервисах</Typography.Text>
              </Col>
              <Row className={styles.checkboxesContainer}>
                <Col span={24}>
                  <Form.Item name="showInMilaServices">
                    <Checkbox
                      checked={fieldValues.showInMilaServices}
                      onChange={(e) => setFieldValue({ ...fieldValues, [e.target.id!]: e.target.checked })}
                    >
                      Отображать врача для пациентов в сервисах Mila
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Row className={styles.formButtonsContainer} gutter={[21, 10]}>
              {pageMode === PageMode.Editing && !isSzpvSync && (
                <Col>
                  <Popconfirm
                    title="Действительно удалить врача из филиала?"
                    onConfirm={handleDeleteDoctorFromBranch}
                    cancelText="Отмена"
                    okText="Удалить"
                  >
                    <Button className={styles.deleteDoctorFromBranch}>Удалить врача из филиала</Button>
                  </Popconfirm>
                </Col>
              )}
              <Col>
                <Button className={styles.buttonCancel} onClick={handleCancelButtonOnClick}>
                  Отмена
                </Button>
              </Col>
              <Col>
                <Button className={styles.buttonSubmit} htmlType="submit" loading={saveLoading}>
                  Сохранить изменения
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
};
