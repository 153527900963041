import { Button, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import RecordsStubIcon from './RecordsStubIcon.png';
import styles from './styles.module.scss';

function RecordsStubPage() {
  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.pageContent}>
          <Col className={styles.iconContainer}>
            <img src={RecordsStubIcon} alt="records-page-icon" />
          </Col>
          <Col className={styles.pageSubTitleWrapper}>
            <Typography.Text>К данному специалисту еще не было записей </Typography.Text>
          </Col>
          <Col className={styles.pageDescription}>
            <Typography.Text>
              Здесь будут видны все записи к специалисту, совершенные через виджет или личный кабинет пациентами
              клиники. Проверьте, что у специалиста есть{' '}
              <Typography.Link className={styles.linkText}>привязанные услуги</Typography.Link> и включено{' '}
              <Typography.Link className={styles.linkText}>отображение в данных сервисах.</Typography.Link>
            </Typography.Text>
          </Col>
          <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
            <Col>
              <Link to="https://support.n3health.ru/" target="__blank">
                <Button className={styles.buttonSubmit} htmlType="submit">
                  Написать в поддержку
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default RecordsStubPage;
