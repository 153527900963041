import { Col, Image, Layout, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import { Notification, NotificationType } from 'src/common/types';
import { NotificationIcon, UserIcon } from 'src/common/Icons/icons';
import { minutesTimezoneOffset } from '../../../common/utils/utils';
import { useDebouncedCall } from 'src/common/hooks/useDebouncedCall';
import { useEffect, useMemo, useRef } from 'react';
import { useMarkNotificationViewedMutation } from 'src/app/services/api';
import AppointmentsIcon from 'src/common/Icons/AppointmentsIcon.svg';
import CallBack from 'src/common/Icons/CallBack.svg';
import Cancel from 'src/common/Icons/Cancel.svg';
import Headphones from 'src/common/Icons/Headphones.svg';
import NewFuncIcon from 'src/common/Icons/NewFuncIcon.svg';
import WarningIcon from 'src/common/Icons/WarningIcon.svg';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface NotificationProps {
  onViewed: () => void;
  notification: Notification;
}

export const NotificationComponent = ({ notification, onViewed }: NotificationProps) => {
  const refContainer = useRef(null);
  const navigate = useNavigate();
  const [markNotification, result] = useMarkNotificationViewedMutation();
  const debouncedMutation = useDebouncedCall(markNotification, 200);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (result.isSuccess) {
      onViewed();
    }
  }, [onViewed, result]);

  const handleScroll = async () => {
    if (!notification.isViewed && refContainer?.current) {
      let currentCardPos = (refContainer.current as Element).getBoundingClientRect().bottom;
      if (window.innerHeight >= currentCardPos) {
        await debouncedMutation(notification.idNotification);
      }
    }
  };

  const notificationMeta = useMemo(() => {
    switch (notification.type) {
      case NotificationType.Appointment:
        return {
          icon: AppointmentsIcon,
          name: 'Запись к врачу в клинику',
          title: `Пациент оформил запись из личного кабинета ${dayjs(notification.content.notificationDateTimeOffset)
            .subtract(minutesTimezoneOffset - 180, 'm')
            .format('DD.MM.YYYY HH:mm')}`,
        };
      case NotificationType.WaitingList:
        return {
          icon: AppointmentsIcon,
          name: 'Запись к врачу в клинику',
          title: `Пациент оформил заявку в лист ожидания ${dayjs(notification.content.notificationDateTimeOffset)
            .subtract(minutesTimezoneOffset - 180, 'm')
            .format('DD.MM.YYYY HH:mm')}. Согласуйте дату и время приема`,
        };
      case NotificationType.Tmk:
        return {
          icon: Headphones,
          name: 'Запись на онлайн-консультацию',
          title: `Пациент оформил запись из личного кабинета ${dayjs(notification.content.notificationDateTimeOffset)
            .subtract(minutesTimezoneOffset - 180, 'm')
            .format('DD.MM.YYYY HH:mm')}`,
        };
      case NotificationType.Callmeback:
        return {
          icon: CallBack,
          name: 'Запрос на обратный звонок',
          title: `Пациент оформил запрос на обратный звонок из виджета ${dayjs(
            notification.content.notificationDateTimeOffset,
          )
            .subtract(minutesTimezoneOffset - 180, 'm')
            .format('DD.MM.YYYY HH:mm')}`,
        };
      case NotificationType.AppointmentCancelled:
        return {
          icon: Cancel,
          name: 'Отмена записи',
          title: `Автоматическая отмена: пациент не оплатил услугу за отведенное время`,
        };
      case NotificationType.GlobalTechWorks:
        return {
          icon: WarningIcon,
          name: 'Технические работы',
          title: `Временное ограничение работы сервисов`,
        };
      case NotificationType.GlobalNewFeature:
        return {
          icon: NewFuncIcon,
          name: 'Новый функционал',
          title: `Оцените новый сервис для проведения больших онлайн-конференций`,
        };
      default:
        return {
          icon: '',
          name: '',
          title: '',
        };
    }
  }, [notification]);

  return (
    <Layout className={styles.NotificationCard} ref={refContainer}>
      {/* temporary fix: hiding of red point*/}
      {/* {!notification.isViewed && <Circle className={styles.ViewedCircle} />} */}
      <Row className={styles.NotificationCardRow}>
        <Col className={styles.TitleCol}>
          <Image className={styles.Icon} preview={false} src={notificationMeta.icon} />
          <Typography className={styles.NotificationTitle}>{notificationMeta.name}</Typography>
        </Col>
        <Col className={styles.ContentCol}>
          <Row className={styles.ContentTopRow}>
            <NotificationIcon />
            <Typography className={notification.isViewed ? styles.ContentMainText : styles.ContentMainTextUnseen}>
              {notification.content?.title ? notification.content.title : notificationMeta.title}
            </Typography>
          </Row>
          {notification.content?.description && (
            <Row className={styles.ContentSubRow}>
              <Typography className={styles.Message}>{notification.content.description}</Typography>
            </Row>
          )}
          {notification.content?.branchShortName && (
            <Row className={styles.ContentSubRow}>
              <Col className={styles.ContentRowName}>
                <Typography>Филиал:</Typography>
              </Col>
              <Col>
                <Typography>{notification.content.branchShortName}</Typography>
              </Col>
            </Row>
          )}
          {notification.content?.branchDoctorFullName && (
            <Row className={styles.ContentSubRow}>
              <Col className={styles.ContentRowName}>
                <Typography>Специалист:</Typography>
              </Col>
              <Col>
                <Link
                  className={styles.Link}
                  to={`/company/doctors/${notification.content.branchId}/${notification.content.branchDoctorId}/general`}
                >
                  {notification.content.branchDoctorFullName}
                </Link>
              </Col>
            </Row>
          )}
          {notification.content?.serviceName && (
            <Row className={styles.ContentSubRow}>
              <Col className={styles.ContentRowName}>
                <Typography>Услуга:</Typography>
              </Col>
              <Col>
                <Typography>{`${notification.content.serviceName} (${notification.content.serviceCost}р)`}</Typography>
              </Col>
            </Row>
          )}
          {notification.content?.appointmentDateTimeFrom && (
            <Row className={styles.ContentSubRow}>
              <Col className={styles.ContentRowName}>
                <Typography>Дата и время:</Typography>
              </Col>
              <Col>
                <Typography>
                  {`${dayjs(notification.content.appointmentDateTimeFrom).format('DD MMMM YYYY, HH:mm')} - ${dayjs(
                    notification.content.appointmentDateTimeTo,
                  ).format('HH:mm')}`}
                </Typography>
              </Col>
            </Row>
          )}
          {notification.content?.whenToCall && (
            <Row className={styles.ContentSubRow}>
              <Col className={styles.ContentRowName}>
                <Typography>Время звонка:</Typography>
              </Col>
              <Col>
                <Typography>{notification.content?.whenToCall}</Typography>
              </Col>
            </Row>
          )}
        </Col>
        <Col className={styles.ActionCol}>
          {notification.content?.patientId && (
            <>
              <Row>
                <Col>
                  <Row className={styles.NameRow}>
                    <UserIcon />
                    <Typography>{notification.content.patientFullName}</Typography>
                  </Row>
                  {notification.content?.patientDateOfBirth && (
                    <Typography className={styles.PaleText}>
                      {dayjs(notification.content.patientDateOfBirth).format('DD.MM.YYYY')}
                    </Typography>
                  )}
                  <Typography>{notification.content.patientPhone}</Typography>
                </Col>
              </Row>
              {/*<Row className={styles.ButtonRow}>*/}
              {/*  <CustomButton*/}
              {/*    onClick={() => navigate('/chats')}*/}
              {/*    isPrimal={false}*/}
              {/*    content={<Typography>Написать пациенту в чат</Typography>}*/}
              {/*  />*/}
              {/*</Row>*/}
            </>
          )}
          {notification.content?.whenToCall && (
            <>
              <Row>
                <Col>
                  <Row className={styles.NameRow}>
                    <UserIcon />
                    <Typography>{notification.content.name}</Typography>
                  </Row>
                  <Typography>{notification.content.phone}</Typography>
                </Col>
              </Row>
            </>
          )}
          {notification.type === NotificationType.GlobalTechWorks && (
            <Link to="https://support.n3health.ru/">
              <Row className={styles.ButtonRow}>
                <CustomButton isPrimal={false} content={<Typography>Написать в техподдержку</Typography>} />
              </Row>
            </Link>
          )}
          {notification.type === NotificationType.GlobalNewFeature && (
            <Row className={styles.ButtonRow}>
              <CustomButton isPrimal={false} content={<Typography>Заказать демонстрацию</Typography>} />
            </Row>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
