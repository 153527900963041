import { Buttons } from '../Buttons/Buttons';
import { Layout, Row, Typography } from 'antd';
import { RecordsDumpPageIcon } from 'src/common/Icons/icons';
import React from 'react';
import styles from './styles.module.scss';

interface RecordsStubProps {
  title?: string;
  text?: string;
}

export const RecordsStub = ({ title, text }: RecordsStubProps) => {
  return (
    <Layout className={styles.StubContainer}>
      <Row className={styles.iconContainer}>
        <RecordsDumpPageIcon />
      </Row>
      <Row>
        <Typography className={styles.StubTitle}>{title}</Typography>
      </Row>
      <Row>
        <Typography className={styles.StubDescription}>{text}</Typography>
      </Row>
      <Buttons />
    </Layout>
  );
};
