import { AccessDeniedStub } from 'src/common/components/AccessDeniedStub/AccessDeniedStub';
import { ContentTable } from './components/ContentTable';
import { Topbar } from './components/Topbar';
import { getPageVisibility } from 'src/common/utils/utils';
import { useAppSelector } from '../../../app/hooks';
import NavSubMenu from '../NavSubMenu';
import React from 'react';
import styles from './styles.module.scss';

export const AmenitiesPage: React.FC = () => {
  const permissions = useAppSelector((state) => state.app.permissions) || [];

  const isPageVisible = getPageVisibility(permissions, 'can-access-page-services');

  if (!isPageVisible)
    return (
      <div className={styles.container}>
        <AccessDeniedStub
          title="Ошибка 403"
          text="Доступ запрещен
        

    У вас недостаточно прав доступа. Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
        />
      </div>
    );

  return (
    <>
      <NavSubMenu />
      <div className={styles.container}>
        <Topbar />
        <ContentTable />
      </div>
    </>
  );
};
