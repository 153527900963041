import { Button } from 'antd';
import { Props } from './ButtonsBlock.types';
import React from 'react';
import styles from './styles.module.scss';

export const ButtonsBlock = (props: Props) => {
  const { handleCheckButtonOnClick, handleCancelButtonOnClick, isCheckButtonDisabled } = props;

  return (
    <div className={styles.container}>
      <Button className={styles.buttonCancel} onClick={handleCancelButtonOnClick}>
        Отмена
      </Button>
      <Button className={styles.buttonCheck} onClick={handleCheckButtonOnClick} disabled={isCheckButtonDisabled}>
        Проверить
      </Button>
    </div>
  );
};
