import {Button, Checkbox, Col, Input, notification, Row, Spin, Typography} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import NavSubMenu from '../../NavSubMenu';
import React, {useEffect, useMemo, useState} from 'react';
import { ReactComponent as Warn } from '../../../../assets/Warn.svg';
import { ReactComponent as OkIcon } from '../../../../assets/OkIcon.svg';
import {
  useChangeAccessToDoctorPortalMutation,
  useGetDoctorContactInfoQuery,
  useGetDoctorGeneralInfoQuery, useResendVerificationEmailMutation
} from '../../../../app/services/api';
import DoctorTabs from '../DoctorTabs';
import DoctorTitle from '../DoctorTitle';
import {useAppSelector} from '../../../../app/hooks';
import {generatePassword} from '../../../../common/utils/utils';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

function DoctorAccessPage() {
  const { pathname } = useLocation();
  const practiceDoctorId = useMemo(() => pathname.split('/').reverse()[1], [pathname]);
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);
  
  const [selectedMode, setSelectedMode] = useState('generate');
  const [patientConsult, setPatientConsult] = useState(false);
  const [doctorConsult, setDoctorConsult] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [isChangePasswordMode, setIsChangePasswordMode] = useState(true);
  const [saveDate, setSaveDate] = useState<Date | null>(null);
  
  const createPassword = selectedMode === 'generate';
  const sendEmail = selectedMode === 'email';

  const {
    data: doctorGeneralInfo,
    refetch: refetchDoctorGeneralInfo,
    isLoading,
  } = useGetDoctorGeneralInfoQuery(
      { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
      {
        skip: !practiceId || !practiceDoctorId,
      },
  );

  const { data: doctorContactInfo, isLoading: isContactsLoading } = useGetDoctorContactInfoQuery(
      { practiceId: practiceId!, practiceDoctorId: practiceDoctorId! },
      {
        skip: !practiceId || !practiceDoctorId,
      },
  );

  const [changeAccessToDoctorPortal] = useChangeAccessToDoctorPortalMutation();
  const [resendVerificationEmail, resendVerificationEmailResult] = useResendVerificationEmailMutation();
  
  const noEmail = !doctorContactInfo?.email || doctorContactInfo?.email?.length === 0;
  
  useEffect(() => {
    if (doctorGeneralInfo) {
      setPatientConsult(doctorGeneralInfo.hasAccessToDoctorPortal);
      setDoctorConsult(doctorGeneralInfo.hasAccessToDoctorPortalWithDoctor);
      setDisabledSave(!(doctorGeneralInfo.hasAccessToDoctorPortal || doctorGeneralInfo.hasAccessToDoctorPortalWithDoctor));
      setIsChangePasswordMode(!(doctorGeneralInfo.hasAccessToDoctorPortal || doctorGeneralInfo.hasAccessToDoctorPortalWithDoctor || doctorGeneralInfo.hasAccessToOtherDoctorPortal));
    }
  }, [doctorGeneralInfo]);
  
  const changePatientConsult = () => {
    setPatientConsult(!patientConsult);
    setDisabledSave(false);
  };
  
  const changeDoctorConsult = () => {
    setDoctorConsult(!doctorConsult);
    setDisabledSave(false);
  };
  
  const handleCancelButtonOnClick = () => {
    if (doctorGeneralInfo) {
      setPatientConsult(doctorGeneralInfo.hasAccessToDoctorPortal);
      setDoctorConsult(doctorGeneralInfo.hasAccessToDoctorPortalWithDoctor);
      setDisabledSave(!(doctorGeneralInfo.hasAccessToDoctorPortal || doctorGeneralInfo.hasAccessToDoctorPortalWithDoctor));
      setPassword('');
    }
  };
  
  const handleSaveButtonOnClick = async () => {
    setIsSaveLoading(true);
    const result = await changeAccess();
    setIsSaveLoading(false);
    if (result) {
      setSaveDate(new Date());
    }
  };
  
  const changeAccess = async () => {
    let passwordValue = '';
    if (isChangePasswordMode && createPassword) {
      passwordValue = generatePassword(16);
    }
    const result: any = await changeAccessToDoctorPortal({
      practiceId,
      practiceDoctorId,
      request: {
        password: passwordValue,
        hasAccessToDoctorPortal: patientConsult,
        hasAccessToDoctorPortalWithDoctor: doctorConsult
      }
    });
    if (result.error) {
      notification.error({ message: result?.error?.data?.detail });
      return false;
    } else {
      setPassword(passwordValue);
      refetchDoctorGeneralInfo();
      return true;
    }
  };
  
  const passwordAction = async () => {
    if (createPassword) {
      setIsChangePasswordLoading(true);
      await changeAccess();
      setIsChangePasswordLoading(false);
    } else {
      resendVerificationEmail({
        practiceId,
        practiceDoctorId,
      });
    }
    setSaveDate(null);
  };
  
  const copyPasswordMessage = async () => {
    await navigator.clipboard.writeText(`Адрес входа в кабинет врача  https://doctor.mila.online\nВаш логин  ${doctorContactInfo?.email}\nВаш пароль  ${password}\n\nИнструкции по подключению  https://mila.online/manuals\nПоддержка  support@n3health.ru, +7 (812) 997-45-62`);
  };

  return (
    <>
      <NavSubMenu />
      {isLoading || isContactsLoading ? (
          <Spin size="large" className={styles.pageLoader} />
      ) : (
      <div className={styles.pageContainer}>
        <DoctorTitle doctorGeneralInfo={doctorGeneralInfo} practiceId={practiceId} />
        <DoctorTabs doctorId={practiceDoctorId} />
        <Col className={styles.educationFormContainer}>
          <Col className={styles.generalDataFormTitle}>
            <Typography.Text>Доступ в кабинет врача для телемедицинских консультаций</Typography.Text>
          </Col>
          {noEmail && <>
            <Typography className={styles.WarnNote}>
              <Warn />
              Сначала добавьте e-mail врача
            </Typography>

            <Typography className={styles.WarnText}>
              У врача не заполнено поле e-mail в разделе «Контактные данные». 
              Вернитесь во вкладку <Link className={styles.WarnLink} to={`/company/doctors/${practiceId}/${practiceDoctorId}/general`}>«Общие данные»</Link> и укажите e-mail.
            </Typography>
          </>}
          {(doctorGeneralInfo?.hasAccessToDoctorPortal || doctorGeneralInfo?.hasAccessToDoctorPortalWithDoctor) && 
              <Col className={styles.infoBlock}>
                <div>
                  <Typography className={styles.infoNote}>
                    <Warn/>
                    Как изменить настройки доступа для врача
                  </Typography>
                  <div>
                    Чтобы изменить пароль доступа для врача - нажмите «Отправить новый доступ», выберите способ отправки,
                    нажмите «Выполнить», а потом «Сохранить». Чтобы добавить тип консультаций без изменения настроек доступа
                    - выберите новый тип консультации и нажмите «Сохранить».
                    Чтобы заблокировать доступ врачу в его кабинет - отключите оба типа консультаций и нажмите «Сохранить».
                  </div>
                </div>
              </Col>}
          {doctorGeneralInfo?.hasAccessToOtherDoctorPortal && 
              <Col className={styles.infoBlock}>
                <div>
                  <div>
                    Доступ в кабинет врача для данного e-mail уже был ранее открыт другим филиалом или клиникой. 
                    Чтобы предоставить врачу доступ в кабинет от вашей клиники на этот же e-mail просто выберите 
                    типы консультаций, доступные врачу в вашей клинике, и нажмите «Сохранить».
                    <br />
                    Если необходимо создать отдельный доступ - укажите другой e-mail в профиле врача.
                  </div>
                </div>
              </Col>}
          <Col className={styles.generalDataHasAccessInput}>
            <Col className={styles.AccessCol}>
              <Typography.Text>
                Типы консультаций, которые может проводить врач:
              </Typography.Text>
            </Col>
            <Col className={styles.AccessCol}>
              <Checkbox
                  disabled={noEmail || isSaveLoading}
                  checked={patientConsult}
                  onClick={changePatientConsult}
            >
              Консультации «Врач - пациент»
            </Checkbox>
            </Col>
            <Col className={styles.AccessCol}>
            <Checkbox
                disabled={noEmail || isSaveLoading}
                checked={doctorConsult}
                onClick={changeDoctorConsult}
            >
              Консультации «Врач - врач»
            </Checkbox>
            </Col>
            <Col className={styles.AccessCol}>
              <Typography.Text>
                Выберите, каким способом отправить доступ врачу:
              </Typography.Text>
            </Col>
            <label className={styles.radio}>
              <Input disabled={noEmail || isSaveLoading || !isChangePasswordMode} type="radio" checked={createPassword} 
                     onClick={() => setSelectedMode('generate')} onChange={() => {}}/>
              <span>Сгенерировать пароль и передать врачу</span>
            </label>

            <label className={styles.radio}>
              <Input disabled={noEmail || isSaveLoading|| !isChangePasswordMode} type="radio" checked={sendEmail} 
                     onClick={() => setSelectedMode('email')} onChange={() => setPassword('')}/>
              <span>Отправить ссылку для установки пароля на e-mail врача</span>
            </label>
          </Col>
          {isChangePasswordMode ?
              (doctorGeneralInfo?.hasAccessToDoctorPortal || doctorGeneralInfo?.hasAccessToDoctorPortalWithDoctor) && 
              <Col className={styles.generalDataHasAccessButton}>
              <Button
                  disabled={!doctorConsult && !patientConsult}
                  loading={resendVerificationEmailResult.isLoading || isChangePasswordLoading}
                      onClick={passwordAction}
                  >
                    Выполнить
                  </Button>
            </Col>
              :
              <Col className={styles.generalDataHasAccessButton}>
                <Button
                    onClick={() => setIsChangePasswordMode(true)}
                >
                  Отправить новый доступ
                </Button>
              </Col>}
          {resendVerificationEmailResult.isSuccess && <div><OkIcon style={{marginRight: 10}}/> 
            Ссылка для доступа отправлена на e-mail врача {doctorContactInfo?.email}</div>}
          {saveDate != null && <div className={styles.SuccessMessage}><OkIcon className={styles.SuccessMessageIcon} /> 
            <span>Доступ для врача (последнее изменение): {dayjs(saveDate).format('DD MMMM YYYY г. в HH:mm ')} 
             для пользователя {doctorContactInfo?.email}</span></div>}
          {password.length > 0 && <>
            <Col className={styles.AccessCol}>
              <OkIcon style={{marginRight: 10}}/> Скопируйте и передайте врачу любым удобным и безопасным способом:
            </Col>
            <Col className={styles.AccessCol}>
              <div className={styles.passwordInfo}>
                <div>Адрес входа в кабинет врача  https://doctor.mila.online</div>
                <div>Ваш логин  {doctorContactInfo?.email}</div>
                <div>Ваш пароль  {password}</div>
                <br />
                <div>Инструкции по подключению  https://mila.online/manuals</div>
                <div>Поддержка  support@n3health.ru, +7 (812) 997-45-62</div>
              </div>
            </Col>
            <Col className={styles.generalDataHasAccessButton}>
              <Button
                  onClick={copyPasswordMessage}
              >
                Скопировать
              </Button>
            </Col>
          </>}
        </Col>

        <Row className={styles.formButtonsContainer} gutter={[21, 10]}>
          <Col>
            <Button disabled={disabledSave || isSaveLoading || isChangePasswordLoading} 
                    className={styles.buttonCancel} 
                    onClick={handleCancelButtonOnClick}>
              Отмена
            </Button>
          </Col>
          <Col>
            <Button disabled={disabledSave || isChangePasswordLoading} 
                    loading={isSaveLoading} 
                    className={styles.buttonSubmit} 
                    onClick={handleSaveButtonOnClick}>
              Сохранить
            </Button>
          </Col>
        </Row>
      </div>)}
    </>
  );
}

export default DoctorAccessPage;
