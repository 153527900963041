import { Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions*/

function NavSubMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPath = location.pathname.split('/')[2];

  return (
    <>
      <div className={styles.menuItemWrapper}>
        <div
          className={selectedPath === 'branches' ? styles.activeMenuItem : ''}
          onClick={() => navigate('/company/branches')}
        >
          <Typography.Text>Филиалы</Typography.Text>
        </div>
        <div
          className={selectedPath === 'amenities' ? styles.activeMenuItem : ''}
          onClick={() => navigate('/company/amenities')}
        >
          <Typography.Text>Услуги</Typography.Text>
        </div>
        <div
          className={selectedPath === 'specializations' ? styles.activeMenuItem : ''}
          onClick={() => navigate('/company/specializations')}
        >
          <Typography.Text>Специализации</Typography.Text>
        </div>
        <div
          className={selectedPath === 'doctors' ? styles.activeMenuItem : ''}
          onClick={() => navigate('/company/doctors')}
        >
          <Typography.Text>Врачи и специалисты</Typography.Text>
        </div>
        <div
          className={selectedPath === 'payments' ? styles.activeMenuItem : ''}
          onClick={() => navigate('/company/payments')}
        >
          <Typography.Text>Эквайринг</Typography.Text>
        </div>
        {/* <div
          className={selectedPath === 'accesses' ? styles.activeMenuItem : ''}
          onClick={() => navigate('/company/accesses')}
        >
          <Typography.Text>Доступ</Typography.Text>
        </div> */}
      </div>
      <div className={styles.subMenuContainer}></div>
    </>
  );
}

export default NavSubMenu;
