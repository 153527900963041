import { Buttons } from '../Buttons/Buttons';
import { Image, Layout, Row, Typography } from 'antd';
import AccessDeniedIcon from 'src/common/Icons/AccessDeniedIcon.svg';
import React from 'react';
import styles from './styles.module.scss';

interface AccessDeniedStubProps {
  title?: string;
  text?: string;
}

export const AccessDeniedStub = ({ title, text }: AccessDeniedStubProps) => {
  return (
    <Layout className={styles.StubContainer}>
      <Row className={styles.iconContainer}>
        <Image src={AccessDeniedIcon} alt="AccessDeniedStubIcon" preview={false} />
      </Row>
      <Row>
        <Typography className={styles.StubTitle}>{title}</Typography>
      </Row>
      <Row>
        <Typography className={styles.StubDescription}>{text}</Typography>
      </Row>
      <Buttons />
    </Layout>
  );
};
