import React from 'react';
import styles from './styles.module.scss';

function StatusWithLabel(props: { value: string }) {
  const { value } = props;
  if (value === 'Активный') {
    return (
      <>
        <div className={styles.statusTextWrapper}>
          <div className={styles.colorCircle}></div>
          <span>Активный</span>
        </div>
      </>
    );
  } else if (value === 'Доступ заблокирован') {
    return (
      <div className={styles.statusTextWrapper}>
        <div className={styles.colorCircle} style={{ background: '#FA6060' }}></div>
        <span>Доступ заблокирован</span>
      </div>
    );
  } else {
    return (
      <div className={styles.statusTextWrapper}>
        <div className={styles.colorCircle} style={{ background: '#BEBFC8' }}></div>
        <span>Приглашение отправлено</span>
      </div>
    );
  }
}

export default StatusWithLabel;
