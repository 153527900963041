import { Circle } from 'src/common/Icons/icons';
import { Row, Typography } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

interface ConfirmCircleProps {
  confirmed: boolean;
}

export const ConfirmCircle = ({ confirmed }: ConfirmCircleProps) => {
  return confirmed ? (
    <Row className={styles.StatusTextRow}>
      <Circle />
      <Typography>Да</Typography>
    </Row>
  ) : (
    <Row className={styles.StatusTextRow}>
      <Circle className={styles.RedCircle} />
      <Typography>Нет</Typography>
    </Row>
  );
};
