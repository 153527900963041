// Couldn't figure out what types to use here
/* eslint-disable @typescript-eslint/no-explicit-any */
export const useDebouncedCall = (query: any, time: number) => {
  let timeoutId: any;
  function wrapper(...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      query(...args);
    }, time);
  }
  return wrapper;
};
