import { Button, Col, Form, Input, Typography } from 'antd';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { apiAuthSlice } from '../../app/services/apiAuth';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';
import { resetApp } from '../../app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import { withId } from 'react-yandex-metrika';

function Login(props: { isAmoCrmLoginEnabled: boolean; startAmoLogin: boolean }) {
  const { isAuthenticated, isLoading } = useAuth();
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';
  const authService = oidcAuthService;
  const navigate = useNavigate();
  const [isAmoCrmLoginEnabled] = useState<boolean>(props.isAmoCrmLoginEnabled);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    if (props.isAmoCrmLoginEnabled && props.startAmoLogin) {
      onAmoCrmLogin();
    }
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      networkId || medOrganizationId ? navigate('/') : navigate('/networkSelection');
    }

    dispatch(resetApp());
    dispatch(apiAuthSlice.util.resetApiState());
  }, [dispatch, isAuthenticated, isLoading, medOrganizationId, navigate, networkId]);

  const onResetPassword = async () => {
    window.location.href = await authService.resetCredentialsUrl();
  };

  const onFinish = async (values: any) => {
    await authService.signInWithLoginAndPassword(values.username, values.password);
    withId(96143494)('reachGoal', 'clinic_login');
    console.log('clinic_login reached');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onAmoCrmLogin = async () => {
    await authService.signInWithAmo();
  };

  return (
    <div className={styles.authFormWrapper}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && !isAuthenticated && (
        <div className={styles.formContainer}>
          <Col className={styles.logo}>
            <MilaIcon />
          </Col>
          <Col className={styles.formTitle}>
            <Typography.Text>Вход в личный кабинет</Typography.Text>
          </Col>
          <Col className={styles.inputrapper}>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста введите логин!' }]}>
                <Input placeholder={'Логин'} className={styles.LoginFormInput} />
              </Form.Item>
              <div className={styles.passwordContainer}>
                <Form.Item name="password" rules={[{required: true, message: 'Пожалуйста введите пароль!'}]}>
                  <Input placeholder={'Пароль'} type={showPassword ? 'text' : 'password'}
                         className={styles.LoginFormInput}/>
                </Form.Item>
                <div onClick={() => setShowPassword(!showPassword)}
                   className={`${styles.passwordEye} ${showPassword ? styles.Open : ''}`}></div>
              </div>

              <Form.Item>
              <Button htmlType="submit" className={styles.loginButton}>
                  Вход
                </Button>
              </Form.Item>

              <Col className={styles.ResetPwdLink}>
                <Button type="link" onClick={onResetPassword}>
                  Восстановить пароль
                </Button>
              </Col>
              {isAmoCrmLoginEnabled && (
                <Col>
                  <Button className={styles.amoLoginButton} onClick={onAmoCrmLogin}>
                    Войти через ЛК N3.Health
                  </Button>
                </Col>
              )}
              <Col className={styles.SupportMsgLink}>
                <Typography.Link href="https://support.n3health.ru" target="_blank">
                  Написать в поддержку
                </Typography.Link>
              </Col>
            </Form>
          </Col>
        </div>
      )}
    </div>
  );
}

export default Login;
