import React, { useState } from 'react';
import styles from './FloatLabel.module.scss';

interface IProps {
  children: React.ReactNode;
  label: string;
  fixSmall?: boolean;
  top?: number;
  left?: number;
}

/**
 * Добавляет placeholder-подобную надпись на целевой элемент. Когда элемент получает фокус, надпись уменьшается и смещается, освобождая место для содержимого.
 *
 * **У целевого элмента должен быть вертикальный padding достаточный, чтобы уменьшенная надпись не пересекалась с содержимым*
 * @param children целевой элемент
 * @param label надпись
 * @param fixSmall передайте boolean-значение, чтобы зафиксировать надпись в уменьшенном режиме
 * @example fixSmall={!!value}
 * @param top отступ сверху в пикселях для уменьшенного режима. По умолчанию 6
 * @param left отступ слева в пикселях для обоих режимах. По умолчанию 13
 */
export function FloatLabel({ children, label, fixSmall = false, top = 6, left = 13 }: IProps) {
  const [focus, setFocus] = useState(false);

  const isSmall = focus || fixSmall;

  const labelClass = isSmall ? styles.labelSmall : styles.labelFull;
  const styleTop = isSmall ? `${top}px` : `calc(50% - 12px)`;
  const styleLeft = `${left}px`;

  return (
    <div className={styles.container} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
      {children}
      <span className={labelClass} style={{ top: styleTop, left: styleLeft }}>
        {label}
      </span>
    </div>
  );
}
