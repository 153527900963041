import { ReactComponent as PlusIcon } from '../../../../../../assets/PlusIcon.svg';
import { Props } from './Specialities.types';
import { Speciality } from './components';
import { Typography } from 'antd';
import { getSpecialityCodeFromLabel } from '../../DoctorsInfoPage.utils';
import { useSpecialities } from './Specialities.hooks';
import React from 'react';
import styles from './styles.module.scss';

export const Specialities = (props: Props) => {
  const {
    selectedSpecialities,
    options,
    handleAddSpecialityOnClick,
    handleDeleteSpecialityOnClick,
    setCanServeAdults,
    setCanServeChildren,
    handleOnChange,
    isDisplayingAddSpecialityButton,
    areSelectsDisabled,
    isDisplayingDeleteSpecialityIcon,
    isDisplaySameCodeSpecialities,
  } = props;
  const { filterOptions, sortOptions } = useSpecialities();
  return (
    <div className={styles.container}>
      <>
        {selectedSpecialities.length === 0 ? (
          <>Специализации не выбраны</>
        ) : (
          <>
            {selectedSpecialities.map((speciality) => {
              const sameCodeSpecialities = options.filter(
                (spec) => getSpecialityCodeFromLabel(spec.label) === speciality.specialityFerId,
              );
              return (
                <Speciality
                  {...speciality}
                  key={speciality.uid}
                  name={speciality.specialityFullName}
                  uid={speciality.uid}
                  options={isDisplaySameCodeSpecialities ? sameCodeSpecialities : options}
                  sortOptions={sortOptions}
                  handleOnChange={handleOnChange}
                  filterOptions={filterOptions}
                  isDisplayingDeleteSpecialityIcon={isDisplayingDeleteSpecialityIcon}
                  handleDeleteSpecialityOnClick={handleDeleteSpecialityOnClick}
                  setCanServeAdults={setCanServeAdults}
                  setCanServeChildren={setCanServeChildren}
                  isSelectDisabled={areSelectsDisabled}
                />
              );
            })}
          </>
        )}
      </>
      {isDisplayingAddSpecialityButton && (
        <div className={styles.addSpecialityTextWrapper}>
          <div onClick={handleAddSpecialityOnClick} className={styles.addSpeciality}>
            <PlusIcon />
            <Typography.Text>Добавить специализацию</Typography.Text>
          </div>
        </div>
      )}
    </div>
  );
};
