import { DownCaretIcon } from 'src/common/Icons/icons';
import { Select, SelectProps, TreeSelect } from 'antd';
import FloatLabelInput from 'src/common/FloatLabelInput/FloatLabelInput';
import React from "react";
import styles from './styles.module.scss';

export interface SelectorOption { 
  value: any; 
  label: string;
  children?: any[],
  icon?: React.JSX.Element,
};

interface SelectorProps {
  onChange?: (key: string) => void;
  placeholder?: string;
  className?: string;
  floatLabel?: string;
  inputName?: string;
  options?: SelectorOption[];
  value?: string;
  onClear?: () => void;
  isTreeSelect?: boolean;
}

export const CustomSelector = ({
  onChange,
  className,
  floatLabel,
  options,
  value,
  onClear,
  isTreeSelect = false,
  ...rest
}: SelectorProps & SelectProps) => (
  <FloatLabelInput label={floatLabel} value={value?.toString()} className={`${styles.FormItemWrapper} ${className}`}>
    {isTreeSelect ? (
      <TreeSelect
        suffixIcon={<DownCaretIcon />}
        treeData={options}
        onChange={onChange}
        className={styles.Search}
        value={value}
        onClear={onClear}
        {...rest}
        allowClear
        treeIcon
      />
    ) : (
      <Select
        suffixIcon={<DownCaretIcon />}
        onChange={onChange}
        className={styles.Search}
        options={options}
        value={value}
        onClear={onClear}
        {...rest}
        allowClear
      />
    )}
  </FloatLabelInput>
);
