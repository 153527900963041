import { PracticeSpecialityService } from 'src/common/types';
import { RowController } from './RowController';
import React from 'react';

type PropsType = {
  speciality: PracticeSpecialityService;
};

export const AmenitySpecialityContext = React.createContext<PropsType>({} as PropsType);

export const RowsList: React.FC<PropsType> = ({ speciality }) => {
  return (
    <>
      <AmenitySpecialityContext.Provider value={{ speciality }}>
        {speciality.services.map((service) => (
          <RowController key={service.serviceId} service={service} />
        ))}
      </AmenitySpecialityContext.Provider>
    </>
  );
};
