import { useAppSelector } from '../../../../app/hooks';
import { useMemo } from 'react';

export const useAddDoctorStub = () => {
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);

  const addDoctorLink = useMemo(() => {
    return `/company/doctors/${practiceId ?? ''}/add-doctor`;
  }, [practiceId]);

  return { addDoctorLink };
};
