import { Layout } from 'antd';
import { NavTabs } from '../../common/components/NavTabs/NavTabs';
import { Outlet, useLocation } from 'react-router';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

const tabsItems = [
  {
    label: 'Записи в клинику',
    key: 'appointments',
  },
  {
    label: 'Записи на ТМК',
    key: 'tmk',
  },
  {
    label: 'Лист ожидания',
    key: 'waitingList',
  },
  {
    label: 'Обратный звонок',
    key: 'callback',
  },
];

export const RecordsPage = () => {
  const { pathname } = useLocation();
  const activeTabKey = useMemo(() => pathname.split('/').reverse()[0], [pathname]);
  return (
    <Layout className={styles.RecordPage}>
      <NavTabs activeTabKey={activeTabKey} items={tabsItems} basicPath="records" />
      <Outlet />
    </Layout>
  );
};
