import { Branch } from 'src/common/types';
import { Button, Col, Popconfirm, Row, notification } from 'antd';
import { DownCaretIcon, ExclamationMarkIcon, ExportFileIcon, ImportFileIcon } from 'src/common/Icons/icons';
import { getPreparedBranches, getPreparedSortedBranches } from 'src/common/utils/utils';
import { oidcAuthService } from 'src/app/services/auth/oidcAuthService';
import { setBranch, setBranchName } from 'src/app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetPracticeBranchesQuery, useGetPracticeServicesQuery } from 'src/app/services/api';
import React, { useEffect, useMemo, useState } from 'react';
import Tooltip from 'antd/lib/tooltip';
import WidthFixedSelect from 'src/common/components/WidthFixedSelect';
import styles from './Topbar.module.scss';
import { useGetUseTmData } from '../../helpers';

export const Topbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const availableBranches = useAppSelector((state) => state.app.availableBranches);
  const branchId = useAppSelector((state) => state.app.selectedBranchId!);
  const branchName = useAppSelector((state) => state.app.selectedBranchName);
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';

  const useTmData = useGetUseTmData(branchesList, branchId);

  const [isImportInProgress, setImportInProgress] = useState<boolean>(false);
  const [isExportInProgress, setExportInProgress] = useState<boolean>(false);

  const { refetch: refetchServices } = useGetPracticeServicesQuery(branchId);

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableBranches || []) : []),
    [availableBranches, branchesList],
  );

  useEffect(() => {
    if (orderedBranches.length === 0) return;

    if (!branchId) {
      const defaultBranch = orderedBranches[0];

      dispatch(setBranch(defaultBranch.branchId));
      dispatch(setBranchName(defaultBranch.shortName));
    } else if (branchId && !branchName) {
      const selectedBranch = orderedBranches.find((branch) => branch.branchId === branchId);

      if (selectedBranch) {
        dispatch(setBranchName(selectedBranch.shortName));
      }
    }
  }, [dispatch, orderedBranches, branchId, branchName]);

  const prepareBranches = (branchesData: Branch[] | undefined) => {
    return branchesData ? getPreparedBranches(branchesList || [], availableBranches || []) : [];
  };

  const branchSelectHandle = (value: string, option: any) => {
    dispatch(setBranch(value));
    dispatch(setBranchName(option.label));
  };

  const exportToExcelHandler = async () => {
    try {
      setExportInProgress(true);
      const bearer = await oidcAuthService.getToken();
      const response = await fetch(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/practice/${branchId}/specialities/services/export`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${bearer}`,
            'x-mila-network-id': networkId,
            'x-mila-med-organization-id': medOrganizationId,
          },
        },
      );
      if (response.status < 200 || response.status > 300) {
        notification.error({ message: 'При загрузке файла возникла ошибка' });
        return;
      }
      // download the file
      const blob = await response.blob();
      const filename = response.headers.get('Content-Disposition')?.split("filename*=UTF-8''")[1];
      const link = document.createElement('a');
      link.download = (filename && decodeURIComponent(filename)) || 'Услуги филиала.xlsx';
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    } finally {
      setExportInProgress(false);
    }
  };

  const importFromExcelHandler = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.name = 'fileToImport';
    input.multiple = false;
    input.accept = '.xlsx';
    input.onchange = async function () {
      if (!input.files) return;
      try {
        setImportInProgress(true);

        const formData = new FormData();
        formData.append('fileToImport', input.files[0], input.files[0].name);

        const bearer = await oidcAuthService.getToken();
        const response = await fetch(
          `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/practice/${branchId}/specialities/services/import`,
          {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `Bearer ${bearer}`,
              'x-mila-network-id': networkId,
              'x-mila-med-organization-id': medOrganizationId,
            },
          },
        );
        if (response.status < 200 || response.status > 300) {
          let errorDetails = '';
          try {
            const problem = await response.json();
            errorDetails = problem && problem.type === 'Business' && problem.detail ? `: ${problem.detail}` : '';
          } catch {
            /* noop */
          }
          notification.error({ message: 'При импорте файла возникла ошибка' + errorDetails });
        } else {
          refetchServices();
          notification.success({ message: 'Файл импортирован' });
        }
      } finally {
        setImportInProgress(false);
      }
    };
    input.click();
  };

  return (
    <Row className={styles.container}>
      <Col>
        <Tooltip
          title="В этом разделе необходимо добавить услуги, на которые пациенты могут записаться в вашу клинику через сервисы Mila. У каждого филиала свой перечень услуг. Все услуги привязываются к врачебным специализациям (по Федеральному справочнику OID 1.2.643.5.1.13.13.11.1066). Вы можете добавить услуги вручную или импортировать из Excel, предварительно скачав Шаблон импорта услуг из Excel."
          placement="right"
        >
          <div className={styles.button}>
            <ExclamationMarkIcon />
          </div>
        </Tooltip>
      </Col>
      <Col className={styles.field}>
        <WidthFixedSelect
          className={styles.select}
          options={prepareBranches(branchesList)}
          onChange={(value, option) => {
            branchSelectHandle(value, option);
          }}
          value={branchId}
          suffixIcon={<DownCaretIcon />}
        />
      </Col>
      {!useTmData && (
        <Col className={styles.field}>
          <Popconfirm
            title={`Будут удалены все услуги, отсутствующие в импортируемом файле.`}
            okText="Продолжить"
            cancelText="Отмена"
            onConfirm={importFromExcelHandler}
          >
            <Button className={styles.excelButton} icon={<ImportFileIcon />} loading={isImportInProgress}>
              Импорт услуг из Excel
            </Button>
          </Popconfirm>
        </Col>
      )}
      <Col className={styles.field}>
        <Button
          className={styles.excelButton}
          icon={<ExportFileIcon />}
          loading={isExportInProgress}
          onClick={exportToExcelHandler}
        >
          Export услуг в Excel
        </Button>
      </Col>
    </Row>
  );
};
