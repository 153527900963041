import { Input } from 'antd';
import FloatLabelInput from 'src/common/FloatLabelInput/FloatLabelInput';
import InputMask from 'react-input-mask';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface PhoneInputProps {
  value?: string;
  onChange?: (e: any) => void;
  name: string;
  style?: any;
  className?: string;
  defaultMask?: string;
  floatLabel?: string;
}

export const PhoneInput = ({
  value,
  onChange,
  name,
  style,
  className,
  defaultMask = '+9 (999) 999-9999',
  floatLabel = '',
}: PhoneInputProps) => {
  const [phoneMask, setPhoneMask] = useState(defaultMask);
  const phoneRef = useRef<any>(null);
  const [pos, setPos] = useState(0);
  const [isNeedChangeMask, setIsNeedChangeMask] = useState(false);

  const handleChangePhoneMask = (e: any) => {
    if (e.key === '+' && e.target.selectionStart === 0) {
      setIsNeedChangeMask(true);
      handleOnChange(e);
    }
  };

  const handleOnChange = useCallback(
    (e: any) => {
      let value = e.target.value.replace('+', '');
      if (isNeedChangeMask) {
        setPhoneMask('+9 (999) 999-9999');
        setIsNeedChangeMask(false);
        value = value[0] === '_' ? `+7${value.slice(1, value.length)}` : `+7${value.slice(1, value.length)}`;
        setPos(4);
      } else if (e.target.value.indexOf('+8') > -1) {
        setPhoneMask('9 (999) 999-9999');
        setPos(3);
      } else if (e.target.value[0] === '7') {
        setPhoneMask('+9 (999) 999-9999');
        setPos(4);
      } else if (value[0] === '_') {
        value = phoneMask[0] === '+' ? `+_${value.slice(1, value.length)}` : `_${value.slice(0, value.length)}`;
      } else if (value?.length > 0) {
        value = phoneMask[0] === '+' ? `+7${value.slice(1, value.length)}` : `8${value.slice(1, value.length)}`;
      }
      onChange && onChange({ ...e, ...{ target: { ...e.target, value, id: name } } });
    },
    [isNeedChangeMask, onChange, phoneMask],
  );

  useEffect(() => {
    if (value) {
      if (value[0] === '+' && phoneMask[0] !== '+') setPhoneMask('+9 (999) 999-9999');
      handleOnChange({ target: phoneRef.current.input });
    }
    if (pos) {
      setTimeout(() => phoneRef.current.setSelectionRange(pos, pos, 'forward'), 0);
      setPos(0);
    }
  }, [value, isNeedChangeMask, phoneMask]);

  return (
    <FloatLabelInput label={floatLabel || ''} value={value}>
      <InputMask
        mask={phoneMask}
        value={value}
        className={className}
        name={name}
        style={style}
        onChange={handleOnChange}
        onKeyPress={handleChangePhoneMask}
      >
        <Input ref={phoneRef} className={className} name="phone" allowClear />
      </InputMask>
    </FloatLabelInput>
  );
};
