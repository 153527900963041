import { amenitiesReducer } from './services/redux/AmenitiesSlice';
import { apiAuthSlice } from './services/apiAuth';
import { apiDictionarySlice } from './services/apiDictionary';
import { apiSlice } from './services/api';
import { metroApiSlice } from './services/metroApi';
import { appReducer } from './services/redux/appSlice';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiSyncSlice } from './services/apiSync';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
};

const reducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [metroApiSlice.reducerPath]: metroApiSlice.reducer,
  [apiDictionarySlice.reducerPath]: apiDictionarySlice.reducer,
  [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
  [apiSyncSlice.reducerPath]: apiSyncSlice.reducer,
  app: appReducer,
  amenities: amenitiesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(apiSlice.middleware)
      .concat(metroApiSlice.middleware)
      .concat(apiDictionarySlice.middleware)
      .concat(apiAuthSlice.middleware)
      .concat(apiSyncSlice.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
