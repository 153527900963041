import { Form, Input } from 'antd';
import { StatusSelect } from '../StatusSelector/StatusSelector';
import React from 'react';
import styles from './styles.module.scss';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'select' | 'text';
  record: any;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  children,
  onChange,
  ...restProps
}) => {
  const inputNode = inputType === 'select' ? <StatusSelect record={record} onChange={onChange} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className={styles.Input}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Пожалуйста, заполните ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
