import { Avatar, Button, Col, Form, Input, Row, Tooltip, Typography } from 'antd';
import { ReactComponent as QuestionMarkIcon } from '../../assets/QuestionMarkIcon.svg';
import { useAuth } from 'react-oidc-context';
import { useForm } from 'antd/lib/form/Form';
import FloatLabelInput from '../../common/FloatLabelInput/FloatLabelInput';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';

const passwordHintText = (
  <>
    <p> Пароль:</p>
    <ul>
      <li>не менее 10 символов;</li>
      <li>не менее 2 латинских букв (как минимум одна заглавная и одна строчная буква);</li>
      <li>не менее 1 цифры (арабские);</li>
      <li>без пробелов;</li>
      <li>не менее 1 спецсимвола.</li>
    </ul>
  </>
);

interface ChangePasswordInitialState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function ChangePasswordPage() {
  const [form] = useForm<ChangePasswordInitialState>();
  const [isSubmitDisabled, setSubmitBtnDisabled] = useState(true);
  const auth = useAuth();
  const loggedUserName = auth.user?.profile.preferred_username || '';

  const [fieldValues, setFieldValue] = useState<ChangePasswordInitialState>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const setDefaultValues = () => {
    form.resetFields();
  };

  const handleSubmitForm = () => {}; // to be implemented later

  const onFormValuesChange = () => {
    setSubmitBtnDisabled(false);
  };

  const isPasswordsValidated = useMemo(
    () => fieldValues.newPassword && fieldValues.newPassword === fieldValues.confirmPassword,
    [fieldValues],
  );

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue({ ...fieldValues, [e.target.id]: e.target.value });
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.titleUserName}>
          <Avatar shape={'circle'} src={''} size={44} className={styles.userAvatar}>
            {loggedUserName?.charAt(0).toLocaleUpperCase()}
          </Avatar>
          <Typography.Text>{loggedUserName}</Typography.Text>
        </div>
        <Form form={form} layout="vertical" onFinish={handleSubmitForm} onValuesChange={onFormValuesChange}>
          <div className={styles.formContainer}>
            <Col className={styles.formTitle}>
              <Typography.Text>Сменить пароль</Typography.Text>
            </Col>
            <Row gutter={24}>
              <Col className={styles.inputsWrapper}>
                <FloatLabelInput label="Текущий пароль" value={fieldValues.currentPassword}>
                  <Form.Item
                    name="currentPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста введите текущий пароль',
                      },
                    ]}
                  >
                    <Input.Password
                      className={styles.FormInput}
                      onChange={handleChangeInput}
                      value={fieldValues.currentPassword}
                    />
                  </Form.Item>
                </FloatLabelInput>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className={styles.inputsWrapper}>
                <FloatLabelInput
                  label="Новый пароль"
                  value={fieldValues.newPassword}
                  suffixOffset={'18px'}
                  suffixIcon={
                    <Tooltip title={passwordHintText} placement="right">
                      <QuestionMarkIcon />
                    </Tooltip>
                  }
                >
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста введите новый пароль',
                        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{10,}$/,
                      },
                    ]}
                  >
                    <Input.Password
                      onChange={handleChangeInput}
                      value={fieldValues.newPassword}
                      className={styles.newPasswordForm}
                    />
                  </Form.Item>
                </FloatLabelInput>
              </Col>
              <Col>
                <FloatLabelInput label="Подтвердить пароль" value={fieldValues.confirmPassword}>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста введите подтверждение пароля',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Пароли не совпадают!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      onChange={handleChangeInput}
                      value={fieldValues.confirmPassword}
                      className={styles.FormInput}
                    />
                  </Form.Item>
                </FloatLabelInput>
              </Col>
            </Row>
          </div>
          <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
            <Col>
              <Button className={styles.buttonCancel} onClick={() => setDefaultValues()}>
                Отмена
              </Button>
            </Col>
            <Col>
              <Button
                className={
                  isSubmitDisabled || !isPasswordsValidated ? styles.buttonSubmitDisabled : styles.buttonSubmit
                }
                htmlType="submit"
              >
                Сохранить изменения
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ChangePasswordPage;
