import { Col, Row, Typography } from 'antd';
import AccessesCardIcon from './CardsIcons/AccessesCardIcon.png';
import BranchCardIcon from './CardsIcons/BranchCardIcon.png';
import CompanyCard from './CompanyCard';
import DoctorsCardIcon from './CardsIcons/DoctorsCardIcon.png';
import PaymentIcon from './CardsIcons/PaymentIcon.svg';
import React from 'react';
import ServicesCardIcon from './CardsIcons/ServicesCardIcon.png';
import SpecializationsCardIcon from './CardsIcons/SpecializationsCardIcon.png';
import styles from './styles.module.scss';

function CompanyPage() {
  return (
    <>
      <div className={styles.pageContainer}>
        <Col className={styles.pageTitleWrapper}>
          <Typography.Text>Компания</Typography.Text>
        </Col>
        <Row gutter={[47, 38]} className={styles.cardsContainer}>
          <Col>
            <CompanyCard
              title={'Филиалы'}
              description={'Управляйте публичным профилем компании, описанием и \nконтактами'}
              imagePath={BranchCardIcon}
              linkPath={'/company/branches'}
            />
          </Col>
          <Col>
            <CompanyCard
              title={'Услуги'}
              description={'Управляйте услугами,\nих стоимостью \nи отображением\nв сервисах'}
              imagePath={ServicesCardIcon}
              linkPath={'/company/amenities'}
            />
          </Col>
          <Col>
            <CompanyCard
              title={'Специализации'}
              description={'Настройте корректное отображение \nспециализаций \nврачей'}
              imagePath={SpecializationsCardIcon}
              linkPath={'/company/specializations'}
            />
          </Col>
          <Col>
            <CompanyCard
              title={'Врачи и медперсонал'}
              description={'Оформите \nи обновляйте \nпубличные профили \nврачей и специалистов'}
              imagePath={DoctorsCardIcon}
              linkPath={'/company/doctors'}
            />
          </Col>
          <Col>
            <CompanyCard
              title={'Эквайринг'}
              description={'Подключите эквайринг Альфа Банка для приема платежей от пациентов'}
              imagePath={PaymentIcon}
              linkPath={'/company/payments'}
            />
          </Col>
          {/* <Col>
            <CompanyCard
              title={'Доступы'}
              description={'Управляйте доступом \nв личный кабинет \nдля сотрудников'}
              imagePath={AccessesCardIcon}
              linkPath={'/company/accesses'}
            />
          </Col> */}
          {/*<Col>
            <CompanyCard
              title={'Соглашения и оферты'}
              description={'Загрузите и вовремя обновляйте соглашения для клиентов'}
              imagePath={AgreementsCardIcon}
              linkPath={'/company/agreements'}
            />
  </Col>*/}
        </Row>
      </div>
    </>
  );
}

export default CompanyPage;
