import { useMemo, useState } from 'react';
import { oidcAuthService } from '../../../../app/services/auth/oidcAuthService';
import { useAppSelector } from '../../../../app/hooks';
import { DoctorSearchResultModel, DoctorSnilsCheckStatus } from './AddDoctorPage.types';
import { useNavigate } from 'react-router-dom';
import FallbackUserPhoto from '../../../../common/Icons/FallbackUserPhoto.svg';
import {PracticeDoctorDocument, PracticeDoctorEducation} from "../../../../common/types";

export const useAddDoctorPage = () => {
  const [doctorSnils, setDoctorSnils] = useState<string>('');
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';
  const selectedBranchId = useAppSelector((state) => state.app.selectedBranchId);
  const [doctorSnilsCheckStatus, setDoctorSnilsCheckStatus] = useState<DoctorSnilsCheckStatus>(
    DoctorSnilsCheckStatus.IsNotChecked,
  );
  const [medOrganizationDoctorId, setMedOrganizationDoctorId] = useState<string>('');
  const [doctorPhotoId, setDoctorPhotoId] = useState<string>('');
  const [doctorFirstName, setDoctorFirstName] = useState<string>('');
  const [doctorLastName, setDoctorLastName] = useState<string>('');
  const [doctorMiddleName, setDoctorMiddleName] = useState<string>('');
  const [doctorSpecializations, setDoctorSpecializations] = useState<string[]>([]);
  const [doctorDocuments, setDoctorDocuments] = useState<PracticeDoctorDocument[]>([]);
  const [doctorEducation, setDoctorEducation] = useState<PracticeDoctorEducation[]>([]);
  const navigate = useNavigate();
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const doctorFullName = useMemo(() => {
    return `${doctorLastName} ${doctorFirstName} ${doctorMiddleName}`;
  }, [doctorFirstName, doctorMiddleName, doctorLastName]);

  const handleCheckButtonOnClick = async () => {
    setDoctorFirstName('');
    setDoctorLastName('');
    setDoctorMiddleName('');
    setDoctorSpecializations([]);
    const bearer = await oidcAuthService.getToken();
    const response = await fetch(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/doctor/search?snils=${doctorSnils.split('-').join('')}`,
      {
        headers: {
          'x-mila-network-id': networkId,
          'x-mila-med-organization-id': medOrganizationId,
          Authorization: `Bearer ${bearer}`,
        },
      },
    );
    const json: DoctorSearchResultModel[] = await response.json();
    let isDoctorExistsInCurrentMedOrganization = false;
    json?.[0]?.medOrganizations?.forEach((organization) => {
      if (organization.medOrganizationId === selectedBranchId) {
        setMedOrganizationDoctorId(organization.medOrganizationDoctorId);
        setDoctorSpecializations(organization.doctorSpecializations);
        isDoctorExistsInCurrentMedOrganization = true;
      }
    });
    if (json.length === 0) {
      setDoctorSnilsCheckStatus(DoctorSnilsCheckStatus.CheckedAndNotExistsInDB);
      setDoctorDocuments([]);
      setDoctorEducation([]);
      setDoctorPhotoId("");
    } else {
      setDoctorPhotoId(json[0].doctorPhotoId);
      setDoctorFirstName(json[0].firstName);
      setDoctorLastName(json[0].lastName);
      setDoctorMiddleName(json[0].middleName);
      if (json[0].documents) {
        setDoctorDocuments(json[0].documents);
      } else {
        setDoctorDocuments([]);
      }
      if (json[0].education) {
        setDoctorEducation(json[0].education);
      } else {
        setDoctorEducation([]);
      }
      if (isDoctorExistsInCurrentMedOrganization) {
        setDoctorSnilsCheckStatus(DoctorSnilsCheckStatus.CheckedAndExistsInCurrentMedOrganization);
      } else {
        const allSpecializations: string[] = [];
        json?.[0]?.medOrganizations?.forEach((organization) => {
          allSpecializations.push(...organization.doctorSpecializations);
        });
        setDoctorSpecializations(Array.from(new Set(allSpecializations)));
        setDoctorSnilsCheckStatus(DoctorSnilsCheckStatus.CheckedAndNotExistsInCurrentMedOrganization);
      }
    }
  };

  const doctorImageSrc = useMemo(() => {
    return doctorPhotoId
      ? `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${doctorPhotoId}?inline=true`
      : FallbackUserPhoto;
  }, [doctorPhotoId]);

  const handleCancelButtonOnClick = () => {
    navigate(`/company/doctors/${practiceId ?? ''}`);
  };

  const isCheckButtonDisabled = useMemo(() => {
    let digitsCount = 0;
    for (let i = 0; i < doctorSnils.length; i++) {
      if ('0123456789'.includes(doctorSnils[i])) {
        digitsCount++;
      }
    }
    return digitsCount < 11;
  }, [doctorSnils]);

  const handleAddDoctorToClinicButtonOnClick = () => {
    setIsAdding(true);
  };

  const handleWatchDoctorProfileOnClick = () => {
    navigate(`/company/doctors/${selectedBranchId}/${medOrganizationDoctorId}/general`);
  };

  return {
    doctorSnilsCheckStatus,
    isCheckButtonDisabled,
    doctorSnils,
    setDoctorSnils,
    handleCancelButtonOnClick,
    handleCheckButtonOnClick,
    doctorFullName,
    doctorSpecializations,
    doctorImageSrc,
    handleAddDoctorToClinicButtonOnClick,
    handleWatchDoctorProfileOnClick,
    isAdding,
    doctorMiddleName,
    doctorLastName,
    doctorFirstName,
    doctorPhotoId,
    doctorDocuments,
    doctorEducation
  };
};
