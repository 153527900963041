import { Layout, Spin, Typography } from 'antd';
import { TMKChatWidget } from './TMKChatWidget/TMKChatWidget';
import { useGetTmkUserInfoQuery } from '../../app/services/api';
import styles from './styles.module.scss';

export const ChatPage = () => {
  const { data: tmkUserInfo } = useGetTmkUserInfoQuery();

  return (
    <Layout className={styles.ChatWrapper}>
      <Typography className={styles.Title}>Чаты с пациентами</Typography>
      {tmkUserInfo ? (
        <TMKChatWidget roomId={tmkUserInfo.firstRoomId} sessionId={tmkUserInfo.sessionId} userId={tmkUserInfo.userId} />
      ) : (
        <Spin size="large" className={styles.pageLoader} />
      )}
    </Layout>
  );
};
