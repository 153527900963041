import { PracticeSpecialityService } from 'src/common/types';
import React, { memo } from 'react';
import getWordDeclension from 'src/common/utils/getWordDeclension';
import styles from './CollapseHeader.module.scss';

type PropsType = {
  speciality: PracticeSpecialityService;
};

export const CollapseHeader: React.FC<PropsType> = memo(({ speciality }) => {
  const name = `${speciality.specialityName}, код ${speciality.specialityFerId}`;
  const count = speciality.specialityServicesCount;

  const wordDecl = getWordDeclension(count, ['услуга', 'услуги', 'услуг']);

  return (
    <div className={styles.container}>
      <span className={styles.name}>{name}</span>
      <span className={styles.count}>{`( ${count} ${wordDecl} )`}</span>
    </div>
  );
});
