import { Col, Layout, Typography } from 'antd';
import { ReactComponent as MilaWhiteIcon } from '../../common/Icons/MilaWhiteIcon.svg';
import { ReactComponent as N3HealthIcon } from '../../common/Icons/N3HealthIcon.svg';
import { Outlet } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

const TwoRowDescription = (props: { row1Text: string; row2Text: string }) => {
  const { row1Text, row2Text } = props;

  return (
    <div className={styles.rowsWrapper}>
      <Typography.Text>{row1Text}</Typography.Text>
      <Typography.Text>{row2Text}</Typography.Text>
    </div>
  );
};

function Footer() {
  return (
    <>
      <Layout className={styles.footerContainer}>
        <Typography.Link className={styles.n3Logo} href="https://n3health.ru/" target="_blank">
          <N3HealthIcon />
        </Typography.Link>
        <Col className={styles.milaLogo}>
          <MilaWhiteIcon />
          <div className={styles.rowsWrapper}>
            <Typography.Text>совместный проект</Typography.Text>
            <Typography.Text>
              <Typography.Link href="https://n3.group/" target="_blank">
                N3.Group
              </Typography.Link>{' '}
              и{' '}
              <Typography.Link href="https://tashir-medica.com/" target="_blank">
                Ташир.Медика
              </Typography.Link>
            </Typography.Text>
          </div>
        </Col>
        <Col className={styles.privatePolicyDesc}>
          <div className={styles.rowsWrapper}>
            <Typography.Link
              href="https://drive.google.com/file/d/1aehL_w5qgA_Rw797xa6-7y8td_UXa0yg/view"
              target="_blank"
            >
              Политика конфиденциальности
            </Typography.Link>
            <Typography.Link href="https://support.n3health.ru" target="_blank">
              Техподдержка
            </Typography.Link>
          </div>
        </Col>
        <Col className={styles.markDesc}>
          <TwoRowDescription
            row1Text={`© ${new Date().getFullYear()} ООО "ЭлНетМед"`}
            row2Text={'Официальный оператор передачи данных в ЕГИСЗ'}
          />
        </Col>
      </Layout>

      <Outlet />
    </>
  );
}

export default Footer;
