import { AccessDeniedStub } from 'src/common/components/AccessDeniedStub/AccessDeniedStub';
import { AddressInput, AddressType } from './AddressInput/AddressInput';
import {
  Branch,
  BranchDocuments,
  DictionarySubwayItem,
  DictionarySubwayLineItem,
  DictionarySubwayStationItem,
  OwnershipType,
} from 'src/common/types';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  notification,
} from 'antd';
import { ReactComponent as CrossSignIcon } from '../../../assets/CrossSignIcon.svg';
import { CustomInput } from 'src/common/components/CustomInput/CustomInput';
import { CustomSelector } from 'src/common/components/CustomSelector/CustomSelector';
import { ReactComponent as DownCaretIcon } from '../../../common/Icons/DownCaretIcon.svg';
import { PhoneInput } from '../../../common/components/PhoneInput/PhoneInput';
import { ReactComponent as PlusIcon } from '../../../assets/PlusIcon.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/QuestionMarkIcon.svg';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { ReactComponent as RemoveIcon } from '../../../common/Icons/RemoveIcon.svg';
import { YMaps } from '@pbe/react-yandex-maps';
import {
  getPageVisibility,
  getPreparedBranches,
  getPreparedSortedBranches,
  transformDictonaryToOptions,
  transformSubwayEntityToOptions,
} from 'src/common/utils/utils';
import { setBranch } from '../../../app/services/redux/appSlice';
import {
  useAddNotificationEmailsMutation,
  useGetBranchContactInfoQuery,
  useGetBranchDocumentsQuery,
  useGetBranchGeneralInfoQuery,
  useGetBranchNotificationEmailsQuery,
  useGetPracticeBranchesQuery,
  useLazyGetBranchDocumentQuery,
  useRemoveBranchDocumentMutation, useRemoveBranchExtraFacadePhotoMutation, useRemoveBranchExtraInteriorPhotoMutation,
  useRemoveBranchFacadePhotoMutation,
  useRemoveBranchInteriorPhotoMutation,
  useRemoveBranchLogoMutation,
  useUpdateBranchContactInfoMutation,
  useUpdateBranchGeneralInfoMutation,
  useUploadBranchDocumentsMutation, useUploadBranchExtraFacadePhotoMutation, useUploadBranchExtraInteriorPhotoMutation,
  useUploadBranchFacadePhotoMutation,
  useUploadBranchInteriorPhotoMutation,
  useUploadBranchLogoMutation,
} from '../../../app/services/api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useForm } from 'antd/lib/form/Form';
import { useGetTimezonesQuery } from 'src/app/services/apiDictionary';
import FloatLabelInput from '../../../common/FloatLabelInput/FloatLabelInput';
import NavSubMenu from '../NavSubMenu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useGetMetroDataQuery } from 'src/app/services/metroApi';
import {EditIcon} from "../../../common/Icons/icons";
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions*/

type FormValuesType = {
  shortName: string;
  description: string;
  timeZone: string;
  workingHours: string;
  ownershipType: number;
  providesAdults: boolean;
  providesPediatrics: boolean;
  websiteUrl: string;
  emails: string[];
  address: string;
  nearestSubway: string;
  nearestSubwayDistanceMeters: string;
  district: string;
  latitude: string;
  longitude: string;
  phoneNumbersForPatients: string[];
  addressForPatients: string;
  howToGetThere: string;
  parkingDescription: string;
  fullName: string;
  state: string;
  region: string;
  locality: string;
  street: string;
  building: string;
};

const ownershipTypeOptions = [
  { label: 'Частное юридическое лицо', value: OwnershipType.Corporation },
  { label: 'Индивидуальный предприниматель', value: OwnershipType.Individual },
  {
    label: 'Государственное или муниципальное юридическое лицо',
    value: OwnershipType.Government,
  },
];

const BranchesPage = () => {
  const [form] = useForm();
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [orgName, setOrgName] = useState('');
  const [facadeImgPath, setFacadeImgPath] = useState<null | string>('');
  const [logoImgPath, setLogoImgPath] = useState<null | string>('');
  const [interiorImgPath, setInteriorImgPath] = useState<null | string>('');
  const [extraFacadeImgPath, setExtraFacadeImgPath] = useState<null | string>('');
  const [extraInteriorImgPath, setExtraInteriorImgPath] = useState<null | string>('');

  const dispatch = useAppDispatch();
  const stateBranch = useAppSelector((state) => state.app.selectedBranchId);
  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const availableBranches = useAppSelector((state) => state.app.availableBranches);
  const isPageVisible = getPageVisibility(permissions, 'can-access-page-branches');

  const { data: branchesList } = useGetPracticeBranchesQuery('');

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableBranches ?? []) : []),
    [availableBranches, branchesList],
  );

  const [defaultBranchInfo, setDefaultBranchInfo] = useState<string>('');
  const [defaultBranchContactInfo, setDefaultBranchContactInfo] = useState<string>('');

  const branchInfoFields: string[] = useMemo(
    () => [
      'fullName',
      'shortName',
      'timeZone',
      'workingHours',
      'description',
      'ownershipType',
      'providesAdults',
      'providesPediatrics',
      'websiteUrl',
    ],
    [],
  );
  const branchContactInfoFields: string[] = useMemo(
    () => [
      'address',
      'addressForPatients',
      'building',
      'district',
      'howToGetThere',
      'latitude',
      'locality',
      'longitude',
      'nearestSubway',
      'nearestSubwayDistanceMeters',
      'parkingDescription',
      'phoneNumbersForPatients',
      'region',
      'state',
      'street',
    ],
    [],
  );

  const uploadDocFormats = [
    'image/jpeg',
    'image/png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'image/tiff',
    'application/msword',
    'image/bmp',
    'application/vnd.oasis.opendocument.text',
  ];

  const photoFormats = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/bmp',
    'image/svg',
    'image/avif',
    'image/tiff',
    'image/svg+xml',
  ];

  const {
    data: branchInfo,
    isLoading: isBranchInfoLoading,
    refetch: refetchBranchInfo,
  } = useGetBranchGeneralInfoQuery(orgName, {
    skip: !orgName,
  });
  const {
    data: branchContactInfo,
    isLoading: isBranchContactInfoLoading,
    refetch: refetchBranchContactInfo,
  } = useGetBranchContactInfoQuery(orgName, {
    skip: !orgName,
  });
  const {
    data: branchDocs,
    isLoading: isBranchDocsLoading,
    refetch: refetchBranchDocs,
  } = useGetBranchDocumentsQuery(orgName, {
    skip: !orgName,
  });
  const {
    data: emailsList,
    isLoading: isEmailsListLoading,
    refetch: refetchEmailsList,
  } = useGetBranchNotificationEmailsQuery(orgName, {
    skip: !orgName,
  });

  const { data: timezones } = useGetTimezonesQuery('');
  const { data: subways } = useGetMetroDataQuery('');
  
  const timezoneOptions = useMemo(() => (timezones ? transformDictonaryToOptions(timezones) : []), [timezones]);

  const subwaysOptions = useMemo(
    () =>
      subways
        ? subways.map((subway: DictionarySubwayItem) => ({
            ...transformSubwayEntityToOptions(subway),
            children: subway.lines?.map((line: DictionarySubwayLineItem) => ({
              ...transformSubwayEntityToOptions(line, line.hex_color, Number(subway.id)),
              children: line.stations?.map((station: DictionarySubwayStationItem) => ({
                ...transformSubwayEntityToOptions(station, line.hex_color, Number(line.id)),
              })),
            })),
          }))
        : [],
    [subways],
  );

  const [downLoadDocument] = useLazyGetBranchDocumentQuery();
  const [uploadBranchFacadePhoto] = useUploadBranchFacadePhotoMutation();
  const [uploadBranchInteriorPhoto] = useUploadBranchInteriorPhotoMutation();
  const [uploadBranchExtraFacadePhoto] = useUploadBranchExtraFacadePhotoMutation();
  const [uploadBranchExtraInteriorPhoto] = useUploadBranchExtraInteriorPhotoMutation();
  const [uploadBranchLogo] = useUploadBranchLogoMutation();
  const [uploadBranchDocuments] = useUploadBranchDocumentsMutation();
  const [addNotificationEmails] = useAddNotificationEmailsMutation();
  const [updateBranchGeneralInfo, generalInfoRequest] = useUpdateBranchGeneralInfoMutation();
  const [updateBranchContactInfo, contactInfoRequest] = useUpdateBranchContactInfoMutation();
  const [removeBranchDocument] = useRemoveBranchDocumentMutation();
  const [removeBranchFacadePhoto] = useRemoveBranchFacadePhotoMutation();
  const [removeBranchInteriorPhoto] = useRemoveBranchInteriorPhotoMutation();
  const [removeBranchExtraFacadePhoto] = useRemoveBranchExtraFacadePhotoMutation();
  const [removeBranchExtraInteriorPhoto] = useRemoveBranchExtraInteriorPhotoMutation();
  const [removeBranchLogo] = useRemoveBranchLogoMutation();

  useEffect(() => {
    if (stateBranch) {
      setOrgName(stateBranch);
      form.setFieldValue('orgName', stateBranch);
    } else if (!orgName) {
      form.setFieldValue('orgName', orderedBranches[0]?.branchId);
      setOrgName(orderedBranches[0]?.branchId);
      dispatch(setBranch(orderedBranches[0]?.branchId));
    }
  }, [dispatch, form, orderedBranches, orgName, stateBranch]);

  useEffect(() => {
    if (branchInfo) {
      const {
        fullName,
        shortName,
        timeZone,
        workingHours,
        description,
        facadePhoto,
        extraFacadePhoto,
        interiorPhoto,
        extraInteriorPhoto,
        ownershipType,
        providesAdults,
        providesPediatrics,
        websiteUrl,
        idLogo,
      } = branchInfo;
      form.setFieldsValue({
        fullName,
        shortName,
        timeZone,
        workingHours,
        description,
        ownershipType,
        providesAdults,
        providesPediatrics,
        websiteUrl,
      });
      setFacadeImgPath(facadePhoto);
      setInteriorImgPath(interiorPhoto);
      setExtraFacadeImgPath(extraFacadePhoto);
      setExtraInteriorImgPath(extraInteriorPhoto);
      setLogoImgPath(idLogo);
    }
  }, [branchInfo, form]);

  useEffect(() => {
    if (branchContactInfo) {
      const {
        address,
        state,
        region,
        locality,
        street,
        building,
        nearestSubway,
        district,
        phoneNumbersForPatients,
        addressForPatients,
        howToGetThere,
        nearestSubwayDistanceMeters,
        latitude,
        longitude,
        parkingDescription,
      } = branchContactInfo;
      form.setFieldsValue({
        address,
        state,
        region,
        locality,
        street,
        building,
        nearestSubway,
        district,
        phoneNumbersForPatients: phoneNumbersForPatients.length !== 0 ? phoneNumbersForPatients : [''],
        addressForPatients,
        howToGetThere,
        nearestSubwayDistanceMeters,
        latitude,
        longitude,
        parkingDescription,
      });
    }
  }, [branchContactInfo, form]);

  useEffect(() => {
    emailsList?.length !== 0 ? form.setFieldValue('emails', emailsList) : form.setFieldValue('emails', ['']);
  }, [emailsList, form]);

  useEffect(() => {
    if (branchInfo && !isBranchInfoLoading)
      setDefaultBranchInfo(
        JSON.stringify(form.getFieldsValue(branchInfoFields)) + branchInfo.facadePhoto + branchInfo.interiorPhoto + branchInfo.extraFacadePhoto + branchInfo.extraInteriorPhoto + branchInfo.idLogo,
      );
    if (branchContactInfo && !isBranchContactInfoLoading && emailsList && !isEmailsListLoading)
      setDefaultBranchContactInfo(JSON.stringify(form.getFieldsValue(branchContactInfoFields)));
  }, [
    branchInfo,
    isBranchInfoLoading,
    isBranchContactInfoLoading,
    isBranchDocsLoading,
    isEmailsListLoading,
    form,
    branchContactInfo,
    emailsList,
    branchContactInfoFields,
    branchInfoFields
  ]);

  const onFormValuesChange = useCallback(() => {
    if (defaultBranchInfo && defaultBranchContactInfo) {
      defaultBranchInfo !==
        JSON.stringify(form.getFieldsValue(branchInfoFields)) + facadeImgPath + interiorImgPath + extraFacadeImgPath + extraInteriorImgPath + logoImgPath ||
      defaultBranchContactInfo !== JSON.stringify(form.getFieldsValue(branchContactInfoFields)) ||
      form.getFieldValue('emails').join(', ') !== emailsList?.join(', ')
        ? setSubmitBtnDisabled(false)
        : setSubmitBtnDisabled(true);
    }
  }, [
    defaultBranchInfo,
    defaultBranchContactInfo,
    form,
    branchInfoFields,
    facadeImgPath,
    interiorImgPath,
    extraFacadeImgPath,
    extraInteriorImgPath,  
    logoImgPath,
    branchContactInfoFields,
    emailsList,
  ]);

  useEffect(() => {
    onFormValuesChange();
  }, [
      facadeImgPath,
      interiorImgPath,
      extraFacadeImgPath,
      extraInteriorImgPath,
      logoImgPath
  ]);

  const normFile = (e: { fileList: string }) => (Array.isArray(e) ? e : e.fileList);

  const prepareBranches = (branchesData: Branch[] | undefined) =>
    branchesData ? getPreparedBranches(branchesList || [], availableBranches ?? []) : [];

  const workingHoursTooltipText = (
    <>
      <p>Пример заполнения:</p>
      <p>Клиника: Пн-Пт: 10:00-20:00</p> <p>Сб-Вс: 10:00-18:00</p>
      <p>Лаборатория: Пн-Сб: 7:30-14:30</p>
      <p>Вызов на дом: Круглосуточно</p>
    </>
  );

  const patientAddressTooltipText = (
    <>
      <p>Пример заполнения:</p>
      <p>Санкт-Петербург, пер. Фуражный, д. 3, лит. К (в здании БЦ "Рождественский")</p>
    </>
  );

  const howToGetThereTooltipText = (
    <>
      <p>Пример заполнения:</p>
      <p>
        После выхода из станции метро "Площадь Восстания" поверните направо и двигайтесь вдоль Лиговского проспекта до
        пересечения с ул. Некрасова, далее направо до Греческого проспекта. По Греческому проспекту налево 80 метров до
        9-ой Советской улицы. На пути в сторону Суворовского проспекта по 9-ой Советской улице через 200 метров свернуть
        налево в Фуражный переулок. Наша клиника находится в БЦ "Рождественский" в конце переулка. Весь путь от метро
        займет не более 15 минут.
      </p>
    </>
  );

  const parkingDescriptionTooltipText = (
    <>
      <p>Пример заполнения:</p>
      <p>
        Для въезда на парковку БЦ "Рождественский" необходимо сообщить администратору марку и номер автомобиля не
        позднее 2х часов до прибытия в клинику. Городская платная парковка расположена вдоль Фуражного переулка,
        стоимость Пн - Сб с 8:00 до 21:00 - 100 руб/ 1 час, Вс - бесплатно. По праздникам возможно изменение тарифов.
      </p>
    </>
  );

  const handleSubmitForm = ({
    shortName,
    description,
    timeZone,
    workingHours,
    ownershipType,
    providesAdults,
    providesPediatrics,
    emails,
    address,
    nearestSubway,
    nearestSubwayDistanceMeters,
    district,
    latitude,
    longitude,
    phoneNumbersForPatients,
    addressForPatients,
    howToGetThere,
    parkingDescription,
    fullName,
    state,
    region,
    locality,
    street,
    building,
    websiteUrl,
  }: FormValuesType) => {
    defaultBranchInfo !==
      JSON.stringify(form.getFieldsValue(branchInfoFields)) + facadeImgPath + interiorImgPath + extraFacadeImgPath + extraInteriorImgPath + logoImgPath &&
      branchInfo?.branchId &&
      updateBranchGeneralInfo({
        branchId: branchInfo.branchId,
        fullName,
        shortName,
        description,
        timeZone,
        workingHours,
        ownershipType,
        providesAdults,
        providesPediatrics,
        websiteUrl,
        facadePhoto: facadeImgPath,
        interiorPhoto: interiorImgPath,
        extraFacadePhoto: extraFacadeImgPath,
        extraInteriorPhoto: extraInteriorImgPath,
        idLogo: logoImgPath,
      })
        .unwrap()
        .then(() => {
          refetchBranchInfo();
          notification.success({ message: 'Общие данные филиала успешно обновлены' });
          setDefaultBranchInfo(
            JSON.stringify(form.getFieldsValue(branchInfoFields)) + facadeImgPath + interiorImgPath + extraFacadeImgPath + extraInteriorImgPath + logoImgPath,
          );
        })
        .catch((error) => notification.error({ message: error.data.detail || error.data.title }));

    defaultBranchContactInfo !== JSON.stringify(form.getFieldsValue(branchContactInfoFields)) &&
      branchInfo?.branchId &&
      updateBranchContactInfo({
        branchId: branchInfo.branchId,
        address,
        nearestSubway: nearestSubway ? nearestSubway : "",
        nearestSubwayDistanceMeters,
        district,
        latitude,
        longitude,
        phoneNumbersForPatients,
        addressForPatients,
        howToGetThere,
        parkingDescription,
        state,
        region,
        locality,
        street,
        building,
      })
        .unwrap()
        .then(() => {
          refetchBranchContactInfo();
          notification.success({ message: 'Контактные данные филиала успешно обновлены' });
          setDefaultBranchContactInfo(JSON.stringify(form.getFieldsValue(branchContactInfoFields)));
        })
        .catch((error) => notification.error({ message: error.data.detail || error.data.title }));

    form.getFieldValue('emails').join(', ') !== emailsList?.join(', ') &&
      branchInfo?.branchId &&
      addNotificationEmails({ practiceId: branchInfo.branchId, emails })
        .unwrap()
        .then(() => {
          refetchEmailsList();
          notification.success({ message: 'Контакты филиала для рассылок уведомлений успешно обновлены' });
        })
        .catch((error) => notification.error({ message: error.data.detail || error.data.title }));
    onFormValuesChange();
  };

  const handleFacadePhotoChange = (options: any) => {
    uploadBranchFacadePhoto({ practiceId: branchInfo?.branchId, file: options.file })
      .unwrap()
      .then((id) => setFacadeImgPath(id));
  };

  const handleExtraFacadePhotoChange = (options: any) => {
    uploadBranchExtraFacadePhoto({ practiceId: branchInfo?.branchId, file: options.file })
      .unwrap()
      .then((id) => setExtraFacadeImgPath(id));
  };

  const handleLogoChange = (options: any) => {
    console.log(options);
    uploadBranchLogo({ practiceId: branchInfo?.branchId, file: options.file })
      .unwrap()
      .then((id) => setLogoImgPath(id));
  };

  const handleInteriorPhotoChange = (options: any) => {
    uploadBranchInteriorPhoto({ practiceId: branchInfo?.branchId, file: options.file })
      .unwrap()
      .then((id) => setInteriorImgPath(id));
  };

  const handleExtraInteriorPhotoChange = (options: any) => {
    uploadBranchExtraInteriorPhoto({ practiceId: branchInfo?.branchId, file: options.file })
      .unwrap()
      .then((id) => setExtraInteriorImgPath(id));
  };

  const handleDocumentSelection = (options: any) => {
    uploadBranchDocuments({ practiceId: branchInfo?.branchId, file: options.file })
      .unwrap()
      .then(() => refetchBranchDocs());
  };

  const handleRemoveFacadePhoto = () => {
    if (branchInfo?.branchId) {
      setFacadeImgPath(null);
      removeBranchFacadePhoto(branchInfo.branchId);
    }
  };

  const handleRemoveExtraFacadePhoto = () => {
    if (branchInfo?.branchId) {
      setExtraFacadeImgPath(null);
      removeBranchExtraFacadePhoto(branchInfo.branchId);
    }
  };

  const handleRemoveInteriorPhoto = () => {
    if (branchInfo?.branchId) {
      setInteriorImgPath(null);
      removeBranchInteriorPhoto(branchInfo.branchId);
    }
  };

  const handleRemoveExtraInteriorPhoto = () => {
    if (branchInfo?.branchId) {
      setExtraInteriorImgPath(null);
      removeBranchExtraInteriorPhoto(branchInfo.branchId);
    }
  };

  const handleRemoveLogo = () => {
    if (branchInfo?.branchId) {
      setLogoImgPath(null);
      removeBranchLogo({practiceId: branchInfo.branchId});
    }
  };

  const handleRemoveDocument = useCallback(
    (docId: string) => {
      if (branchInfo?.branchId) {
        removeBranchDocument({ practiceId: branchInfo.branchId, documentId: docId })
          .unwrap()
          .then(() => refetchBranchDocs());
      }
    },
    [branchInfo?.branchId, refetchBranchDocs, removeBranchDocument],
  );

  const handleDocumentDownload = useCallback(
    (uid: string) => {
      if (branchInfo?.branchId) {
        downLoadDocument({ documentId: uid, practiceId: branchInfo.branchId });
      }
    },
    [branchInfo?.branchId, downLoadDocument],
  );

  const handleAddressChange = ({
    address,
    latitude,
    longitude,
    region,
    state,
    locality,
    street,
    building,
  }: AddressType) => {
    form.setFieldsValue({
      address,
      region,
      state,
      locality,
      street,
      building,
      latitude,
      longitude,
    });
    onFormValuesChange();
  };

  const handleEmailsChange = (value: string, fieldKey: number) => {
    const updatedEmails = [...form.getFieldValue('emails')];

    updatedEmails.splice(fieldKey, 1, value);
    form.setFieldValue('emails', updatedEmails);
  };

  const handleEmailRemove = (fieldKey: number) => () => {
    const updatedEmails = [...form.getFieldValue('emails')];

    updatedEmails.splice(fieldKey, 1);
    form.setFieldValue('emails', updatedEmails);
    onFormValuesChange();
  };

  const checkFile = (file: RcFile, formats: string[], isDocument = false) => {
    if (file.size / 1024 / 1024 > 1) {
      notification.error({message: 'Размер файла не должен превышать 1МБ!'});
      return false;
    }
    if (!formats.includes(file.type)) {
      notification.error({ message: 'Формат файла не поддерживается!' });
      return false;
    }
    
    return true;
  };

  const fileList = useMemo(
    () =>
      branchDocs
        ? branchDocs.map((doc: BranchDocuments) => ({
            uid: doc.practiceDocumentId,
            name: (
              <div className={styles.docNameWrapper}>
                <Popconfirm
                  title={`Вы уверены, что хотите удалить документ?`}
                  onConfirm={() => handleRemoveDocument(doc.practiceDocumentId)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <RemoveIcon className={styles.removeDocumentIcon} />
                </Popconfirm>
                <span onClick={() => handleDocumentDownload(doc.practiceDocumentId)}>{doc.name}</span>
                <span>({doc.size})</span>
              </div>
            ),
            status: 'done',
          }))
        : [],
    [branchDocs, handleDocumentDownload, handleRemoveDocument],
  );
  
  const handleChangeLogo = () => {
    document.getElementById('logoUpload')?.click();
  };
  
  const handleChangeFacadeImg = () => {
    document.getElementById('facadeImgUpload')?.click();
  };  
  
  const handleChangeExtraFacadeImg = () => {
    document.getElementById('extraFacadeImgUpload')?.click();
  };

  const handleChangeInteriorImg = () => {
    document.getElementById('interiorImgUpload')?.click();
  };

  const handleChangeExtraInteriorImg = () => {
    document.getElementById('extraInteriorImgUpload')?.click();
  };
  
  return (
    <>
      <NavSubMenu />
      <div className={styles.pageContainer}>
        {isPageVisible ? (
          <Form
            form={form}
            layout="vertical"
            className={styles.branchesInfoForm}
            onFinish={handleSubmitForm}
            onValuesChange={onFormValuesChange}
            initialValues={{
              orgName: stateBranch ? stateBranch : orderedBranches[0]?.branchId,
            }}
          >
            <Col span={8}>
              <Form.Item name="orgName" className={styles.inputOrgName}>
                <Select
                  options={prepareBranches(branchesList)}
                  onChange={(value) => dispatch(setBranch(value))}
                  suffixIcon={<DownCaretIcon />}
                />
              </Form.Item>
            </Col>
            <Col className={styles.blockContainer}>
              <Col className={styles.blockTitle}>
                <Typography.Title level={3}>Общие данные о филиале</Typography.Title>
              </Col>
              <Row className={styles.inputsRowWrapper}>
                <Col className={styles.inputsWrapper}>
                  <FloatLabelInput
                    label="Полное наименование клиники"
                    value={form.getFieldValue('fullName')}
                    isDisabled
                  >
                    <Form.Item name="fullName" className={styles.fullName}>
                      <Input.TextArea disabled />
                    </Form.Item>
                  </FloatLabelInput>
                  <Form.Item
                    name="shortName"
                    rules={[{ required: true, message: 'Пожалуйста введите сокращенное название организации' }]}
                  >
                    <CustomInput floatLabel="Сокращённое наименование для пациентов*" />
                  </Form.Item>
                  <Form.Item
                    name="ownershipType"
                    rules={[{ required: true, message: 'Пожалуйста выберите форму собственности' }]}
                  >
                    <CustomSelector floatLabel="Форма собственности*" options={ownershipTypeOptions} />
                  </Form.Item>
                  <Row className={styles.inputAge}>
                    <Typography.Text>Прием пациентов</Typography.Text>
                    <Form.Item name="providesAdults" valuePropName="checked">
                      <Checkbox>Взрослые</Checkbox>
                    </Form.Item>
                    <Form.Item name="providesPediatrics" valuePropName="checked">
                      <Checkbox>Дети</Checkbox>
                    </Form.Item>
                  </Row>
                  <Form.Item name="description" className={styles.description}>
                    <CustomInput isTextArea floatLabel="Краткое описание" showCount maxLength={1000} />
                  </Form.Item>
                </Col>
                <Col className={styles.inputsWrapper}>
                  <Form.Item name="timeZone" rules={[{ required: true, message: 'Пожалуйста выберите часовой пояс' }]}>
                    <CustomSelector floatLabel="Часовой пояс*" options={timezoneOptions} />
                  </Form.Item>
                  <Form.Item
                    name="workingHours"
                    className={styles.workingHours}
                    rules={[{ required: true, message: 'Пожалуйста заполните график работы' }]}
                  >
                    <CustomInput
                      isTextArea
                      floatLabel="График работы*"
                      showCount
                      maxLength={400}
                      suffixIcon={
                        <Tooltip title={workingHoursTooltipText} placement="right">
                          <QuestionMarkIcon />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="websiteUrl"
                    rules={[
                      {
                        type: 'url',
                        message: 'Пожалуйста введите валидный url',
                      },
                    ]}
                  >
                    <CustomInput floatLabel="Адрес сайта клиники" />
                  </Form.Item>
                  <div className={styles.photosFormTitle}>
                    <Col className={styles.uploadTitle}>
                      <Typography.Text>Загрузите фото фасада и интерьера</Typography.Text>
                      <Tooltip title={'Размер загружаемого файла не более 1 Мб'} placement="right">
                        <QuestionMarkIcon />
                      </Tooltip>
                    </Col>
                    <Col className={styles.uploadForm}>
                      <div className={styles.photoFormContainer}>
                        <Form.Item getValueFromEvent={normFile} valuePropName="fileList">
                          <Upload.Dragger
                              className={styles.fileUploader}
                              accept={'.jpg, .jpeg, .svg, .png, .bmp, .tiff, .avif, .webp'}
                              maxCount={1}
                              customRequest={(options) => handleFacadePhotoChange(options)}
                              beforeUpload={(file) => checkFile(file, photoFormats)}
                              showUploadList={{showRemoveIcon: false, removeIcon: <RemoveIcon/>}}
                              openFileDialogOnClick={false}
                              id="facadeImgUpload"
                          >
                            {facadeImgPath ? (
                                <img
                                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${facadeImgPath}?inline=true`}
                                    alt=""
                                    height={96}
                                    width={96}
                                />
                            ) : (
                                <p>Загрузите фото</p>
                            )}
                          </Upload.Dragger>
                        </Form.Item>
                        <div className={styles.imgSmallPhotoIcons}>

                          <EditIcon
                              style={{width: 25, marginRight: 0}}
                              onClick={() => {
                                handleChangeFacadeImg();
                              }}
                          />
                        </div>
                        {facadeImgPath && (
                            <Popconfirm
                                title={`Вы уверены, что хотите удалить фото фасада?`}
                                onConfirm={handleRemoveFacadePhoto}
                                okText="Да"
                                cancelText="Нет"
                            >
                              <RemoveIcon className={styles.removePhotoIcon}/>
                            </Popconfirm>
                        )}
                      </div>
                      <div className={styles.photoFormContainer}>
                        <Form.Item getValueFromEvent={normFile}>
                          <Upload.Dragger
                              accept={'.jpg, .jpeg, .svg, .png, .bmp, .tiff, .avif, .webp'}
                              className={styles.fileUploader}
                              maxCount={1}
                              beforeUpload={(file) => checkFile(file, photoFormats)}
                              customRequest={(options) => handleInteriorPhotoChange(options)}
                              openFileDialogOnClick={false}
                              id="interiorImgUpload"
                          >
                            {interiorImgPath ? (
                                <img
                                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${interiorImgPath}?inline=true`}
                                    height={96}
                                    width={96}
                                    alt=""
                                />
                            ) : (
                                <p>Загрузите фото</p>
                            )}
                          </Upload.Dragger>
                        </Form.Item>
                        <div className={styles.imgSmallPhotoIcons}>

                          <EditIcon
                              style={{width: 25, marginRight: 0}}
                              onClick={() => {
                                handleChangeInteriorImg();
                              }}
                          />
                        </div>
                        {interiorImgPath && (
                            <Popconfirm
                                title={`Вы уверены, что хотите удалить фото интерьера?`}
                                onConfirm={handleRemoveInteriorPhoto}
                                okText="Да"
                                cancelText="Нет"
                            >
                              <RemoveIcon className={styles.removePhotoIcon}/>
                            </Popconfirm>
                        )}
                      </div>
                      <div className={styles.photoFormContainer}>
                        <Form.Item getValueFromEvent={normFile} valuePropName="fileList">
                          <Upload.Dragger
                              className={styles.fileUploader}
                              accept={'.jpg, .jpeg, .svg, .png, .bmp, .tiff, .avif, .webp'}
                              maxCount={1}
                              customRequest={(options) => handleExtraFacadePhotoChange(options)}
                              beforeUpload={(file) => checkFile(file, photoFormats)}
                              showUploadList={{showRemoveIcon: false, removeIcon: <RemoveIcon/>}}
                              openFileDialogOnClick={false}
                              id="extraFacadeImgUpload"
                          >
                            {extraFacadeImgPath ? (
                                <img
                                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${extraFacadeImgPath}?inline=true`}
                                    alt=""
                                    height={93}
                                    width={93}
                                />
                            ) : (
                                <p>Загрузите фото</p>
                            )}
                          </Upload.Dragger>
                        </Form.Item>
                        <div className={styles.imgSmallPhotoIcons}>

                          <EditIcon
                              style={{width: 25, marginRight: 0}}
                              onClick={() => {
                                handleChangeExtraFacadeImg();
                              }}
                          />
                        </div>
                        {extraFacadeImgPath && (
                            <Popconfirm
                                title={`Вы уверены, что хотите удалить фото фасада?`}
                                onConfirm={handleRemoveExtraFacadePhoto}
                                okText="Да"
                                cancelText="Нет"
                            >
                              <RemoveIcon className={styles.removePhotoIcon}/>
                            </Popconfirm>
                        )}
                      </div>
                      <div className={styles.photoFormContainer}>
                        <Form.Item getValueFromEvent={normFile}>
                          <Upload.Dragger
                              accept={'.jpg, .jpeg, .svg, .png, .bmp, .tiff, .avif, .webp'}
                              className={styles.fileUploader}
                              maxCount={1}
                              beforeUpload={(file) => checkFile(file, photoFormats)}
                              customRequest={(options) => handleExtraInteriorPhotoChange(options)}
                              openFileDialogOnClick={false}
                              id="extraInteriorImgUpload"
                          >
                            {extraInteriorImgPath ? (
                                <img
                                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${extraInteriorImgPath}?inline=true`}
                                    height={93}
                                    width={93}
                                    alt=""
                                />
                            ) : (
                                <p>Загрузите фото</p>
                            )}
                          </Upload.Dragger>
                        </Form.Item>
                        <div className={styles.imgSmallPhotoIcons}>

                          <EditIcon
                              style={{width: 25, marginRight: 0}}
                              onClick={() => {
                                handleChangeExtraInteriorImg();
                              }}
                          />
                        </div>
                        {extraInteriorImgPath && (
                            <Popconfirm
                                title={`Вы уверены, что хотите удалить фото интерьера?`}
                                onConfirm={handleRemoveExtraInteriorPhoto}
                                okText="Да"
                                cancelText="Нет"
                            >
                              <RemoveIcon className={styles.removePhotoIcon}/>
                            </Popconfirm>
                        )}
                      </div>
                    </Col>
                  </div>
                </Col>
                <Col>
                  <Form.Item getValueFromEvent={normFile} valuePropName="fileList">
                    <Upload.Dragger
                        className={styles.fileLogoUploader}
                        accept={'.jpg, .jpeg, .svg, .png, .bmp, .tiff, .avif, .webp'}
                        maxCount={1}
                        customRequest={options => handleLogoChange(options)}
                        beforeUpload={(file) => checkFile(file, photoFormats)}
                        showUploadList={{showRemoveIcon: false, removeIcon: <RemoveIcon/>}}
                        openFileDialogOnClick={false}
                        id="logoUpload"
                    >
                      {logoImgPath ? (
                          <img
                              src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${logoImgPath}?inline=true`}
                              alt=""
                              height={136}
                              width={145}
                          />
                      ) : (
                          <p>Загрузите логотип</p>
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                  <div className={styles.imgPhotoIcons}>

                    <EditIcon
                        onClick={() => {
                          handleChangeLogo();
                        }}
                    />
                  </div>
                  {logoImgPath && (
                      <Popconfirm
                          title={`Вы уверены, что хотите удалить логотип?`}
                          onConfirm={handleRemoveLogo}
                          okText="Да"
                          cancelText="Нет"
                      >
                        <Typography.Text className={styles.RemoveLogo}>Удалить логотип</Typography.Text>
                      </Popconfirm>
                  )}
                </Col>
              </Row>
            </Col>
            <Col className={styles.blockContainer}>
              <Col className={styles.blockTitle}>
                <Typography.Title level={3}>Контактная информация</Typography.Title>
              </Col>
              <Row className={`${styles.inputsRowWrapper} ${styles.inputsRowWrapperCol}`}>
              <Col>
                  <FloatLabelInput label="Адрес клиники*" value={form.getFieldValue('address')}>
                    <YMaps
                      query={{
                        apikey: window.__RUNTIME_CONFIG__.REACT_APP_YANDEX_API_KEY,
                        suggest_apikey: window.__RUNTIME_CONFIG__.REACT_APP_YANDEX_SUGGEST_API_KEY,
                      }}
                    >
                      <Form.Item
                        name="address"
                        rules={[{ required: true, message: 'Пожалуйста заполните адрес клиники' }]}
                      >
                        <AddressInput val={form.getFieldValue('address')} handleChange={handleAddressChange} />
                      </Form.Item>
                    </YMaps>
                  </FloatLabelInput>
                </Col>
                <Col>
                  <Form.Item name="region">
                    <CustomInput floatLabel="Регион" />
                  </Form.Item>
                </Col>
                <Row className={styles.singleInputRowWrapper}>
                  <Col>
                    <Form.Item name="state">
                      <CustomInput floatLabel="Страна" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="locality">
                      <CustomInput floatLabel="Населенный пункт" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={styles.singleInputRowWrapper}>
                  <Col>
                    <Form.Item name="street">
                      <CustomInput floatLabel="Улица" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="building">
                      <CustomInput floatLabel="Номер дома" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={styles.singleInputRowWrapper}>
                  <Col>
                    <Form.Item name="nearestSubway">
                      <CustomSelector
                        isTreeSelect
                        floatLabel="Ближайшее метро"
                        options={subwaysOptions}
                        className={styles.subwaySelectInput}
                      />
                    </Form.Item>
                  </Col>
                  <Row className={styles.singleInputRowWrapper}>
                    <Col>
                      <Form.Item name="nearestSubwayDistanceMeters">
                        <CustomInput floatLabel="Дальность, метров" />
                      </Form.Item>
                    </Col>
                    <Col />
                  </Row>
                </Row>
                <Row className={styles.singleInputRowWrapper}>
                  <Col>
                    <Form.Item name="district">
                      <CustomInput floatLabel="Район города" />
                    </Form.Item>
                  </Col>
                  <Row className={styles.singleInputRowWrapper}>
                    <Form.Item name="latitude" rules={[{ required: true, message: 'Пожалуйста заполните широту' }]}>
                      <CustomInput floatLabel="Широта*" />
                    </Form.Item>
                    <Form.Item name="longitude" rules={[{ required: true, message: 'Пожалуйста заполните долготу' }]}>
                      <CustomInput floatLabel="Долгота*" />
                    </Form.Item>
                  </Row>
                </Row>
                <Row className={styles.singleInputRowWrapper}>
                  <Form.List name="phoneNumbersForPatients">
                    {(fields, { add, remove }) => (
                      <>
                        <Col className={styles.PhonesCol}>
                          {fields.map((field, index) => (
                            <Col key={field.key}>
                              <Form.Item
                                {...field}
                                name={field.name}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Пожалуйста введите номер телефона',
                                    pattern: /(\d|\+\d) \(\d{3}\) \d{3}-\d{4}/,
                                  },
                                ]}
                              >
                                <PhoneInput name={field.name.toString()} floatLabel="Номер телефона для пациентов*" />
                              </Form.Item>
                              {fields.length > 1 && index !== 0 ? (
                                <CrossSignIcon
                                  className={`dynamic-delete-button ${styles.deleteButton}`}
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Col>
                          ))}
                        </Col>
                        <Col>
                          <Form.Item>
                            <div className={styles.addNewBtn} onClick={() => add()}>
                              <PlusIcon />
                              <Typography.Text>Добавить дополнительный номер телефона</Typography.Text>
                            </div>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Row>
                <Row className={styles.singleInputRowWrapper}>
                  <Col>
                    <Form.Item name="addressForPatients" className={styles.addressForPatients}>
                      <CustomInput
                        floatLabel="Адрес для пациентов"
                        suffixOffset={'12px'}
                        suffixIcon={
                          <Tooltip title={patientAddressTooltipText} placement="right">
                            <QuestionMarkIcon />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col />
                </Row>
                <Row className={styles.singleInputRowWrapper}>
                  <Col>
                    <Form.Item name="howToGetThere" className={styles.howToGetThere}>
                      <CustomInput
                        isTextArea
                        floatLabel="Как добраться"
                        suffixOffset={'12px'}
                        suffixIcon={
                          <Tooltip title={howToGetThereTooltipText} placement="right">
                            <QuestionMarkIcon />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="parkingDescription" className={styles.parking}>
                      <CustomInput
                        isTextArea
                        floatLabel="Парковка"
                        suffixOffset={'12px'}
                        suffixIcon={
                          <Tooltip title={parkingDescriptionTooltipText} placement="right">
                            <QuestionMarkIcon />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col className={styles.blockContainer}>
              <Col className={styles.blockTitle}>
                <Typography.Title level={3}> Лицензия и документы</Typography.Title>
              </Col>
              <Row gutter={24}>
                <Form.Item name="uploadDoc" getValueFromEvent={normFile}>
                  <Upload
                    name="logo"
                    listType="text"
                    customRequest={options => handleDocumentSelection(options)}
                    fileList={fileList as unknown as UploadFile[]}
                    showUploadList={{ showRemoveIcon: false }}
                    className={styles.uploadDocumentForm}
                    beforeUpload={(file) => checkFile(file, uploadDocFormats, true)}
                  >
                    <Row className={styles.singleInputRowWrapper}>
                      <Button className={styles.downloadBtnWrapper}>Загрузить документ</Button>
                    </Row>
                  </Upload>
                </Form.Item>
              </Row>
            </Col>
            <Col className={styles.blockContainer}>
              <Col className={styles.blockTitle}>
                <Typography.Title level={3}>Контакты для уведомлений</Typography.Title>
              </Col>
              <Col className={styles.mailsListSubTitle}>
                <Typography.Text>
                  Укажите email адреса сотрудников филиала для получения информации о записях пациентов и других
                  уведомлений из Mila
                </Typography.Text>
              </Col>
              <Col className={styles.inputsMailWrapper}>
                <Form.List name="emails">
                  {(fields, { add }) => (
                    <>
                      <Col>
                        {fields.map((field, index) => (
                          <Col key={field.key}>
                            <FloatLabelInput label="Email" value={form.getFieldValue('emails')[index]}>
                              <Form.Item
                                {...field}
                                name={field.name}
                                validateTrigger={['onChange', 'onBlur']}
                                noStyle
                                rules={[
                                  {
                                    message: 'Пожалуйста введите корректную электронную почту',
                                    pattern:
                                      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                                  },
                                ]}
                              >
                                <Input onChange={(e) => handleEmailsChange(e.target.value, field.key)} />
                              </Form.Item>
                            </FloatLabelInput>
                            {fields.length > 1 && index !== 0 ? (
                              <CrossSignIcon
                                className={`dynamic-delete-button ${styles.deleteButton}`}
                                onClick={handleEmailRemove(index)}
                              />
                            ) : null}
                          </Col>
                        ))}
                      </Col>
                      <Col className={styles.newEmailButtonWrapper}>
                        <Form.Item className={styles.addNewBtn}>
                          <div onClick={() => add()}>
                            <PlusIcon />
                            <Typography.Text>Добавить Email</Typography.Text>
                          </div>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>
              </Col>
            </Col>
            <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
              <Col>
                <Button className={styles.buttonCancel} htmlType="reset">
                  Отмена
                </Button>
              </Col>
              <Col>
                <Button
                  className={isSubmitBtnDisabled ? styles.buttonSubmitDisabled : styles.buttonSubmit}
                  htmlType="submit"
                  disabled={isSubmitBtnDisabled}
                  loading={generalInfoRequest.isLoading || contactInfoRequest.isLoading}
                >
                  Сохранить изменения
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <AccessDeniedStub
            title="Ошибка 403"
            text="Доступ запрещен

          У вас недостаточно прав доступа. Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
          />
        )}
      </div>
    </>
  );
};

export default BranchesPage;
