import { Tabs } from 'antd';
import { getPreparedSortedBranches } from '../../../common/utils/utils';
import { setBranch } from '../../../app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useGetPracticeBranchesQuery } from '../../../app/services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';

function DoctorTabs(props: { doctorId: string | undefined }) {
  const { doctorId } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTabKey = useMemo(() => {
    if (!doctorId) return 'general';

    return pathname.split('/').reverse()[0];
  }, [pathname, doctorId]);
  const availableDoctorsBranches = useAppSelector((state) => state.app.availableBranches);
  const dispatch = useAppDispatch();

  const { data: branchesList } = useGetPracticeBranchesQuery('');

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableDoctorsBranches ?? []) : []),
    [availableDoctorsBranches, branchesList],
  );

  useEffect(() => {
    if (orderedBranches.length) {
      const branch =
        orderedBranches?.find(({ branchId }) => branchId === pathname.split('/').reverse()[2])?.branchId || null;

      /* !orgName && setOrgName(orderedBranches[0]?.branchId);*/
      if (branch) {
        dispatch(setBranch(branch));
      } else {
        if (doctorId) {
          navigate('/company/doctors');
        }
      }
    }
  }, [dispatch, navigate, orderedBranches, pathname]);

  const tabsItems = [
    {
      label: 'Общие данные',
      key: 'general',
      disabled: !doctorId,
      //  children: <>Общие данные</>,
    },
    {
      label: 'Услуги врача',
      key: 'amenitiesDoctor',
      disabled: !doctorId,
      //  children: <p>Услуги врача</p>,
    },
    {
      label: 'Доступ в кабинет врача',
      key: 'access',
      disabled: !doctorId,
      //  children: <p>Доступ</p>,
    },
    {
      label: 'Записи',
      key: 'records',
      disabled: !doctorId,
      // children: <p>Записи</p>,
    }
  ];

  const handleTabClick = (tabName: string) => {
    navigate(`/company/doctors/${pathname.split('/').reverse()[2]}/${doctorId}/${tabName}`);
  };

  return (
    <>
      <div className={styles.TabContainer}>
        <Tabs
          size={'large'}
          items={tabsItems}
          className={styles.customTabsWrapper}
          type="card"
          onTabClick={(tab) => handleTabClick(tab)}
          activeKey={activeTabKey}
        />
      </div>
    </>
  );
}

export default DoctorTabs;
