import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';

export const useSpecialities = () => {
  const filterOptions: FilterFunc<DefaultOptionType> = (inputValue, option) => {
    if (!option || !option.label || !option.value) return false;

    return option.label?.toString().toUpperCase().includes(inputValue.toUpperCase());
  };

  const sortOptions = (optionA: DefaultOptionType, optionB: DefaultOptionType) => {
    if (!optionA.label || !optionB.label) return 0;

    return optionA.label.toString().toUpperCase().localeCompare(optionB.label.toString().toUpperCase());
  };

  return {
    filterOptions,
    sortOptions,
  };
};
