import { NewPracticeService } from 'src/common/types';
import { notification } from 'antd';

export const useAmenityValidation = () => {
  const validateFields = (data: NewPracticeService) => {
    if (!data.specialityFerId) {
      notification.error({ message: 'Не выбрана специализация' });
      return false;
    }

    if (!data.nameForPatient) {
      notification.error({ message: 'Не введено название услуги' });

      return false;
    }

    if (!data.cost) {
      notification.error({ message: 'Не введена цена услуги' });

      return false;
    }

    if (isNaN(data.cost)) {
      notification.error({ message: 'Цена услуги должна быть числом' });

      return false;
    }

    if (data.cost < 0) {
      notification.error({ message: 'Цена услуги не может быть отрицательной' });

      return false;
    }

    if (!data.compositeProfileId) {
      notification.error({ message: 'Не выбран составной профиль помощи' });

      return false;
    }

    if (!data.serviceKindId) {
      notification.error({ message: 'Не выбрано наименование услуги в соответствии с Номенклатурой МЗ РФ' });

      return false;
    }

    return true;
  };

  return { validateFields };
};
