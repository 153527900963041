import { useGetPracticeBranchesQuery } from '../../../../../../app/services/api';
import { useAppSelector } from '../../../../../../app/hooks';
import { useMemo } from 'react';

export const useHeader = () => {
  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const selectedBranchId = useAppSelector((state) => state.app.selectedBranchId);

  const selectedBranchName = useMemo(() => {
    return branchesList?.find((branch) => branch.branchId === selectedBranchId)?.shortName ?? '';
  }, [branchesList, selectedBranchId]);

  return { selectedBranchName };
};
