import {
  AccessToDoctorPortalArgs,
  AmoCrmAuthUrl,
  Appointment,
  AppointmentsStatisticData,
  Branch,
  BranchContactInfo,
  BranchDocuments,
  BranchGeneralInfo,
  CallMeBackArgs,
  CallMeBackRequest,
  CallMeBackStatisticData,
  CreateDoctorEducationArgs,
  CreateMedOrganizationSpecialtyServiceArgs,
  DashboardStatisticsArgs,
  DataResponse,
  DateAndSortArgs,
  DeleteMedOrganizationSpecialtyServiceArgs,
  DoctorArguments,
  DoctorEducationArguments,
  DoctorEducationType,
  DoctorGeneralInfoArgs,
  DoctorReviewsStatisticData,
  DocumentArguments,
  EmailsArguments,
  NewPromotionArgs,
  Notification,
  Patient,
  PatientsStatisticData,
  PracticeDoctorContactInfo,
  PracticeDoctorDocument,
  PracticeDoctorEducation,
  PracticeDoctorGeneralInfo,
  PracticeDoctorList,
  PracticeDoctorSpecializationWithServices,
  PracticeDoctorVisibility,
  PracticeDoctorVisibilityArgs,
  PracticePositionDeleteArgs,
  PracticePositionPostArgs,
  PracticePositionPutArgs,
  PracticeSpecialityPosition,
  PracticeSpecialityService,
  PracticeSpecialty,
  PracticeUserAccess,
  PracticeUserArgs,
  PracticeWidgetGeneralSettings,
  PracticeWidgetInfoBanner,
  PromotionArgs,
  PromotionDoctors,
  PromotionDoctorsLstArgs,
  PromotionSpecializationData,
  PromotionsList,
  PromotionsRequestArgs,
  RecordsArguments,
  RecordsResponse,
  ResendVerificationEmailArgs,
  ServiceInfo,
  ServiceRequestArgs,
  TeleHealthStatisticData,
  TmkRequest,
  TmkUserInfo,
  UpdateDoctorEducationArgs,
  UpdateMedOrganizationSpecialtyServiceArgs,
  UpdatePracticeDoctorSpecialtyServiceArgs,
  UpdatePracticeUserArgs,
  UpdatePracticeWidgetBannerArgs,
  UpdatePracticeWidgetGeneralSettingsArgs,
  UpdatePromotionArgs,
  UpdateSpecialityMarketingNameArgs,
  UserAccessArguments,
  UserAccessResponse,
  WaitingListRequest,
  WaitingListStatisticData,
  WhiteLabelData,
  WhiteLabelUpdateData,
  WidgetAgreementMetadata,
  WidgetArguments, ChangeAccessToDoctorPortalArgs, WidgetDoctors, PracticeWidgetColorSettingsModel, BranchPaymentInfo,
} from 'src/common/types';
import { AppState } from './redux/appSlice';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { oidcAuthService } from './auth/oidcAuthService';

interface MainState {
  app: AppState;
}

export const apiSlice = createApi({
  reducerPath: 'milaPracticeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/practice`,

    prepareHeaders: async (headers, { getState }) => {
      const networkId = ((await getState()) as MainState).app.networkId;
      if (networkId) {
        headers.set('x-mila-network-id', networkId);
      }

      const medOrganizationId = ((await getState()) as MainState).app.medOrganizationId || '';
      if (medOrganizationId) {
        headers.set('x-mila-med-organization-id', medOrganizationId);
      }

      headers.set('Authorization', `Bearer ${await oidcAuthService.getToken()}`);

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getPracticeBranches: builder.query<Branch[], string>({
      query: () => ({
        url: `/branches`,
      }),
    }),

    // Queries

    getPracticeAppointments: builder.query<RecordsResponse<Appointment>, RecordsArguments>({
      query: ({ sortType, offset, count, branchId }) => ({
        url: `records/appointments`,
        params: { Sort: sortType, Offset: offset, Count: count, BranchId: branchId },
      }),
    }),

    getCallbackRequests: builder.query<RecordsResponse<CallMeBackRequest>, RecordsArguments>({
      query: ({ sortType, offset, count }) => ({
        url: `/records/callmeback`,
        params: { Sort: sortType, Offset: offset, Count: count },
      }),
    }),

    getWaitingListRequests: builder.query<RecordsResponse<WaitingListRequest>, RecordsArguments>({
      query: ({ sortType, offset, count, branchId }) => ({
        url: `/records/waiting-list`,
        params: { Sort: sortType, Offset: offset, Count: count, BranchId: branchId },
      }),
    }),

    getTmkRequests: builder.query<RecordsResponse<TmkRequest>, RecordsArguments>({
      query: ({ sortType, offset, count, branchId }) => ({
        url: `/records/tmk`,
        params: { Sort: sortType, Offset: offset, Count: count, BranchId: branchId },
      }),
    }),

    getPatients: builder.query<RecordsResponse<Patient>, RecordsArguments>({
      query: ({ sortType, offset, count, branchId }) => ({
        url: `/patients`,
        params: { Sort: sortType, Offset: offset, Count: count, BranchId: branchId },
      }),
    }),

    getPracticeServices: builder.query<PracticeSpecialityService[], string>({
      query: (medOrganizationId) => ({
        url: `${medOrganizationId}/specialities/services`,
      }),
    }),

    getBranchGeneralInfo: builder.query<BranchGeneralInfo, string>({
      query: (practiceId) => ({
        url: `${practiceId}/generalinfo`,
      }),
    }),

    getBranchContactInfo: builder.query<BranchContactInfo, string>({
      query: (practiceId) => ({
        url: `${practiceId}/contactinfo`,
      }),
    }),

    getBranchDocuments: builder.query<BranchDocuments[], string>({
      query: (practiceId) => ({
        url: `${practiceId}/documents`,
      }),
    }),

    getBranchNotificationEmails: builder.query<string[], string>({
      query: (practiceId) => ({
        url: `${practiceId}/notificationemails`,
      }),
    }),

    getBranchSpecialities: builder.query<PracticeSpecialty[], string>({
      query: (practiceId) => ({
        url: `${practiceId}/specialities`,
      }),
    }),

    getBranchUsers: builder.query<UserAccessResponse, UserAccessArguments>({
      query: ({ branchId, sortType, offset, count }) => ({
        url: `/users`,
        params: {
          branchId: branchId,
          Sort: sortType,
          Offset: offset,
          Count: count,
        },
      }),
    }),

    getBranchUserById: builder.query<PracticeUserAccess, string>({
      query: (userId) => ({
        url: `/users/${userId}`,
      }),
    }),

    getBranchDoctors: builder.query<PracticeDoctorList[], string>({
      query: () => ({
        url: `/branches/doctors`,
      }),
    }),

    getPracticeDoctors: builder.query<PracticeDoctorList[], string>({
      query: (practiceId) => ({
        url: `/branches/${practiceId}/doctors`,
      }),
    }),

    getDoctorGeneralInfo: builder.query<PracticeDoctorGeneralInfo, DoctorArguments>({
      query: ({ practiceDoctorId, practiceId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/generalinfo`,
      }),
    }),

    getDoctorEducationInfo: builder.query<PracticeDoctorEducation[], DoctorArguments>({
      query: ({ practiceDoctorId, practiceId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/educations`,
      }),
    }),

    getDoctorContactInfo: builder.query<PracticeDoctorContactInfo, DoctorArguments>({
      query: ({ practiceDoctorId, practiceId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/contactinfo`,
      }),
    }),

    getDoctorServicesVisibility: builder.query<PracticeDoctorVisibility, DoctorArguments>({
      query: ({ practiceDoctorId, practiceId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/visibility`,
      }),
    }),

    getDoctorDocuments: builder.query<PracticeDoctorDocument[], DoctorArguments>({
      query: ({ practiceDoctorId, practiceId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/documents`,
      }),
    }),

    getDoctorEducationTypes: builder.query<DoctorEducationType[], string>({
      query: () => ({
        url: `/doctors/educations/types`,
      }),
    }),

    getBranchDocument: builder.query<BranchDocuments, DocumentArguments>({
      query: ({ documentId, practiceId }) => ({
        url: `${practiceId}/documents/${documentId}`,
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),

    getDoctorDocument: builder.query<BranchDocuments, DocumentArguments & DoctorArguments>({
      query: ({ documentId, practiceId, practiceDoctorId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/documents/${documentId}`,
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),

    getDoctorProfileServices: builder.query<PracticeDoctorSpecializationWithServices[], DoctorArguments>({
      query: ({ practiceDoctorId, practiceId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/specializations/services`,
      }),
    }),

    getDoctorProfileRecords: builder.query<RecordsResponse<Appointment>, RecordsArguments & DoctorArguments>({
      query: ({ practiceId, practiceDoctorId, sortType, offset, count }) => ({
        url: `${practiceId}/doctor/${practiceDoctorId}/appointments`,
        params: {
          Sort: sortType,
          Offset: offset,
          Count: count,
        },
      }),
    }),

    getWidgetGeneralSettings: builder.query<PracticeWidgetGeneralSettings, string>({
      query: () => ({
        url: 'widget/generalsettings',
      }),
    }),

    getWidgetBannerSettings: builder.query<PracticeWidgetInfoBanner, string>({
      query: () => ({
        url: 'widget/banner',
      }),
    }),

    getWidgetColor: builder.query<PracticeWidgetColorSettingsModel, string>({
      query: () => ({
        url: '/widget/color',
      }),
    }),

    getWidgetCode: builder.query<string, {openDoctorsList?: boolean, doctorId?: string, widgetHeight?: string, widgetWidth?: string}>({
      query: (arg) => ({
        url: '/widget/htmlcode',
        params: {
          openDoctorsList: arg.openDoctorsList,
          doctorId: arg.doctorId,
          widgetHeight: arg.widgetHeight,
          widgetWidth: arg.widgetWidth
        }
      }),
    }),

    getWidgetPreviewUrl: builder.query<string, string>({
      query: () => ({
        url: '/widget/previewurl',
      }),
    }),

    getTermsOfServiceData: builder.query<WidgetAgreementMetadata, string>({
      query: () => ({
        url: '/widget/termsofservicemetadata',
      }),
    }),

    getUserAgreementData: builder.query<WidgetAgreementMetadata, string>({
      query: () => ({
        url: '/widget/personaldataprocessingagreementmetadata',
      }),
    }),

    getPromotionsList: builder.query<PromotionsList, PromotionsRequestArgs>({
      query: ({ sortType, offset, count, branchId }) => ({
        url: '/marketing/promotions',
        params: { Sort: sortType, Offset: offset, Count: count, BranchId: branchId },
      }),
    }),

    getPromotionById: builder.query<PromotionArgs, string>({
      query: (promotionId) => ({
        url: `/marketing/promotions/${promotionId}`,
      }),
    }),

    getPromotionsDoctors: builder.query<PromotionDoctors[], PromotionDoctorsLstArgs>({
      query: ({ specializationId, branches }) => {
        const params = new URLSearchParams({ specializationId });
        for (const branch of branches) {
          params.append('branches', branch);
        }
        return {
          url: `/marketing/promotions/doctors`,
          params: params,
        };
      },
    }),

    getWidgetDoctors: builder.query<WidgetDoctors[], {branches: string[]}>({
      query: ({branches}) => {
        const params = new URLSearchParams({});
        for (const branch of branches) {
          params.append('branches', branch);
        }
        return {
          url: `/widget/doctors`,
          params: params
        };
      },
    }),

    getPromotionSpecializations: builder.query<PromotionSpecializationData[], string>({
      query: () => ({
        url: `/marketing/promotions/specializations`,
      }),
    }),

    getPatientsStatistic: builder.query<PatientsStatisticData, DashboardStatisticsArgs>({
      query: ({ asOfDate, branchId }) => ({
        url: '/dashboard/patients',
        params: { asOfDate, branchId },
      }),
    }),

    getAppointmentsStatistic: builder.query<AppointmentsStatisticData, DashboardStatisticsArgs>({
      query: ({ asOfDate, branchId }) => ({
        url: '/dashboard/appointments',
        params: { asOfDate, branchId },
      }),
    }),

    getTeleHealthStatistic: builder.query<TeleHealthStatisticData, DashboardStatisticsArgs>({
      query: ({ asOfDate, branchId }) => ({
        url: '/dashboard/telehealthAppointments',
        params: { asOfDate, branchId },
      }),
    }),

    getWaitingListStatistic: builder.query<WaitingListStatisticData, DashboardStatisticsArgs>({
      query: ({ asOfDate, branchId }) => ({
        url: '/dashboard/inWaitingList',
        params: { asOfDate, branchId },
      }),
    }),

    getCallMeBackStatistic: builder.query<CallMeBackStatisticData, string>({
      query: (asOfDate) => ({
        url: '/dashboard/callmebacks',
        params: { asOfDate },
      }),
    }),

    getDoctorReviewsStatistic: builder.query<DoctorReviewsStatisticData, DashboardStatisticsArgs>({
      query: ({ asOfDate, branchId }) => ({
        url: '/dashboard/doctorreviews',
        params: { asOfDate, branchId },
      }),
    }),

    getServiceInfo: builder.query<ServiceInfo, string>({
      query: (serviceName) => ({
        url: `/services/about/${serviceName}`,
      }),
    }),

    getNotifications: builder.query<DataResponse<Notification>, DateAndSortArgs>({
      query: ({ sortType, offset, count, branchId, asOfDate }) => ({
        url: `notifications`,
        params: { Sort: sortType, Offset: offset, Count: count, BranchId: branchId, AsOfDate: asOfDate },
      }),
    }),

    getTmkUserInfo: builder.query<TmkUserInfo, void>({
      query: () => '/chats/session',
    }),

    getAmoCrmLoginUrl: builder.query<AmoCrmAuthUrl, void>({
      query: () => '/amo-login',
    }),

    // Mutations

    addTermsOfServiceData: builder.mutation<string, any>({
      query: (data) => {
        const { file } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'widget/termsofservice',
          method: 'POST',
          body: formData,
        };
      },
    }),

    addUserAgreementData: builder.mutation<string, any>({
      query: (data) => {
        const { file } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'widget/personaldataprocessingagreement',
          method: 'POST',
          body: formData,
        };
      },
    }),

    updateWidgetGeneralSettings: builder.mutation<string, UpdatePracticeWidgetGeneralSettingsArgs>({
      query: (data) => ({
        url: 'widget/generalsettings',
        method: 'PUT',
        body: data,
      }),
    }),

    updateWidgetBannerSettings: builder.mutation<string, UpdatePracticeWidgetBannerArgs>({
      query: (data) => ({
        url: 'widget/banner',
        method: 'PUT',
        body: data,
      }),
    }),

    uploadWidgetBannerBackground: builder.mutation<string, any>({
      query: (data) => {
        const { file } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'widget/banner/background',
          method: 'POST',
          body: formData,
        };
      },
    }),

    deleteWidgetBannerBackground: builder.mutation<string, string>({
      query: (backgroundId: string) => ({
        url: `widget/banner/background?backgroundId=${backgroundId}`,
        method: 'DELETE',
      }),
    }),

    uploadPromotionProfileBackground: builder.mutation<string, any>({
      query: (data) => {
        const { file, promotionId } = data;
        const formData = new FormData();
        formData.append('backgroundImage', file);
        return {
          url: `marketing/promotions/${promotionId}/backgroundimage`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    updatePromotionProfileData: builder.mutation<string, UpdatePromotionArgs>({
      query: ({ promotionId, ...body }) => ({
        url: `marketing/promotions/${promotionId}`,
        method: 'PUT',
        body: body,
      }),
    }),

    deletePromotionProfileData: builder.mutation<string, string>({
      query: (promotionId) => ({
        url: `marketing/promotions/${promotionId}`,
        method: 'DELETE'
      }),
    }),

    createNewPromotion: builder.mutation<string, NewPromotionArgs>({
      query: (body) => ({
        url: `marketing/promotions`,
        method: 'POST',
        body: body,
      }),
    }),

    uploadWidgetLogo: builder.mutation<string, any>({
      query: (data) => {
        const { file, networkCode, businessCode } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'widget/logo',
          method: 'POST',
          body: formData,
          params: {
            NetworkCode: networkCode,
            BusinessCode: businessCode,
          },
        };
      },
    }),

    uploadBranchDocuments: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/documents`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadDoctorDocuments: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId, doctorId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/doctors/${doctorId}/documents`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadBranchFacadePhoto: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/uploadfacadephoto`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadBranchExtraFacadePhoto: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/uploadextrafacadephoto`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadBranchInteriorPhoto: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/uploadinteriorphoto`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadBranchExtraInteriorPhoto: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/uploadextrainteriorphoto`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadBranchLogo: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/logo`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    uploadDoctorPhoto: builder.mutation<string, any>({
      query: (data) => {
        const { file, practiceId, idMilaDoctor } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${practiceId}/doctors/${idMilaDoctor}/photo`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    removeBranchFacadePhoto: builder.mutation<string, string>({
      query: (practiceId) => ({
        url: `${practiceId}/deletefacadephoto`,
        method: 'DELETE',
      }),
    }),

    removeBranchExtraFacadePhoto: builder.mutation<string, string>({
      query: (practiceId) => ({
        url: `${practiceId}/deleteextrafacadephoto`,
        method: 'DELETE',
      }),
    }),

    removeBranchInteriorPhoto: builder.mutation<string, string>({
      query: (branchId) => ({
        url: `${branchId}/deleteinteriorphoto`,
        method: 'DELETE',
      }),
    }),

    removeBranchExtraInteriorPhoto: builder.mutation<string, string>({
      query: (branchId) => ({
        url: `${branchId}/deleteextrainteriorphoto`,
        method: 'DELETE',
      }),
    }),

    removeBranchLogo: builder.mutation<string, any>({
      query: ({ practiceId }) => ({
        url: `${practiceId}/logo`,
        method: 'DELETE',
      }),
    }),

    removeDoctorPhoto: builder.mutation<string, DoctorArguments>({
      query: ({ practiceId, practiceDoctorId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/photo`,
        method: 'DELETE',
      }),
    }),

    removeBranchDocument: builder.mutation<string, DocumentArguments>({
      query: ({ practiceId, documentId }) => ({
        url: `${practiceId}/documents/${documentId}`,
        method: 'DELETE',
      }),
    }),

    removeDoctorDocument: builder.mutation<string, DoctorArguments & DocumentArguments>({
      query: ({ practiceId, documentId, practiceDoctorId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/documents/${documentId}`,
        method: 'DELETE',
      }),
    }),

    removeWidgetLogo: builder.mutation<string, WidgetArguments>({
      query: ({ networkCode, businessCode }) => ({
        url: 'widget/logo',
        params: {
          NetworkCode: networkCode,
          BusinessCode: businessCode,
        },
        method: 'DELETE',
      }),
    }),

    removeWidgetAgreementDoc: builder.mutation<string, string>({
      query: () => ({
        url: 'widget/personaldataprocessingagreement',
        method: 'DELETE',
      }),
    }),

    removeWidgetTermsDoc: builder.mutation<string, string>({
      query: () => ({
        url: 'widget/termsofservice',
        method: 'DELETE',
      }),
    }),
    addNotificationEmails: builder.mutation<string, EmailsArguments>({
      query: ({ emails, practiceId }) => ({
        url: `${practiceId}/notificationemails`,
        body: emails,
        method: 'POST',
      }),
    }),

    addNewUserAccess: builder.mutation<string, PracticeUserArgs>({
      query: (data) => ({
        url: `/users`,
        body: data,
        method: 'POST',
      }),
    }),

    updateUserAccess: builder.mutation<string, UpdatePracticeUserArgs>({
      query: ({ userId, ...body }) => ({
        url: `/users/${userId}`,
        body: body,
        method: 'PUT',
      }),
    }),

    updateBranchPaymentInfo: builder.mutation<string, BranchPaymentInfo>({
      query: ({ branchId, ...body }) => ({
        url: `payments/${branchId}`,
        body: body,
        method: 'POST',
      }),
    }),

    getBranchPaymentInfo: builder.query<BranchPaymentInfo, string>({
      query: (practiceId) => ({
        url: `payments/${practiceId}`,
      }),
    }),

    updateBranchGeneralInfo: builder.mutation<string, BranchGeneralInfo>({
      query: ({ branchId, ...body }) => ({
        url: `${branchId}/generalinfo`,
        body: body,
        method: 'POST',
      }),
    }),

    updateBranchContactInfo: builder.mutation<string, BranchContactInfo>({
      query: ({ branchId, ...body }) => ({
        url: `${branchId}/contactinfo`,
        body: body,
        method: 'POST',
      }),
    }),

    updateDoctorGeneralInfo: builder.mutation<string, DoctorGeneralInfoArgs & DoctorArguments>({
      query: ({ practiceDoctorId, practiceId, ...body }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/generalinfo`,
        body: body,
        method: 'POST',
      }),
    }),

    addDoctorEducationInfo: builder.mutation<string, CreateDoctorEducationArgs & DoctorArguments>({
      query: ({ practiceDoctorId, practiceId, ...body }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/educations`,
        method: 'POST',
        body: body,
      }),
    }),

    updateDoctorEducationInfo: builder.mutation<string, UpdateDoctorEducationArgs & DoctorEducationArguments>({
      query: ({ practiceDoctorId, practiceId, practiceDoctorEducationId, ...body }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/educations/${practiceDoctorEducationId}`,
        method: 'PUT',
        body: body,
      }),
    }),

    deleteDoctorEducationInfo: builder.mutation<string, DoctorEducationArguments>({
      query: ({ practiceDoctorId, practiceId, practiceDoctorEducationId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/educations/${practiceDoctorEducationId}`,
        method: 'DELETE',
      }),
    }),

    updateDoctorContactInfo: builder.mutation<string, PracticeDoctorContactInfo & DoctorArguments>({
      query: ({ practiceDoctorId, practiceId, ...body }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/contactinfo`,
        method: 'POST',
        body: body,
      }),
    }),

    updateDoctorServicesVisibility: builder.mutation<string, PracticeDoctorVisibilityArgs & DoctorArguments>({
      query: ({ practiceDoctorId, practiceId, ...body }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/visibility`,
        params: { idPractice: practiceId, practiceDoctorId: practiceDoctorId },
        method: 'POST',
        body: body,
      }),
    }),

    updateSpecialityMarketingName: builder.mutation<string, UpdateSpecialityMarketingNameArgs>({
      query: ({ practiceId, specialityFederalId, newName }) => ({
        url: `${practiceId}/specialities/${specialityFederalId}/marketingname`,
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(newName),
        method: 'PUT',
      }),
    }),

    createServicesSpecialityData: builder.mutation<string, CreateMedOrganizationSpecialtyServiceArgs>({
      query: ({ medOrganizationId, ...body }) => ({
        url: `${medOrganizationId}/specialities/services`,
        body: body,
        method: 'POST',
      }),
    }),

    updateServicesSpecialityData: builder.mutation<string, UpdateMedOrganizationSpecialtyServiceArgs>({
      query: ({ serviceId, specialityId, medOrganizationId, ...body }) => ({
        url: `${medOrganizationId}/specialities/${specialityId}/services/${serviceId}`,
        body: body,
        method: 'PUT',
      }),
    }),

    deleteServicesSpecialityData: builder.mutation<string, DeleteMedOrganizationSpecialtyServiceArgs>({
      query: ({ serviceId, specialityId, medOrganizationId }) => ({
        url: `${medOrganizationId}/specialities/${specialityId}/services/${serviceId}`,
        method: 'DELETE',
      }),
    }),

    updateDoctorProfileServicesData: builder.mutation<string, UpdatePracticeDoctorSpecialtyServiceArgs>({
      query: ({ practiceServiceId, practiceSpecializationId, medOrganizationId, practiceDoctorId, ...body }) => ({
        url: `${medOrganizationId}/doctors/${practiceDoctorId}/specializations/${practiceSpecializationId}/services/${practiceServiceId}`,
        body: body,
        method: 'PUT',
      }),
    }),

    updateCallMeBackRequest: builder.mutation<string, CallMeBackArgs>({
      query: ({ comment, id, status }) => {
        return {
          url: `records/callmeback/${id}`,
          method: 'PUT',
          body: { comment, status },
        };
      },
    }),
    updateWaitingListRequest: builder.mutation<string, CallMeBackArgs>({
      query: ({ comment, id, status }) => {
        return {
          url: `records/waiting-list/${id}`,
          method: 'PUT',
          body: { comment, status },
        };
      },
    }),

    requestServiceDemonstration: builder.mutation<string, ServiceRequestArgs>({
      query: ({ serviceKey, idMedOrganization }) => ({
        url: `services/${serviceKey}/requestDemonstration`,
        method: 'POST',
        params: { idMedOrganization },
      }),
    }),

    requestServiceActivation: builder.mutation<string, ServiceRequestArgs>({
      query: ({ serviceKey, idMedOrganization }) => ({
        url: `services/${serviceKey}/requestActivation`,
        method: 'POST',
        params: { idMedOrganization },
      }),
    }),

    markNotificationViewed: builder.mutation<string, string>({
      query: (id) => ({
        url: `notifications/${id}/viewed`,
        method: 'PUT'
      }),
    }),

    AccessToDoctorPortal: builder.mutation<string, AccessToDoctorPortalArgs>({
      query: ({ practiceId, practiceDoctorId, setAccess }) => {
        const setAccessString = setAccess ? 'grant-access-to-doctor-portal' : 'deny-access-to-doctor-portal';
        return {
          url: `${practiceId}/doctors/${practiceDoctorId}/${setAccessString}`,
          method: 'PUT',
        };
      },
    }),

    changeAccessToDoctorPortal: builder.mutation<string, ChangeAccessToDoctorPortalArgs>({
      query: ({ practiceId, practiceDoctorId, request }) => {
        return {
          url: `${practiceId}/doctors/${practiceDoctorId}/change-access`,
          method: 'PUT',
          body: request
        };
      },
    }),

    resendVerificationEmail: builder.mutation<string, ResendVerificationEmailArgs>({
      query: ({ practiceId, practiceDoctorId }) => ({
        url: `${practiceId}/doctors/${practiceDoctorId}/resend-verification-email`,
        method: 'PUT',
      }),
    }),

    getSpecialitiesPositions: builder.query<PracticeSpecialityPosition[], string>({
      query: (branchId) => ({
        url: `${branchId}/specialities/positions`,
      }),
    }),

    postSpecialityPosition: builder.mutation<string, PracticePositionPostArgs>({
      query: ({ branchId, ...body }) => ({
        url: `${branchId}/specialities/positions`,
        body: body,
        method: 'POST',
      }),
    }),

    putSpecialityPosition: builder.mutation<string, PracticePositionPutArgs>({
      query: ({ branchId, specialityId, specialityPositionId, ...body }) => ({
        url: `${branchId}/specialities/${specialityId}/positions/${specialityPositionId}`,
        body: body,
        method: 'PUT',
      }),
    }),

    deleteSpecialityPosition: builder.mutation<string, PracticePositionDeleteArgs>({
      query: ({ branchId, specialityId, specialityPositionId }) => ({
        url: `${branchId}/specialities/${specialityId}/positions/${specialityPositionId}`,
        method: 'DELETE',
      }),
    }),

    getSessionToScheduleByBranchId: builder.query<string, string>({
      query: (branchId) => ({
        url: `schedule/${branchId}/session`,
      }),
    }),

    getDoctorsAcademicDegrees: builder.query<Array<any>, string>({
      query: () => ({
        url: `doctors/academicdegrees`,
      }),
    }),

    getWhiteLabelData: builder.query<WhiteLabelData, string>({
      query: () => ({
        url: 'white-label',
      }),
    }),

    getWhiteLabelFileInfo: builder.query<any, string>({
      query: (fileId) => ({
        url: `white-label/file/${fileId}/info`,
      }),
    }),

    uploadWhiteLabelLogo: builder.mutation<string, any>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'white-label/logo',
          method: 'PATCH',
          body: formData,
        };
      },
    }),

    uploadWhiteLabelAgreement: builder.mutation<string, any>({
      query: ({file, agreementType}) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `white-label/agreement?agreementType=${agreementType}`,
          method: 'PATCH',
          body: formData,
        };
      },
    }),

    uploadWhiteLabelIcon: builder.mutation<string, any>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'white-label/icon',
          method: 'PATCH',
          body: formData,
        };
      },
    }),

    uploadWhiteLabelFavIcon: builder.mutation<string, any>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'white-label/favicon',
          method: 'PATCH',
          body: formData,
        };
      },
    }),

    uploadWhiteLabelPwaIcon: builder.mutation<string, any>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: 'white-label/pwaicon',
          method: 'PATCH',
          body: formData,
        };
      },
    }),

    updateWhiteLabelData: builder.mutation<WhiteLabelUpdateData, any>({
      query: (data) => {
        return {
          url: 'white-label/general',
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLazyGetAmoCrmLoginUrlQuery,
  useGetPromotionSpecializationsQuery,
  useGetPromotionsDoctorsQuery,
  useGetWidgetDoctorsQuery,
  useGetPromotionByIdQuery,
  useGetPromotionsListQuery,
  useGetDoctorProfileServicesQuery,
  useGetWidgetGeneralSettingsQuery,
  useGetBranchUserByIdQuery,
  useGetDoctorEducationTypesQuery,
  useGetPracticeAppointmentsQuery,
  useGetPracticeServicesQuery,
  useGetPracticeBranchesQuery,
  useGetBranchGeneralInfoQuery,
  useGetBranchContactInfoQuery,
  useGetBranchDocumentsQuery,
  useGetBranchNotificationEmailsQuery,
  useGetBranchSpecialitiesQuery,
  useGetBranchUsersQuery,
  useGetDoctorGeneralInfoQuery,
  useGetBranchDocumentQuery,
  useGetTermsOfServiceDataQuery,
  useGetUserAgreementDataQuery,
  useLazyGetBranchDocumentQuery,
  useLazyGetDoctorDocumentQuery,
  useGetDoctorContactInfoQuery,
  useGetDoctorEducationInfoQuery,
  useGetBranchDoctorsQuery,
  useGetDoctorServicesVisibilityQuery,
  useGetDoctorDocumentsQuery,
  useGetDoctorProfileRecordsQuery,
  useGetWidgetBannerSettingsQuery,
  useGetWidgetColorQuery,
  useGetPracticeDoctorsQuery,
  useGetWidgetCodeQuery,
  useLazyGetWidgetCodeQuery,
  useGetWidgetPreviewUrlQuery,
  useGetCallbackRequestsQuery,
  useGetWaitingListRequestsQuery,
  useGetTmkRequestsQuery,
  useGetPatientsQuery,
  useGetPatientsStatisticQuery,
  useGetAppointmentsStatisticQuery,
  useGetTeleHealthStatisticQuery,
  useGetWaitingListStatisticQuery,
  useGetCallMeBackStatisticQuery,
  useGetDoctorReviewsStatisticQuery,
  useGetServiceInfoQuery,
  useGetNotificationsQuery,
  useGetTmkUserInfoQuery,
  useUploadDoctorDocumentsMutation,
  useRemoveDoctorDocumentMutation,
  useUpdateDoctorGeneralInfoMutation,
  useAddNotificationEmailsMutation,
  useUploadBranchDocumentsMutation,
  useUploadBranchFacadePhotoMutation,
  useUploadBranchExtraFacadePhotoMutation,
  useUploadBranchInteriorPhotoMutation,
  useUploadBranchExtraInteriorPhotoMutation,
  useUploadBranchLogoMutation,
  useUpdateBranchGeneralInfoMutation,
  useUpdateBranchContactInfoMutation,
  useUpdateSpecialityMarketingNameMutation,
  useUploadDoctorPhotoMutation,
  useRemoveDoctorPhotoMutation,
  useRemoveBranchDocumentMutation,
  useRemoveBranchFacadePhotoMutation,
  useRemoveBranchExtraFacadePhotoMutation,
  useRemoveBranchInteriorPhotoMutation,
  useRemoveBranchExtraInteriorPhotoMutation,
  useRemoveBranchLogoMutation,
  useUpdateDoctorContactInfoMutation,
  useUpdateDoctorEducationInfoMutation,
  useUpdateDoctorServicesVisibilityMutation,
  useAddDoctorEducationInfoMutation,
  useDeleteDoctorEducationInfoMutation,
  useCreateServicesSpecialityDataMutation,
  useUpdateServicesSpecialityDataMutation,
  useDeleteServicesSpecialityDataMutation,
  useUpdateDoctorProfileServicesDataMutation,
  useUpdateWidgetGeneralSettingsMutation,
  useUpdateWidgetBannerSettingsMutation,
  useUploadWidgetBannerBackgroundMutation,
  useUploadWidgetLogoMutation,
  useRemoveWidgetLogoMutation,
  useAddTermsOfServiceDataMutation,
  useAddUserAgreementDataMutation,
  useRemoveWidgetAgreementDocMutation,
  useRemoveWidgetTermsDocMutation,
  useAddNewUserAccessMutation,
  useUpdateUserAccessMutation,
  useUpdateCallMeBackRequestMutation,
  useUpdateWaitingListRequestMutation,
  useUploadPromotionProfileBackgroundMutation,
  useUpdatePromotionProfileDataMutation,
  useDeletePromotionProfileDataMutation,
  useCreateNewPromotionMutation,
  useRequestServiceDemonstrationMutation,
  useRequestServiceActivationMutation,
  useMarkNotificationViewedMutation,
  useAccessToDoctorPortalMutation,
  useChangeAccessToDoctorPortalMutation,
  useResendVerificationEmailMutation,
  useGetSpecialitiesPositionsQuery,
  usePostSpecialityPositionMutation,
  usePutSpecialityPositionMutation,
  useDeleteSpecialityPositionMutation,
  useGetSessionToScheduleByBranchIdQuery,
  useDeleteWidgetBannerBackgroundMutation,
  useGetDoctorsAcademicDegreesQuery,
  useGetWhiteLabelDataQuery,
  useGetWhiteLabelFileInfoQuery,
  useUploadWhiteLabelLogoMutation,
  useUploadWhiteLabelAgreementMutation,
  useUploadWhiteLabelIconMutation,
  useUpdateWhiteLabelDataMutation,
  useUploadWhiteLabelFavIconMutation,
  useUploadWhiteLabelPwaIconMutation,
  useUpdateBranchPaymentInfoMutation,
  useGetBranchPaymentInfoQuery
} = apiSlice;
