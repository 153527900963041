import React, { useState } from 'react';

import './styles.css';

interface IFloatLabelProps {
  children: React.ReactNode;
  label?: string;
  value?: string | string[];
  suffixIcon?: React.ReactNode;
  suffixOffset?: string;
  isFormList?: boolean;
  isDisabled?: boolean;
  className?: string;
}

function FloatLabelInput({
  children,
  label,
  value,
  suffixIcon,
  suffixOffset,
  isDisabled,
  className,
}: IFloatLabelProps) {
  const [focus, setFocus] = useState(false);
  const labelClass =
    focus || (value && value.length !== 0) || (typeof value === 'number' && value === 0)
      ? 'label label-float'
      : 'label';
  return (
    <div className={`float-label ${className}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <i className="suffixIcon" style={{ right: suffixOffset }}>
        {suffixIcon}
      </i>
      {children}
      {label && <label className={`${labelClass} ${isDisabled ? 'labelDisabled' : ''}`}>{label}</label>}
    </div>
  );
}

export default FloatLabelInput;
