import { Circle } from 'src/common/Icons/icons';
import { Row, Typography } from 'antd';
import { Statuses } from 'src/common/types/enums';
import React from 'react';
import styles from './styles.module.scss';

interface RecordWithStatus {
  status: Statuses;
}

interface StatusTextProps<T extends RecordWithStatus> {
  record: T;
}

export const StatusText = <T extends RecordWithStatus>({ record }: StatusTextProps<T>) => {
  return record.status ? (
    <Row className={styles.StatusRow}>
      <Circle />
      <Typography className={styles.StatusText}>{Statuses[record.status]}</Typography>
    </Row>
  ) : (
    <Row className={styles.StatusRow}>
      <Circle className={styles.RedCircle} />
      <Typography className={styles.StatusText}>{Statuses[record.status]}</Typography>
    </Row>
  );
};
