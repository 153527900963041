import { CheckMarkIcon, DeveloperIcon, DownCaretIcon, RubleIcon } from 'src/common/Icons/icons';
import { Col, Collapse, Layout, Row, Spin, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { serviceData } from './AboutServiceData';
import { useAppSelector } from 'src/app/hooks';
import {
  useGetPracticeBranchesQuery,
  useGetWhiteLabelDataQuery,
  useRequestServiceActivationMutation,
  useRequestServiceDemonstrationMutation,
} from 'src/app/services/api';
import React, { useCallback, useMemo } from 'react';
import styles from './styles.module.scss';

interface AboutServiceProps {
  serviceName: string;
}

export const AboutService = ({ serviceName }: AboutServiceProps) => {
  const [requestDemonstration, demonstrationResult] = useRequestServiceDemonstrationMutation();
  const [requestActivation, activationResult] = useRequestServiceActivationMutation();
  const { data: whiteLabelData } = useGetWhiteLabelDataQuery('');

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const serviceBranch = useAppSelector((state) => state.app.serviceBranchId);

  const isActive = useMemo(() => {
    if (serviceBranch) {
      const selectedBranch = branchesList?.find((branch) => branch.branchId === serviceBranch);
      if (serviceName === 'widget') {
        return branchesList?.some((branch) => branch.isShowInPatientAccountServiceEnabled);
      }
      if (serviceName === 'patient') {
        return selectedBranch?.isShowInPatientAccountServiceEnabled;
      }
      if (serviceName === 'tmk') {
        return selectedBranch?.isTmkServiceEnabled;
      }
      if (serviceName === 'schedule') {
        return selectedBranch?.isScheduleServiceEnabled;
      }
      if (serviceName === 'whiteLabel') {
        return whiteLabelData?.isServiceEnabled;
      }
      return false;
    } else {
      return branchesList?.some((branch) => branch.isShowInPatientAccountServiceEnabled);
    }
  }, [branchesList, serviceBranch, serviceName, whiteLabelData]);

  const handleDemonstration = useCallback(() => {
    if (!serviceBranch) {
      notification.error({ message: 'Для отправки заявки выберите нужный филиал в селекторе над названием сервиса' });
    } else {
      requestDemonstration({ serviceKey: serviceName, idMedOrganization: serviceBranch! });
    }
  }, [requestDemonstration, serviceBranch, serviceName]);

  const buttons = useMemo(
    () => (
      <>
        <CustomButton
          className={styles.DemonstrationButton}
          isPrimal={false}
          onClick={handleDemonstration}
          content={
            demonstrationResult.isSuccess ? (
              <Typography className={styles.SubmitText}>
                <CheckMarkIcon /> Заявка отправлена
              </Typography>
            ) : demonstrationResult.isLoading ? (
              <Spin />
            ) : (
              <Typography>Заказать демонстрацию</Typography>
            )
          }
        />
        {!isActive && (
          <CustomButton
            className={styles.TurnOnButton}
            onClick={() => requestActivation({ serviceKey: serviceName, idMedOrganization: serviceBranch! })}
            isPrimal={true}
            content={
              activationResult.isSuccess ? (
                <Typography className={styles.SubmitText}>
                  <CheckMarkIcon className={styles.CheckPrimalIcon} /> Заявка отправлена
                </Typography>
              ) : activationResult.isLoading ? (
                <Spin />
              ) : (
                <Typography>Подключить</Typography>
              )
            }
          />
        )}
      </>
    ),
    [
      handleDemonstration,
      demonstrationResult,
      isActive,
      activationResult,
      requestActivation,
      serviceName,
      serviceBranch,
    ],
  );

  return (
    <Layout className={styles.AboutServiceWrapper}>
      {serviceData[serviceName]?.slideshowImageStaticFiles?.map((src) => (
        <div
          style={{
            background: `url(${src})`,
            backgroundSize: 'cover',
          }}
          className={styles.ServiceImage}
        ></div>
      ))}
      <Layout className={styles.AboutServiceBlock}>
        <Row>
          <Typography className={styles.Title}>О сервисе</Typography>
        </Row>
        <Row className={styles.AboutServiceTopRow}>
          <Col>
            <Row>
              <DeveloperIcon />
              <Col className={styles.SubCol}>
                <Typography>Разработчик:</Typography>
                <Typography>{serviceData[serviceName]?.aboutServiceDeveloper}</Typography>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <RubleIcon />
              <Col className={styles.SubCol}>
                <Typography>Цена:</Typography>
                <Typography>{serviceData[serviceName]?.aboutServicePrice}</Typography>
              </Col>
            </Row>
          </Col>
          <Row className={styles.ButtonsRow}>{buttons}</Row>
        </Row>
        <Row className={styles.AboutServiceDescriptionRow}>
          <Typography
            className={styles.Text}
            dangerouslySetInnerHTML={{ __html: serviceData[serviceName]?.aboutServiceDescription! }}
          />
          <Collapse
            ghost
            expandIconPosition={'end'}
            expandIcon={({ isActive }) =>
              isActive ? <DownCaretIcon className={styles.rotatedIcon} /> : <DownCaretIcon />
            }
          >
            <Collapse.Panel
              header={
                <Typography className={styles.CollapseHeader}>{serviceData[serviceName]?.collapseTitle}</Typography>
              }
              key="1"
              className={styles.CollapsePanel}
            >
              <Typography
                className={styles.Text}
                dangerouslySetInnerHTML={{ __html: serviceData[serviceName]?.collapseContent! }}
              />
            </Collapse.Panel>
          </Collapse>
        </Row>
      </Layout>
      {serviceData[serviceName]?.limitationsDescription && (
        <Layout className={styles.AboutServiceBlock}>
          <Row>
            <Typography className={styles.Title}>Ограничения</Typography>
          </Row>
          <Row className={styles.AboutServiceTextRow}>
            <Typography
              className={styles.Text}
              dangerouslySetInnerHTML={{ __html: serviceData[serviceName]?.limitationsDescription }}
            />
          </Row>
        </Layout>
      )}
      <Layout className={styles.AboutServiceBlock}>
        <Row>
          <Typography className={styles.Title}>Установка</Typography>
        </Row>
        <Row className={styles.AboutServiceTextRow}>
          <Typography
            className={styles.Text}
            dangerouslySetInnerHTML={{ __html: serviceData[serviceName]?.setupDescription! }}
          />
        </Row>
      </Layout>
      <Layout className={styles.AboutServiceBlock}>
        <Row>
          <Typography className={styles.Title}>Поддержка</Typography>
        </Row>
        <Row className={styles.AboutServiceTextRow}>
          <Typography className={styles.Text}>{serviceData[serviceName]?.supportDescription}</Typography>
        </Row>
      </Layout>
      {/* <Layout className={styles.AboutServiceBlock}>
        <Row>
          <Typography className={styles.Title}>Частые вопросы</Typography>
        </Row>
        <Row className={styles.AboutServiceTextRow}>
          <Collapse
            className={styles.FaqCollapse}
            expandIconPosition={'end'}
            expandIcon={(panelProps) => (panelProps.isActive ? <Cross className={styles.CrossIcon} /> : <Plus />)}
            ghost
          >
            {serviceData[serviceName]?.faq?.map((item) => (
              <Collapse.Panel
                className={styles.FaqPanel}
                header={<Typography className={styles.FaqTitle}>{item.question}</Typography>}
                key={item.question}
              >
                <Typography className={styles.FaqText}>{item.answer}</Typography>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Row>
      </Layout> */}
      <Row className={styles.ButtonsBottomRow}>{buttons}</Row>
    </Layout>
  );
};
