import AmenitiesPng from 'src/common/images/Amenities.png';
import React from 'react';
import styles from './NoItems.module.scss';

import { Button } from 'antd';

type PropsType = {
  show: boolean;
  onBegin: () => void;
};

export const NoItems: React.FC<PropsType> = ({ show, onBegin }) => {
  if (!show) return null;

  return (
    <div className={styles.container}>
      <img src={AmenitiesPng} alt="" />

      <h1 className={styles.title}>Добавьте услуги</h1>

      <p className={styles.text}>
        Пожалуйста, добавьте услуги, на которые пациенты могут записаться в вашу клинику через сервисы Mila. У каждого
        филиала свой перечень услуг. Все услуги привязываются к врачебным специализациям (по единому Федеральному
        справочнику врачебных специализаций).
      </p>

      <p className={styles.text}>
        Вы можете добавить услуги вручную или импортировать из Excel, предварительно скачав{' '}
        <a href="#excel">Шаблон импорта услуг из Excel.</a>
      </p>

      <Button onClick={onBegin} className={styles.begin}>
        Добавить услуги
      </Button>
    </div>
  );
};
