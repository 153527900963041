import { AccessDeniedStub } from 'src/common/components/AccessDeniedStub/AccessDeniedStub';
import { AddDoctorStub } from './AddDoctorStub/AddDoctorStub';
import { ReactComponent as AddUserIcon } from '../../../common/Icons/AddUserIcon.svg';
import { Branch, PracticeDoctorList } from '../../../common/types';
import { Button, Col, Form, Row, Select, Table, Typography } from 'antd';
import { DownCaretIcon } from '../../../common/Icons/icons';
import { ReactComponent as EyeInvisibleIcon } from '../../../common/Icons/EyeInvisibleIcon.svg';
import { ReactComponent as EyeVisibleIcon } from '../../../common/Icons/EyeVisibleIcon.svg';
import { getPageVisibility, getPreparedBranches, getPreparedSortedBranches } from 'src/common/utils/utils';
import { setBranch } from '../../../app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useForm } from 'antd/lib/form/Form';
import {
  useGetBranchDoctorsQuery,
  useGetPracticeBranchesQuery,
  useGetPracticeDoctorsQuery,
} from '../../../app/services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import FallbackUserPhoto from '../../../common/Icons/FallbackUserPhoto.svg';
import NavSubMenu from '../NavSubMenu';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

function DoctorsListPage() {
  const [form] = useForm();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [orgName, setOrgName] = useState('');
  const dispatch = useAppDispatch();
  const stateBranch = useAppSelector((state) => state.app.selectedBranchId);
  const stateNetwork = useAppSelector((state) => state.app.networkId);
  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const availableDoctorsBranches = useAppSelector((state) => state.app.availableBranches);
  const isPageVisible = getPageVisibility(permissions, 'can-access-page-doctors');

  const { data: branchesDoctorsList } = useGetBranchDoctorsQuery(stateNetwork!, {
    skip: !stateNetwork,
  });

  const { data: practiceDoctorsList, refetch: refetchPracticeDoctorsList } = useGetPracticeDoctorsQuery(orgName, {
    skip: !orgName || orgName === 'all',
  });

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const [isAddDoctorButtonVisible, setIsAddDoctorButtonVisible] = useState<boolean>(false);

  type PrepareDoctorTableProps = {
    key: string;
    fullName: PracticeDoctorList;
    speciality: ReactNode[];
    photo: ReactNode;
    records: PracticeDoctorList;
    amenities: PracticeDoctorList;
    widgetShow: ReactNode;
  };

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableDoctorsBranches ?? []) : []),
    [availableDoctorsBranches, branchesList],
  );

  const columns: any = [
    {
      title: 'Фото',
      dataIndex: 'photo',
      key: 'photo',
      width: 58,
    },
    {
      title: 'ФИО врача',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 246,
      render: (doctor: PracticeDoctorList) => (
        <Typography.Link
          onClick={
            orgName !== 'all'
              ? () =>
                  navigate(
                    `/company/doctors/${pathname.split('/').reverse()[0] ?? stateBranch}/${
                      doctor.practiceDoctorId
                    }/general`,
                  )
              : () => null
          }
        >{`${doctor.lastName} ${doctor.firstName} ${doctor.middleName}`}</Typography.Link>
      ),
      sorter: (a: PrepareDoctorTableProps, b: PrepareDoctorTableProps) =>
        a.fullName?.lastName?.localeCompare(b.fullName?.lastName),
      className: styles.cellLinkText,
    },
    {
      title: 'Специализация',
      dataIndex: 'speciality',
      key: 'speciality',
      width: 260,
    },
    {
      title: 'Записи',
      dataIndex: 'records',
      key: 'records',
      width: 123,
      render: (doctor: PracticeDoctorList) => (
          <Typography.Link
              onClick={
                orgName !== 'all'
                    ? () =>
                        navigate(
                            `/company/doctors/${pathname.split('/').reverse()[0] ?? stateBranch}/${
                                doctor.practiceDoctorId
                            }/records`,
                        )
                    : () => null
              }
          >{doctor.appointmentsCount}</Typography.Link>
      ),
      sorter: (a: PrepareDoctorTableProps, b: PrepareDoctorTableProps) =>
          a.records?.appointmentsCount - b.records?.appointmentsCount,
      className: styles.cellLinkText,
    },
    {
      title: 'Услуги',
      dataIndex: 'amenities',
      key: 'amenities',
      width: 94,
      render: (doctor: PracticeDoctorList) => (
          <Typography.Link
              onClick={
                orgName !== 'all'
                    ? () =>
                        navigate(
                            `/company/doctors/${pathname.split('/').reverse()[0] ?? stateBranch}/${
                                doctor.practiceDoctorId
                            }/amenitiesDoctor`,
                        )
                    : () => null
              }
          >{doctor.specialityServicesCount}</Typography.Link>
      ),
      sorter: (a: PrepareDoctorTableProps, b: PrepareDoctorTableProps) =>
          a.amenities?.specialityServicesCount - b.amenities?.specialityServicesCount,
      className: styles.cellLinkText,
    },
    /*{
      title: 'Тип приёма',
      dataIndex: 'receptionType',
      key: 'receptionType',
      width: 164,
    },*/ // temporary postponed to the next milestone
    {
      title: 'Отображение для пациентов',
      dataIndex: 'widgetShow',
      key: 'widgetShow',
      width: 110,
      className: styles.cellWidgetVisibility,
    },
  ];

  const prepareBranches = (branchesData: Branch[]) =>
    branchesData ? getPreparedBranches(branchesList || [], availableDoctorsBranches ?? []) : [];

  const prepareDoctorsTable = () => {
    const doctorsList = orgName === 'all' ? branchesDoctorsList : practiceDoctorsList;

    return doctorsList
      ? doctorsList.map((doctor: PracticeDoctorList) => {
          return {
            key: doctor.practiceDoctorId,
            fullName: doctor,
            speciality: doctor.specializations?.map((specialization: string, index: number) => (
              <div key={index}>{specialization}</div>
            )),
            photo: (
              <div className={styles.doctorPhotoContainer}>
                <img
                  alt=""
                  src={
                    doctor.practiceDoctorPhotoId
                      ? `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${doctor.practiceDoctorPhotoId}?inline=true`
                      : FallbackUserPhoto
                  }
                  height={58}
                  width={58}
                />
              </div>
            ),
            records: doctor,
            amenities: doctor,
            widgetShow: doctor.isVisibleInWidget ? <EyeVisibleIcon /> : <EyeInvisibleIcon />,
          };
        })
      : [];
  };

  useEffect(() => {
    if (orgName && orgName !== 'all') {
      const currentBranch = branchesList?.find((branch) => branch.branchId === orgName)!;
      if (currentBranch?.useTmData) {
        setIsAddDoctorButtonVisible(false);
      } else {
        setIsAddDoctorButtonVisible(true);
      }
    } else {
      setIsAddDoctorButtonVisible(false);
    }
  }, [orgName]);

  useEffect(() => {
    // refetchDoctorsList();
    orgName && orgName !== 'all' && refetchPracticeDoctorsList();

    if (orderedBranches.length) {
      const branch =
        orderedBranches?.find(({ branchId }) => branchId === pathname.split('/').reverse()[0])?.branchId || null;
      /* !orgName && setOrgName(orderedBranches[0]?.branchId);*/
      if (stateBranch && orgName !== 'all') {
        setOrgName(stateBranch);
        navigate(`/company/doctors/${stateBranch}`);
      } else if (branch) {
        setOrgName(branch);
        dispatch(setBranch(branch));
        navigate(`/company/doctors/${branch}`);
      } else if (!orgName) {
        setOrgName(orderedBranches[0]?.branchId);
        dispatch(setBranch(orderedBranches[0]?.branchId));
        navigate(`/company/doctors/${orderedBranches[0]?.branchId ?? ''}`);
      }
    }
  }, [dispatch, navigate, orderedBranches, orgName, pathname, refetchPracticeDoctorsList, stateBranch]);

  const tableDataSource = useMemo(() => {
    return prepareDoctorsTable();
  }, [prepareDoctorsTable]);
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);

  const handleAddDoctorButtonClick = () => {
    navigate(`/company/doctors/${practiceId ?? ''}/add-doctor`);
  };

  return (
    <>
      <NavSubMenu />
      {isPageVisible ? (
        <div className={styles.pageContainer}>
          <Row>
            {isAddDoctorButtonVisible && (
              <Button className={styles.addDoctorButton} icon={<AddUserIcon />} onClick={handleAddDoctorButtonClick} />
            )}
            <Col className={styles.inputOrgName}>
              <Select
                options={prepareBranches(branchesList || [])}
                style={{ width: '328px', height: '40px' }}
                onChange={(value) => {
                  setOrgName(value);
                  value !== 'all' && dispatch(setBranch(value));
                }}
                value={orgName}
                suffixIcon={<DownCaretIcon />}
              />
            </Col>
            {/*<Col className={styles.searchInput}>
              <Input
                style={{ width: '328px', height: '40px' }}
                prefix={<SearchOutlined className={styles.inputPrefix} />}
                placeholder={'Поиск по ФИО'}
              />
            </Col>
            <Col className={styles.toolbarButtonsRow}>
              <Button className={styles.filtersButton} icon={<FiltersButtonIcon />}>
                Все фильтры
              </Button>
              <Button className={styles.exportButton} icon={<ExportFileIcon />}>
                Export
              </Button>
              <Button className={styles.toolbarButton} icon={<SettingsIcon />}></Button>
            </Col>*/}
          </Row>
          <div className={styles.pageContentContainer}>
            {typeof practiceDoctorsList === 'undefined' ? null : tableDataSource.length ? (
              <Form form={form}>
                <Table columns={columns} dataSource={tableDataSource} pagination={false} />
              </Form>
            ) : isAddDoctorButtonVisible ? (
              <AddDoctorStub />
            ) : null}
          </div>
        </div>
      ) : (
        <Col className={styles.pageContainer}>
          <AccessDeniedStub
            title="Ошибка 403"
            text="Доступ запрещен
              

          У вас недостаточно прав доступа. Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
          />
        </Col>
      )}
    </>
  );
}

export default DoctorsListPage;
