import { DictionaryItem, DictionarySubwayItem } from 'src/common/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiDictionarySlice = createApi({
  reducerPath: 'milaDictionaryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/dictionaries/`,
  }),

  endpoints: (builder) => ({
    getFerSpecialities: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=ferSpecialities`,
        };
      },
    }),

    getMilaPositions: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=milaSpecialities`,
        };
      },
    }),

    getM3Services: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=services`,
        };
      },
    }),

    getCompositeProfiles: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=compositeProfiles`,
        };
      },
    }),

    getDoctorEducationType: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=doctorEducationTypes`,
        };
      },
    }),

    getTimezones: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=time_zones`,
        };
      },
    }),

    getSubways: builder.query<DictionarySubwayItem[], string>({
      query: () => ({ url: `subway` }),
    }),
    
    getPositions: builder.query<DictionaryItem[], string>({
      query: () => {
        return {
          url: `?Type=frmrPositions`,
        };
      }
    })
  }),
});

export const {
  useGetFerSpecialitiesQuery,
  useGetDoctorEducationTypeQuery,
  useGetTimezonesQuery,
  useGetSubwaysQuery,
  useGetMilaPositionsQuery,
  useGetM3ServicesQuery,
  useGetCompositeProfilesQuery,
  useGetPositionsQuery,
} = apiDictionarySlice;
