import { Input } from 'antd';

import { Rule } from 'antd/es/form';
import { TextAreaProps } from 'antd/es/input';
import FloatLabelInput from 'src/common/FloatLabelInput/FloatLabelInput';
import InputMask from 'react-input-mask';
import React, { ChangeEvent, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';

interface CustomInputProps extends TextAreaProps {
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  floatLabel?: string;
  inputName?: string;
  isTextArea?: boolean;
  textAreaRows?: number;
  floatLabelClassName?: string;
  rules?: Rule[];
  mask?: string;
  suffixIcon?: ReactNode;
  suffixOffset?: string;
}

export const CustomInput = ({
  placeholder,
  value,
  onChange,
  floatLabel,
  floatLabelClassName = '',
  inputName,
  className = '',
  disabled,
  isTextArea = false,
  textAreaRows = 4,
  mask,
  suffixIcon,
  suffixOffset,
  ...props
}: CustomInputProps) => {
  const input = useMemo(
    () =>
      isTextArea ? (
        <Input.TextArea
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          value={value}
          className={`${styles.textArea} ${className}`}
          rows={textAreaRows}
          name={inputName}
          disabled={disabled}
          {...props}
        />
      ) : mask ? (
        <InputMask
          mask={mask}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          value={value}
          name={inputName}
        >
          <Input className={`${styles.input} ${className}`} name="phone" />
        </InputMask>
      ) : (
        <Input
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          value={value}
          name={inputName}
          className={`${styles.input} ${className}`}
        />
      ),
    [isTextArea, placeholder, onChange, value, className, textAreaRows, inputName, disabled, props, mask],
  );

  return (
    <FloatLabelInput
      label={floatLabel || ''}
      suffixIcon={suffixIcon}
      value={value}
      className={floatLabelClassName}
      suffixOffset={suffixOffset}
    >
      {input}
    </FloatLabelInput>
  );
};
