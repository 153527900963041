import { PracticeSpecialityPosition } from 'src/common/types';
import React from 'react';
import styles from './CollapseHeader.module.scss';

type PropsType = {
  speciality: PracticeSpecialityPosition;
};

export const CollapseHeader: React.FC<PropsType> = ({ speciality }) => {
  const title = `${speciality.specialityName}, код ${speciality.specialityFerId}`;
  const count = speciality.positions.length;

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <span className={styles.count}>{`(наименований врачей: ${count})`}</span>
    </div>
  );
};
