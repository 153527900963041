import { AccessDeniedStub } from 'src/common/components/AccessDeniedStub/AccessDeniedStub';
import { ContentList } from './components/ContentList';
import { Topbar } from './components/Topbar';
import { getPageVisibility } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import NavSubMenu from '../NavSubMenu';
import React from 'react';
import styles from './styles.module.scss';

export const SpecializationsPage = () => {
  const permissions = useAppSelector((state) => state.app.permissions) || [];
  const isPageVisible = getPageVisibility(permissions, 'can-access-page-specializations');

  return (
    <>
      <NavSubMenu />
      <div className={styles.pageContainer}>
        {isPageVisible ? (
          <>
            <Topbar />
            <div className={styles.contentContainer}>
              <ContentList />
            </div>
          </>
        ) : (
          <>
            <AccessDeniedStub
              title="Ошибка 403"
              text="Доступ запрещен
              

          У вас недостаточно прав доступа. Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
            />
          </>
        )}
      </div>
    </>
  );
};
