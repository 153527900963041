import { CommentIcon, RateIcon } from 'src/common/Icons/icons';
import { Popover, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface RatingProps {
  score: number;
  comment: string;
}

export const Rating = ({ score, comment }: RatingProps) => {
  const highlightClass = useMemo(() => {
    if (score === 5) {
      return styles.Green;
    }
    if (score === 4) {
      return styles.Orange;
    }
    if (score < 4) {
      return styles.Red;
    }
    return '';
  }, [score]);
  return score ? (
    <Row className={styles.RatingRow}>
      <RateIcon />
      <Typography className={`${styles.Score} ${highlightClass}`}>{score}</Typography>
      <Popover content={<Typography className={styles.CommentText}>{comment}</Typography>} title="Комментарий">
        <CommentIcon />
      </Popover>
    </Row>
  ) : (
    <Typography className={styles.NotRatedText}>Не оценено</Typography>
  );
};
