import { Button, Checkbox, Input, Modal, notification } from 'antd';
import { CompositeProfilesSelect } from '../selects/CompositeProfilesSelect';
import { CreateMedOrganizationSpecialtyServiceArgs, NewPracticeService } from 'src/common/types';
import { ServicesSelect } from '../selects/ServicesSelect';
import { SpecialitiesSelect } from '../selects/SpecialitiesSelect';
import { useAmenityValidation } from '../hooks/useAmenityValidation';
import { useAppSelector } from 'src/app/hooks';
import { useCreateServicesSpecialityDataMutation, useGetPracticeServicesQuery } from 'src/app/services/api';
import { useState } from 'react';
import FloatLabel from 'src/common/components/FloatLabel';
import styles from './AmenityForm.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export function CreateForm({ open, onClose }: IProps) {
  const branchId = useAppSelector((state) => state.app.selectedBranchId!);
  const branchName = useAppSelector((state) => state.app.selectedBranchName);

  const [createMutation, createRequest] = useCreateServicesSpecialityDataMutation();
  const { refetch: refetchServices } = useGetPracticeServicesQuery(branchId);

  const [specialityCode, setSpecialityCode] = useState<string | null>(null);
  const [serviceName, setServiceName] = useState<string>('');
  const [cost, setCost] = useState<string>('');
  const [selectedMode, setSelectedMode] = useState<'isTmk' | 'isOnSite' | 'isAtHome'>('isOnSite');
  const [profileCode, setProfileCode] = useState<string | null>(null);
  const [serviceCode, setServiceCode] = useState<string | null>(null);
  const [showMode, setShowMode] = useState<boolean>(true);

  const isPending = createRequest.status === 'pending';

  const { validateFields } = useAmenityValidation();

  const isTmk = selectedMode === 'isTmk';
  const isOnSite = selectedMode === 'isOnSite';
  const isAtHome = selectedMode === 'isAtHome';

  const resetFields = () => {
    setSpecialityCode(null);
    setServiceName('');
    setCost('');
    setSelectedMode('isTmk');
    setProfileCode(null);
    setServiceCode(null);
    setShowMode(true);
  };

  const cancelHandle = () => {
    if (isPending) return;

    onClose();
  };

  const submitHandle = () => {
    if (isPending) return;

    const data: NewPracticeService = {
      specialityFerId: specialityCode!,
      nameForPatient: serviceName,
      cost: cost as unknown as number,
      isOnSite: isOnSite,
      isTmk: isTmk,
      isAtHome: isAtHome,
      showInWidget: showMode,
      compositeProfileId: profileCode!,
      serviceKindId: serviceCode!,
    };

    if (!validateFields(data)) return;

    const postData: CreateMedOrganizationSpecialtyServiceArgs = {
      ...data,
      medOrganizationId: branchId,
    };

    createMutation(postData)
      .unwrap()
      .then(() => {
        refetchServices();
        resetFields();
        notification.success({ message: 'Услуга успешно добавлена' });
        onClose();
      })
      .catch((error) => {
        notification.error({
          message: `При выполнении запроса возникла ошибка: ${error.status} ${error.data.title ?? ''}`,
        });
      });
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onCancel={cancelHandle}
      centered
      footer={null}
      width={763}
      maskClosable={false}
    >
      <div className={`${styles.container} ${styles.createForm}`}>
        <h1 className={styles.title}>{`Добавить новую услугу в ${branchName}`}</h1>

        <div className={styles.block}>
          <SpecialitiesSelect currentValue={specialityCode} onSelect={setSpecialityCode} />

          <FloatLabel label="Наименование услуги для пациентов *" fixSmall={!!serviceName}>
            <Input
              className={styles.input}
              value={serviceName}
              onChange={(event) => setServiceName(event.target.value)}
            />
          </FloatLabel>

          <div className={styles.row}>
            <div className={styles.cost}>
              <FloatLabel label="Цена услуги, Р *" fixSmall={!!cost}>
                <Input className={styles.input} value={cost} onChange={(event) => setCost(event.target.value)} />
              </FloatLabel>
            </div>

            <label className={styles.radio}>
              <Input type="radio" checked={isOnSite} onClick={() => setSelectedMode('isOnSite')} onChange={() => {}} />
              <span>В клинике</span>
            </label>

            <label className={styles.radio}>
              <Input type="radio" checked={isTmk} onClick={() => setSelectedMode('isTmk')} onChange={() => {}} />
              <span>Онлайн</span>
            </label>

            <label className={styles.radio}>
              <Input type="radio" checked={isAtHome} onClick={() => setSelectedMode('isAtHome')} onChange={() => {}} />
              <span>На дому</span>
            </label>
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.attributes}>Атрибуты услуги</div>
          <CompositeProfilesSelect currentValue={profileCode} onSelect={setProfileCode} />
          <ServicesSelect currentValue={serviceCode} onSelect={setServiceCode} />
          <Checkbox rootClassName={styles.showmode} checked={showMode} onChange={(e) => setShowMode(e.target.checked)}>
            Отображать услугу для пациентов в сервисах Mila
          </Checkbox>
        </div>

        <div className={styles.buttons}>
          <Button className={styles.cancel} onClick={cancelHandle}>
            Отмена
          </Button>
          <Button className={styles.submit} loading={createRequest.isLoading} onClick={submitHandle}>
            Добавить услугу
          </Button>
        </div>
      </div>
    </Modal>
  );
}
