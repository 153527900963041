import { MicroFrontend } from "./MicroFrontend/MicroFrontend";
import { useEffect } from "react";
import { useNavigate } from "react-router";

type TMKScheduleWidgetProps = {
    sessionId: string;
}

const widgetUrl = window.__RUNTIME_CONFIG__.REACT_APP_TM_WIDGETS_URL;

const TMKScheduleWidget = ({ sessionId }: TMKScheduleWidgetProps) => {
    const history = useNavigate();
    
    const listener = () => {        
        try {
            window?.postMessage(
                {
                    isActualData: true,
                    sessionId: sessionId,
                },
                '*'
            );
        } catch (e) {
            console.error(e);
        }
    };

    const loadIframe = (val: any) => {             
        if (val.data === "load") listener();
    };

    useEffect(() => {
        if (window.addEventListener) {
            window.addEventListener("message", loadIframe, false);
        }
        return () => window.removeEventListener("message", loadIframe, false);
    }, [sessionId]);

    useEffect(() => {
        if (sessionId) {
            listener();
        }
    }, [sessionId]);

    return <div style={{ height: 'unset' }}>
        {sessionId && <MicroFrontend history={history} host={widgetUrl ?? ""} name="TMSchedule" />}
    </div>;
};

export default TMKScheduleWidget;
