import { PracticeDoctorGeneralInfo } from '../../../common/types';
import { Typography } from 'antd';
import { useGetPracticeBranchesQuery } from '../../../app/services/api';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

function DoctorTitle(props: { doctorGeneralInfo?: PracticeDoctorGeneralInfo; practiceId?: string }) {
  const { doctorGeneralInfo, practiceId } = props;

  const { data: branchesList } = useGetPracticeBranchesQuery('');

  const doctorTitleName = useMemo(
    () =>
      `${doctorGeneralInfo?.lastName} ${doctorGeneralInfo?.firstName} ${doctorGeneralInfo?.middleName} (${
        branchesList?.find((f) => f.branchId === practiceId)?.shortName
      })`,
    [
      doctorGeneralInfo?.middleName,
      doctorGeneralInfo?.lastName,
      doctorGeneralInfo?.firstName,
      branchesList,
      practiceId,
    ],
  );

  return (
    <>
      {doctorGeneralInfo && (
        <div className={styles.titleDoctorsName}>
          <Typography.Text>{doctorTitleName}</Typography.Text>
        </div>
      )}
    </>
  );
}

export default DoctorTitle;
