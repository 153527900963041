import { Button } from 'antd';
import React, { ReactElement } from 'react';
import styles from './styles.module.scss';

interface CustomButtonProps {
  content: ReactElement;
  isPrimal: boolean;
  onClick?: () => void;
  className?: string;
}

export const CustomButton = ({ content, isPrimal, onClick, className }: CustomButtonProps) => {
  return (
    <Button className={`${isPrimal ? styles.PrimalButton : styles.SecondaryButton} ${className}`} onClick={onClick}>
      {content}
    </Button>
  );
};
