import { DownCaretIcon } from 'src/common/Icons/icons';
import { Select } from 'antd';
import { StatusText } from '../StatusText/StatusText';
import { Statuses } from 'src/common/types/enums';
import React, { FormEventHandler } from 'react';
import styles from './styles.module.scss';

interface RecordWithStatus {
  status: Statuses;
}

interface StatusSelectProps<T extends RecordWithStatus> {
  record: T;
  onChange: FormEventHandler<HTMLElement> | undefined;
}

export const StatusSelect = <T extends RecordWithStatus>({ record, onChange }: StatusSelectProps<T>) => {
  return (
    <Select
      suffixIcon={<DownCaretIcon />}
      //@ts-ignore
      defaultValue={record.status}
      className={styles.StatusSelect}
      onChange={onChange}
      options={[
        {
          value: 0,
          label: <StatusText record={{ status: 0 }} />,
        },
        {
          value: 1,
          label: <StatusText record={{ status: 1 }} />,
        },
      ]}
    />
  );
};
