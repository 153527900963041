import { Col, Row, Select, Tooltip } from 'antd';
import { DownCaretIcon, ExclamationMarkIcon } from 'src/common/Icons/icons';
import { getPreparedBranches, getPreparedSortedBranches } from 'src/common/utils/utils';
import { setBranch, setBranchName } from 'src/app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetPracticeBranchesQuery } from 'src/app/services/api';
import React, { useEffect, useMemo } from 'react';
import styles from './Topbar.module.scss';

const Info = () => {
  return (
    <div>
      <p>
        В этом разделе вы можете настроить отображение наименований врачей для пациентов в сервисах Mila. Чтобы перейти
        к настройке необходимо сначала добавить специализации в разделе “Услуги”. В этом разделе они появятся
        автоматически и вы сможете указать наименования врачей для пациентов.
      </p>

      <p>
        Сводный перечень наименований принимающих врачей отображается пациентам при записи на прием в вашу клинику.
        Также наименования отображаются в профилях врачей. Если отображение наименований врачей не настроено, пациентам
        будет показано значение из справочника Mila, выставленное по умолчанию для соответствующей специализации.
        Перечень наименований врачей устанавливается отдельно для каждого филиала.
      </p>
    </div>
  );
};

export const Topbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const branchId = useAppSelector((state) => state.app.selectedBranchId);
  const branchName = useAppSelector((state) => state.app.selectedBranchName);
  const availableBranches = useAppSelector((state) => state.app.availableBranches);
  const { data: branchesList } = useGetPracticeBranchesQuery('');

  const options = useMemo(() => {
    return branchesList ? getPreparedBranches(branchesList || [], availableBranches || []) : [];
  }, [branchesList, availableBranches]);

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableBranches || []) : []),
    [availableBranches, branchesList],
  );

  useEffect(() => {
    if (orderedBranches.length === 0) return;

    if (!branchId) {
      const defaultBranch = orderedBranches[0];

      dispatch(setBranch(defaultBranch.branchId));
      dispatch(setBranchName(defaultBranch.shortName));
    } else if (branchId && !branchName) {
      const selectedBranch = orderedBranches.find((branch) => branch.branchId === branchId);

      if (selectedBranch) {
        dispatch(setBranchName(selectedBranch.shortName));
      }
    }
  }, [dispatch, orderedBranches, branchId, branchName]);

  const branchSelectHandle = (value: string, option: any) => {
    dispatch(setBranch(value));
    dispatch(setBranchName(option.label));
  };

  return (
    <Row className={styles.container}>
      <Col>
        <Tooltip title={<Info />} placement="right" overlayClassName={styles.tooltipOverlay}>
          <div className={styles.button}>
            <ExclamationMarkIcon />
          </div>
        </Tooltip>
      </Col>
      <Col className={styles.field}>
        <Select
          className={styles.select}
          options={options}
          onChange={(value, option) => {
            branchSelectHandle(value, option);
          }}
          value={branchId}
          suffixIcon={<DownCaretIcon />}
        />
      </Col>
    </Row>
  );
};
