import NavSubMenu from '../NavSubMenu';
import React from 'react';
import styles from './styles.module.scss';

function AgreementsPage() {
  return (
    <>
      <NavSubMenu />
      <div className={styles.pageContainer}>Соглашения и оферты</div>
    </>
  );
}

export default AgreementsPage;
