import { PromotionTabDataType } from 'src/common/types';
import { PromotionsListPage } from 'src/features/MarketingPage/PromotionsListPage/PromotionsListPage';
import { ServiceComponent } from 'src/features/ServicesPage/ServiceComponent/ServiceComponent';
import { useParams } from 'react-router';

const PromotionTabData = {
  promotionsList: {
    aboutComponent: <PromotionsListPage />,
    pageTitle: 'Акции',
  },
  promocodesGenerator: {
    settingsComponent: <p>Генератор промокодов (страница в разработке)</p>,
    aboutComponent: <p>Генератор промокодов (страница в разработке)</p>,
    pageTitle: 'Генератор промокодов',
  },
  yandexMaps: {
    settingsComponent: <p>Яндекс карты (страница в разработке)</p>,
    aboutComponent: <p>Яндекс карты (страница в разработке)</p>,
    pageTitle: 'Яндекс карты',
  },
  yandexDoctors: {
    settingsComponent: <p>Врачи в яндекс поиске (страница в разработке)</p>,
    aboutComponent: <p>Врачи в яндекс поиске (страница в разработке)</p>,
    pageTitle: 'Врачи в яндекс поиске',
  },
  widgetPromoBlock: {
    settingsComponent: <p>Промоблоки виджета (страница в разработке)</p>,
    aboutComponent: <p>Промоблоки виджета (страница в разработке)</p>,
    pageTitle: 'Промоблоки виджета',
  },
  messengers: {
    settingsComponent: <p>Рассылки в мессенджеры (страница в разработке)</p>,
    aboutComponent: <p>Рассылки в мессенджеры (страница в разработке)</p>,
    pageTitle: 'Рассылки в мессенджеры',
  },
} as PromotionTabDataType;

export const NavWrapperComponent = () => {
  const { promotionTab } = useParams();
  return (
    <ServiceComponent
      aboutServiceTab={PromotionTabData[promotionTab!].aboutComponent}
      name={PromotionTabData[promotionTab!].pageTitle}
    />
  );
};
