import { Branch } from "src/common/types";
import { DownCaretIcon } from "src/common/Icons/icons";
import { Layout, Select, Typography } from "antd";
import { getPreparedBranches } from "src/common/utils/utils";
import { useAppSelector } from "src/app/hooks";
import { useEffect, useState } from "react";
import { useGetPracticeBranchesQuery, useGetSessionToScheduleByBranchIdQuery } from "src/app/services/api";
import TMKScheduleWidget from "./TMKScheduleWidget/TMKScheduleWidget";
import styles from './styles.module.scss';

export const SchedulePage = () => {
    const [branch, setBranch] = useState<string>("");
    const [branchesListToSelector, setBranchesListToSelector] = useState<{
        label: string;
        value: string;
    }[]>([]);
    const [branchesHasScheduleWidget, setBranchesHasScheduleWidget] = useState<boolean>(false);

    const { data: branchesList } = useGetPracticeBranchesQuery('');
    const { data: sessionData, isFetching, isLoading } = useGetSessionToScheduleByBranchIdQuery(
        branch,
        { skip: !branch, refetchOnMountOrArgChange: true }
    );
    const availableBranches = useAppSelector((state) => state.app.availableBranches);

    const prepareBranches = (branchesData: Branch[] | undefined) =>
        branchesData ? getPreparedBranches(branchesList || [], availableBranches ?? []) : [];

    useEffect(() => {
        const branchesWithSchedule = branchesList?.filter((item) => item.isScheduleServiceEnabled);
        const preparedBranches = prepareBranches(branchesWithSchedule);

        if (branchesWithSchedule) {
            const brachesToSelector = preparedBranches.filter((item) => {
                const itemIndex = branchesWithSchedule.findIndex((branch) => branch.branchId === item.value);
                return itemIndex !== - 1;
            });            
           
            brachesToSelector && setBranchesListToSelector(brachesToSelector);
        }
    }, [branchesList]);

    useEffect(() => {
        branchesList?.forEach((branch) => {        
            if (branch.isScheduleServiceEnabled && 
                branchesListToSelector.findIndex((item) => item.value === branch.branchId) !== -1) {
                setBranchesHasScheduleWidget(true);
            }
        });
    }, [branchesListToSelector, branchesList]);

    return (
        <Layout className={`${styles.ScheduleWrapper} ${!branchesHasScheduleWidget ? styles.NoScheduleWrapper : ""}`}>
            {
                !branchesHasScheduleWidget && 
                <Typography.Text>Не подключён сервис «Расписание». Пожалуйста, обратитесь к менеджерам N3.Health</Typography.Text>
            }
            {
                branchesHasScheduleWidget &&
                <>
                    <Typography className={styles.Title}>Расписание</Typography>
                    <Select
                        className={styles.BranchesSelector}
                        options={branchesListToSelector}
                        onChange={(value) => setBranch(value)}
                        suffixIcon={<DownCaretIcon />}
                    />
                    {
                        sessionData && branch && !isFetching && !isLoading &&
                        <TMKScheduleWidget sessionId={sessionData} />
                    }
                </>
            }
        </Layout>
    );
};
