import { Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

interface Tab {
  key: string;
  label: string;
}

interface NavTabsProps {
  items: Tab[];
  basicPath: string;
  activeTabKey?: string;
}

export const NavTabs = ({ items, basicPath, activeTabKey }: NavTabsProps) => {
  const navigate = useNavigate();
  const handleTabClick = (tabName: string) => {
    navigate(`/${basicPath}/${tabName}`);
  };

  return (
    <Tabs
      size={'large'}
      items={items}
      defaultActiveKey="all"
      className={styles.NavTabsContainer}
      onTabClick={(tab) => handleTabClick(tab)}
      activeKey={activeTabKey}
    />
  );
};
