import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {RunSzpvSyncForMedOraganization} from '../../common/types';


export const apiSyncSlice = createApi({
  reducerPath: 'milaSyncApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/service-area/tm/`,
  }),

  endpoints: (builder) => ({
    runSzpvSyncForMedOrganization: builder.mutation<void, RunSzpvSyncForMedOraganization>({
      query: (data: RunSzpvSyncForMedOraganization) => {
        return {
          url: `medorganization/${data.moId}/specialities`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useRunSzpvSyncForMedOrganizationMutation } = apiSyncSlice;
