import React, { useMemo } from "react";
import { DefaultOptionType } from "antd/es/select";
import useSelectorSortFilter from "src/common/hooks/useSelectorSortFilter";
import FloatLabel from "src/common/components/FloatLabel";
import WidthFixedSelect from "src/common/components/WidthFixedSelect";
import { DictionaryItem } from "src/common/types";

type PropsType = {
    currentValue: string | null;
    onSelect: (value: string, option?: DefaultOptionType | DefaultOptionType[]) => void;
    data: DictionaryItem[] | undefined;
    handlePositionChange: (value: string) => void;
};

export const PositionsSelect: React.FC<PropsType> = ({ currentValue, onSelect, data, handlePositionChange }) => {
    const specOptions = useMemo(
        () => data?.map((item) => ({ label: `${item.name} (код ${item.code})`, value: item.code })) ?? [],
        [data],
    );

    const { filterOptions, sortOptions } = useSelectorSortFilter();

    return (
        <FloatLabel label="Должность *" fixSmall={!!currentValue}>
          <WidthFixedSelect
            options={specOptions}
            showSearch={true}
            filterSort={sortOptions}
            filterOption={filterOptions}
            value={currentValue}
            onChange={(value, option) => {
              onSelect(value, option);
              if (!Array.isArray(option)) {
                handlePositionChange(String(option.label) ?? "");
              }        
            }}            
          ></WidthFixedSelect>
        </FloatLabel>
    );
};
