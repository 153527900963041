import { Button, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import AmenitiesStubIcon from './AmenitiesStubIcon.png';
import React from 'react';
import styles from './styles.module.scss';

function AmenitiesStubPage() {
  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.pageContent}>
          <Col className={styles.iconContainer}>
            <img src={AmenitiesStubIcon} alt="records-page-icon" />
          </Col>
          <Col className={styles.pageSubTitleWrapper}>
            <Typography.Text>Для данного специалиста из МИС не получены связанные услуги </Typography.Text>
          </Col>
          <Col className={styles.pageDescription}>
            <Typography.Text>
              Привязка услуг к специалисту настраивается в МИС филиала. Для данного специалиста из МИС не получены
              связанные услуги. Пожалуйста, проверьте данные в МИС. Если вы считаете, что это ошибка, пожалуйста,
              обратитесь в службу технической поддержки.
            </Typography.Text>
          </Col>
          <Row className={styles.formButtonsContainer} gutter={[10, 10]}>
            <Col>
              <Link to="https://support.n3health.ru/" target="__blank">
                <Button className={styles.buttonSubmit} htmlType="submit">
                  Написать в поддержку
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AmenitiesStubPage;
