import { SpecialityPostionsContext } from './ContentList';
import React, { useContext } from 'react';
import WidthFixedSelect from 'src/common/components/WidthFixedSelect';
import useSelectorSortFilter from 'src/common/hooks/useSelectorSortFilter';

type PropsType = {
  value?: string | null;
  onChange: (value: string, option: any) => void;
  placeholder?: string;
};

export const PositionSelect: React.FC<PropsType> = ({ value, onChange, placeholder }) => {
  const { options } = useContext(SpecialityPostionsContext);

  const { filterOptions, sortOptions } = useSelectorSortFilter();

  return (
    <WidthFixedSelect
      options={options}
      value={value}
      onChange={onChange}
      showSearch={true}
      filterSort={sortOptions}
      filterOption={filterOptions}
      placeholder={placeholder}
    ></WidthFixedSelect>
  );
};
