import { Collapse } from 'antd';
import { CollapseHeader } from './CollapseHeader';
import { DownCaretIcon } from 'src/common/Icons/icons';
import { ExpandedContent } from './ExpandedContent';
import { ListHeader } from './ListHeader';
import { NoItems } from './NoItems';
import { useAppSelector } from 'src/app/hooks';
import { useGetMilaPositionsQuery } from 'src/app/services/apiDictionary';
import { useGetSpecialitiesPositionsQuery } from 'src/app/services/api';
import React, { useMemo } from 'react';
import styles from './ContentList.module.scss';

type ContextType = {
  options: {
    label: string;
    value: string;
  }[];
  checkExistingPosition: (code: string) => {
    exists: boolean;
    specialityCode: string | null;
    specialityName: string | null;
  };
};

export const SpecialityPostionsContext = React.createContext<ContextType>({} as ContextType);

export const ContentList: React.FC = () => {
  const branchId = useAppSelector((state) => state.app.selectedBranchId!);

  const { data: specialities } = useGetSpecialitiesPositionsQuery(branchId, {
    skip: !branchId,
  });

  const { data: milaPositions } = useGetMilaPositionsQuery('');

  const options = useMemo(
    () => milaPositions?.map((position) => ({ label: position.name, value: position.code })) ?? [],
    [milaPositions],
  );

  const isEmpty = specialities?.length === 0;

  const collapseItems = useMemo(() => {
    if (!specialities) return [];

    const sortedSpecialities = [...specialities];

    sortedSpecialities.sort((a, b) => a.specialityName.localeCompare(b.specialityName));

    return (
      sortedSpecialities.map((speciality) => ({
        key: speciality.specialityFerId,
        label: <CollapseHeader speciality={speciality} />,
        children: <ExpandedContent speciality={speciality} />,
      })) ?? []
    );
  }, [specialities]);

  const checkExistingPosition: ContextType['checkExistingPosition'] = (code) => {
    let exists = false;
    let specialityCode: string | null = null;
    let specialityName: string | null = null;

    for (const speciality of specialities!) {
      const positionExists = speciality.positions.some((position) => position.positionFerId === code);

      if (positionExists) {
        exists = true;
        specialityCode = speciality.specialityFerId;
        specialityName = speciality.specialityName;

        break;
      }
    }

    return {
      exists,
      specialityCode,
      specialityName,
    };
  };

  if (isEmpty) return <NoItems />;

  return (
    <>
      <SpecialityPostionsContext.Provider value={{ options, checkExistingPosition }}>
        <ListHeader />
        <Collapse
          items={collapseItems}
          bordered={false}
          rootClassName={styles.root}
          expandIcon={(panelProps) =>
            panelProps.isActive ? <DownCaretIcon className={styles.rotate} /> : <DownCaretIcon />
          }
        />
      </SpecialityPostionsContext.Provider>
    </>
  );
};
