import {PracticeDoctorDocument, PracticeDoctorEducation} from "../../../../common/types";

export enum DoctorSnilsCheckStatus {
  IsNotChecked,
  CheckedAndNotExistsInDB,
  CheckedAndExistsInCurrentMedOrganization,
  CheckedAndNotExistsInCurrentMedOrganization,
}

export type DoctorSearchResultModel = {
  doctorId: string;
  doctorPhotoId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  documents?: PracticeDoctorDocument[];
  education?: PracticeDoctorEducation[];
  medOrganizations: {
    medOrganizationId: string;
    doctorSpecializations: string[];
    medOrganizationDoctorId: string;
  }[];
};
