import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';

export const apiAuthSlice = createApi({
  reducerPath: 'milaAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/auth`,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      headers.set('Authorization', `Bearer ${await oidcAuthService.getToken()}`);
      return headers;
    },
  }),

  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getAccessToPages: builder.query<any, string>({
      query: () => ({ url: `/accesses/access-to-pages` }),
    }),

    getUserAccesses: builder.query<any, string>({
      query: () => ({ url: `/accesses` }),
    }),
    getUserLoginType: builder.query<string, void>({
      query: () => ({
        url: `/accesses/auth-type`,
        responseHandler: 'text',
      }),
    }),
  }),
});

export const { useLazyGetUserLoginTypeQuery, useGetAccessToPagesQuery, useGetUserAccessesQuery } = apiAuthSlice;
