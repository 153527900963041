import NavSubMenu from "../NavSubMenu";
import styles from "./styles.module.scss";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Form, notification, Row, Select, Typography} from "antd";
import {useForm} from "antd/lib/form/Form";
import { ReactComponent as Warn } from '../../../assets/Warn.svg';
import {setBranch} from "../../../app/services/redux/appSlice";
import {DownCaretIcon} from "../../../common/Icons/icons";
import {useDispatch} from "react-redux";
import {Branch} from "../../../common/types";
import {getPageVisibility, getPreparedBranches, getPreparedSortedBranches} from "../../../common/utils/utils";
import {
    useGetBranchPaymentInfoQuery,
    useGetPracticeBranchesQuery, useUpdateBranchPaymentInfoMutation
} from "../../../app/services/api";
import {useAppSelector} from "../../../app/hooks";
import {AccessDeniedStub} from "../../../common/components/AccessDeniedStub/AccessDeniedStub";
import {CustomInput} from "../../../common/components/CustomInput/CustomInput";

const PaymentsPage = () => {
    const dispatch = useDispatch();
    const [form] = useForm();

    const [orgName, setOrgName] = useState('');
    const permissions = useAppSelector((state) => state.app.permissions) || [];
    const isPageVisible = getPageVisibility(permissions, 'can-access-page-branches');
    const stateBranch = useAppSelector((state) => state.app.selectedBranchId);
    const availableBranches = useAppSelector((state) => state.app.availableBranches);
    const { data: branchesList } = useGetPracticeBranchesQuery('');
    const { data: branchesPaymentInfo, refetch: refetchPaymentInfo, } = useGetBranchPaymentInfoQuery(orgName, {
        skip: !orgName,
    });
    const [updateBranchPaymentInfoMutation, paymentInfoRequest] = useUpdateBranchPaymentInfoMutation();

    const orderedBranches = useMemo(
        () => (branchesList ? getPreparedSortedBranches(branchesList, availableBranches ?? []) : []),
        [availableBranches, branchesList],
    );

    useEffect(() => {
        if (stateBranch) {
            setOrgName(stateBranch);
            form.setFieldValue('orgName', stateBranch);
        } else if (!orgName) {
            form.setFieldValue('orgName', orderedBranches[0]?.branchId);
            setOrgName(orderedBranches[0]?.branchId);
            dispatch(setBranch(orderedBranches[0]?.branchId));
        }
    }, [dispatch, form, orderedBranches, orgName, stateBranch]);

    useEffect(() => {
        if (branchesPaymentInfo) {
            form.setFieldValue('paymentLogin', branchesPaymentInfo?.paymentLogin);
            form.setFieldValue('paymentPassword', branchesPaymentInfo?.paymentPassword);
            form.setFieldValue('paymentToken', branchesPaymentInfo?.paymentToken);
        }
    }, [branchesPaymentInfo]);

    const prepareBranches = (branchesData: Branch[] | undefined) =>
        branchesData ? getPreparedBranches(branchesList || [], availableBranches ?? []) : [];
    
    const handleSubmitForm = (values: any) => {
        updateBranchPaymentInfoMutation({
            branchId: orgName,
            paymentLogin: values?.paymentLogin,
            paymentPassword: values?.paymentPassword,
            paymentToken: values?.paymentToken
        })
            .unwrap()
            .then(() => {
                refetchPaymentInfo();
                notification.success({ message: 'Эквайринг успешно обновлен' });
            })
            .catch((error) => notification.error({ message: error.data.detail || error.data.title }));
    };
    return <>
        <NavSubMenu />
        <div className={styles.pageContainer}>
            {isPageVisible ? (<Form
                form={form}
                layout="vertical"
                onFinish={handleSubmitForm}
                initialValues={{
                    orgName: stateBranch ? stateBranch : orderedBranches[0]?.branchId,
                }}
            >
                <Col span={8}>
                    <Form.Item name="orgName" className={styles.inputOrgName}>
                        <Select
                            options={prepareBranches(branchesList)}
                            onChange={(value) => dispatch(setBranch(value))}
                            suffixIcon={<DownCaretIcon />}
                        />
                    </Form.Item>
                </Col>
                <Col className={styles.blockContainer}>
                    <Col className={styles.blockTitle}>
                        <Typography.Title level={3}>Подключение эквайринга</Typography.Title>
                    </Col>
                    <Col className={styles.infoBlock}>
                        <div>
                            <Typography className={styles.infoNote}>
                                <Warn/>
                                Как подключить прием платежей
                            </Typography>
                            <div>
                                В Mila реализована интеграция с интернет-эквайрингом Альфа Банка для приема 
                                платежей пользователей (при этом денежные средства будут переведены на 
                                счет клиники в любом банке). Чтобы подключить интернет-эквайринг клинике 
                                необходимо подписать договор-оферту от Альфа Банка 
                                (предоставляется менеджером ЭлНетМед). После подписания клинике будут предоставлены 
                                реквизиты для доступа в Личный кабинет в Альфа Банке, где в настройках можно получить 
                                логин и пароль для API, платежный токен и указать их в форме ниже.
                            </div>
                        </div>
                    </Col>
                    <Typography className={styles.paymentSubtitle}>Введите данные из ЛК Альфа Банка:</Typography>
                    <Row className={styles.singleInputRowWrapper}>
                        <Col className={styles.singleInputRow}>
                            <Form.Item name="paymentLogin">
                                <CustomInput floatLabel="Логин для доступа по API в ЛК Альфа Банка" />
                            </Form.Item>
                        </Col>
                        <Col className={styles.singleInputRow}>
                            <Form.Item name="paymentPassword">
                                <CustomInput floatLabel="Пароль для доступа по API в ЛК Альфа Банка" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={styles.singleInputRowWrapper}>
                        <Col className={styles.singleInputRow}>
                            <Form.Item name="paymentToken">
                                <CustomInput floatLabel="Введите платежный токен из ЛК Альфа Банка" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col>
                        <Button
                            className={styles.buttonSubmit}
                            htmlType="submit"
                            loading={paymentInfoRequest.isLoading}
                        >
                            Сохранить
                        </Button>
                    </Col>
                </Col>
            </Form>) : (
                <AccessDeniedStub
                    title="Ошибка 403"
                    text="Доступ запрещен

          У вас недостаточно прав доступа. Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
                />
            )}
        </div>
    </>;
};

export default PaymentsPage;