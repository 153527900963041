import { Button, Col, Image, Row, Select, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CustomTable } from 'src/common/components/CustomTable/CustomTable';
import { DownCaretIcon } from 'src/common/Icons/icons';
import { ReactComponent as EyeInvisibleIcon } from '../../../common/Icons/EyeInvisibleIcon.svg';
import { ReactComponent as EyeVisibleIcon } from '../../../common/Icons/EyeVisibleIcon.svg';
import { ReactComponent as InfiniteIcon } from 'src/common/Icons/InfiniteIcon.svg';
import { ReactComponent as PlusButtonIcon } from 'src/common/Icons/PlusButtonIcon.svg';
import { PromotionListColumns, PromotionListItem } from 'src/common/types';
import {
  getDateLabel,
  getPreparedSortedBranches,
  getTimeLabel,
  prepareBranchesByPermissions,
} from 'src/common/utils/utils';
import { useAppSelector } from '../../../app/hooks';
import { useGetPracticeBranchesQuery, useGetPromotionsListQuery } from '../../../app/services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const renderUpdateDateLabel = (updateDate: string) => (
  <Col>
    <Typography>{getDateLabel(updateDate)}</Typography>
    <Typography>{getTimeLabel(updateDate)}</Typography>
  </Col>
);

const getLifeTimeLabelClass = (startDate: string, endDate: string, isInfinite: boolean) => {
  const currentDate = dayjs(new Date());

  if ((dayjs(startDate).isBefore(currentDate) && dayjs(endDate).isAfter(currentDate)) || isInfinite) {
    return styles.GreenCircle;
  } else if (dayjs(currentDate).isAfter(endDate)) {
    return styles.RedCircle;
  } else {
    return styles.GreyCircle;
  }
};

const renderPromotionLifeTime = (startDate: string, endDate: string, isInfinite: boolean) => (
  <Col className={styles.LifeTimeLabel}>
    <Col className={getLifeTimeLabelClass(startDate, endDate, isInfinite)}></Col>
    {isInfinite ? (
      <Col>
        <InfiniteIcon />
        <Typography>Без срока</Typography>
      </Col>
    ) : (
      <Col>
        <Typography>{getDateLabel(startDate)}</Typography>
        <Typography>{getDateLabel(endDate)}</Typography>
      </Col>
    )}
  </Col>
);

export const PromotionsListPage = () => {
  const navigate = useNavigate();

  const columns: ColumnsType<PromotionListColumns> = [
    {
      title: 'Баннер',
      dataIndex: 'banner',
      key: 'banner',
      width: 92,
    },
    {
      title: 'Дата обновления',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 130,
    },
    {
      title: 'Заголовок',
      dataIndex: 'header',
      key: 'header',
      width: 230,
      render: (promotion) => (
        <Typography.Link className={styles.LinkText} onClick={() => navigate(`promotionForm/${promotion.promotionId}`)}>
          {promotion.header}
        </Typography.Link>
      ),
    },
    {
      title: 'Период действия',
      dataIndex: 'timePeriod',
      key: 'timePeriod',
      width: 127,
    },
    {
      title: 'Включено филиалов',
      dataIndex: 'branchesCount',
      key: 'branchesCount',
      width: 119,
      className: styles.CenteredContent,
    },
    {
      title: 'Включено врачей',
      dataIndex: 'doctorsCount',
      key: 'doctorsCount',
      width: 117,
      className: styles.CenteredContent,
    },
    {
      title: 'Количество просмотров',
      dataIndex: 'viewsCount',
      key: 'viewsCount',
      width: 134,
      className: styles.CenteredContent,
    },
    {
      title: 'Отображение для пациентов',
      dataIndex: 'visibility',
      key: 'visibility',
      width: 151,
      className: styles.CenteredContent,
    },
  ];

  const [branchId, setBranchId] = useState('');
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const availableBranches = useAppSelector((state) => state.app.availableBranches) || [];
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const { data: promotionsList, refetch } = useGetPromotionsListQuery({
    sortType: 'updateDate',
    offset: pageOffset,
    count: pageCount,
    branchId: branchId,
  });

  const handleBranchSelector = (value: string) => {
    setBranchId(value);
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageCount(pageSize);
    setPageOffset((page - 1) * pageSize);
  };

  const orderedBranches = useMemo(
    () => (branchesList ? getPreparedSortedBranches(branchesList, availableBranches) : []),
    [branchesList],
  );

  const branchesOptions = useMemo(() => {
    const options = prepareBranchesByPermissions(branchesList!, availableBranches);
    return options?.length > 1 ? [{label:'Все филиалы', value: ''}, ...options] : options;
    }, [branchesList]);

  const handleAddNewAction = () => {
    navigate('/marketing/promotions/promotionsList/promotionForm');
  };

  const formattedPromotions = useMemo(
    () =>
      promotionsList
        ? promotionsList.data?.map((promotion: PromotionListItem) => ({
            key: promotion.promotionId,
            banner: (
              <Image
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/staticfiles/${promotion.backgroundImageStaticFileId}?inline=true`}
                alt=""
                className={styles.BannerWrapper}
                preview={false}
              />
            ),
            updatedAt: renderUpdateDateLabel(promotion.updateDate),
            header: { header: promotion.title, promotionId: promotion.promotionId },
            timePeriod: renderPromotionLifeTime(promotion.startDate, promotion.endDate, promotion.isTimeLimited),
            branchesCount: promotion.branchesCount,
            doctorsCount: promotion.doctorsCount,
            viewsCount: promotion.viewsCount,
            visibility: promotion.isVisibleForPatients ? <EyeVisibleIcon /> : <EyeInvisibleIcon />,
          }))
        : [],
    [promotionsList],
  );

  useEffect(() => {
    if (branchesOptions?.length === 1) {
      setBranchId(branchesOptions[0].value);
    }
  }, [branchesOptions]);

  useEffect(() => {
    if (searchParams.get('update')) {
      refetch();
      setSearchParams('');
    }
  }, []);

  return (
    <Col className={styles.PageContainer}>
      <Row className={styles.FiltersPanel}>
        <Col>
          <Button onClick={handleAddNewAction} className={styles.AddActionButton} icon={<PlusButtonIcon />} />
        </Col>
        <Col className={styles.InputOrgNameSelector}>
          <Select
            className={styles.FormInput}
            onChange={handleBranchSelector}
            value={branchId}
            suffixIcon={<DownCaretIcon />}
            options={branchesOptions}
          />
        </Col>
      </Row>
      <Row className={styles.PromotionsTable}>
        <CustomTable
          dataSource={formattedPromotions}
          columns={columns}
          total={promotionsList?.total ?? 0}
          onPageChange={handlePageChange}
          pageNumber={pageNumber}
        />
      </Row>
    </Col>
  );
};
