import { Props } from './Speciality.types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const useSpeciality = (props: Props) => {
  const { uid, setCanServeAdults, setCanServeChildren } = props;

  const handleCanServeAdultsOnChange = (e: CheckboxChangeEvent) => {
    setCanServeAdults(uid, e.target.checked);
  };

  const handleCanServeChildrenOnChange = (e: CheckboxChangeEvent) => {
    setCanServeChildren(uid, e.target.checked);
  };

  const handleOnChange = (specialityPositionId: string) => {
    props.handleOnChange(uid, specialityPositionId);
  };

  const handleDeleteSpecialityOnClick = () => {
    props.handleDeleteSpecialityOnClick(uid);
  };

  return {
    handleOnChange,
    handleCanServeAdultsOnChange,
    handleDeleteSpecialityOnClick,
    handleCanServeChildrenOnChange,
  };
};
