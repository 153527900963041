import { Checkbox, Popconfirm } from 'antd';
import { ReactComponent as CrossSignIcon } from '../../../../../../../../assets/CrossSignIcon.svg';
import { Props } from './Speciality.types';
import { useSpeciality } from './Speciality.hooks';
import React from 'react';
import WidthFixedSelect from '../../../../../../../../common/components/WidthFixedSelect';
import styles from './styles.module.scss';

export const Speciality = (props: Props) => {
  const {
    options,
    canServeChildren,
    canServeAdults,
    name,
    sortOptions,
    filterOptions,
    isDisplayingDeleteSpecialityIcon,
    isSelectDisabled,
  } = props;
  const {
    handleCanServeAdultsOnChange,
    handleCanServeChildrenOnChange,
    handleDeleteSpecialityOnClick,
    handleOnChange,
  } = useSpeciality(props);

  return (
    <div className={styles.container}>
      <WidthFixedSelect
        className={styles.select}
        options={options}
        showSearch={true}
        placeholder="Наименование и специализация врача"
        filterSort={sortOptions}
        filterOption={filterOptions}
        value={name.length === 0 ? undefined : name}
        onChange={handleOnChange}
        disabled={isSelectDisabled}
      />
      {isDisplayingDeleteSpecialityIcon && (
        <Popconfirm
          title="Вы уверены, что хотите удалить поле?"
          onConfirm={handleDeleteSpecialityOnClick}
          okText="Да"
          cancelText="Нет"
        >
          <div className={styles.crossSignIcon}>
            <CrossSignIcon className="dynamic-delete-button" />
          </div>
        </Popconfirm>
      )}
      <div className={styles.checkbox}>
        <Checkbox checked={canServeAdults} onChange={handleCanServeAdultsOnChange}>
          Взрослый
        </Checkbox>
      </div>
      <div className={styles.checkbox}>
        <Checkbox checked={canServeChildren} onChange={handleCanServeChildrenOnChange}>
          Детский
        </Checkbox>
      </div>
    </div>
  );
};
