import { Button, Col, Form, Input, Popconfirm, Select, Spin, Table, Tooltip, Typography, notification } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as CheckMarkButtonIcon } from '../../../../common/Icons/CheckMarkButtonIcon.svg';
import { ReactComponent as EditIcon } from '../../../../common/Icons/EditIcon.svg';
import { ReactComponent as EyeInvisibleIcon } from '../../../../common/Icons/EyeInvisibleIcon.svg';
import { ReactComponent as EyeVisibleIcon } from '../../../../common/Icons/EyeVisibleIcon.svg';
import { useForm } from 'antd/lib/form/Form';
import AmenitiesStubPage from './AmenitiesStubPage';
import NavSubMenu from '../../NavSubMenu';
import styles from './styles.module.scss';

import { ReactComponent as DownCaretIcon } from '../../../../common/Icons/DownCaretIcon.svg';
import {
  PracticeDoctorSpecializationService,
  PracticeDoctorSpecializationWithServices,
} from '../../../../common/types';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/QuestionMarkIcon.svg';
import { useAppSelector } from '../../../../app/hooks';
import {
  useGetDoctorGeneralInfoQuery,
  useGetDoctorProfileServicesQuery,
  useUpdateDoctorProfileServicesDataMutation,
} from '../../../../app/services/api';
import DoctorTabs from './../DoctorTabs';
import DoctorTitle from '../DoctorTitle';

function DoctorsAmenitiesPage() {
  const [form] = useForm();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const practiceDoctorId = useMemo(() => pathname.split('/').reverse()[1], [pathname]);
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);

  const [editCellId, setEditCellId] = useState('');
  const [editWidgetIconShown, setWidgetIconShown] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const columns = [
    {
      title: '',
      dataIndex: 'buttonRow',
      key: 'buttonRow',
      width: 40,
      className: styles.buttonCell,
    },
    {
      title: 'Наименование услуги',
      dataIndex: 'publicName',
      key: 'publicName',
      width: 186 + 162,
    },

    {
      title: 'Цена, ₽',
      dataIndex: 'price',
      key: 'price',
      width: 74,
    },
    {
      title: (
        <>
          Возраст пациента
          <Tooltip
            title={`Вы можете уточнить возраст пациентов, принимаемых специалистом, для каждой из услуг. По умолчанию если для соответствующей специализации у врача во вкладке "Общие данные" установлен тип "Детский" - всем услугам в рамках специализации присваивается возраст 0 - 17 лет, если тип "Взрослый" - 18 - 99 лет`}
            placement="right"
          >
            <QuestionMarkIcon />
          </Tooltip>
        </>
      ),
      dataIndex: 'age',
      key: 'age',
      width: 218,
      editable: true,
    },
    {
      title: 'В клинике',
      dataIndex: 'clinic',
      key: 'clinic',
      width: 98,
      className: styles.cellWithCheckbox,
    },
    {
      title: 'Онлайн',
      dataIndex: 'online',
      key: 'online',
      width: 77,
      className: styles.cellWithCheckbox,
    },
    {
      title: 'На дому',
      dataIndex: 'home',
      key: 'home',
      width: 81,
      className: styles.cellWithCheckbox,
    },
    {
      title: 'Отображение',
      dataIndex: 'widgetShown',
      key: 'widgetShown',
      width: 110,
      className: styles.cellWidgetVisibility,
    },
  ];

  const {
    data: doctorGeneralInfo,
    error: errorDoctorGeneralInfo,
    isLoading,
  } = useGetDoctorGeneralInfoQuery(
    { practiceId: practiceId!, practiceDoctorId },
    {
      skip: !practiceId,
    },
  );

  const { data: doctorServicesData, refetch: refetchDoctorServices } = useGetDoctorProfileServicesQuery(
    {
      practiceId: practiceId!,
      practiceDoctorId,
    },
    { skip: !practiceId },
  );

  const [updateDoctorProfileServicesData] = useUpdateDoctorProfileServicesDataMutation();

  const handleEditButton = (service: PracticeDoctorSpecializationService) => {
    setEditCellId(service.serviceId);
    setWidgetIconShown(service.showInWidget);

    form.setFieldsValue({
      ageFrom: service.patientAgeFrom,
      ageTo: service.patientAgeTo,
    });
  };

  const ageOptions = Array.from(Array(100).keys()).map((number) => {
    return { value: number, label: number };
  });

  const saveChanges = (practiceServiceId: string, practiceSpecializationId: string, medOrganizationId?: string) => {
    if (medOrganizationId) {
      updateDoctorProfileServicesData({
        practiceServiceId: practiceServiceId,
        practiceSpecializationId: practiceSpecializationId,
        medOrganizationId: medOrganizationId,
        practiceDoctorId: practiceDoctorId,
        serveAgeFrom: form.getFieldsValue().ageFrom,
        serveAgeTo: form.getFieldsValue().ageTo,
        showInWidget: editWidgetIconShown,
      })
        .unwrap()
        .then(() => {
          refetchDoctorServices();
          setEditCellId('');
        })
        .catch((error) => notification.error({ message: error.data.detail }));
    }
  };

  const handleIconChange = (iconState: boolean) => {
    setWidgetIconShown(!iconState);
  };

  useEffect(() => {
    const dataTable = doctorServicesData
      ? [...doctorServicesData]
          .sort((service1, service2) => service1.specialityMainMisName.localeCompare(service2.specialityMainMisName))
          .map((service: PracticeDoctorSpecializationWithServices, index: number) => {
            return {
              key: `doctor_services_${index}`,
              publicName: (
                <div className={styles.misNameCell}>
                  <p>
                    <span className={styles.serviceNameText}>{service.specialityMainMisName}</span>
                    {service.specialityMisNames?.length > 1 && (
                      <Tooltip
                        title={
                          <Col className={styles.misNameTooltipText}>
                            {service.specialityMisNames.map((v: string) => (
                              <Typography.Text key={`tooltip_${service.specialityMainMisName}_${v}`}>
                                {v}
                                <br />
                              </Typography.Text>
                            ))}
                          </Col>
                        }
                      >
                        <span>...</span>
                      </Tooltip>
                    )}
                    <span
                      className={styles.servicesNumberText}
                    >{`(услуг: ${service.specializationServicesCount})`}</span>
                  </p>
                </div>
              ),
              children: service.services?.map((subService: PracticeDoctorSpecializationService) => {
                const isReadMode = subService.serviceId !== editCellId;
                return {
                  key: subService.serviceId,
                  buttonRow: isReadMode ? (
                    <Button
                      className={styles.tableRowButton}
                      onClick={() => handleEditButton(subService)}
                      icon={<EditIcon />}
                    ></Button>
                  ) : (
                    <Popconfirm
                      title={`Сохранить внесенные изменения?`}
                      onConfirm={() => {
                        saveChanges(subService.serviceId, subService.specializationId, practiceId);
                      }}
                      onCancel={() => setEditCellId('')}
                      okText="Сохранить"
                      cancelText="Отмена"
                    >
                      <Button className={styles.tableRowSaveButton} icon={<CheckMarkButtonIcon />}></Button>
                    </Popconfirm>
                  ),
                  publicName: <span className={styles.expandableMisNameCell}>{subService.serviceNameForPatients}</span>,
                  price: <span>{subService.cost}</span>,
                  age: (
                    <div className={styles.ageCellWrapper}>
                      {isReadMode ? (
                        <>
                          <span>{`От ${subService.patientAgeFrom}`}</span>
                          <span>{`До ${subService.patientAgeTo}`}</span>
                        </>
                      ) : (
                        <div className={styles.ageCellFormWrapper}>
                          <p>От</p>
                          <Form.Item className={styles.ageSelector} name="ageFrom" rules={[{ required: true }]}>
                            <Select
                              style={{ width: '65px', height: '40px' }}
                              suffixIcon={<DownCaretIcon />}
                              options={ageOptions}
                            />
                          </Form.Item>
                          <p>До</p>
                          <Form.Item className={styles.ageSelector} name="ageTo">
                            <Select
                              style={{ width: '65px', height: '40px' }}
                              suffixIcon={<DownCaretIcon />}
                              options={ageOptions}
                            />
                          </Form.Item>
                        </div>
                      )}
                    </div>
                  ),
                  clinic: <Input type="radio" disabled defaultChecked={subService.isOnSite} />,
                  online: <Input type="radio" disabled defaultChecked={subService.isTmk} />,
                  home: <Input type="radio" disabled defaultChecked={subService.isAtHome} />,
                  widgetShown: isReadMode ? (
                    <Button
                      className={styles.visibilityButton}
                      disabled={isReadMode}
                      icon={subService.showInWidget ? <EyeVisibleIcon /> : <EyeInvisibleIcon />}
                    ></Button>
                  ) : (
                    <Button
                      className={styles.visibilityButton}
                      onClick={() => handleIconChange(editWidgetIconShown)}
                      icon={editWidgetIconShown ? <EyeVisibleIcon /> : <EyeInvisibleIcon />}
                    ></Button>
                  ),
                };
              }),
            };
          })
      : [];

    setDataSource(dataTable);
  }, [doctorServicesData, editCellId, editWidgetIconShown]);

  if (errorDoctorGeneralInfo) {
    navigate(`/company/doctors/${practiceId ?? ''}`);
  }

  return (
    <>
      <NavSubMenu />
      {isLoading ? (
        <Spin size="large" className={styles.pageLoader} />
      ) : (
        <div className={styles.pageContainer}>
          <DoctorTitle doctorGeneralInfo={doctorGeneralInfo} practiceId={practiceId} />
          <DoctorTabs doctorId={practiceDoctorId} />
          {doctorServicesData?.length ? (
            <div className={styles.pageContentContainer}>
              <Form form={form}>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  expandable={{
                    expandIcon: ({ record }) => {
                      return record.children?.length !== 0 ? <DownCaretIcon /> : null;
                    },
                    expandRowByClick: true,
                  }}
                  /*expandedRowRender={(record) => {
                  console.log(9, record.children);
                  return <Table columns={columns} dataSource={record.children} showHeader={false} pagination={false} />;
                }}*/
                />
              </Form>
            </div>
          ) : (
            <AmenitiesStubPage />
          )}
        </div>
      )}
    </>
  );
}

export default DoctorsAmenitiesPage;
