import { Button, Typography } from 'antd';
import { ReactComponent as CheckMarkIcon } from 'src/common/Icons/CheckMarkIcon.svg';
import { ReactComponent as GearWheelIcon } from 'src/common/Icons/GearWheelIcon.svg';
import { useNavigate } from 'react-router-dom';
import React, { MouseEvent } from 'react';
import styles from './styles.module.scss';

interface IServiceCardProps {
  title: string;
  description: string;
  imagePath?: string;
  serviceName: string;
  isActive?: boolean;
  hasSettings?: boolean;
}

function ServiceCard(props: IServiceCardProps) {
  const { title, description, imagePath, serviceName, isActive, hasSettings } = props;
  const navigate = useNavigate();

  const handleSettingsIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/services/${serviceName}/settings`);
  };

  return (
    <>
      <div className={styles.serviceCard} onClick={() => navigate(`/services/${serviceName}`)}>
        <div className={styles.cardTitle}>
          <Typography.Text>{title}</Typography.Text>
        </div>
        <div className={styles.cardDescription}>
          <Typography.Text className={`${serviceName === "schedule" ? styles.wideDescription : ''}`}>{description}</Typography.Text>
        </div>
        {isActive ? (
          <div className={styles.cardButtonWrapper}>
            <Button icon={<CheckMarkIcon />} className={styles.AssertButton}>
              Подключено
            </Button>

            {hasSettings && (
              <Button icon={<GearWheelIcon />} className={`${styles.iconButton}`} onClick={handleSettingsIconClick} />
            )}
          </div>
        ) : (
          <Button className={styles.ActivateButton}>Подключить</Button>
        )}

        <div className={styles.cardIcon}>
          <img src={imagePath} alt={`svg-logo-${title}`} />
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
