import { Branch } from 'src/common/types';
import { BranchFilter } from '../../common/components/BranchFilter/BranchFilter';
import { Col, Row, Typography } from 'antd';
import { getPreparedBranches } from '../../common/utils/utils';
import { setServiceBranch } from 'src/app/services/redux/appSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetPracticeBranchesQuery, useGetWhiteLabelDataQuery } from 'src/app/services/api';
import PatientsCabinetIcon from './Icons/PatientsCabinetIcon.svg';
import WhiteLabelIcon from './Icons/WhiteLabelIcon.svg';
import React, { ReactElement, useEffect, useMemo } from 'react';
import ServiceCard from './ServiceCard';
import TMKServiceIcon from './Icons/TMKServiceIcon.svg';
import WidgetServiceIcon from './Icons/WidgetServiceIcon.svg';
import ScheduleCabinetIcon from './Icons/ScheduleCabinetIcon.svg';
import styles from './styles.module.scss';

function ServicesPage() {
  const dispatch = useAppDispatch();
  const serviceBranch = useAppSelector((state) => state.app.serviceBranchId);
  const availableFiltersBranches = useAppSelector((state) => state.app.availableBranches) || [];

  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const { data: whiteLabelData } = useGetWhiteLabelDataQuery('');

  const handleBranchSelect = (value: string) => {
    dispatch(setServiceBranch(value));
  };

  useEffect(() => {
    if (!serviceBranch && branchesList && branchesList?.length > 0) {
      dispatch(setServiceBranch(getPreparedBranches(branchesList, availableFiltersBranches)?.[0]?.value));
    }
  }, [serviceBranch, branchesList]);

  const serviceCards = useMemo(() => {
    let tempCards: ReactElement[] = [];
    let selectedBranch: Branch | undefined;
    if (serviceBranch && branchesList) {
      selectedBranch = branchesList.find((branch) => branch.branchId === serviceBranch);
    }

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Виджет онлайн-записи'}
          description={'Легко подключите онлайн-запись на ваш сайт. Управляйте дизайном виджета.'}
          imagePath={WidgetServiceIcon}
          serviceName={'widget'}
          isActive={
            selectedBranch
              ? selectedBranch?.isWidgetServiceEnabled
              : branchesList?.some((branch) => branch.isWidgetServiceEnabled)
          }
          hasSettings
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Профиль клиники в Mila'}
          description={'Предоставьте пациентам доступ к их медкарте и сервисам от клиники\n \n'}
          imagePath={PatientsCabinetIcon}
          serviceName={'patient'}
          isActive={
            selectedBranch
              ? selectedBranch?.isShowInPatientAccountServiceEnabled
              : branchesList?.some((branch) => branch.isShowInPatientAccountServiceEnabled)
          }
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'White Label'}
          description={'Брендированная версия личного кабинета и виджета записи только для вас и ваших пациентов\n \n'}
          imagePath={WhiteLabelIcon}
          serviceName={'whiteLabel'}
          isActive={whiteLabelData?.isServiceEnabled}
          hasSettings
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Телемедицина в Mila'}
          description={'Расширяйте географию услуг и платите только за проведенные консультации'}
          imagePath={TMKServiceIcon}
          serviceName={'tmk'}
          isActive={
            selectedBranch
              ? selectedBranch?.isTmkServiceEnabled
              : branchesList?.some((branch) => branch.isTmkServiceEnabled)
          }
          hasSettings
        />
      </Col>,
    );

    tempCards.push(
      <Col>
        <ServiceCard
          title={'Расписание в Mila'}
          description={'Возможность вести расписание вручную внутри сервиса как альтернатива интеграции с МИС'}
          imagePath={ScheduleCabinetIcon}
          serviceName={'schedule'}
          isActive={
            selectedBranch
              ? selectedBranch?.isScheduleServiceEnabled
              : branchesList?.some((branch) => branch.isScheduleServiceEnabled)
          }
        />
      </Col>,
    );

    return tempCards;
  }, [branchesList, serviceBranch, whiteLabelData]);

  return (
    <>
      <div className={styles.pageContainer}>
        <Col className={styles.pageTitleWrapper}>
          <Typography.Text>Сервисы</Typography.Text>
        </Col>
        {serviceBranch && (
          <Row>
            <BranchFilter onChange={handleBranchSelect} value={serviceBranch} addAll={false} />
          </Row>
        )}
        <div className={styles.pageContent}>
          <Row gutter={[47, 38]} className={styles.cardsContainer}>
            {serviceCards}
          </Row>
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
