import { Button, Col, Form, Select, Spin, Typography } from 'antd';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { UserRole } from '../../common/types/enums';
import { apiSlice } from '../../app/services/api';
import { getBranchesClaims } from '../../common/utils/utils';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';
import {
  resetApp,
  setAvailableBranches,
  setMedOrganizationId,
  setNetwork,
  setPermissionsList,
} from '../../app/services/redux/appSlice';
import { uniqueId } from 'lodash';
import { useAppDispatch } from '../../app/hooks';
import { useAuth } from 'react-oidc-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetUserAccessesQuery, useLazyGetUserLoginTypeQuery } from '../../app/services/apiAuth';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

interface EntryOptionIdentifier {
  networkId?: string;
  medOrganizationId?: string;
}
interface UserAccesses extends EntryOptionIdentifier {
  practiceName: string;
  role: string;
  claims: [{ name: string; value: string }];
}

interface UserAccessesPractice {
  claims: { name: string; value: string }[];
  medOrganizationId: null | string;
  networkId: string;
  practiceName: string;
  role: string;
  roleId: number;
}

function NetworkSelection(props: { isAmoCrmLoginEnabled: boolean; amoCrmLink: string }) {
  const { isAuthenticated, isLoading } = useAuth();
  const authService = oidcAuthService;
  const navigate = useNavigate();

  const { data: userAccesses, isLoading: userAccessLoading } = useGetUserAccessesQuery('');
  const [isAmoCrmLoginEnabled] = useState<boolean>(props.isAmoCrmLoginEnabled);
  const [amoCrmLink] = useState<string>(props.amoCrmLink);
  const [getAuthType] = useLazyGetUserLoginTypeQuery();

  const wrappedData = useMemo(
    () =>
      userAccesses
        ? userAccesses
            .filter(({ roleId }: UserAccessesPractice) => roleId !== UserRole['Доктор'])
            .map((item: UserAccesses) => ({
              id: uniqueId('access_key_'),
              data: item,
            }))
        : [],
    [userAccesses],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && wrappedData.length === 1) {
      onFinish(userAccesses[0]);
      return;
    }
  }, [isAuthenticated, isLoading, userAccesses?.length]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated && isAmoCrmLoginEnabled && amoCrmLink && userAccesses && userAccesses?.length < 1) {
        await getAuthType().then((s) => {
          if (s.data === 'amo-crm') {
            window.location.href = amoCrmLink;
          }
        });
      }
    })();
  }, [userAccesses, isAuthenticated, isAmoCrmLoginEnabled, amoCrmLink]);

  const onFinish = (v: any) => {
    var item = wrappedData.find((x: any) => x.id === v.selectedOption);

    if (item) {
      dispatch(setNetwork(item.data.networkId));
      dispatch(setMedOrganizationId(item.data.medOrganizationId));
      dispatch(setPermissionsList(item.data.claims));
      dispatch(setAvailableBranches(getBranchesClaims(item.data.claims)));
      dispatch(apiSlice.util.resetApiState());
      navigate('/');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const prepareOptionsList = useMemo(
    () => wrappedData.sort((a: any, b: any) => a?.data?.practiceName.localeCompare(b?.data?.practiceName)).map((item: any) => ({ label: item.data.practiceName, value: item.id })),
    [wrappedData],
  );

  const handleLogout = async () => {
    dispatch(resetApp());
    dispatch(apiSlice.util.resetApiState());
    await authService.logout();
  };

  return (
    <div className={styles.authFormWrapper}>
      <div className={styles.formNetworkContainer}>
        <Col className={styles.logo}>
          <MilaIcon />
        </Col>
        {(isLoading || userAccessLoading) && <Spin size="large" />}
        {!wrappedData.length && !userAccessLoading ? (
          <>
            <Col className={styles.formTitle}>
              <Typography.Text>Извините, у Вас нет прав доступа</Typography.Text>
            </Col>
            <Col className={styles.inputrapper}>
              <Button onClick={handleLogout} className={styles.loginButton}>
                Ок
              </Button>
            </Col>
          </>
        ) : (
          <>
            <Col className={styles.formTitle}>
              <Typography.Text>Выберите куда подключиться</Typography.Text>
            </Col>
            <Col className={styles.inputrapper}>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="selectedOption"
                  rules={[{ required: true, message: 'Пожалуйста выберите куда подключиться!' }]}
                >
                  <Select
                    disabled={!userAccesses?.length}
                    className={styles.SelectNetwork}
                    placeholder={'Выберите куда подключиться'}
                    options={prepareOptionsList}
                    showSearch={true}
                    filterOption={(inputValue, option) => {
                      if (!option || !option.label || !option.value) return false;

                      return option.label?.toString().toUpperCase().includes(inputValue.toUpperCase());
                    }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" className={styles.loginButton}>
                    Вход
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </>
        )}
      </div>
    </div>
  );
}

export default NetworkSelection;
