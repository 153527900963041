import { CustomButton, Header, SupportBlock } from '../../Components';
import { Props } from './DoctorIsInDBBlock.types';
import React from 'react';
import styles from './styles.module.scss';

export const DoctorIsInDBBlock = (props: Props) => {
  const { doctorFullName, doctorSpecializations, doctorImageSrc, headerText, buttonText, buttonOnClick } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header text={headerText} />
      </div>

      <div className={styles.dividerWrapper}>
        <div className={styles.divider}></div>
      </div>

      <div className={styles.profile}>
        <img className={styles.profile__photo} src={doctorImageSrc} alt="" height={58} width={58} />
        <div className={styles.profile__name}>{doctorFullName}</div>
        <div className={styles.profile__specialization}>
          {doctorSpecializations.map((specialization, index) => (
            <div key={`${specialization}_${index}`}>{specialization}</div>
          ))}
        </div>
        <div className={styles.profile__button}>
          <CustomButton buttonText={buttonText} buttonOnClick={buttonOnClick} />
        </div>
      </div>

      <div className={styles.divider}></div>

      <div className={styles.supportBlock}>
        <SupportBlock />
      </div>
    </div>
  );
};
