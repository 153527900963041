/**
 * Возвращает вариант слова в правильном склоненении в зависимости от количества
 * @param count количество сущностей, обозначаемых словом
 * @param variants массив вариантов. Порядок: ["услуга", "услуги", "услуг"]
 */
const getWordDeclension = (count: number, variants: [string, string, string]) => {
  const value = Math.abs(count) % 100;
  const num = value % 10;

  if (value > 10 && value < 20) return variants[2];
  if (num > 1 && num < 5) return variants[1];
  if (num === 1) return variants[0];
  return variants[2];
};

export default getWordDeclension;
