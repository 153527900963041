const SubwayLineDot = ({
    color
}: { color: string}) => {    
    return <span style={{ 
        height: '10px',
        width: '10px', 
        borderRadius: '50%', 
        backgroundColor: `#${color}`, 
        display: 'inline-block'
    }}></span>;
};

export default SubwayLineDot;
