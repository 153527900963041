import { ReactComponent as CheckMarkIcon } from 'src/common/Icons/CheckMarkIcon.svg';
import { Col, Row, Typography } from 'antd';
import ActionsMarketingIcon from './Icons/ActionsMarketingIcon.svg';
import MarketingCard from '../../common/components/MarketingCard/MarketingCard';
import MessengersMarketingIcon from './Icons/MessengersMarketingIcon.svg';
import PromoBlockMarketingIcon from './Icons/PromoBlockMarketingIcon.svg';
import PromoCodesGenMarketingIcon from './Icons/PromoCodesGenMarketingIcon.svg';
import React from 'react';
import YandexDoctorsMarketingIcon from './Icons/YandexDoctorsMarketingIcon.svg';
import YandexMapsMarketingIcon from './Icons/YandexMapsMarketingIcon.svg';

import { IMarketingCardProps } from 'src/common/types';
import styles from './styles.module.scss';

const marketingCardsAttributes = [
  {
    title: 'Акции клиники',
    description: 'Расскажите о своих предложениях пациентам в Mila',
    imagePath: ActionsMarketingIcon,
    linkPath: '/marketing/promotions/promotionsList',
    buttonIcon: <CheckMarkIcon />,
    buttonText: 'Подключено',
    isPrimaryButtonDisabled: false,
  },
  {
    title: 'Промоблок в виджете записи',
    description: 'Разместите предложение в виджете записи на вашем сайте, его там гарантированно заметят',
    imagePath: PromoBlockMarketingIcon,
    linkPath: '/marketing/promotions/widgetPromoBlock',
    buttonIcon: <CheckMarkIcon />,
    buttonText: 'Подключено',
    isPrimaryButtonDisabled: false,
  },
  {
    title: 'Яндекс.Карты',
    description: 'Привлекайте тех, кто ищет медицинские услуги в Яндекс.Картах',
    imagePath: YandexMapsMarketingIcon,
    linkPath: '/marketing/promotions/yandexMaps',
    buttonIcon: <></>,
    buttonText: 'В разработке',
    isPrimaryButtonDisabled: true,
  },
  {
    title: 'Генератор промокодов',
    description: 'Создавайте свои промокоды. С ними \nваши рассылки будут гораздо эффективнее',
    imagePath: PromoCodesGenMarketingIcon,
    linkPath: '/marketing/promotions/promocodesGenerator',
    buttonIcon: <></>,
    buttonText: 'В разработке',
    isPrimaryButtonDisabled: true,
  },
  {
    title: 'Врачи в Яндекс.Поиске',
    description: 'Разместите карточки ваших врачей прямо в результатах поиска на Яндексе',
    imagePath: YandexDoctorsMarketingIcon,
    linkPath: '/marketing/promotions/yandexDoctors',
    buttonIcon: <></>,
    buttonText: 'В разработке',
    isPrimaryButtonDisabled: true,
  },
  {
    title: 'Рассылки в мессенджеры',
    description: 'SMS уже не читают, попробуйте массовые рассылки в WhatsApp',
    imagePath: MessengersMarketingIcon,
    linkPath: '/marketing/promotions/messengers',
    buttonIcon: <></>,
    buttonText: 'В разработке',
    isPrimaryButtonDisabled: true,
  },
];

function CompanyPage() {
  return (
    <>
      <Col className={styles.PageContainer}>
        <Col className={styles.PageTitleWrapper}>
          <Typography.Text>Маркетинг</Typography.Text>
        </Col>
        <Row gutter={[47, 38]} className={styles.CardsContainer}>
          {marketingCardsAttributes.map((cardData: IMarketingCardProps, index: number) => (
            <Col key={`marketing-card-${index}`}>
              <MarketingCard
                title={cardData.title}
                description={cardData.description}
                imagePath={cardData.imagePath}
                linkPath={cardData.linkPath}
                buttonIcon={cardData.buttonIcon}
                buttonText={cardData.buttonText}
                buttonIcon2={cardData.buttonIcon2}
                isPrimaryButtonDisabled={cardData.isPrimaryButtonDisabled}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </>
  );
}

export default CompanyPage;
