import { IDataManager, IEventManager, IOptionManager } from 'yandex-maps';
import { Input } from 'antd';
import { useYMaps } from '@pbe/react-yandex-maps';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './styles.module.scss';

export type AddressType = {
  address: string;
  latitude: string;
  longitude: string;
  state: string;
  region: string;
  locality: string;
  street: string;
  building: string;
};

interface AddressInputProps {
  val?: string;
  handleChange?: (e: AddressType) => void;
}

type SuggestViewType = {
  events: IEventManager;
  options: IOptionManager;
  state: IDataManager;
};

export const AddressInput = ({ val = '', handleChange }: AddressInputProps) => {
  const ymaps = useYMaps(['SuggestView', 'geocode']);
  const [inputValue, setInputValue] = useState('');
  const [suggestView, setSuggestView] = useState<SuggestViewType | null>(null);

  useEffect(() => {
    setInputValue(val);
  }, [val]);

  useEffect(() => {
    //@ts-ignore
    if (ymaps && !suggestView) setSuggestView(new ymaps.SuggestView('suggest'));
  }, [suggestView, ymaps]);

  useEffect(() => {
    if (suggestView) {
      suggestView.events.add('select', function (e) {
        ymaps?.geocode(e.get('item').value).then((res) => {
          const geoObject = res.geoObjects?.get(0);
          if (geoObject) {
            // @ts-ignore
            const coords = geoObject.geometry?.getCoordinates();
            setInputValue(e.get('item').value);
            handleChange?.({
              address: e.get('item').value,
              latitude: coords[0],
              longitude: coords[1],
              // @ts-ignore
              state: geoObject.getCountry() || null,
              // @ts-ignore
              region: geoObject.getAdministrativeAreas().join(', ') || null,
              // @ts-ignore
              locality: geoObject.getLocalities().join(', ') || null,
              // @ts-ignore
              street: geoObject.getThoroughfare() || null,
              // @ts-ignore
              building: geoObject.getPremiseNumber() || null,
            });
          }
        });
      });
    }
  }, [handleChange, suggestView, ymaps]);

  const handleUserInput = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);

  return <Input value={inputValue} id="suggest" className={styles.Input} onChange={handleUserInput} />;
};
