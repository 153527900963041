import { Collapse } from 'antd';
import { CollapseHeader } from './CollapseHeader';
import { DownCaretIcon } from 'src/common/Icons/icons';
import { NoItems } from './NoItems';
import { RowsList } from './rows/RowsList';
import { TableHeader } from './TableHeader';
import { useAppSelector } from 'src/app/hooks';
import { useGetPracticeBranchesQuery, useGetPracticeServicesQuery } from 'src/app/services/api';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './ContentTable.module.scss';
import { useGetUseTmData } from '../../helpers';

export const ContentTable: React.FC = () => {
  const branchId = useAppSelector((state) => state.app.selectedBranchId);
  const [noItemsShow, setNoItemsShow] = useState<boolean>(true);

  const { data: specialities, isFetching } = useGetPracticeServicesQuery(branchId!, {
    skip: !branchId,
  });
  const { data: branchesList } = useGetPracticeBranchesQuery('');
  const practiceId = useAppSelector((state) => state.app.selectedBranchId);

  const useTmData = useGetUseTmData(branchesList, practiceId);

  const isEmpty = specialities?.length === 0;

  useEffect(() => {
    const show = !isFetching && isEmpty && !useTmData;

    setNoItemsShow(show);
  }, [isEmpty, setNoItemsShow, isFetching, useTmData]);

  const collapseItems = useMemo(() => {
    if (!specialities) return [];

    const sortedSpecialities = [...specialities];

    sortedSpecialities.sort((a, b) => a.specialityName.localeCompare(b.specialityName));

    return (
      sortedSpecialities.map((spec, index) => ({
        key: spec.services[0]?.specialityId ?? index,
        label: <CollapseHeader speciality={spec} />,
        children: <RowsList speciality={spec} />,
      })) ?? []
    );
  }, [specialities]);

  if (noItemsShow)
    return (
      <div className={styles.container}>
        <NoItems show={noItemsShow} onBegin={() => setNoItemsShow(false)} />
      </div>
    );

  return (
    <div className={styles.container}>
      <TableHeader />
      <Collapse
        items={collapseItems}
        bordered={false}
        rootClassName={styles.collapseRoot}
        expandIcon={(panelProps) =>
          panelProps.isActive ? <DownCaretIcon className={styles.rotate} /> : <DownCaretIcon />
        }
      />
    </div>
  );
};
