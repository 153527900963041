import { Image, Layout, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useAddDoctorStub } from './AddDoctorStub.hooks';
import DoctorsCardIcon from '../../CardsIcons/DoctorsCardIcon.png';
import React from 'react';
import styles from './styles.module.scss';

export const AddDoctorStub = () => {
  const { addDoctorLink } = useAddDoctorStub();

  return (
    <Layout className={styles.StubContainer}>
      <Row className={styles.iconContainer}>
        <Image src={DoctorsCardIcon} alt="DoctorsCardIcon" preview={false} />
      </Row>
      <Row>
        <Typography className={styles.StubTitle}>Добавьте врачей и специалистов</Typography>
      </Row>
      <Row>
        <Typography className={styles.StubDescription}>
          Пожалуйста, добавьте врачей, которые ведут прием в вашей клинике. Профиль врача единый и отображается для
          пациентов во всех подключенных сервисах Милы. Врач может вести прием в нескольких филиалах, при этом перечень
          услуг отдельный для каждого филиала. Врач уже может быть в нашей базе, в том случае вы можете добавить его в
          свою клинику и получить право на совместное редактирование профиля.
          <br />
          <br />
          Для специалистов, которые будут вести онлайн-консультации, необходимо открыть доступ в кабинет врача от вашей
          клиники.
        </Typography>
      </Row>
      <Link className={styles.ButtonSubmit} to={addDoctorLink}>
        Добавить врача или специалиста
      </Link>
    </Layout>
  );
};
