import { Modal, Typography } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

interface ConfirmEditModalProps {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export const ConfirmEditModal = ({ isOpen, handleOk, handleCancel }: ConfirmEditModalProps) => {
  return (
    <Modal
      className={styles.ConfirmEditModal}
      title={<Typography className={styles.ModalTitle}>Сохранить внесенные изменения?</Typography>}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Отмена"
      okText="Сохранить"
    ></Modal>
  );
};
