import { CalendarIcon } from 'src/common/Icons/icons';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import React, { memo } from 'react';
import styles from './styles.module.scss';

interface DatePickerProps {
  onChange?: (val: Dayjs | null) => void;
  className?: string;
  placeholder?: string;
  value?: Dayjs;
  disabled?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
}

export const CustomDatePicker = memo(
  ({ onChange, className, placeholder, value, disabled, disabledDate }: DatePickerProps) => {
    return (
      <DatePicker
        placeholder={placeholder}
        onChange={onChange}
        className={`${styles.DatePicker} ${className ? className : ''}`}
        suffixIcon={<CalendarIcon />}
        value={value}
        disabled={disabled}
        allowClear={false}
        disabledDate={disabledDate}
        format={'DD.MM.YYYY'}
      />
    );
  },
);
