import { Typography } from 'antd';
import { useHeader } from './Header.hooks';
import React from 'react';
import styles from './styles.module.scss';

export const Header = () => {
  const { selectedBranchName } = useHeader();

  return (
    <div className={styles.container}>
      <Typography.Text>Добавить врача или специалиста в {selectedBranchName}</Typography.Text>
    </div>
  );
};
