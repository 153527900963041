import { QuestionMarkIcon } from 'src/common/Icons/icons';
import { Tooltip } from 'antd';
import React from 'react';
import styles from './ListHeader.module.scss';

export const ListHeader: React.FC = () => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Наименования врачей для пациентов</span>
      <Tooltip
        title="Для формирования единой структуры каталога врачей и специалистов в Mila мы используем Федеральный справочник специальностей и свой справочник синонимов к ним. Но вы можете заменить предложенный нами вариант наименования для пациентов: например, вместо “Хирург” указать  “Абдоминальный хирург” и “Лазерный хирург”. Одному врачу в рамках специальности по Федеральному справочнику может быть добавлено несколько наименований для пациентов."
        placement="right"
      >
        <QuestionMarkIcon />
      </Tooltip>
    </div>
  );
};
