import { Button, Col, Image, Layout, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import AccessesCardIcon from 'src/features/CompanyPage/CardsIcons/AccessesCardIcon.png';
import React from 'react';
import styles from './styles.module.scss';

interface AccessesStubProps {
  title?: string;
  text?: string;
}

export const AccessesStub = ({ title, text }: AccessesStubProps) => {
  return (
    <Layout className={styles.StubContainer}>
      <Row className={styles.iconContainer}>
        <Image src={AccessesCardIcon} alt="AccessesStubIcon" preview={false} />
      </Row>
      <Row>
        <Typography className={styles.StubTitle}>{title}</Typography>
      </Row>
      <Row>
        <Typography className={styles.StubDescription}>{text}</Typography>
      </Row>
      <Row className={styles.FormButtonsContainer} gutter={[10, 10]}>
        <Col>
          <Link to="https://support.n3health.ru/" target="__blank">
            <Button className={styles.ButtonCancel} htmlType="reset">
              Написать в поддержку
            </Button>
          </Link>
        </Col>
        <Col>
          <Link className={styles.GoToMainButton} to="/">
            Вернуться на главную
          </Link>
        </Col>
        <Col>
          <Link className={styles.ButtonSubmit} to="/company/accesses/userAccesses">
            Добавить пользователя
          </Link>
        </Col>
      </Row>
    </Layout>
  );
};
