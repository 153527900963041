import { GetOrganizationInfoBySpecialityPositionId } from './DoctorsInfoPage.types';
import {
  NormalizedSpecialitiesForCreating,
  NormalizedSpecialitiesForEditing,
  PracticeSpecialityWithPositionsModel,
  SelectedSpeciality,
} from './components/Specialities/Specialities.types';

export const getSizeRepresentation = (size: number): string => {
  let rsize = size;
  if (rsize < 1024) {
    return `${rsize} B`;
  }
  rsize = Math.round(rsize / 1024);
  if (rsize < 1024) {
    return `${rsize} kB`;
  }
  rsize = Math.round(rsize / 1024);
  return `${rsize} mB`;
};

export const normalizeSpecializationsForEditing = (
  specialitiesWithPosition: PracticeSpecialityWithPositionsModel,
  specialities: SelectedSpeciality[],
): NormalizedSpecialitiesForEditing[] => {
  const normalizedSpecialities: NormalizedSpecialitiesForEditing[] = [];

  specialities
    .filter((sp) => sp.specialityPositionId)
    .forEach((speciality) => {
      specialitiesWithPosition.forEach((item) => {
        item.positions.forEach((position) => {
          if (speciality.specialityPositionId === position.specialityPositionId) {
            normalizedSpecialities.push({
              canServeAdults: speciality.canServeAdults,
              canServeChildren: speciality.canServeChildren,
              doctorSpecializationId: speciality.specializationId,
              doctorSpecializationName: item.specialityName,
              doctorSpecializationPositionId: position.specialityPositionId,
              doctorSpecializationPositionName: position.positionName,
            });
          }
        });
      });
    });

  return normalizedSpecialities;
};

export const normalizeSpecializationsForCreating = (
  specialitiesWithPosition: PracticeSpecialityWithPositionsModel,
  specialities: SelectedSpeciality[],
): NormalizedSpecialitiesForCreating[] => {
  const normalizedSpecialities: NormalizedSpecialitiesForCreating[] = [];

  specialities
    .filter((sp) => sp.specialityPositionId)
    .forEach((speciality) => {
      specialitiesWithPosition.forEach((item) => {
        item.positions.forEach((position) => {
          if (speciality.specialityPositionId === position.specialityPositionId) {
            normalizedSpecialities.push({
              canServeAdults: speciality.canServeAdults,
              canServeChildren: speciality.canServeChildren,
              medOrganizationSpecialityId: position.specialityId,
              medOrganizationSpecialityPositionId: speciality.specialityPositionId,
            });
          }
        });
      });
    });

  return normalizedSpecialities;
};

export const getSpecialityFullName = (
  positionName: string,
  specialityName: string,
  specialityFerId: string,
): string => {
  return `${positionName} (специализация: ${specialityName}, код ${specialityFerId})`;
};

export const getOrganizationInfoBySpecialityPositionId = (
  specialitiesWithPosition: PracticeSpecialityWithPositionsModel,
  specialityPositionId: string,
): GetOrganizationInfoBySpecialityPositionId | undefined => {
  for (let i = 0; i < specialitiesWithPosition.length; i++) {
    for (let j = 0; j < specialitiesWithPosition[i].positions.length; j++) {
      if (specialitiesWithPosition[i].positions[j].specialityPositionId === specialityPositionId) {
        return {
          positionName: specialitiesWithPosition[i].positions[j].positionName,
          specialityFerId: specialitiesWithPosition[i].specialityFerId,
          specialityName: specialitiesWithPosition[i].specialityName,
        };
      }
    }
  }

  return undefined;
};

export const createEmptySpeciality = (): SelectedSpeciality => {
  return {
    uid: Math.random().toString(),
    specialityPositionId: undefined,
    specializationId: undefined,
    specialityFullName: '',
    canServeChildren: false,
    canServeAdults: false,
  };
};

export const checkAtLeastOneSpecialityIsFilled = (specialities: SelectedSpeciality[]): boolean => {
  for (let i = 0; i < specialities.length; i++) {
    if (specialities[i].specialityFullName && (specialities[i].canServeAdults || specialities[i].canServeChildren)) {
      return true;
    }
  }

  return false;
};

export const checkAllFilledSpecialityAreChecked = (specialities: SelectedSpeciality[]): boolean => {
  for (let i = 0; i < specialities.length; i++) {
    if (specialities[i].specialityFullName) {
      if (!specialities[i].canServeAdults && !specialities[i].canServeChildren) {
        return false;
      }
    }
  }

  return true;
};

export const getSpecialityCodeFromLabel = (label: string): string | null => {
  const regex = /код (\d+)/;
  const match = label.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
};
