import { Layout } from 'antd';
import { NavTabs } from '../../../common/components/NavTabs/NavTabs';
import { Outlet, useParams } from 'react-router';
import React from 'react';
import styles from './styles.module.scss';

const tabsItems = [
  {
    label: 'Акции клиники',
    key: 'promotionsList',
  },
  {
    label: 'Промоблок в виджете записи',
    key: 'widgetPromoBlock',
  },
  {
    label: 'Яндекс.Карты',
    key: 'yandexMaps',
  },
  {
    label: 'Генератор промокодов',
    key: 'promocodesGenerator',
  },
  {
    label: 'Врачи в Яндекс.Поиске',
    key: 'yandexDoctors',
  },
  {
    label: 'Рассылки в мессенджеры',
    key: 'messengers',
  },
];

export const PromotionsPage = () => {
  const { promotionTab } = useParams();
  return (
    <Layout className={styles.PromotionsPage}>
      <NavTabs activeTabKey={promotionTab} items={tabsItems} basicPath="marketing/promotions" />
      <Outlet />
    </Layout>
  );
};
