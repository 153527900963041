import { Button, Col, Image, Row, Typography } from 'antd';
import { IMarketingCardProps } from 'src/common/types';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

function CompanyCard(props: IMarketingCardProps) {
  const {
    title,
    description,
    imagePath,
    linkPath,
    buttonIcon,
    buttonText,
    buttonText2,
    buttonIcon2,
    isPrimaryButtonDisabled,
  } = props;

  const navigate = useNavigate();

  const handlePrimaryBtnClick = (e: React.MouseEvent) => {
    // to be implemented later
    e.stopPropagation();
  };

  const handleSettingsIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate('/');
  };

  return (
    <>
      <Col className={styles.MarketingCard} onClick={() => navigate(linkPath)}>
        <Row className={styles.CardTitle}>
          <Typography.Text>{title}</Typography.Text>
        </Row>
        <Row className={styles.CardDescription}>
          <Typography.Text>{description}</Typography.Text>
        </Row>
        <div className={styles.CardButtonWrapper}>
          <Button
            icon={buttonIcon}
            disabled={isPrimaryButtonDisabled}
            className={styles.PrimaryButton}
            onClick={handlePrimaryBtnClick}
          >
            {buttonText}
          </Button>
          {(buttonText2 || buttonIcon2) && (
            <Button icon={buttonIcon2} className={styles.IconButton} onClick={handleSettingsIconClick}>
              {buttonText2}
            </Button>
          )}
        </div>
        <Row className={styles.CardIcon}>
          <Image src={imagePath} alt={`svg-logo-${title}`} preview={false} />
        </Row>
      </Col>
    </>
  );
}

export default CompanyCard;
