import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppState } from "./redux/appSlice";
import { oidcAuthService } from "./auth/oidcAuthService";

const metroApiBaseUrl = "https://api.hh.ru/";

interface MainState {
    app: AppState;
}

export const metroApiSlice = createApi({
    reducerPath: "metroApi",
    baseQuery: fetchBaseQuery({
        baseUrl: metroApiBaseUrl,
    }),
    endpoints: (builder) => ({
        getMetroData: builder.query<any[], string>({
          query: () => ({
            url: `/metro`,
          }),
        }),
    }),
});

export const {
    useGetMetroDataQuery,
} = metroApiSlice;
