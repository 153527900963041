import { EditForm } from '../forms/EditForm';
import { PracticeService } from 'src/common/types';
import { ViewAmenityRow } from './ViewAmenityRow';
import { useAppSelector } from 'src/app/hooks';
import React from 'react';

type PropsType = {
  service: PracticeService;
};

export const RowController: React.FC<PropsType> = ({ service }) => {
  const editId = useAppSelector((state) => state.amenities.editServiceId);

  if (service.serviceId === editId)
    return (
      <>
        <ViewAmenityRow service={service} />
        <EditForm service={service} />
      </>
    );

  return <ViewAmenityRow service={service} />;
};
