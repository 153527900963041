import { Col, Image, Layout, Row, Statistic, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { StatisticBlockProps } from 'src/common/types';
import React, { memo } from 'react';
import styles from './styles.module.scss';

export const StatisticBlock = memo(({ data, title, icon, link, minimized = false }: StatisticBlockProps) => {
  return (
    <Layout className={styles.StatisticBlock}>
      <Row className={styles.TopRow}>
        <Col>
          <Row className={styles.SubRow}>
            <Col className={styles.IconWrapper}>
              <Image src={icon} preview={false} />
            </Col>
            <Col>
              <Typography className={styles.Title}>{title}</Typography>
            </Col>
          </Row>
        </Col>
        <Col>
          <Link className={styles.Button} to={link}>
            Перейти
          </Link>
        </Col>
      </Row>
      <Row className={styles.BottomRow}>
        {data.map((dataEntry) => (
          <Col key={dataEntry.label} className={`${styles.StatisticWrapper} ${minimized ? styles.Minimized : ''}`}>
            <Statistic
              className={styles.Statistic}
              title={dataEntry.label}
              value={dataEntry.value}
              formatter={(val) => (
                <Typography
                  className={`${styles.StatisticValue} ${
                    dataEntry.highlight && dataEntry.value > 0 && styles.RedHighlight
                  }`}
                >
                  {val}
                </Typography>
              )}
            />
          </Col>
        ))}
      </Row>
    </Layout>
  );
});
