import { Col, Row, Select } from 'antd';
import { DownCaretIcon } from 'src/common/Icons/icons';
import { getPreparedBranches } from 'src/common/utils/utils';
import { useAppSelector } from 'src/app/hooks';
import { useGetPracticeBranchesQuery } from 'src/app/services/api';
import React, { memo, useMemo } from 'react';
import styles from './styles.module.scss';

interface BranchFilterProps {
  value: string;
  onChange: (val: string) => void;
  addAll?: boolean;
}

export const BranchFilter = memo(({ value, onChange, addAll = true }: BranchFilterProps) => {
  const { data: branchesList } = useGetPracticeBranchesQuery('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableFiltersBranches = useAppSelector((state) => state.app.availableBranches) || [];

  const branchOptions = useMemo(() => {
    const availableBranches = branchesList ? getPreparedBranches(branchesList, availableFiltersBranches) : [];
    if (addAll) availableBranches.unshift({ label: 'Все филиалы', value: '' });
    return availableBranches;
  }, [availableFiltersBranches, branchesList]);

  return (
    <Row className={styles.FilterRow}>
      <Col className={styles.inputOrgName}>
        <Select
          options={branchOptions}
          className={styles.Select}
          onChange={onChange}
          value={value}
          suffixIcon={<DownCaretIcon />}
        />
      </Col>
    </Row>
  );
});
