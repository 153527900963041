import { Button, Input } from 'antd';
import { EditIcon, EyeInvisibleIcon, EyeVisibleIcon } from 'src/common/Icons/icons';
import { PracticeService } from 'src/common/types';
import { ameniniesActions } from 'src/app/services/redux/AmenitiesSlice';
import { useAppDispatch } from 'src/app/hooks';
import React, { memo } from 'react';
import styles from './ViewAmenityRow.module.scss';

type PropsType = {
  service: PracticeService;
};

export const ViewAmenityRow: React.FC<PropsType> = memo(({ service }) => {
  const dispatch = useAppDispatch();

  const editClickHandle = () => {
    dispatch(ameniniesActions.setEditServiceId(service.serviceId));
  };

  return (
    <div className={styles.container}>
      <Button className={styles.editButton} onClick={editClickHandle} icon={<EditIcon />}></Button>

      <div className={styles.text}>{service.serviceNameForPatients}</div>

      <div className={styles.text}>{service.cost.toLocaleString('ru')}</div>

      <div className={styles.radio}>
        <Input type="radio" disabled checked={service.isOnSite} />
      </div>

      <div className={styles.radio}>
        <Input type="radio" disabled checked={service.isTmk} />
      </div>

      <div className={styles.radio}>
        <Input type="radio" disabled checked={service.isAtHome} />
      </div>

      <div>
        <Button
          className={styles.widget}
          icon={service.showInWidget ? <EyeVisibleIcon /> : <EyeInvisibleIcon />}
          disabled
        ></Button>
      </div>
    </div>
  );
});
