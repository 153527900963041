import {PracticeDoctorDocument, PracticeDoctorEducation} from "../../../../common/types";

export enum PageMode {
  Editing,
  Creating,
}

export type Props = {
  pageMode: PageMode;
  initialFieldsState?: {
    snils?: string;
    surname?: string;
    firstname?: string;
    middlename?: string;
    showInMilaServices?: boolean;
    photoId?: string;
    documents?: PracticeDoctorDocument[];
    education?: PracticeDoctorEducation[];
  };
};

export type AcademicDegrees = {
  label: string;
  value: string;
}[];

export type ExtendedFile = {
  fileId: string;
  file: File;
};

export type GetOrganizationInfoBySpecialityPositionId = {
  positionName: string;
  specialityName: string;
  specialityFerId: string;
};
