import { ColumnsType } from 'antd/es/table';
import { EditableCell } from '../EditableCell/EditableCell';
import { Pagination, Table, Typography } from 'antd';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';

interface CustomTableProps<T> {
  //columns: EditableColumnsType<T>;
  columns: ColumnsType<T>;
  dataSource?: T[];
  total?: number;
  pageNumber?: number;
  onPageChange?: (page: number, pageSize: number) => void;
  onRowClick?: (record: T) => void;
  className?: string;
  totalString?: string;
}

export const CustomTable = <T extends Object>({
  columns,
  dataSource,
  total,
  totalString = 'Всего записей',
  pageNumber,
  onPageChange,
  onRowClick,
  className,
}: CustomTableProps<T>) => {
  const showTotal = useCallback(
    () => (
      <Typography>
        {totalString}: {total}
      </Typography>
    ),
    [total, totalString],
  );
  return (
    <>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        className={`${styles.CustomTable} ${className}`}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 'max-content' }}
        onRow={(record) => ({
          onClick: () => {
            if (onRowClick) {
              onRowClick(record);
            }
          },
        })}
      />
      <Pagination
        className={styles.CustomTablePagination}
        total={total}
        showSizeChanger
        showTotal={showTotal}
        onChange={onPageChange}
        current={pageNumber}
      />
    </>
  );
};
