import React from 'react';
import styles from './styles.module.scss';

export const SupportBlock = () => (
  <div className={styles.container}>
    Если вы считаете, что данные содержат ошибку, пожалуйста, обратитесь в
    <a href="https://support.n3health.ru/" className={styles.container__link} target="_blank" rel="noreferrer">
      {' '}
      техподдержку
    </a>
    .
  </div>
);
