import { Col, Form, Input, Row, Typography } from 'antd';
import { Props } from './SnilsCheckBlock.types';
import { useSnilsCheckBlock } from './SnilsCheckBlock.hooks';
import FloatLabelInput from '../../../../../../common/FloatLabelInput/FloatLabelInput';
import InputMask from 'react-input-mask';
import React from 'react';
import { ReactComponent as Warn } from '../../../../../../assets/Warn.svg';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export const SnilsCheckBlock = (props: Props) => {
  const { doctorSnils } = props;
  const { handleSnilsOnChange, form } = useSnilsCheckBlock(props);

  return (
    <div className={styles.container}>
      <div className={styles.header}>Проверка СНИЛС врача</div>
      <div className={styles.description}>
        Врач уже может быть в нашей базе, в этом случае вы сможете добавить его в свою клинику. Введите СНИЛС для
        проверки
      </div>

      <Form form={form} layout="vertical">
        <Row>
          <Col>
            <FloatLabelInput label="СНИЛС врача или специалиста" value={doctorSnils}>
              <Form.Item
                name="snils"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите СНИЛС в формате XXX-XXX-XXX-XX',
                    pattern: /\d{3}-\d{3}-\d{3}-\d{2}/,
                  },
                ]}
              >
                <InputMask
                  mask="999-999-999-99"
                  value={doctorSnils}
                  name="snils"
                  onChange={handleSnilsOnChange}
                  className={styles.snils}
                >
                  <Input name="snils" />
                </InputMask>
              </Form.Item>
            </FloatLabelInput>
          </Col>
        </Row>
      </Form>
        <Typography className={styles.WarnNote}>
            <Warn />
            Сначала добавьте услуги, а потом врачей
        </Typography>

        <Typography className={styles.WarnText}>
            Проверьте, что у вас заполнен раздел <Link className={styles.WarnLink} to="/company/amenities">Услуги</Link>. 
            При добавлении врача вы сможете привязать ему только те специализации, 
            на которые заведена хотя бы одна услуга.
        </Typography>
    </div>
  );
};
